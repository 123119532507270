import { API, graphqlOperation } from "aws-amplify";
import { GetPracticeQuestions, GetPracticeQuestion, CreatePracticeQuestion, UpdatePracticeQuestion, DeletePracticeQuestion } from "./graphql/practice-questions";
import {GetContent} from "./graphql/contents";

export const fetchPracticeQuestions = async (contentId) => {
  const response = await API.graphql({
    query: GetPracticeQuestions,
    variables: {contentId},
    authMode: "API_KEY",
  });

  return response.data.getPracticeQuestions;
};

export const fetchPracticeQuestion = async (id) => {
  const response = await API.graphql(graphqlOperation(
    GetPracticeQuestion,
    { id },
    "AMAZON_COGNITO_USER_POOLS"
  ));

  return response.data.getPracticeQuestion;
};

export const createPracticeQuestion = async ({id, contentId, title, svgUrl, characters, chats, answers}) => {
  const response = await API.graphql(graphqlOperation(
    CreatePracticeQuestion,
    { input: { id, contentId, title, svgUrl , characters, chats, answers} },
    "AMAZON_COGNITO_USER_POOLS"
  ));

  return response.data.createPracticeQuestion;
};

export const updatePracticeQuestion = async ({id, title, svgUrl, characters, chats, answers}) => {
  const response = await API.graphql(graphqlOperation(
    UpdatePracticeQuestion,
    { input: { id, title, svgUrl , characters, chats, answers} },
    "AMAZON_COGNITO_USER_POOLS"
  ));

  return response.data.updatePracticeQuestion;
};

export const deletePracticeQuestion = async (id) => {
  const response = await API.graphql(graphqlOperation(
    DeletePracticeQuestion,
    { id },
    "AMAZON_COGNITO_USER_POOLS"
  ));

  return response.data.deletePracticeQuestion;
};
