export const GetTestQuestions = `
  query GetTestQuestions($contentId: ID!, $first: Int, $after: String) {
    getTestQuestions(contentId: $contentId, first: $first, after: $after) {
      items {
        id
        title
        subtitle
        svgUrl
        characters
        chats
        answers
        createdAt
      }
      nextToken
    }
  }
`;

export const GetTestQuestion = `
  query GetTestQuestion($id: ID!) {
    getTestQuestion(id: $id) {
      id
      contentId
      title
        subtitle
      svgUrl
      characters
      chats
      answers
      createdAt
    }
  }
`;

export const CreateTestQuestion = `
  mutation CreateTestQuestion($input: TestQuestionCreateInput!) {
    createTestQuestion(input: $input) {
      id
    }
  }
`;

export const UpdateTestQuestion = `
  mutation UpdateTestQuestion($input: TestQuestionUpdateInput!) {
    updateTestQuestion(input: $input) {
      id
    }
  }
`;

export const DeleteTestQuestion = `
  mutation DeleteTestQuestion($id: ID!) {
    deleteTestQuestion(id: $id) {
      id
    }
  }
`;
