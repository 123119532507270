import React, { Component } from "react";
import { connect } from "react-redux";
import { Auth } from 'aws-amplify';
import { Route, Switch, Redirect } from "react-router-dom";
import {Container, Nav, Navbar, NavDropdown} from "react-bootstrap";
import {LinkContainer} from "react-router-bootstrap";

import ManagersPage from "./Managers/index";
import ManagerPage from "./Managers/Manager/index";

import UsersPage from "./Users/index";
import UserPage from "./Users/User/index";

import CoursesPage from "./Courses/index";
import CoursePage from "./Courses/Course/index";
import PracticeQuestionPage from "./Courses/Course/PracticeQuestion/index";
import TestQuestionPage from "./Courses/Course/TestQuestion/index";

import ComicsLifePage from "./ComicsLife/index";
import ComicLifePage from "./ComicsLife/ComicLife/index";

import ComicsSpiritPage from "./ComicsSpirit/index";
import ComicSpiritPage from "./ComicsSpirit/ComicSpirit/index";

import NotificationsPage from "./Notifications/index";
import NotificationPage from "./Notifications/Notification/index";

import SubtleTestPage from "./SubtleTest/index";

import NursingPage from "./Nursing/index";
import NursingFreePage from "./NursingFree/index";

import SimpleFlowTestListPage from "./SimpleFlowTest/index";
import SimpleFlowTestPage from "./SimpleFlowTest/Item/index";

import TestCSVPage from "./TestCSV/index";

import TestAaPage from "./TestAa/index";
import TestBbsPage from "./TestBbs/index";

class AdminPage extends Component {

  constructor(props, context) {
    super(props, context);

    this.state = {
      isLogout: false,
    };
  }

  async onLogout() {

    try {
      await Auth.signOut();
      this.setState({ isLogout: true });
    } catch (e) {
      console.error(e);
    }

  }

  render() {
    let { match, location } = this.props;

    return (
      <>
        <Navbar bg="dark" variant="dark">
          <Navbar.Brand>運営管理画面</Navbar.Brand>
          <Nav className="mr-auto">
            <LinkContainer to={`/admin`} activeClassName={location.pathname === "/admin" ? "active" : ""}>
              <Nav.Link>代理店管理</Nav.Link>
            </LinkContainer>
            <LinkContainer to={`/admin/users`} activeClassName={location.pathname === "/admin/users" ? "active" : ""}>
              <Nav.Link>ユーザー管理</Nav.Link>
            </LinkContainer>
            <LinkContainer to={`/admin/courses`} activeClassName={location.pathname === "/admin/courses" ? "active" : ""}>
              <Nav.Link>コース管理</Nav.Link>
            </LinkContainer>
            <LinkContainer to={`/admin/comics/life`} activeClassName={location.pathname === "/admin/comics/life" ? "active" : ""}>
              <Nav.Link>漫画Life管理</Nav.Link>
            </LinkContainer>
            <LinkContainer to={`/admin/comics/spirit`} activeClassName={location.pathname === "/admin/comics/spirit" ? "active" : ""}>
              <Nav.Link>漫画Spirit管理</Nav.Link>
            </LinkContainer>
            <LinkContainer to={`/admin/subtle`} activeClassName={location.pathname === "/admin/subtle" ? "active" : ""}>
              <Nav.Link>機微力テスト管理</Nav.Link>
            </LinkContainer>
            <LinkContainer to={`/admin/testaa`} activeClassName={location.pathname === "/admin/testaa" ? "active" : ""}>
              <Nav.Link>TestAa</Nav.Link>
            </LinkContainer>
            <LinkContainer to={`/admin/testbbs`} activeClassName={location.pathname === "/admin/testbbs" ? "active" : ""}>
              <Nav.Link>TestBbs</Nav.Link>
            </LinkContainer>
            <LinkContainer to={`/admin/nursing`} activeClassName={location.pathname === "/admin/nursing" ? "active" : ""}>
              <Nav.Link>きづっきー診断(有料)管理</Nav.Link>
            </LinkContainer>
            <LinkContainer to={`/admin/nursing-free`} activeClassName={location.pathname === "/admin/nursing-free" ? "active" : ""}>
              <Nav.Link>きづっきー診断(無料)管理</Nav.Link>
            </LinkContainer>
            <LinkContainer to={`/admin/test/simple-flow`} activeClassName={location.pathname === "/admin/test/simple-flow" ? "active" : ""}>
              <Nav.Link>公式練習問題集管理</Nav.Link>
            </LinkContainer>
            <LinkContainer to={`/admin/notifications`} activeClassName={location.pathname === "/admin/notifications" ? "active" : ""}>
              <Nav.Link>お知らせ管理</Nav.Link>
            </LinkContainer>
          </Nav>
          <Nav>
            <NavDropdown title="アカウント">
	      <NavDropdown.Item href="/admin/test-csv">検定CSVアップロード</NavDropdown.Item>
              <NavDropdown.Item onClick={this.onLogout.bind(this)}>ログアウト</NavDropdown.Item>
            </NavDropdown>
          </Nav>
        </Navbar>
        <Container>
          <Switch>
            <Route path={`${match.url}`} exact component={ManagersPage} />
            <Route path={`${match.url}/:userId/user`} exact component={ManagerPage} />

            <Route path={`${match.url}/users`} exact component={UsersPage} />
            <Route path={`${match.url}/users/:userId`} exact component={UserPage} />
            <Route path={`${match.url}/users/:userId/course-test-results/:testId`} exact component={UserPage} />

            <Route path={`${match.url}/courses`} exact component={CoursesPage} />
            <Route path={`${match.url}/courses/:courseId`} exact component={CoursePage} />
            <Route path={`${match.url}/courses/:courseId/practice-questions/:practiceQuestionId`} exact component={PracticeQuestionPage} />
            <Route path={`${match.url}/courses/:courseId/test-questions/:testQuestionId`} exact component={TestQuestionPage} />

            <Route path={`${match.url}/comics/life`} exact component={ComicsLifePage} />
            <Route path={`${match.url}/comics/life/:comicId`} exact component={ComicLifePage} />

            <Route path={`${match.url}/comics/spirit`} exact component={ComicsSpiritPage} />
            <Route path={`${match.url}/comics/spirit/:comicId`} exact component={ComicSpiritPage} />

            <Route path={`${match.url}/test/simple-flow`} exact component={SimpleFlowTestListPage} />
            <Route path={`${match.url}/test/simple-flow/:contentId`} exact component={SimpleFlowTestPage} />

            <Route path={`${match.url}/notifications`} exact component={NotificationsPage} />
            <Route path={`${match.url}/notifications/:notificationId`} exact component={NotificationPage} />

            <Route path={`${match.url}/subtle`} exact component={SubtleTestPage} />
            <Route path={`${match.url}/testaa`} exact component={TestAaPage} />
            <Route path={`${match.url}/testbbs`} exact component={TestBbsPage} />

            <Route path={`${match.url}/nursing`} exact component={NursingPage} />
            <Route path={`${match.url}/nursing-free`} exact component={NursingFreePage} />
	    <Route path={`${match.url}/test-csv`} exact component={TestCSVPage} />

          </Switch>
        </Container>
        {this.state.isLogout && (<Redirect to="/login"/>)}
      </>
    )};
}

function mapStateToProps(state) {
  return {};
}

export default connect(mapStateToProps)(AdminPage);
