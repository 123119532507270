import React, { Component } from "react";
import { connect } from "react-redux";
import {Alert, Button, ButtonToolbar, Form, Card, Col, InputGroup } from "react-bootstrap";
import * as generator from "generate-password"
import * as usersService  from "../../../../services/users";
import { SmallBgLoading } from "../../../../components/commons/SmallBgLoading"

class ManagerPage extends Component {

  constructor(props, context) {
    super(props, context);

    this.state = {
      touched: false,
      name: {
        value: "",
        touched: false
      },
      department: {
        value: "",
        touched: false
      },
      manager: {
        value: "",
        touched: false
      },
      childMaxCount: {
        value: "1",
        touched: false
      },
      permissionCourse: {
        value: "",
        touched: false
      },
      permissionSubtleTest: {
        value: "",
        touched: false
      },
      permissionComic: {
        value: "",
        touched: false
      },
      permissionNursing: {
        value: "",
        touched: false
      },
      permissionMovie: {
        value: "",
        touched: false
      },
      email: {
        value: "",
        touched: false
      },
      password: {
        value: "",
        touched: false
      },
      error: null,
      loading: false,
      data: null,
      user: null
    };
  }

  async componentWillMount() {
    let userId = this.props.match.params.userId;
    if (userId !== "new") {
      this.setState({loading: true});
      try {
        let user = await usersService.fetchUser(userId);
        let permissions = user.permissions ? JSON.parse(user.permissions) : [];
        this.setState({
          loading: false,
          user,
          email: { value: user.email, touched: false},
          name: { value: user.name ? user.name : "", touched: false},
          department: { value: user.department ? user.department : "", touched: false},
          manager: { value: user.manager ? user.manager : "", touched: false},
          childMaxCount: { value: user.childMaxCount ? user.childMaxCount.toString() : "", touched: false},
          permissionCourse: { value: permissions.indexOf("COURSE") !== -1 ? "1" : "0", touched: false },
          permissionComic: { value: permissions.indexOf("COMIC") !== -1 ? "1" : "0", touched: false },
          permissionSubtleTest: { value: permissions.indexOf("SUBTLE") !== -1 ? "1" : "0", touched: false },
          permissionNursing: { value: permissions.indexOf("NURSING") !== -1 ? "1" : "0", touched: false },
          permissionMovie: { value: permissions.indexOf("MOVIE") !== -1 ? "1" : "0", touched: false },
        });
      } catch (error) {
        this.onError(error);
      }
    }
  }

  onCancelClick = () => {
    this.props.history.push("/admin/");
  };

  onDeleteClick = async () => {

    this.setState({error: null, loading: true});

    try {
      await usersService.deleteUser(this.state.user.id);
      this.setState({loading: false});
      this.props.history.push("/admin/");
    } catch (error) {
      this.onError(error);
    }

  };

  onAutoPasswordClick = () => {
    let password = generator.generate({
      length: 6,
      uppercase: false
    });

    this.setState({password: { value: password, touched: true }});
  };

  onDisable = async () => {
    this.setState({error: null, loading: true});

    try {
      await usersService.disableUser(this.state.user.id);
      let user = await usersService.fetchUser(this.state.user.id);
      this.setState({loading: false, user});
    } catch (error) {
      this.onError(error);
    }
  };

  onEnable = async () => {
    this.setState({error: null, loading: true});

    try {
      let test = await usersService.enableUser(this.state.user.id);
      let user = await usersService.fetchUser(this.state.user.id);
      this.setState({loading: false, user});
    } catch (error) {
      this.onError(error);
    }
  };

  onDoneClick = () => {
    document
      .getElementById('form')
      .dispatchEvent(new Event('submit', { cancelable: true }))
  };

  onSubmit = async (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    this.setState({error: null});
    if (form.checkValidity() === false) {
      this.setState({touched: true});
      return;
    }

    let permissions = [];
    if (this.state.permissionCourse.value === "1") {
      permissions.push("COURSE");
    }
    if (this.state.permissionComic.value === "1") {
      permissions.push("COMIC");
    }

    if (this.state.permissionSubtleTest.value === "1") {
      permissions.push("SUBTLE");
    }

    if (this.state.permissionNursing.value === "1") {
      permissions.push("NURSING");
    }

    if (this.state.permissionMovie.value === "1") {
      permissions.push("MOVIE");
    }

    if (permissions.length === 0) {
      this.setState({touched: true, error: new Error("権限を設定してください")});
      return
    }

    this.setState({loading: true});

    try {
      if (this.state.user) {
        await usersService.updateUserManager({
          id: this.state.user.id,
          password: this.state.password.value > 0 ? this.state.password.value : null,
          name: this.state.name.value.length > 0 ? this.state.name.value : null,
          department: this.state.department.value.length > 0 ? this.state.department.value : null,
          manager: this.state.manager.value.length > 0 ? this.state.manager.value : null,
          childMaxCount: this.state.childMaxCount.value.length > 0 ? this.state.childMaxCount.value : null,
          permissions: JSON.stringify(permissions)
        });
      } else {
        await usersService.createUserManager({
          email: this.state.email.value,
          password: this.state.password.value,
          name: this.state.name.value.length > 0 ? this.state.name.value : null,
          department: this.state.department.value.length > 0 ? this.state.department.value : null,
          manager: this.state.manager.value.length > 0 ? this.state.manager.value : null,
          childMaxCount: this.state.childMaxCount.value.length > 0 ? this.state.childMaxCount.value : null,
          permissions: JSON.stringify(permissions)
        });
      }

      this.setState({loading: false});
      this.props.history.push("/admin/");
    } catch (error) {
      this.onError(error);
    }

  };

  onError = (error) => {
    if (error.errors) {
      console.error(error.errors[0]);
      this.setState({ loading: false, error: error.errors[0] });
    } else {
      console.error(error);
      this.setState({ loading: false, error });
    }
  };

  formFieldRequiredValidation = (fieldName) => {
    if (!this.state.touched && !this.state[fieldName].touched) {
      return true;
    }

    return this.state[fieldName].value && this.state[fieldName].value.length > 0;
  };

  render() {
    let isNew = this.props.match.params.userId === "new";

    return (
      <>
        <SmallBgLoading isLoading={this.state.loading}>
          {this.state.error && (
            <Alert variant="danger" className="mt-4">{this.state.error.message}</Alert>
          )}


          <ButtonToolbar className="mt-4 justify-content-between align-items-center">
            <h1 className="m-0">代理店{isNew ? "制作" : "編集"}</h1>
            <div>
              <Button variant="primary"
                      type="submit"
                      className="mr-2"
                      onClick={this.onDoneClick}>保存</Button>
              <Button variant="outline-secondary"
                      onClick={this.onCancelClick}>キャンセル</Button>
            </div>
          </ButtonToolbar>

          <Form id="form" className="mt-4" onSubmit={this.onSubmit}>
            <Form.Row>
              <Form.Group as={Col}>
                <Form.Label>会社名</Form.Label>
                <Form.Control required={isNew}
                              type="text"
                              value={this.state.name.value}
                              onChange={(e) => this.setState({name: {value: e.target.value, touched: true}})}
                              isInvalid={isNew && !this.formFieldRequiredValidation("name")} />
                <Form.Control.Feedback type="invalid">
                  会社名を入力してください
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} md="2">
                <Form.Label>アカウント数</Form.Label>
                <Form.Control type="number"
                              value={this.state.childMaxCount.value}
                              onChange={(e) => this.setState({childMaxCount: {value: e.target.value, touched: true}})} />
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col}>
                <Form.Label>部署名</Form.Label>
                <Form.Control type="text"
                              value={this.state.department.value}
                              onChange={(e) => this.setState({department: {value: e.target.value, touched: true}})} />
              </Form.Group>
              <Form.Group as={Col}>
                <Form.Label>担当者名</Form.Label>
                <Form.Control type="text"
                              value={this.state.manager.value}
                              onChange={(e) => this.setState({manager: {value: e.target.value, touched: true}})} />
              </Form.Group>
            </Form.Row>
            <Form.Group controlId="exampleForm.ControlSelect1">
              <Form.Label>権限</Form.Label>
              <Form.Check
                id="course"
                type="checkbox"
                label="コース"
                checked={this.state.permissionCourse.value === "1"}
                onChange={(e) => this.setState({permissionCourse: {value: e.target.checked ? "1": "0", touched: true}})}
              />
              <Form.Check
                id="sublte-test"
                type="checkbox"
                label="機微力テスト"
                checked={this.state.permissionSubtleTest.value === "1"}
                onChange={(e) => this.setState({permissionSubtleTest: {value: e.target.checked ? "1": "0", touched: true}})}
              />
              <Form.Check
                id="comic"
                type="checkbox"
                label="漫画"
                checked={this.state.permissionComic.value === "1"}
                onChange={(e) => this.setState({permissionComic: {value: e.target.checked ? "1": "0", touched: true}})}
              />
              <Form.Check
                id="nursing"
                type="checkbox"
                label="きづっきー診断"
                checked={this.state.permissionNursing.value === "1"}
                onChange={(e) => this.setState({permissionNursing: {value: e.target.checked ? "1": "0", touched: true}})}
              />
              <Form.Check
                id="movie"
                type="checkbox"
                label="動画"
                checked={this.state.permissionMovie.value === "1"}
                onChange={(e) => this.setState({permissionMovie: {value: e.target.checked ? "1": "0", touched: true}})}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>メールアドレス</Form.Label>
                <Form.Control required
                            type="email"
                            disabled={!isNew}
                            value={this.state.email.value}
                            onChange={(e) => this.setState({email: {value: e.target.value, touched: true}})}
                            isInvalid={!this.formFieldRequiredValidation("email")} />
                <Form.Control.Feedback type="invalid">
                  メールアドレスを入力してください
                </Form.Control.Feedback>
            </Form.Group>
            <Form.Group>
              <Form.Label>{isNew ? "パスワード" : "新規パスワード"}</Form.Label>
              <InputGroup>
                <Form.Control required={isNew}
                              type="text"
                              value={this.state.password.value}
                              onChange={(e) => this.setState({password: {value: e.target.value, touched: true}})}
                              isInvalid={isNew && !this.formFieldRequiredValidation("password")} />
                <Form.Control.Feedback type="invalid">
                  {isNew ? "パスワード" : "新規パスワード"}を入力してください
                </Form.Control.Feedback>

                <InputGroup.Append>
                  <Button variant="outline-primary" onClick={this.onAutoPasswordClick}>自動制作</Button>
                </InputGroup.Append>
              </InputGroup>
              <p className="text-secondary mt-2">6桁以上の英数字を入力してください</p>
            </Form.Group>

          </Form>
        </SmallBgLoading>
        {!isNew && (
          <>
            {this.state.user && (
              <Card className="mt-4 mb-4">
                <Card.Header variant="danger" text="white">アカウント状態</Card.Header>
                <Card.Body>
                  {!this.state.user.disabled && <Button variant="danger" className="mt-2" onClick={this.onDisable}>アカウントを停止する</Button>}
                  {this.state.user.disabled && <Button variant="success" className="mt-2" onClick={this.onEnable}>アカウント停止を解除する</Button>}
                </Card.Body>
              </Card>
            )}

            <Card className="mt-4 mb-4">
              <Card.Header variant="danger" text="white">注意アクション</Card.Header>
              <Card.Body>
                <Button variant="danger" className="mt-2" onClick={this.onDeleteClick}>代理店を削除する</Button>
              </Card.Body>
            </Card>
          </>
        )}
      </>
    )
  };
}

function mapStateToProps(state) {
  return {};
}

export default connect(mapStateToProps)(ManagerPage);
