export const GetNursingResults = `
  query {
    getNursingResults {
        items {
            id
            results
            createdAt
        }
    }
  }
`;

export const GetAllNursingResults = `
  query GetAllNursingResults($userId: ID){
    getAllNursingResults(userId: $userId) {
      items {
        id
        userId
        results
        createdAt
        user {
            id
            parentId
            name
        }
      }
    }
  }
`;

export const GetAdminNursingResults = `
    query GetAdminNursingResults{
        getAdminNursingResults {
            items {
                id
                userId
                results
                createdAt
                user {
                    id
                    name
                    parentId
                }
            }
        }
    }
`;

export const GetNursingResult = `
  query GetNursingResult($id: ID!) {
    getNursingResult(id: $id) {
      id
      results
    }
  }
`;

export const CreateNursingResult = `
  mutation CreateNursingResult($input: NursingResultInput!) {
    createNursingResult(input: $input) {
      id
    }
  }
`;
