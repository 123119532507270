import React from "react";
import { connect } from "react-redux";
import { ReactComponent as KiiibinBackSvg } from "../../../assets/svg/kiiibin_back.svg";
import { ReactComponent as KiiibinReluxSvg } from "../../../assets/svg/kiiibin_relux.svg";
import { ReactComponent as KiiibinSadSvg } from "../../../assets/svg/kiiibin_sad.svg";
import { ReactComponent as KiiibinSitSvg } from "../../../assets/svg/kiiibin_sit.svg";
import { ReactComponent as KiiibinSmileSvg } from "../../../assets/svg/kiiibin_smile.svg";
import { ReactComponent as KiiibinReachSvg } from "../../../assets/svg/kiiibin_teach.svg";
import * as langService from '../../../services/lang';
import "./index.scss";

class QuestionChatItem extends React.Component {

  formatByLanguage = (value) => {
    if (value[this.props.currentLang]) {
      return value[this.props.currentLang];
    } else {
      return null
    }
  };

  render() {

    let { characterId, characters, answer } = this.props;

    let character = null;
    if (characters) {
      character = characters.filter((item) => item.id === characterId);
    }

    return (
      <div className="question-chat-item">
        {character && (
          <>
            <div>
              <img
                src={character[0].image.value}
                style={{
                  minWidth: 50,
                  minHeight: 50,
                  maxWidth: 50,
                  maxHeight: 50,
                }}
                alt="イラスト"
              />
              <div className="question-chat-item__name">{this.formatByLanguage(character[0].name)}</div>
            </div>
            <div className="question-chat-item__speech">
              {this.props.reading && this.formatByLanguage(this.props.reading) && (<div className="reading">{this.formatByLanguage(this.props.reading)}</div>)}
              {this.props.text && this.formatByLanguage(this.props.text) && (<div>{this.formatByLanguage(this.props.text)}</div>)}
            </div>
          </>
        )}
        {answer && (
          <>
            <div>
              {answer.descriptionKibiAvatar.value === "kiiibin_back" && <KiiibinBackSvg width={50} height={50} />}
              {answer.descriptionKibiAvatar.value === "kiiibin_relux" && <KiiibinReluxSvg width={50} height={50} />}
              {answer.descriptionKibiAvatar.value === "kiiibin_sad" && <KiiibinSadSvg width={50} height={50} />}
              {answer.descriptionKibiAvatar.value === "kiiibin_sit" && <KiiibinSitSvg width={50} height={50} />}
              {answer.descriptionKibiAvatar.value === "kiiibin_smile" && <KiiibinSmileSvg width={50} height={50} />}
              {answer.descriptionKibiAvatar.value === "kiiibin_teach" && <KiiibinReachSvg width={50} height={50} />}
              <div className="question-chat-item__name">{langService.translate("kiiibin", this.props.currentLang)}</div>
            </div>
            <div className="question-chat-item__speech kibin">
              {answer.descriptionKibi && (<div dangerouslySetInnerHTML={{ __html: this.formatByLanguage(answer.descriptionKibi).replace(/\r?\n/g, '<br />').replace(/\[([^,]*),([ぁ-ん]*)\]/g, '<ruby><rb>$1</rb><rt>$2</rt></ruby>') }} ></div>)}
            </div>
          </>
        )}
      </div>
    );
  };
}

function mapStateToProps(state) {
  return {
    currentLang: state.root.currentLang
  };
}

export default connect(mapStateToProps)(QuestionChatItem);
