import React, { Component } from "react";
import { Modal, Button } from "react-bootstrap";
import { connect } from "react-redux";
import * as langService from "../../services/lang";
import "./AnswerReactionModals.scss";

class AnswerReactionModals extends Component {

  formatByLanguage = (value) => {
    let array = typeof value === "string" ? JSON.parse(value) : value;
    if (array[this.props.currentLang]) {
      return array[this.props.currentLang];
    } else {
      return "Undefined"; //array[commonService.mainLanguage];
    }
  };

  render() {
    let { answer } = this.props;
    let avatarSide = 200;
    let message = "";
    let reaction = "";
    if (answer && answer.message.value === "WELL_DONE") {
      message = langService.translate("wellDone", this.props.currentLang);
      reaction = "◎";
    } else if (answer && answer.message.value === "ALMOST") {
      message = langService.translate("almost", this.props.currentLang);
      reaction = "△";
    } else if (answer && answer.message.value === "TRY_AGAIN") {
      message = langService.translate("tryAgain", this.props.currentLang);
      reaction = "×";
    }

    return (
      <Modal show={this.props.show} onHide={this.props.onClose} backdrop="static">
        {answer && (
          <Modal.Body>
            <div className="d-flex flex-row justify-content-start align-items-center">
              <div style={{ width: avatarSide, height: avatarSide, marginBottom: "8px", }}>
                <img
                  src={answer.descriptionImage.value}
                  alt="イラスト"
                />
              </div>
              <div className="d-flex flex-column flex-grow-1 align-items-center">
                <div style={{ fontSize: "24px" }} dangerouslySetInnerHTML={{ __html: message.replace(/\r?\n/g, '<br />').replace(/\[([^,]*),([ぁ-ん]*)\]/g, '<ruby><rb>$1</rb><rt>$2</rt></ruby>') }} ></div>
                <div className="text-danger mt-2" style={{ fontSize: "80px", fontWeight: "700", lineHeight: "60px" }}>
                  {reaction}
                </div>
              </div>
            </div>
            <div className="answer-reaction-japanese-filling-bubble" dangerouslySetInnerHTML={{ __html: this.formatByLanguage(answer.descriptionJapaneseFeeling).replace(/\r?\n/g, '<br />').replace(/\[([^,]*),([ぁ-ん]*)\]/g, '<ruby><rb>$1</rb><rt>$2</rt></ruby>') }} ></div>
          </Modal.Body>
        )}

        <Modal.Footer>
          <Button variant="flat" onClick={this.props.onOk}>
            OK
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

AnswerReactionModals.defaultProps = {
  show: false,
  onClose: () => { }
};


function mapStateToProps(state) {
  return {
    currentLang: state.root.currentLang
  };
}

export default connect(mapStateToProps)(AnswerReactionModals);

