import React, { Component } from "react";
import { connect } from "react-redux";
import {Alert, Button, ButtonToolbar, Form, Card } from "react-bootstrap";
import * as usersService  from "../../../../services/users";
import * as notificationsService  from "../../../../services/notifications";
import uuid from "uuid";
import { SmallBgLoading } from "../../../../components/commons/SmallBgLoading"

class NotificationPage extends Component {

  constructor(props, context) {
    super(props, context);

    this.state = {
      touched: false,
      type: {
        value: "GENERAL",
        touched: false
      },
      avatar: {
        value: "",
        touched: false
      },
      title: {
        value: "",
        touched: false
      },
      description: {
        value: "",
        touched: false
      },
      user: {
        value: "all",
        touched: false
      },
      managers: [],
      users: [],
      error: null,
      loading: false,
      data: null
    };
  }

  async componentWillMount() {
    let response = await usersService.fetchUsers();
    let managers = response.items.filter(item => item.userGroup === "MANAGER");
    let users = response.items.filter(item => item.userGroup === "USER");

    let state = {
      managers,
      users
    };

    let notificationId = this.props.match.params.notificationId;
    if (notificationId !== "new") {
      this.setState({loading: true});
      try {
        let notification = await notificationsService.fetchNotification(notificationId);
        state.data = notification;
        state.type = { value: notification.type, touched: false};

        if (notification.type === 'GENERAL') {
          state.title = { value: notification.title, touched: false};
          state.description = { value: notification.description, touched: false};
          if (notification.userId) {
            state.user = { value: notification.userId, touched: false};
          } else if (notification.userGroup) {
            state.user = { value: notification.userGroup, touched: false};
          } else {
            state.user = { value: "all", touched: false};
          }
        } else {
          state.avatar = { value: notification.avatar, touched: false};
          state.description = { value: notification.description, touched: false};
          if (notification.userId) {
            state.user = { value: notification.userId, touched: false};
          } else if (notification.userGroup) {
            state.user = { value: notification.userGroup, touched: false};
          } else {
            state.user = { value: "all", touched: false};
          }
        }
        state.loading = false;
      } catch (error) {
        state.loading = false;
        this.onError(error);
      }
    }

    this.setState(state);

  }

  onCancelClick = () => {
    this.props.history.push("/admin/notifications");
  };

  onDeleteClick = async () => {

    this.setState({error: null, loading: true});

    try {
      await notificationsService.deleteNotification(this.state.data.id);
      this.setState({loading: false});
      this.props.history.push("/admin/notifications");
    } catch (error) {
      this.onError(error);
    }

  };

  onDoneClick = () => {
    document
      .getElementById('form')
      .dispatchEvent(new Event('submit', { cancelable: true }))
  };

  onSubmit = async (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    this.setState({error: null});
    if (form.checkValidity() === false) {
      this.setState({touched: true});
      return;
    }

    this.setState({loading: true});

    try {
      if (this.state.data) {
        let input = {
          id: this.state.data.id,
        };

        if (this.state.type.value === 'GENERAL') {
          input.title = this.state.title.value;
          input.description = this.state.description.value;
          if (this.state.user.value === "all") {
            // Do nothing
          } else if (["MANAGER", "USER"].indexOf(this.state.user.value) !== -1) {
            input.userGroup = this.state.user.value.toUpperCase();
          } else {
            input.userId = this.state.user.value;
          }
        } else {
          input.avatar = this.state.avatar.value;
          input.description = this.state.description.value;
          if (this.state.user.value === "all") {
            input.userGroup = 'USER';
          } else if (["MANAGER", "USER"].indexOf(this.state.user.value) !== -1) {
            input.userGroup = this.state.user.value.toUpperCase();
          } else {
            input.userId = this.state.user.value;
          }
        }

        await notificationsService.updateNotification(input);
      } else {
        let input = {
          id: uuid.v4(),
          type: this.state.type.value,
        };

        if (this.state.type.value === 'GENERAL') {
          input.title = this.state.title.value;
          input.description = this.state.description.value;
          if (this.state.user.value === "all") {
            // Do nothing
          } else if (["MANAGER", "USER"].indexOf(this.state.user.value) !== -1) {
            input.userGroup = this.state.user.value.toUpperCase();
          } else {
            input.userId = this.state.user.value;
          }
        } else {
          input.avatar = this.state.avatar.value;
          input.description = this.state.description.value;
          if (this.state.user.value === "all") {
            input.userGroup = 'USER';
          } else if (["MANAGER", "USER"].indexOf(this.state.user.value) !== -1) {
            input.userGroup = this.state.user.value.toUpperCase();
          } else {
            input.userId = this.state.user.value;
          }
        }

        await notificationsService.createNotification(input);
      }

      this.setState({loading: false});
      this.props.history.push("/admin/notifications");
    } catch (error) {
      this.onError(error);
    }

  };

  onError = (error) => {
    if (error.errors) {
      console.error(error.errors[0]);
      this.setState({ loading: false, error: error.errors[0] });
    } else {
      console.error(error);
      this.setState({ loading: false, error });
    }
  };

  formFieldRequiredValidation = (fieldName) => {
    if (!this.state.touched && !this.state[fieldName].touched) {
      return true;
    }

    return this.state[fieldName].value && this.state[fieldName].value.length > 0;
  };

  renderGeneral = () => {
    let isNew = this.props.match.params.notificationId === "new";

    return (
      <>
        <Form.Group>
          <Form.Label>タイトル</Form.Label>
          <Form.Control required={true}
                        type="text"
                        value={this.state.title.value}
                        onChange={(e) => this.setState({title: {value: e.target.value, touched: true}})}
                        isInvalid={isNew && !this.formFieldRequiredValidation("title")} />
          <Form.Control.Feedback type="invalid">
            タイトルを入力してください
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group>
          <Form.Label>内容</Form.Label>
          <Form.Control required={true}
                        as="textarea"
                        rows="5"
                        value={this.state.description.value}
                        onChange={(e) => this.setState({description: {value: e.target.value, touched: true}})}
                        isInvalid={isNew && !this.formFieldRequiredValidation("description")} />
          <Form.Control.Feedback type="invalid">
            内容を入力してください
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group controlId="exampleForm.ControlSelect1">
          <Form.Label>送り先</Form.Label>
          <Form.Control required={true}
                        as="select"
                        value={this.state.user.value}
                        onChange={(e) => this.setState({user: {value: e.target.value, touched: true}})}
                        isInvalid={!this.formFieldRequiredValidation('user')}>
            <optgroup label="権限">
              <option value="all">全て</option>
              <option value="MANAGER">代理店</option>
              <option value="USER">一般</option>
            </optgroup>
            {this.state.managers.length > 0 && (
              <optgroup label="代理店">
                {this.state.managers.map(manager => (
                  <option key={manager.id} value={manager.id}>{manager.name ? manager.name : "未定"} ({manager.email})</option>
                ))}
              </optgroup>
            )}
            {this.state.users.length > 0 && (
              <optgroup label="一般">
                {this.state.users.map(user => (
                  <option key={user.id} value={user.id}>{user.name ? user.name : "未定"} ({user.email})</option>
                ))}
              </optgroup>
            )}
          </Form.Control>
          <Form.Control.Feedback type="invalid">
            送り先を選択してください
          </Form.Control.Feedback>
        </Form.Group>
      </>
    );
  };

  renderKibiin = () => {
    let isNew = this.props.match.params.notificationId === "new";

    return (
      <>
        <Form.Group>
          <Form.Label>アバター</Form.Label>
          <Form.Control required
                        as="select"
                        value={this.state.avatar.value}
                        onChange={(e) => this.setState({avatar: {value: e.target.value, touched: true}})}
                        isInvalid={!this.formFieldRequiredValidation('avatar')}>
            <option label='' value='' className="d-none" />
            <option label="Back" value="kiiibin_back" />
            <option label="Relux" value="kiiibin_relux" />
            <option label="Sad" value="kiiibin_sad" />
            <option label="Sit" value="kiiibin_sit" />
            <option label="Smile" value="kiiibin_smile" />
            <option label="Teach" value="kiiibin_teach" />
          </Form.Control>
          <Form.Control.Feedback type="invalid">
            アバターを選択してください
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group>
          <Form.Label>内容</Form.Label>
          <Form.Control required={true}
                        as="textarea"
                        rows="5"
                        value={this.state.description.value}
                        onChange={(e) => this.setState({description: {value: e.target.value, touched: true}})}
                        isInvalid={isNew && !this.formFieldRequiredValidation("description")} />
          <Form.Control.Feedback type="invalid">
            内容を入力してください
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group controlId="exampleForm.ControlSelect1">
          <Form.Label>送り先</Form.Label>
          <Form.Control required={true}
                        as="select"
                        value={this.state.user.value}
                        onChange={(e) => this.setState({user: {value: e.target.value, touched: true}})}
                        isInvalid={!this.formFieldRequiredValidation('user')}>
            <optgroup label="権限">
              <option value="USER">一般</option>
            </optgroup>
            {this.state.users.length > 0 && (
              <optgroup label="一般">
                {this.state.users.map(user => (
                  <option key={user.id} value={user.id}>{user.name ? user.name : "未定"} ({user.email})</option>
                ))}
              </optgroup>
            )}
          </Form.Control>
          <Form.Control.Feedback type="invalid">
            送り先を選択してください
          </Form.Control.Feedback>
        </Form.Group>
      </>
    );
  };

  render() {
    let isNew = this.props.match.params.notificationId === "new";

    return (
      <>
        <SmallBgLoading isLoading={this.state.loading}>
          {this.state.error && (
            <Alert variant="danger" className="mt-4">{this.state.error.message}</Alert>
          )}


          <ButtonToolbar className="mt-4 justify-content-between align-items-center">
            <h1 className="m-0">お知らせ{isNew ? "制作" : "編集"}</h1>
            <div>
              <Button variant="primary"
                      type="submit"
                      className="mr-2"
                      onClick={this.onDoneClick}>保存</Button>
              <Button variant="outline-secondary"
                      onClick={this.onCancelClick}>キャンセル</Button>
            </div>
          </ButtonToolbar>

          <Form id="form" className="mt-4" onSubmit={this.onSubmit}>
            <Form.Group>
              <Form.Label>お知らせ種類</Form.Label>
              <Form.Control required
                            disabled={!isNew}
                            as="select"
                            value={this.state.type.value}
                            onChange={(e) => this.setState({type: {value: e.target.value, touched: true}})}
              >
                <option label='' value='' className="d-none" />
                <option label="一般" value="GENERAL" />
                <option label="キービン" value="KIBIIN" />
              </Form.Control>
            </Form.Group>
            {this.state.type.value === 'GENERAL' && this.renderGeneral()}
            {this.state.type.value === 'KIBIIN' && this.renderKibiin()}
          </Form>
        </SmallBgLoading>
        {!isNew && (
          <Card className="mt-4 mb-4">
            <Card.Header variant="danger" text="white">注意アクション</Card.Header>
            <Card.Body>
              <Button variant="danger" className="mt-2" onClick={this.onDeleteClick}>お知らせを削除する</Button>
            </Card.Body>
          </Card>
        )}
      </>
    )
  };
}

function mapStateToProps(state) {
  return {};
}

export default connect(mapStateToProps)(NotificationPage);
