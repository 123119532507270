import React, { Component } from "react";
import { connect } from "react-redux";
import Div100vh from "react-div-100vh";
import {Button, Container, Nav, Navbar, NavDropdown} from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faArrowLeft, faArrowRight} from '@fortawesome/free-solid-svg-icons'
import * as contentsService from '../../../services/contents';
import "./index.scss";
import {LinkContainer} from "react-router-bootstrap";
import * as commonService from "../../../services/common";
import * as rootActions from "../../../store/root/actions";
import { Auth } from 'aws-amplify';

class ComicSpiritPage extends Component {

  constructor(props, context) {
    super(props, context);

    this.state = {
      authUser: null,
      isMap: false,
      isComic: false,
      pageIndex: 0,
      data: null,
      loading: false,
    };
  }

  async componentDidMount() {
    const {contentId} = this.props.match.params;
    this.setState({loading: true});
    let authUser = null;
    try {
      authUser = await Auth.currentAuthenticatedUser();
    } catch (e) {
      // do noting
    }
    try {

      let content = await contentsService.fetchContent(contentId);

      if (content) {
        content.comicPages = JSON.parse(content.comicPages);
      }
      let state = {
        loading: false,
        data: content,
        isMap: !!content,
        authUser,
      };

      this.setState(state);

    } catch (e) {
      console.log(e);
    }
  }

  formatByLanguage = (value) => {
    try {
      let array = typeof value === "string" ? JSON.parse(value) : value;
      if (array[this.props.currentLang]) {
        return array[this.props.currentLang];
      } else {
        return "Undefined";
      }
    } catch (e) {
      return null;
    }
  };

  onNext = () => {
    this.setState({isMap: false, isComic: true});
  };

  onNextPage = () => {
    const {data, pageIndex} = this.state;
    if (pageIndex + 1 < data.comicPages.length) {
      this.setState({pageIndex: pageIndex + 1});
    }
  };

  onPreviousPage = () => {
    const {pageIndex} = this.state;
    if (pageIndex - 1 >= 0) {
      this.setState({pageIndex: this.state.pageIndex - 1});
    }
  };

  onNextComic = () => {
    const {comicSpiritIds} = this.props;
    const {contentId} = this.props.match.params;

    const comicIndex = comicSpiritIds.indexOf(contentId);
    const nextComicId = comicSpiritIds[comicIndex + 1];

    this.props.history.push(`/comic-spirit/${nextComicId}`);
  };

  renderHeader = () => {
    return (
      <Navbar bg="white" fixed="top" expand="lg">
        <Navbar.Brand
          style={{ margin: "-10px 0", padding: 0 }}
          className="cursor-hover"
        >
          <LinkContainer to="/">
            <div>kibi-<span style={{color: "#0F80B4"}}>learning</span>.jp</div>
          </LinkContainer>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav>
            <LinkContainer to={`/`} activeClassName="">
              <Nav.Link>マーケットへ</Nav.Link>
            </LinkContainer>
          </Nav>
          <Nav className="ml-auto">
            <NavDropdown
              title={commonService.languages[this.props.currentLang]}
              id="basic-nav-dropdown"
              className="mr-3"
            >
              {commonService.languagesList.map(lang => (
                <NavDropdown.Item
                  key={lang}
                  onClick={() => this.props.dispatch(rootActions.setLang(lang))}
                >
                  {commonService.languages[lang]}
                </NavDropdown.Item>
              ))}
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    );
  };

  renderMap = () => {
    const {data} = this.state;
    return (
      <>
        <Container className="comic-spirit-map">
          <h1 className="comic-spirit-map-header">Introduction</h1>
          <div>
            <h2 className="comic-spirit-map-sub-header">人物相関図</h2>
          </div>
          <div className="comic-spirit-map-content">
            <div
              className="comic-spirit-map-image"
              style={{
                background: `url('${this.formatByLanguage(data.comicIntroductionSvgUrl)}') no-repeat`
              }}
            />
            <div className="comic-spirit-map-introduction">
              <p>{this.formatByLanguage(data.comicIntroduction)}</p>
              <div className="comic-spirit-map-introduction-actions">
                <Button className="ml-2" onClick={this.onNext}>まんがを読む</Button>
              </div>
            </div>
          </div>
        </Container>
      </>
    );
  };

  renderComic = () => {
    const {data, pageIndex} = this.state;
    const {comicSpiritIds} = this.props;
    const {contentId} = this.props.match.params;

    const comicIndex = comicSpiritIds.indexOf(contentId);
    const nextComicIndex = comicIndex === comicSpiritIds.length - 1 ? -1 : comicIndex + 1;

    if (!data.comicPages[pageIndex]) {
      return (<div/>);
    }
    const page = data.comicPages[pageIndex];

    return (
      <>
        <div className="comic-spirit-page">
          <div className="comic-spirit-page-image" style={{
            background: `url('${this.formatByLanguage(page.url)}') no-repeat`,
          }}/>
          <div className="comic-spirit-page-actions">
            <div className={'comic-spirit-page-actions-action' + (pageIndex === 0 ? ' inactive' : '')}  onClick={this.onPreviousPage}>
              <div>
                <FontAwesomeIcon icon={faArrowLeft} />
              </div>
            </div>
            <div className={'comic-spirit-page-actions-action' + (pageIndex + 1 >= data.comicPages.length ? ' inactive' : '')} onClick={this.onNextPage}>
              <div>
                <FontAwesomeIcon icon={faArrowRight} />
              </div>
            </div>
            {nextComicIndex >= 0 && pageIndex === data.comicPages.length - 1 && (
              <div className={'comic-spirit-page-actions-action'} style={{width: 100}} onClick={this.onNextComic}>
                <div>
                  <div>次の話へ</div>
                </div>
              </div>
            )}
          </div>

        </div>
      </>
    );
  };

  render() {
    const {loading, data, authUser} = this.state;

    if (loading || !data) {
      return (
        <div/>
      );
    }

    return (
      <div style={{backgroundColor: 'white'}}>
      <Div100vh>
        {authUser && this.renderHeader()}
        <div className="comic-spirit-container">
        {this.state.isMap && this.renderMap()}
        {this.state.isComic && this.renderComic()}
        </div>
      </Div100vh>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    currentLang: state.root.currentLang,
    comicSpiritIds: state.root.comicSpiritIds,
  };
}

export default connect(mapStateToProps)(ComicSpiritPage);
