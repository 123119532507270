import { API, graphqlOperation } from "aws-amplify";
import {
  GetAccount,
  GetAccountSettings,
  UpdateAccountSettings,
  GetAccountReadNotifications,
  UpdateAccountReadNotifications,
  GetAccountSubtleResults,
  UpdateAccountSubtleResults
} from "./graphql/account";

export const fetchAccount = async () => {
  const response = await API.graphql(graphqlOperation(
    GetAccount,
    { },
    "AMAZON_COGNITO_USER_POOLS"
  ));

  return response.data.getAccount;
};

export const fetchAccountSetting = async () => {
  const response = await API.graphql(graphqlOperation(
    GetAccountSettings,
    { },
    "AMAZON_COGNITO_USER_POOLS"
  ));

  return response.data.getAccountSettings;
};

export const updateAccountSettings = async (input) => {
  const response = await API.graphql(graphqlOperation(
    UpdateAccountSettings,
    input,
    "AMAZON_COGNITO_USER_POOLS"
  ));

  return response.data.updateAccountSettings;
};

export const fetchAccountReadNotifications = async () => {
  const response = await API.graphql(graphqlOperation(
    GetAccountReadNotifications,
    { },
    "AMAZON_COGNITO_USER_POOLS"
  ));

  return response.data.getAccountReadNotifications;
};

export const updateAccountReadNotifications = async (input) => {
  const response = await API.graphql(graphqlOperation(
    UpdateAccountReadNotifications,
    input,
    "AMAZON_COGNITO_USER_POOLS"
  ));

  return response.data.updateAccountReadNotifications;
};

export const fetchAccountSubtleResults = async () => {
  const response = await API.graphql(graphqlOperation(
    GetAccountSubtleResults,
    { },
    "AMAZON_COGNITO_USER_POOLS"
  ));

  return response.data.getAccountSubtleResults;
};

export const updateAccountSubtleResults = async (input) => {
  const response = await API.graphql(graphqlOperation(
    UpdateAccountSubtleResults,
    input,
    "AMAZON_COGNITO_USER_POOLS"
  ));

  return response.data.updateAccountSubtleResults;
};
