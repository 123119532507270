import React, { Component } from "react";
import { Modal, Button } from "react-bootstrap";

export class PolicyModal extends Component {
  render() {
    return (
      <Modal show={this.props.show} onHide={this.props.onClose} backdrop="static" scrollable={true}>
        <Modal.Header>
          <Modal.Title>プライバシーポリシー</Modal.Title>
        </Modal.Header>

        <Modal.Body style={{backgroundColor: "#FAFAFA", maxHeight: "calc(100vh - 210px)", overflowY: 'auto'}} >
          <p>
            株式会社ＫＩＢＩ（以下「当社」といいます）は、人財育成、採用支援、定着支援、イーラーニングおよびウェブアプリの提供その他のサービス（以下「本サービス」といいます）をご利用になる方（以下「利用者」といいます）のプライバシーを尊重し、利用者の個人情報（以下の定義に従います）の管理に細心の注意を払い、これを取扱うものとします。<br/>
            <br/>
            １　個人情報<br/>
            個人情報とは、利用者個人に関する情報であって、当該情報を構成する氏名、住所、電話番号、メールアドレス、学校名その他の記述等により当該利用者を識別できるものであり、EEA（European Economic Area,欧州経済領域）の方がご利用になられる場合には、EU General Data Protection Regulation（以下「GDPR」といいます）の適用のあるものをいいます。また、その情報のみでは識別できない場合でも、他の情報と容易に照合することができ、結果的に利用者個人を識別できるものも個人情報に含まれます。<br/>
            <br/>
            ２　プライバシーポリシーへの同意および同意の撤回<br/>
            利用者は、プライバシーポリシーに同意いただくものとし、当社は、当社および本サービスに適用のある法令（GDPRが適用される場合には当該法令を含みますがこれに限りません）および/または利用者の同意に基づき個人情報を取扱いますが、利用者は、いつでも、後記の当社の個人情報に関するお問い合わせ先に連絡する方法により、同意を撤回することができます。なお、同意の撤回は、同意の撤回前の個人情報の取扱いの有効性に影響を及ぼしません。<br/>
            <br/>
            当社は、当社の本サービスの提供に関して利用者に個人情報の提供を求めることができます。利用者が個人情報の提供を当社にしない場合には、当社は、本サービスの提供を行うことができない場合があります。<br/>
            <br/>
            ３　個人情報の利用目的<br/>
            当社は、本サービスの提供にあたり、以下に定める目的の範囲内で個人情報を取得し、適切に利用します。一般的に公開されているホームページなどから間接的に取得する場合も同様です。なお、当社は、利用者の同意に基づかずに、個人データをプロファイリングなどの自動化された判断・処理の目的で利用することはありません。<br/>
            Ａ．人財育成、採用支援、定着支援、イーラーニングおよびウェブアプリの提供ならびにこれらに関連するサービスの提供<br/>
            Ｂ．ウェブサイトその他各種媒体等に掲載するための情報の加工、統計および分析<br/>
            Ｃ．サービスの開発およびマーケティング<br/>
            Ｄ．当社からの各種連絡・メールマガジン・ＤＭ・お知らせ等の配信・送付<br/>
            Ｅ．利用者の承諾・申し込みに基づく、就職・転職に関する当社サービス（当社が他の事業者又は法人から委託を受けて運営するサービス含む）を利用する求人企業・人材紹介エージェント（以下「利用企業等」といいます）による、求人情報を記載したオファー送付<br/>
            Ｆ．就職・転職後の状況確認およびサービス向上を目的としたご意見、ご要望等の聴取<br/>
            Ｇ．サービス提供期間中またはサービス終了後における応募、入社等の事実に関する求人企業への確認および求人企業からの通知の受領<br/>
            Ｈ．求人企業の人材採用計画立案のための助言・提案<br/>
            Ｉ．お問い合わせ、ご相談および苦情への対応ならびに紛争の解決<br/>
            Ｊ．属性情報・端末情報・位置情報・行動履歴等に基づく広告・コンテンツ等の配信・表示、本サービスの提供<br/>
            <br/>
            ４　個人情報の保有期間<br/>
            当社は、利用者の個人情報を、本サービスを提供するに際し、上記の個人情報の利用目的を達成するに必要な期間に限り保有することができます。<br/>
            <br/>
            ５　個人情報提供の任意性<br/>
            本サービスが提供を求めるすべての項目にお答えいただく必要はありませんが、必要となる情報が不足している場合には、本サービスを提供できない場合があります。<br/>
            <br/>
            ６　個人情報の第三者への提供<br/>
            当社は、本サービスを行うため、個人情報の適切な取扱いに関する事項を含む契約を結んだ利用企業等に対し、本人から受領した個人情報を、書面を送付または持参する等の方法により提供することがあります。また、当社は、利用企業等に個人情報を提供した場合には、就職・転職後の状況確認、当該企業の人材採用計画立案のための助言・提案等を行うために、個人情報を利用することがあります。<br/>
            <br/>
            個人情報の第三者への提供先には、EEA（European Economic Area,欧州経済領域）域外の国に所在する利用企業等の第三者も含まれます。<br/>
            <br/>
            なお、以下の場合は、個人情報を求人企業以外の第三者に提供することがあります。<br/>
            Ａ．利用者が第三者に不利益を及ぼすと判断した場合<br/>
            Ｂ．公衆衛生の向上または児童の健全な育成の推進のために特に必要がある場合であって、利用者本人の承諾を得ることが困難である場合<br/>
            Ｃ．国の機関若しくは地方公共団体またはその委託を受けた者が法令の定める事務を遂行することに対して協力する必要がある場合で、利用者本人の同意を得ることによりその事務の遂行に支障を及ぼすおそれがある場合<br/>
            Ｄ．裁判所、検察庁、警察またはこれらに準じた権限を有する機関から、個人情報についての開示を求められた場合<br/>
            Ｅ．利用者本人から明示的に第三者への開示または提供を求められた場合<br/>
            Ｆ．法令により開示または提供が許容されている場合<br/>
            Ｇ．合併その他の事由による事業の承継に伴い個人情報を提供する場合であって、承継前の利用目的の範囲で取り扱われる場合<br/>
            <br/>
            また、EEA（European Economic Area,欧州経済領域）の利用者は、GDPRの規定に則り個人情報を第三者に提供することがあります。<br/>
            <br/>
            ７　第三者提供に関する免責事項<br/>
            以下の場合は、第三者による個人情報の取得に関し、当社は何らの責任を負いません。<br/>
            Ａ．利用者自らが本サービスの機能または別の手段を用いて利用企業等に個人情報を明らかにする場合（なお、利用企業等における個人情報の取扱いについては、各利用企業に直接お問合せください）<br/>
            Ｂ．本サービスに入力した情報により、期せずして本人が特定できてしまった場合<br/>
            Ｃ．本サービスからリンクされる外部サイトにおいて、利用者より個人情報が提供され、またそれが利用された場合<br/>
            Ｄ．利用者本人以外が利用者個人を識別できる情報（ID・パスワード等）を入手した場合<br/>
            <br/>
            ８　個人情報処理の外部委託<br/>
            当社は、個人情報取扱い業務の一部または全部を外部委託することがあります。当該委託先に個人情報の開示を行う場合には、十分な個人情報保護水準を確保していることを条件として委託先を選定し、機密保持契約を結んだ上で開示します。<br/>
            <br/>
            ９　統計処理されたデータの利用<br/>
            当社は、提供を受けた個人情報をもとに、個人を特定できないよう加工した統計データを作成することがあります。個人を特定できない統計データについては、当社は何ら制限なく利用することができるものとします。<br/>
            <br/>
            １０　個人情報の開示、訂正、利用停止等、異議の申し立て<br/>
            「個人情報の利用目的」の通知、登録した個人情報の開示、訂正、追加または削除、ならびに利用停止・制限、消去、データポタビリティ権の利用者に認められた権利行使の依頼を受けた場合は、原則として利用者本人のご依頼に限り、後記の当社の個人情報に関するお問い合わせ先に連絡する方法により、GDPR等を含めた関連法令および当社の社内規程に従って、速やかに対応します。<br/>
            <br/>
            また、EEA（European Economic Area,欧州経済領域）の利用者は、当社の個人情報の取扱いに関して、GDPRの規定に則り個人情報保護監督機関に対して異議を申し立てることができます。<br/>
            <br/>
            なお、以下の場合については個人情報の開示等の依頼にお応えできないことがあります。<br/>
            Ａ．利用者本人または第三者の生命、身体、財産その他の権利・利益を害するおそれがある場合<br/>
            Ｂ．当社の業務に支障を及ぼすおそれがある場合<br/>
            Ｃ．他の法令に違反することとなる場合、また、当該個人情報の変更等に多額の費用を要する場合､その他、個人情報の変更等を行うことが困難な場合であって、利用者の権利・利益を保護するため必要なこれに代わるべき措置をとるときは、個人情報の変更等に応じないことがあります。<br/>
            <br/>
            ただし、EEA（European Economic Area,欧州経済領域）の利用者からの個人情報の開示等の依頼についてはGDPRの要請に従い対応するものとします。<br/>
            <br/>
            １１　適正管理<br/>
            当社は、利用目的の達成に必要な範囲内において、ご提供いただいた個人情報を正確かつ最新の状態で管理するよう努めるとともに、個人情報への不正なアクセスまたは個人情報の紛失、破壊、改竄、漏洩などの危険に対して、技術面及び組織面において必要な安全対策を継続的に講じるよう努めます。ただし、ご提供いただいた個人情報の内容が正確かつ最新であることについては、利用者が責任を負うものとします。 また、当社は、個人情報の安全管理が図られるよう、従業員に対する必要かつ適切な教育および管理を行います。なお、個人情報はサービスを終了した後も法令等の定めに従い一定期間保管します。保管期間が経過した個人情報は速やかに消去します。<br/>
            <br/>
            １２　機微な個人情報の取得制限<br/>
            当社は、次に示す内容を含む個人情報の取得は原則として行いません。ただし、利用者が自ら提供した場合は、この限りではありません。<br/>
            Ａ．思想、信条及び宗教に関する事項<br/>
            Ｂ．人種、民族、門地、本籍地（所在都道府県に関する情報を除く）、身体・精神障害、犯罪歴、その他社会的差別の原因となる事項<br/>
            Ｃ．勤労者の団結権、団体交渉及びその他団体行動の行為に関する事項<br/>
            Ｄ．集団示威行為への参加、請願権の行使、及びその他政治的権利の行使に関する事項<br/>
            Ｅ．保健医療及び性生活<br/>
            <br/>
            １３　本人確認について<br/>
            当社は、個人情報の開示等の求めに応じる場合など、個人を識別できる情報（氏名､住所､電話番号､生年月日､メールアドレス､会員番号､パスワードなど）により、本人であることを確認します。ただし、本人以外が個人を識別できる情報を入手し使用した場合、当社は責任を負いません。<br/>
            <br/>
            １４　属性情報・端末情報・位置情報・行動履歴等の取得及び利用について<br/>
            •属性情報・端末情報・位置情報・行動履歴等（以下「行動履歴等」といいます）のうちCookie等について<br/>
            <br/>
            当社は、利用者のプライバシーの保護、利便性の向上、広告の配信および統計データの取得のため、Cookieを使用します。また、CookieやJavaScript等の技術を利用して、ご提供いただいた情報のうち、年齢や性別、職業、居住地域など個人が特定できない属性情報（組み合わせることによっても個人が特定できないものに限られます）や端末情報、本サービスサイト内における利用者の行動履歴（アクセスしたURL、コンテンツ、参照順等）およびスマートフォン等利用時の、利用者の承諾・申込みに基づく位置情報を取得することがあります。<br/>
            <br/>
            •行動履歴等のうち以下の場合のアクセスログ等について<br/>
            <br/>
            当社は利用者がログインして本サービスを利用した場合には、個人を特定したうえで、当該ログイン以前からの行動履歴等を用いて、広告・コンテンツ等の配信・表示および本サービスの提供をする場合があります。こちらの広告等の配信停止については、下記の個人情報管理の問い合わせ先にご連絡ください｡<br/>
            <br/>
            １５　個人情報管理責任者<br/>
            株式会社ＫＩＢＩ　個人情報保護管理責任者<br/>
            <br/>
            １６　個人情報に関するお問い合わせ先<br/>
            <a rel="noopener noreferrer" href="http://kibi-global.jp/contact/" target="_blank">個人情報に関するお問い合わせはこちら</a><br/>
            <br/>
            １７　プライバシーポリシーの変更<br/>
            当社は、法令等の定めがある場合を除き、プライバシーポリシーを随時変更することができるものとします。変更後の内容は、当社のウェブページにおいて掲載するものとし、掲載後１か月経過した時点で本サービスを継続して利用している利用者は、変更後の本プライバシーポリシーの内容に同意したものとみなします。<br/>
            <br/>
            以上<br/>
          </p>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="outline-secondary" onClick={this.props.onClose}>閉じる</Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

PolicyModal.defaultProps = {
  show: false,
  onClose: () => {},
  onOk: () => {}
};
