export const shuffleArr = (arr) => {
  var j, temp;
  for (var i = arr.length - 1; i > 0; i--) {
    j = Math.floor(Math.random() * (i + 1));
    temp = arr[j];
    arr[j] = arr[i];
    arr[i] = temp;
  }
  return arr;
};

export const isLastItemOfArray = (arr, item) => {
  const currentQuestionIndex = arr.indexOf(item);

  return currentQuestionIndex + 1 === arr.length;
}

export const formatDate = (string) => {
  return new Intl.DateTimeFormat('ja-JP', {
    dateStyle: 'long',
    timeStyle: 'short'
  }).format(new Date(string));
};

export const languages = {
  // "jp": "日本語org",
  "jp2": "日本語",
  "en": "English",
  "vn": "việt nam"
  // "cn": "中文",
};

// export const languagesList = ["jp", "en", "vn", "cn", "jp2"];
export const languagesList = ["jp2", "en", "vn"];

export const mainLanguage = "jp2";

export const prefectureList = [
  {key: '北海道', label: '北海道'},
  {key: '青森県', label: '青森県'},
  {key: '岩手県', label: '岩手県'},
  {key: '宮城県', label: '宮城県'},
  {key: '秋田県', label: '秋田県'},
  {key: '山形県', label: '山形県'},
  {key: '福島県', label: '福島県'},
  {key: '茨城県', label: '茨城県'},
  {key: '栃木県', label: '栃木県'},
  {key: '群馬県', label: '群馬県'},
  {key: '埼玉県', label: '埼玉県'},
  {key: '千葉県', label: '千葉県'},
  {key: '東京都', label: '東京都'},
  {key: '神奈川県', label: '神奈川県'},
  {key: '新潟県', label: '新潟県'},
  {key: '富山県', label: '富山県'},
  {key: '石川県', label: '石川県'},
  {key: '福井県', label: '福井県'},
  {key: '山梨県', label: '山梨県'},
  {key: '長野県', label: '長野県'},
  {key: '岐阜県', label: '岐阜県'},
  {key: '静岡県', label: '静岡県'},
  {key: '愛知県', label: '愛知県'},
  {key: '三重県', label: '三重県'},
  {key: '滋賀県', label: '滋賀県'},
  {key: '京都府', label: '京都府'},
  {key: '大阪府', label: '大阪府'},
  {key: '兵庫県', label: '兵庫県'},
  {key: '奈良県', label: '奈良県'},
  {key: '和歌山県', label: '和歌山県'},
  {key: '鳥取県', label: '鳥取県'},
  {key: '島根県', label: '島根県'},
  {key: '岡山県', label: '岡山県'},
  {key: '広島県', label: '広島県'},
  {key: '山口県', label: '山口県'},
  {key: '徳島県', label: '徳島県'},
  {key: '香川県', label: '香川県'},
  {key: '愛媛県', label: '愛媛県'},
  {key: '高知県', label: '高知県'},
  {key: '福岡県', label: '福岡県'},
  {key: '佐賀県', label: '佐賀県'},
  {key: '長崎県', label: '長崎県'},
  {key: '熊本県', label: '熊本県'},
  {key: '大分県', label: '大分県'},
  {key: '宮崎県', label: '宮崎県'},
  {key: '鹿児島県', label: '鹿児島県'},
  {key: '沖縄県', label: '沖縄県'},
];
