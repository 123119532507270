import React, { Component } from "react";
import { connect } from "react-redux";
import { Auth } from 'aws-amplify';
import { Route, Switch, Redirect } from "react-router-dom";
import {Badge, Container, Nav, Navbar, NavDropdown, Button} from "react-bootstrap";
import {LinkContainer} from "react-router-bootstrap";

import UsersPage from "./Users/index";
import UserPage from "./Users/User/index";
import CourseResultsPage from "./CourseResults/index";
import SubtleResultsPage from "./SubtleResults/index";
import NotificationsPage from "./Notifications/index";
import ManualPage from "./Manual/index";
import * as rootActions from "../../store/root/actions";
import * as accountService from "../../services/account";
import * as langService from "../../services/lang";
import {TermsModal} from "../../components/modals/TermsModal";
import {PolicyModal} from "../../components/modals/PolicyModal";
import Intercom from 'react-intercom';

import Div100vh from "react-div-100vh";

class ManagerPage extends Component {

  constructor(props, context) {
    super(props, context);

    this.state = {
      account: null,
      user: null,
      groups: [],
      accountPermissions: null,
      isLogout: false,
      isShowTermsModal: false,
      isShowPolicyModal: false,
    };
  }

  async componentDidMount() {
    this._isMounted = true;

    await this.fetchAccount();
    this.props.dispatch(rootActions.fetchNotifications());
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  fetchAccount = async () => {
    try {
      const user = await Auth.currentAuthenticatedUser();
      const account = await accountService.fetchAccount();
      let accountPermissions = null
      if (account.permissions) {
        accountPermissions = JSON.parse(account.permissions);
      }
      let session = await Auth.currentSession();
      let groups = session.idToken.payload["cognito:groups"];

      if (this._isMounted) {
        this.setState({ user, account, groups, accountPermissions });
      }
    } catch (e) {
      console.log(e);
    }
  };

  async onLogout() {

    try {
      await Auth.signOut();
      this.setState({ isLogout: true });
      this.props.history.push('/login');
    } catch (e) {
      console.error(e);
    }

  }

  renderDisabled = (intercomUser) => {
    return (
      <Div100vh className="d-flex justify-content-center align-items-center flex-column text-center">
        <div>アカウントは停止されています。</div>
        <Button variant="primary" className="mt-3" onClick={this.onLogout.bind(this)}>ログアウトする</Button>
        {intercomUser && <Intercom appID={process.env.REACT_APP_INTERCOM_API_KEY} { ...intercomUser }/>}
      </Div100vh>
    );
  };

  render() {
    let { match, notificationsList, notificationsReaded } = this.props;
    const {account, accountPermissions, user, groups} = this.state;

    let notifications = notificationsList.filter(item => {
      if (item.userGroup) {
        return groups.indexOf(item.userGroup.toLowerCase()) !== -1;
      } else {
        return user && item.userId === user.getUsername();
      }
    });

    let totalUnread = 0;
    for (let i = 0; i < notifications.length; i++) {
      if (notificationsReaded.indexOf(notifications[i].id) === -1) {
        totalUnread += 1;
      }
    }

    let intercomUser = null;

    if (account) {
      intercomUser = {
        user_id: account.id,
        email: account.email,
        name: account.name,
      };
    }

    if (account && (account.disabled || (account.parent && account.parent.disabled))) {
      return this.renderDisabled(intercomUser);
    }


    return (
      <>
        <Navbar bg="dark" variant="dark">
          <Navbar.Brand>代理店管理画面</Navbar.Brand>
          <Nav className="mr-auto">
            <LinkContainer to={`/manager`} activeClassName="">
              <Nav.Link>ユーザー管理</Nav.Link>
            </LinkContainer>
            {accountPermissions && accountPermissions.indexOf('COURSE') !== -1 && (
              <LinkContainer to={`/manager/course-results`} activeClassName="">
                <Nav.Link>コーステスト結果</Nav.Link>
              </LinkContainer>
            )}
            {accountPermissions && accountPermissions.indexOf('SUBTLE') !== -1 && (
            <LinkContainer to={`/manager/subtle-results`} activeClassName="">
              <Nav.Link>機微力診断</Nav.Link>
            </LinkContainer>
            )}
            {accountPermissions && accountPermissions.indexOf('NURSING') !== -1 && (
            <LinkContainer to={`/manager/manual`} activeClassName="">
              <Nav.Link>管理者マニュアル</Nav.Link>
            </LinkContainer>
            )}
          </Nav>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ml-auto">
              <Nav.Link href="/manager/notifications" className="d-flex align-items-center">お知らせ {totalUnread > 0 && <Badge variant="danger" className="ml-2">{totalUnread}</Badge>}</Nav.Link>
              <NavDropdown title="アカウント">
                <NavDropdown.Item onClick={() => this.setState({isShowTermsModal: true})}>{langService.translate("terms", this.props.currentLang)}</NavDropdown.Item>
                <NavDropdown.Item onClick={() => this.setState({isShowPolicyModal: true})}>{langService.translate("policy", this.props.currentLang)}</NavDropdown.Item>
                {/*<NavDropdown.Divider />*/}
                {/*<NavDropdown.Item href="#">FAQ</NavDropdown.Item>*/}
                {/*<NavDropdown.Item href="#">お問い合わせ</NavDropdown.Item>*/}

                <NavDropdown.Divider />
                <NavDropdown.Item onClick={this.onLogout.bind(this)}>ログアウト</NavDropdown.Item>
              </NavDropdown>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
        <Container>
          <Switch>
            <Route path={`${match.url}`} exact component={UsersPage} />
            <Route path={`${match.url}/:userId/user`} exact component={UserPage} />
            <Route path={`${match.url}/course-results`} exact component={CourseResultsPage} />
            <Route path={`${match.url}/subtle-results`} exact component={SubtleResultsPage} />
            <Route path={`${match.url}/notifications`} exact component={NotificationsPage} />
            <Route path={`${match.url}/manual`} exact component={ManualPage} />
          </Switch>
        </Container>
        {this.state.isLogout && (<Redirect to="/login"/>)}
        <TermsModal show={this.state.isShowTermsModal}
                    isSimple={true}
                    onClose={() => this.setState({ isShowTermsModal: false })} />
        <PolicyModal show={this.state.isShowPolicyModal}
                     onClose={() => this.setState({ isShowPolicyModal: false })} />
        {intercomUser && <Intercom appID={process.env.REACT_APP_INTERCOM_API_KEY} { ...intercomUser }/>}
      </>
    )};
}

function mapStateToProps(state) {
  return {
    currentLang: state.root.currentLang,
    notificationsObject: state.root.notifications,
    notificationsList: state.root.notifications.list,
    notificationsReaded: state.root.notifications.readed,
  };
}

export default connect(mapStateToProps)(ManagerPage);
