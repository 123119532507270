import React, { Component } from "react";
import { connect } from "react-redux";
import { ButtonToolbar, Button, Badge, ListGroup } from "react-bootstrap";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars } from '@fortawesome/free-solid-svg-icons'
import "./index.scss";
import * as contentsService from "../../../services/contents";
import * as commonService from "../../../services/common";
import * as jsonService from "../../../services/json";

class CoursesPage extends Component {

  constructor(props, context) {
    super(props, context);

    this.state = {
      contentType: "COURSE",
      isEdit: false,
      loading: true,
      error: null,
      items: [],
      oldItems: [],
    };
  }

  async componentWillMount() {
    await this.fetchContents();
  }

  fetchContents = async () => {
    this.setState({ loading: true, error: null });
    try {
      let setting = await contentsService.fetchContentSetting(this.state.contentType);
      let response = await contentsService.fetchContents();
      let items = response.items.filter((item) => item.contentType === this.state.contentType);
      if (setting) {
        let order = JSON.parse(setting.order);
        let newList = [];
        for (let i = 0; i < order.length; i++) {
          let index = items.findIndex(item => item.id === order[i]);
          if (items[index]) {
            newList.push(items[index]);
          }
        }
        items = newList;
      }

      this.setState({ items: items, loading: false });
    } catch (e) {
      console.error(e);
      this.setState({ loading: false, error: e });
    }
  };

  onCreateClick = () => {
    this.props.history.push("/admin/courses/new");
  };

  onEditClick = (item) => {
    this.props.history.push(`/admin/courses/${item.id}`);
  };

  onDragEnd = async (result) => {
    if (!result.destination) return;

    // Update local list
    const items = Array.from(this.state.items);
    const [removed] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, removed);
    this.setState({ items });
  };

  onDownload = () => {

    let schema = [
      {path: "id"},
      {path: "title", type: "JSON"},
      {path: "description", type: "JSON"},
      {path: "languages", type: "JSON"},
      {
        path: "practiceQuestions",
        type: "Array",
        items: [
          {path: "id"},
          {path: "title", type: "JSON"},
          {path: "svgUrl"},
          {path: "characters", type: "JSON"},
          {path: "chats", type: "JSON"},
          {path: "answers", type: "JSON"},
        ]
      },
      {
        path: "testQuestions",
        type: "Array",
        items: [
          {path: "id"},
          {path: "title", type: "JSON"},
          {path: "svgUrl"},
          {path: "characters", type: "JSON"},
          {path: "chats", type: "JSON"},
          {path: "answers", type: "JSON"},
        ]
      }
    ];

    let json = jsonService.prepareJson(schema, this.state.items);
    jsonService.downloadJson(json, "courses.json");
  };

  onUploadSample = () => {

    let schema = [
      {path: "title"},
      {path: "description"},
      {path: "languages"},
      {
        path: "practiceQuestions",
        type: "Array",
        items: [
          {path: "id"},
          {path: "title"},
          {path: "svgUrl"},
          {
            path: "characters",
            type: "Array",
            items: [
              {path: "id"},
              {path: "title"},
              {path: "svgUrl"},
            ]
          },
          {
            path: "chats",
            type: "Array",
            items: [
              {path: "id"},
              {path: "character"},
              {path: "text"},
              {path: "reading"},
            ]
          },
          {
            path: "answers",
            type: "Array",
            items: [
              {path: "text"},
              {path: "reading"},
              {path: "message"},
              {path: "isCorrect"},
              {path: "image"},
              {path: "descriptionImage"},
              {path: "descriptionJapaneseFeeling"},
              {path: "descriptionKibi"},
              {path: "descriptionKibiAvatar"},
              {path: "descriptionJapanesePoint"},
              {path: "descriptionBehavePoint"},
            ]
          },
        ]
      },
      {
        path: "testQuestions",
        type: "Array",
        items: [
          {path: "id"},
          {path: "title"},
          {path: "svgUrl"},
          {
            path: "characters",
            type: "Array",
            items: [
              {path: "id"},
              {path: "title"},
              {path: "svgUrl"},
            ]
          },
          {
            path: "chats",
            type: "Array",
            items: [
              {path: "id"},
              {path: "character"},
              {path: "text"},
              {path: "reading"},
            ]
          },
          {
            path: "answers",
            type: "Array",
            items: [
              {path: "text"},
              {path: "reading"},
              {path: "isCorrect"},
            ]
          },
        ]
      },
    ];

    let items = [
      {
        title: {jp: "Some title"},
        description: {jp: "Some description"},
        languages: ["jp"],
        practiceQuestions: [
          {
            id: "practice-question-1",
            title: {jp: "Practice question 1"},
            svgUrl: "practice-question-svg-filename.svg",
            characters: [
              {
                id: "practice-question-character-1",
                title: {jp: "John"},
                svgUrl: "character-svg-filename.svg",
              }
            ],
            chats: [
              {
                id: "practice-question-chat-1",
                character: {
                  value: "practice-question-character-1"
                },
                text: {
                  jp: "Chat message"
                },
                reading: {
                  jp: "Chat message reading"
                }
              }
            ],
            answers: [
              {
                text: {
                  jp: "Answer 1"
                },
                reading: {
                  jp: "Reading"
                },
                message: {
                  value: "WELL_DONE"
                },
                isCorrect: {
                  value: "1"
                },
                image: {
                  value: "practice-question-answer-1-svg-filename.svg"
                },
                descriptionImage: {
                  value: "practice-question-answer-description-1-svg-filename.svg"
                },
                descriptionJapaneseFeeling: {
                  jp: "日本人の気持ち"
                },
                descriptionKibi: {
                  jp: "キービンの背景解説"
                },
                descriptionKibiAvatar: {
                  value: "kiiibin_relux"
                },
                descriptionJapanesePoint: {
                  jp: "日本の慣習ポイント"
                },
                descriptionBehavePoint: {
                  jp: "振る舞い方のポイント"
                },
              },
              {
                text: {
                  jp: "Answer 2"
                },
                reading: {
                  jp: "Reading"
                },
                message: {
                  value: "ALMOST"
                },
                isCorrect: {
                  value: "0"
                },
                image: {
                  value: "practice-question-answer-2-svg-filename.svg"
                },
                descriptionImage: {
                  value: "practice-question-answer-description-2-svg-filename.svg"
                },
                descriptionJapaneseFeeling: {
                  jp: "日本人の気持ち"
                },
                descriptionKibi: {
                  jp: "キービンの背景解説"
                },
                descriptionKibiAvatar: {
                  value: "kiiibin_sit"
                },
                descriptionJapanesePoint: {
                  jp: "日本の慣習ポイント"
                },
                descriptionBehavePoint: {
                  jp: "振る舞い方のポイント"
                },
              },
              {
                text: {
                  jp: "Answer 3"
                },
                reading: {
                  jp: "Reading"
                },
                message: {
                  value: "TRY_AGAIN"
                },
                isCorrect: {
                  value: "0"
                },
                image: {
                  value: "practice-question-answer-3-svg-filename.svg"
                },
                descriptionImage: {
                  value: "practice-question-answer-description-3-svg-filename.svg"
                },
                descriptionJapaneseFeeling: {
                  jp: "日本人の気持ち"
                },
                descriptionKibi: {
                  jp: "キービンの背景解説"
                },
                descriptionKibiAvatar: {
                  value: "kiiibin_teach"
                },
                descriptionJapanesePoint: {
                  jp: "日本の慣習ポイント"
                },
                descriptionBehavePoint: {
                  jp: "振る舞い方のポイント"
                },
              }
            ]
          }
        ],
        testQuestions: [
          {
            id: "test-question-1",
            title: {jp: "Test question 1"},
            svgUrl: "test-question-svg-filename.svg",
            characters: [
              {
                id: "test-question-character-1",
                title: {jp: "John"},
                svgUrl: "character-svg-filename.svg",
              }
            ],
            chats: [
              {
                id: "test-question-chat-1",
                character: {
                  value: "test-question-character-1"
                },
                text: {
                  jp: "Chat message"
                },
                reading: {
                  jp: "Chat message reading"
                }
              }
            ],
            answers: [
              {
                text: {
                  jp: "Answer 1"
                },
                reading: {
                  jp: "Reading"
                },
                isCorrect: {
                  value: "1"
                },
              },
              {
                text: {
                  jp: "Answer 2"
                },
                reading: {
                  jp: "Reading"
                },
                isCorrect: {
                  value: "0"
                },
              },
              {
                text: {
                  jp: "Answer 3"
                },
                reading: {
                  jp: "Reading"
                },
                isCorrect: {
                  value: "0"
                },
              }
            ]
          }
        ]
      }
    ];

    let json = jsonService.prepareJson(schema, items);
    jsonService.downloadJson(json, "courses-sample.json");
  };

  onUpload = async (file) => {


    // byValue

    let schema = [
      {path: "title", required: true, separatedLanguage: true},
      {path: "description", separatedLanguage: true},
      {
        path: "practiceQuestions",
        type: "Array",
        items: [
          {path: "id", required: true},
          {path: "title", required: true, separatedLanguage: true},
          {path: "svgUrl", required: true},
      //     {
      //       path: "characters",
      //       type: "Array",
      //       items: [
      //         {path: "id"},
      //         {path: "title"},
      //         {path: "svgUrl"},
      //       ]
      //     },
      //     {
      //       path: "chats",
      //       type: "Array",
      //       items: [
      //         {path: "id"},
      //         {path: "character"},
      //         {path: "text"},
      //         {path: "reading"},
      //       ]
      //     },
      //     {
      //       path: "answers",
      //       type: "Array",
      //       items: [
      //         {path: "text"},
      //         {path: "reading"},
      //         {path: "message"},
      //         {path: "isCorrect"},
      //         {path: "image"},
      //         {path: "descriptionImage"},
      //         {path: "descriptionJapaneseFeeling"},
      //         {path: "descriptionKibi"},
      //         {path: "descriptionKibiAvatar"},
      //         {path: "descriptionJapanesePoint"},
      //         {path: "descriptionBehavePoint"},
      //       ]
      //     },
        ]
      },
      // {
      //   path: "testQuestions",
      //   type: "Array",
      //   items: [
      //     {path: "id"},
      //     {path: "title"},
      //     {path: "svgUrl"},
      //     {
      //       path: "characters",
      //       type: "Array",
      //       items: [
      //         {path: "id"},
      //         {path: "title"},
      //         {path: "svgUrl"},
      //       ]
      //     },
      //     {
      //       path: "chats",
      //       type: "Array",
      //       items: [
      //         {path: "id"},
      //         {path: "character"},
      //         {path: "text"},
      //         {path: "reading"},
      //       ]
      //     },
      //     {
      //       path: "answers",
      //       type: "Array",
      //       items: [
      //         {path: "text"},
      //         {path: "reading"},
      //         {path: "isCorrect"},
      //       ]
      //     },
      //   ]
      // },
    ];

    // let schema = [
    //   {path: "email", type: "String", required: true},
    //   {path: "password", type: function(value) {
    //       if (value) return value;
    //       return generator.generate({
    //         length: 6,
    //         uppercase: false
    //       });
    //     }},
    //   {path: "name", type: "String", required: true},
    //   {path: "department", type: "String"},
    //   {path: "manager", type: "String"},
    //   {path: "childMaxCount", type: "Int", required: true},
    //   {path: "permissions", type: function(value) {
    //       if (!value) throw new Error("権限が必須になってます");
    //       return JSON.stringify(value.replace("\r","").split(";"));
    //     }, required: true},
    // ];
    try {
      let items = await jsonService.readJson(file, schema);
      // let item;
      // for (item of items) {
      //   await userService.createUserManager(item);
      // }
      // await this.fetchUsers();
      // window.alert("アップロードは完了しました");
    } catch (e) {
      if (e.errors) {
        window.alert(e.errors[0].message);
      } else {
        window.alert(e.message);
      }
    }
  };

  onEditOrder = () => {
    const {items} = this.state;
    this.setState({oldItems: [...items], isEdit: true})
  };

  onEditOrderCancel = () => {
    const {oldItems} = this.state;
    this.setState({items: [...oldItems], isEdit: false});
  };

  onEditOrderDone = async () => {
    const {items} = this.state;

    let order = items.map(item => item.id);
    await contentsService.updateContentSetting({id: this.state.contentType, order: JSON.stringify(order)})

    this.setState({oldItems: [], isEdit: false});
  };

  renderSimpleList = () => {
    const {items} = this.state;
    return (
      <ListGroup className="mt-3">
          {items.map((item, index) => (

              <div
                key={item.id}
                className="d-flex justify-content-between align-items-center list-group-item pointer"
                onClick={() => this.onEditClick(item)}
              >
                <div>
                  <FontAwesomeIcon className="text-secondary mr-2 grab" icon={faBars} />
                  {JSON.parse(item.title)[commonService.mainLanguage] + " "}
                  {JSON.parse(item.languages).map((language) => (
                    <Badge key={language} variant="info" className="mr-1">{commonService.languages[language]}</Badge>
                  ))}
                </div>
              </div>
        ))}
        {!this.state.loading && this.state.items.length === 0 && (
          <ListGroup.Item>
            何も見つかりませんでした
          </ListGroup.Item>
        )}
      </ListGroup>
    );
  };

  renderDraggableList = () => {
    return (
      <ListGroup className="mt-3">

        <DragDropContext onDragEnd={this.onDragEnd}>
          <Droppable droppableId="droppable">
            {(provided, snapshot) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                {this.state.items.map((item, index) => (
                  <Draggable key={item.id} draggableId={item.id} index={index}>
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        className="d-flex justify-content-between align-items-center list-group-item pointer"
                        onClick={() => this.onEditClick(item)}
                      >
                        <div>
                          <FontAwesomeIcon className="text-secondary mr-2 grab" icon={faBars} />
                          {JSON.parse(item.title)[commonService.mainLanguage] + " "}
                          {JSON.parse(item.languages).map((language) => (
                            <Badge key={language} variant="info" className="mr-1">{commonService.languages[language]}</Badge>
                          ))}
                        </div>
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
        {!this.state.loading && this.state.items.length === 0 && (
          <ListGroup.Item>
            何も見つかりませんでした
          </ListGroup.Item>
        )}
      </ListGroup>
    );
  };

  render() {
    const {isEdit} = this.state;
    return (
      <>
        <ButtonToolbar className="mt-4 justify-content-between align-items-center">
          <h1 className="m-0">コース一覧</h1>
          {!isEdit && (
            <div className="d-flex">
              <Button variant="primary" className="mr-2" onClick={this.onEditOrder}>順番変更</Button>
              <div className='btn btn-outline-primary mr-2 pointer'>
                <label htmlFor="file" style={{marginBottom: 0}}>
                  アップロード
                </label>
                <input id="file" accept="application/json" type='file' style={{display: 'none'}} onChange={(e) => {this.onUpload(e.target.files[0]); e.target.value = null;}} />
              </div>
              <Button variant="outline-primary mr-2" onClick={this.onUploadSample}>CSVサンプル</Button>
              <Button variant="outline-primary mr-2" onClick={this.onDownload}>ダウンロード</Button>
              <Button variant="primary" className="mr-2" onClick={this.onCreateClick}>制作</Button>
            </div>
          )}
          {isEdit && (
            <div className="d-flex">
              <Button variant="outline-primary mr-2" onClick={this.onEditOrderCancel}>キャンセル</Button>
              <Button variant="primary" className="mr-2" onClick={this.onEditOrderDone}>保存</Button>
            </div>
          )}
        </ButtonToolbar>

        {!isEdit && this.renderSimpleList()}
        {isEdit && this.renderDraggableList()}

      </>
    )
  };
}

function mapStateToProps(state) {
  return {};
}

export default connect(mapStateToProps)(CoursesPage);
