export const buildResult = ({results, id, isFree, nickname}) => {

  // console.log('id', id)

  let totalCorrect = 0;
  let totalPositiveStroke = 0;
  let totalNegativeStroke = 0;
  let totalDiscount = 0;
  let totalFlexibility = 0;
  let totalImagination = 0;
  let totalReaction = 0;
  let totalEmpathy = 0;
  let totalFeeling = 0;

  results.forEach(item => {
    totalCorrect += item.positiveStroke > 0 ? 2 : 0;
    totalPositiveStroke += item.positiveStroke > 0 ? 1 : 0;
    totalNegativeStroke += item.negativeStroke > 0 ? 1 : 0;
    totalDiscount += item.discount > 0 ? 1 : 0;

    totalFlexibility += item.flexibility && item.flexibility > 0 ? 1 : 0;
    totalImagination += item.imagination && item.imagination > 0 ? 1 : 0;
    totalReaction += item.reaction && item.reaction > 0 ? 1 : 0;
    totalEmpathy += item.empathy && item.empathy > 0 ? 1 : 0;
    totalFeeling += item.feeling && item.feeling > 0 ? 1 : 0;
  })

  let textTypeNumber = 0;
  let textType = '';
  let typeImage = '';
  let textTypeLong = ``;
  let textBubble = ``;
  let textBanner = ``;
  let textBannerText = '';
  let textAdvice = '';
  let textShare = '';
  let freeUrl = 'https://app.kibi-learning.com/nursing'; //isFree ? 'https://kibi-global.jp/kidukky/' : 'https://app.kibi-learning.com/nursing'; //window.location.href

  // + - d
  if (totalPositiveStroke >= totalNegativeStroke && totalNegativeStroke >= totalDiscount) {
    if (!isFree) {
      if (totalCorrect >= 48) {
        textTypeNumber = 1;
        textType = `マッサージチェア`; //  O
        typeImage = `massage`; // ?
        textTypeLong = `揉み技豊富！\nマッサージチェアタイプ`; // P
        textBubble = `「好みに合わせて多彩な揉み技を繰りだすよ。優しく丁寧に。嫌がられたとしても、痛みを取り除くために時には強さも必要だからね。」`; // Q
        textBanner = `相手を見る高い洞察力で適切な行動がとれる、人望の厚いタイプです。`; // R
        textBannerText = `あなたは、相手の気持ちを第一に考えながら、上手に励ませる人です。状況によっては相手を上手に叱ることもできます。`; // N
        textAdvice = '気を使いすぎて疲れた時は、ゆっくり入浴！半身浴は３８度で２０分。（諸説あり）'; //T
        textShare = `${nickname}の家電タイプは #マッサージチェアタイプ #揉み技豊富 #優しく #丁寧に #時には強さも必要 #高い洞察力 #人望厚い #コミュニケーションタイプ #コミュニケーション #きづっきー #診断` //U
      } else if (totalCorrect >= 35) {
        textTypeNumber = 2;
        textType = `炊飯器`; //  O
        typeImage = `rice-cooker`; // ?
        textTypeLong = `感度も精度も高めです。\n炊飯器タイプ`; // P
        textBubble = `「お米の状態によって、浸漬時間や水加減は変わるよ。ツヤとハリのあるごはんを炊き上げるには、お米の状態を見極めるのが大切なんだ。」`; // Q
        textBanner = `相手の空気や場の空気を察知することに長けたバランサータイプです。`; // R
        textBannerText = `あなたは、相手の気持ちと物事の道筋のバランスを上手にとって励ましたり、叱ったりできる人です`; // N
        textAdvice = 'あなたが忙しいのは、あなたが優秀だからです。役に立ってますから、背筋を伸ばして歩きましょう！'; //T
        textShare = `${nickname}の家電タイプは #炊飯器タイプ #感度高め #精度も高め #見極め力 #空気を察知 #場を読む #バランサータイプ #コミュニケーションタイプ #コミュニケーション #きづっきー #診断` //U
      } else {
        textTypeNumber = 3;
        textType = `テレビ`; //  O
        typeImage = `tv`; // ?
        textTypeLong = `わがままアイドル\nテレビタイプ`; // P
        textBubble = `「私はお茶の間の人気者よ。画面越しに皆をHAPPYにするのが喜びなの！皆、私のこと見てね！」`; // Q
        textBanner = `天性の魅力を持っていて注目の的になるタイプです。`; // R
        textBannerText = `あなたは、相手の気持ちよりも、筋を通すことを優先します。しかしあくまでも励ますという形で、相手に伝えますが、必要なら叱ることもできる人です`; // N
        textAdvice = '聞き上手になる近道はね、思いついたことをすぐに話さないのがコツですよ。'; //T
        textShare = `${nickname}の家電タイプは #テレビタイプ #わがままアイドル #人気者 #皆にHappyを #私を見て #天性の魅力 #注目の的 #コミュニケーションタイプ #コミュニケーション  #きづっきー #診断` //U
      }
    } else {
      if (totalCorrect >= 17) {
        textTypeNumber = 1;
        textType = `マッサージチェア`; //  O
        typeImage = `massage`; // ?
        textTypeLong = `揉み技豊富！\nマッサージチェアタイプ`; // P
        textBubble = `「好みに合わせて多彩な揉み技を繰りだすよ。優しく丁寧に。嫌がられたとしても、痛みを取り除くために時には強さも必要だからね。」`; // Q
        textBanner = `相手を見る高い洞察力で適切な行動がとれる、人望の厚いタイプです。`; // R
        textBannerText = `あなたは、相手の気持ちを第一に考えながら、上手に励ませる人です。状況によっては相手を上手に叱ることもできます。`; // N
        textAdvice = '気を使いすぎて疲れた時は、ゆっくり入浴！半身浴は３８度で２０分。（諸説あり）'; //T
        textShare = `${nickname}の家電タイプは #マッサージチェアタイプ #揉み技豊富 #優しく #丁寧に #時には強さも必要 #高い洞察力 #人望厚い #コミュニケーションタイプ #コミュニケーション #きづっきー #診断` //U
      } else if (totalCorrect >= 13) {
        textTypeNumber = 2;
        textType = `炊飯器`; //  O
        typeImage = `rice-cooker`; // ?
        textTypeLong = `感度も精度も高めです。\n炊飯器タイプ`; // P
        textBubble = `「お米の状態によって、浸漬時間や水加減は変わるよ。ツヤとハリのあるごはんを炊き上げるには、お米の状態を見極めるのが大切なんだ。」`; // Q
        textBanner = `相手の空気や場の空気を察知することに長けたバランサータイプです。`; // R
        textBannerText = `あなたは、相手の気持ちと物事の道筋のバランスを上手にとって励ましたり、叱ったりできる人です`; // N
        textAdvice = 'あなたが忙しいのは、あなたが優秀だからです。役に立ってますから、背筋を伸ばして歩きましょう！'; //T
        textShare = `${nickname}の家電タイプは #炊飯器タイプ #感度高め #精度も高め #見極め力 #空気を察知 #場を読む #バランサータイプ #コミュニケーションタイプ #コミュニケーション #きづっきー #診断` //U
      } else {
        textTypeNumber = 3;
        textType = `テレビ`; //  O
        typeImage = `tv`; // ?
        textTypeLong = `わがままアイドル\nテレビタイプ`; // P
        textBubble = `「私はお茶の間の人気者よ。画面越しに皆をHAPPYにするのが喜びなの！皆、私のこと見てね！」`; // Q
        textBanner = `天性の魅力を持っていて注目の的になるタイプです。`; // R
        textBannerText = `あなたは、相手の気持ちよりも、筋を通すことを優先します。しかしあくまでも励ますという形で、相手に伝えますが、必要なら叱ることもできる人です`; // N
        textAdvice = '聞き上手になる近道はね、思いついたことをすぐに話さないのがコツですよ。'; //T
        textShare = `${nickname}の家電タイプは #テレビタイプ #わがままアイドル #人気者 #皆にHappyを #私を見て #天性の魅力 #注目の的 #コミュニケーションタイプ #コミュニケーション  #きづっきー #診断` //U
      }
    }

    // + d -
  } else if (totalPositiveStroke >= totalDiscount && totalDiscount >= totalNegativeStroke) {
    if (!isFree) {
      if (totalCorrect >= 48) {
        textTypeNumber = 4;
        textType = `ホームベーカリー`; //  O
        typeImage = `home-bakery`; // ?
        textTypeLong = `繊細な気持ちを察する！\nホームベーカリータイプ`; // P
        textBubble = `「発酵のための温度管理など、パンには繊細な配慮が必要だし、きれいに膨らむようにじっくりじっくり時間をかけることも大切なの。」`; // Q
        textBanner = `相手に必要な時間を理解し、自分を律して待つことができる根気強いタイプです。`; // R
        textBannerText = `あなたは、相手の気持ちに配慮しながら、上手に励ませる人です。叱るよりも見守る、自覚を待つタイプです`; // N
        textAdvice = '察してるんだったら、なんか言ってあげても、いいんですよ。'; //T
        textShare = `${nickname}の家電タイプは #ホームベーカリータイプ #繊細 #気持ちを察する #配慮 #じっくりじっくり #自分を律する #根気強い #コミュニケーションタイプ #コミュニケーション  #きづっきー #診断` //U
      } else if (totalCorrect >= 35) {
        textTypeNumber = 5;
        textType = `コーヒーメーカー`; //  O
        typeImage = `coffee-maker`; // ?
        textTypeLong = `のほほん癒し系\nコーヒーメーカータイプ`; // P
        textBubble = `「豆から選んで家で丁寧に淹れるコーヒー。\nなんかほっとするでしょ。\nみなさん、たまにはゆっくりしませんか。」`; // Q
        textBanner = `相手に安らぎを与えるマイペースさん。癒し系タイプです。`; // R
        textBannerText = `あなたは、相手の気持ちと物事の道筋のバランスを上手にとって励ましたり、見守ったりできる人です`; // N
        textAdvice = '実はね、あなたは結構期待されたりします。がんばれじゃなくて、力を抜いてニコニコ仕事してくださいな'; //T
        textShare = `${nickname}の家電タイプは #コーヒーメーカータイプ #のほほん #癒し系 #丁寧に #ホッとする #ゆっくりしよ #安らぎ #マイペース #コミュニケーションタイプ #コミュニケーション  #きづっきー #診断` //U
      } else {
        textTypeNumber = 6;
        textType = `エアコン`; //  O
        typeImage = `air-conditioner`; // ?
        textTypeLong = `必殺仕事人！\nエアコンタイプ`; // P
        textBubble = `「なるべく皆を快適に…とは思ってるんだけどね、寒い！とか暑い！とか意見聞いていたらまとまらないから。だから割り切ることも重要だと思ってるんだ。」`; // Q
        textBanner = `本質をとらえて論理的に物事を進めるのが得意なタイプです。`; // R
        textBannerText = `あなたは、相手の気持ちよりも、筋を通すこと優先します。しかしあくまでも励ますという形で、相手に伝えますが、必要なら自覚を待つこともできます`; // N
        textAdvice = 'そりゃ、いやなことされたら、傷つきますよね～。でも仕返しとか復讐とかで、相手に縛られないでくださいね。'; //T
        textShare = `${nickname}の家電タイプは #エアコンタイプ #必殺仕事人 #割り切り #本質 #論理的 #物事を進める #みんなをまとめる #コミュニケーションタイプ #コミュニケーション  #きづっきー #診断` //U
      }
    } else {
      if (totalCorrect >= 17) {
        textTypeNumber = 4;
        textType = `ホームベーカリー`; //  O
        typeImage = `home-bakery`; // ?
        textTypeLong = `繊細な気持ちを察する！\nホームベーカリータイプ`; // P
        textBubble = `「発酵のための温度管理など、パンには繊細な配慮が必要だし、きれいに膨らむようにじっくりじっくり時間をかけることも大切なの。」`; // Q
        textBanner = `相手に必要な時間を理解し、自分を律して待つことができる根気強いタイプです。`; // R
        textBannerText = `あなたは、相手の気持ちに配慮しながら、上手に励ませる人です。叱るよりも見守る、自覚を待つタイプです`; // N
        textAdvice = '察してるんだったら、なんか言ってあげても、いいんですよ。'; //T
        textShare = `${nickname}の家電タイプは #ホームベーカリータイプ #繊細 #気持ちを察する #配慮 #じっくりじっくり #自分を律する #根気強い #コミュニケーションタイプ #コミュニケーション  #きづっきー #診断` //U
      } else if (totalCorrect >= 13) {
        textTypeNumber = 5;
        textType = `コーヒーメーカー`; //  O
        typeImage = `coffee-maker`; // ?
        textTypeLong = `のほほん癒し系\nコーヒーメーカータイプ`; // P
        textBubble = `「豆から選んで家で丁寧に淹れるコーヒー。\nなんかほっとするでしょ。\nみなさん、たまにはゆっくりしませんか。」`; // Q
        textBanner = `相手に安らぎを与えるマイペースさん。癒し系タイプです。`; // R
        textBannerText = `あなたは、相手の気持ちと物事の道筋のバランスを上手にとって励ましたり、見守ったりできる人です`; // N
        textAdvice = '実はね、あなたは結構期待されたりします。がんばれじゃなくて、力を抜いてニコニコ仕事してくださいな'; //T
        textShare = `${nickname}の家電タイプは #コーヒーメーカータイプ #のほほん #癒し系 #丁寧に #ホッとする #ゆっくりしよ #安らぎ #マイペース #コミュニケーションタイプ #コミュニケーション  #きづっきー #診断` //U
      } else {
        textTypeNumber = 6;
        textType = `エアコン`; //  O
        typeImage = `air-conditioner`; // ?
        textTypeLong = `必殺仕事人！\nエアコンタイプ`; // P
        textBubble = `「なるべく皆を快適に…とは思ってるんだけどね、寒い！とか暑い！とか意見聞いていたらまとまらないから。だから割り切ることも重要だと思ってるんだ。」`; // Q
        textBanner = `本質をとらえて論理的に物事を進めるのが得意なタイプです。`; // R
        textBannerText = `あなたは、相手の気持ちよりも、筋を通すこと優先します。しかしあくまでも励ますという形で、相手に伝えますが、必要なら自覚を待つこともできます`; // N
        textAdvice = 'そりゃ、いやなことされたら、傷つきますよね～。でも仕返しとか復讐とかで、相手に縛られないでくださいね。'; //T
        textShare = `${nickname}の家電タイプは #エアコンタイプ #必殺仕事人 #割り切り #本質 #論理的 #物事を進める #みんなをまとめる #コミュニケーションタイプ #コミュニケーション  #きづっきー #診断` //U
      }
    }

    // - + d
  } else if (totalNegativeStroke >= totalPositiveStroke && totalPositiveStroke >= totalDiscount) {
    if (!isFree) {
      if (totalCorrect >= 20) {
        textTypeNumber = 7;
        textType = `ホームシアター`; //  O
        typeImage = `home-theater`; // ?
        textTypeLong = `前しか向かねぇ!\nホームシアタータイプ`; // P
        textBubble = `「後ろ向いてちゃだめだ！後悔したってしょうがない！そもそも自分、前しか見えないんで！」`; // Q
        textBanner = `勝ち気でポジティブ。周囲を元気にする、頼りがいのあるタイプです。`; // R
        textBannerText = `あなたは、相手を十分理解したうえで、叱ることによって成長させることを好みます。相手の事情や気持ちを十分理解しているので、叱ってばかりではいけないことも十分理解しているので、必要な時には励ますこともできます。`; // N
        textAdvice = 'ノリノリで仕事をするのは、それは快感です！でもあなたのノリについていけない人がいるかも？'; //T
        textShare = `${nickname}の家電タイプは #ホームシアタータイプ #前向き #前だけ向く #後悔しない #超勝ち気 #ポジティブ #元気 #頼りがい #コミュニケーションタイプ #コミュニケーション  #きづっきー #診断` //U
      } else if (totalCorrect >= 10) {
        textTypeNumber = 8;
        textType = `コードレスクリーナー`; //  O
        typeImage = `vacuum-cleaner`; // ?
        textTypeLong = `自立したオ・ト・ナ\nコードレスクリーナータイプ`; // P
        textBubble = `「床に物置かないでくださいね。これは大事なものだ！とか、考えるのは自分の仕事じゃないのでね。冷たいと思うかもしれないけど、自立してほしいんです。」`; // Q
        textBanner = `相手を信じて直球勝負ができる、理性的で正直なタイプです。`; // R
        textBannerText = `あなたは、相手の気持ちや事情と、行動の正しさや合理性について、バランスよく考えることができます。相手の気持ちや事情は理解していても、やはり行動を直してもらうには、叱ったり、強い言葉が有効だという考え方を基本にしていますが、放置するよりは、時にはおだてたりすることも必要だと理解してます`; // N
        textAdvice = '仕事できない人でも、もっと尊重しましょうね。彼（女）にも、人生があるのですから。'; //T
        textShare = `${nickname}の家電タイプは #コードレスクリーナータイプ #自立 #オトナ #冷たい？ #直球勝負 #理性的 #正直 #コミュニケーションタイプ #コミュニケーション  #きづっきー #診断` //U
      } else {
        textTypeNumber = 9;
        textType = `洗濯機`; //  O
        typeImage = `washing-machine`; // ?
        textTypeLong = `八方美人は嫌いです。\n洗濯機タイプ`; // P
        textBubble = `「汚れを落とすには、厳しさも必要。時に洋服が縮んでしまったりもするけど、それは私のせいじゃないわ。とにかく私は汚れを落とすの。」`; // Q
        textBanner = `嫌われるのを恐れない芯の強いタイプです。`; // R
        textBannerText = `あなたは、相手の気持ちや事情よりも、正しさを優先したい考えを持つ傾向があります。相手に正しい行動をとってもらうためには、厳しい言葉をあえて使うことも多いでしょう。また、励ましたほうが、相手の行動が正しくなると判断した場合は、励まそうとすることもできます`; // N
        textAdvice = '正しくても、通らないことがあるのが人生です、残念ですけど。どうせなら社会を変えていきましょう、ゆっくりとね。'; //T
        textShare = `${nickname}の家電タイプは #洗濯機タイプ #厳しい #芯強め #嫌われる？ #全然怖くない #正しく生きたい #コミュニケーションタイプ #コミュニケーション  #きづっきー #診断` //U
      }
    } else {
      if (totalCorrect >= 6) {
        textTypeNumber = 7;
        textType = `ホームシアター`; //  O
        typeImage = `home-theater`; // ?
        textTypeLong = `前しか向かねぇ!\nホームシアタータイプ`; // P
        textBubble = `「後ろ向いてちゃだめだ！後悔したってしょうがない！そもそも自分、前しか見えないんで！」`; // Q
        textBanner = `勝ち気でポジティブ。周囲を元気にする、頼りがいのあるタイプです。`; // R
        textBannerText = `あなたは、相手を十分理解したうえで、叱ることによって成長させることを好みます。相手の事情や気持ちを十分理解しているので、叱ってばかりではいけないことも十分理解しているので、必要な時には励ますこともできます。`; // N
        textAdvice = 'ノリノリで仕事をするのは、それは快感です！でもあなたのノリについていけない人がいるかも？'; //T
        textShare = `${nickname}の家電タイプは #ホームシアタータイプ #前向き #前だけ向く #後悔しない #超勝ち気 #ポジティブ #元気 #頼りがい #コミュニケーションタイプ #コミュニケーション  #きづっきー #診断` //U
      } else if (totalCorrect >= 3) {
        textTypeNumber = 8;
        textType = `コードレスクリーナー`; //  O
        typeImage = `vacuum-cleaner`; // ?
        textTypeLong = `自立したオ・ト・ナ\nコードレスクリーナータイプ`; // P
        textBubble = `「床に物置かないでくださいね。これは大事なものだ！とか、考えるのは自分の仕事じゃないのでね。冷たいと思うかもしれないけど、自立してほしいんです。」`; // Q
        textBanner = `相手を信じて直球勝負ができる、理性的で正直なタイプです。`; // R
        textBannerText = `あなたは、相手の気持ちや事情と、行動の正しさや合理性について、バランスよく考えることができます。相手の気持ちや事情は理解していても、やはり行動を直してもらうには、叱ったり、強い言葉が有効だという考え方を基本にしていますが、放置するよりは、時にはおだてたりすることも必要だと理解してます`; // N
        textAdvice = '仕事できない人でも、もっと尊重しましょうね。彼（女）にも、人生があるのですから。'; //T
        textShare = `${nickname}の家電タイプは #コードレスクリーナータイプ #自立 #オトナ #冷たい？ #直球勝負 #理性的 #正直 #コミュニケーションタイプ #コミュニケーション  #きづっきー #診断` //U
      } else {
        textTypeNumber = 9;
        textType = `洗濯機`; //  O
        typeImage = `washing-machine`; // ?
        textTypeLong = `八方美人は嫌いです。\n洗濯機タイプ`; // P
        textBubble = `「汚れを落とすには、厳しさも必要。時に洋服が縮んでしまったりもするけど、それは私のせいじゃないわ。とにかく私は汚れを落とすの。」`; // Q
        textBanner = `嫌われるのを恐れない芯の強いタイプです。`; // R
        textBannerText = `あなたは、相手の気持ちや事情よりも、正しさを優先したい考えを持つ傾向があります。相手に正しい行動をとってもらうためには、厳しい言葉をあえて使うことも多いでしょう。また、励ましたほうが、相手の行動が正しくなると判断した場合は、励まそうとすることもできます`; // N
        textAdvice = '正しくても、通らないことがあるのが人生です、残念ですけど。どうせなら社会を変えていきましょう、ゆっくりとね。'; //T
        textShare = `${nickname}の家電タイプは #洗濯機タイプ #厳しい #芯強め #嫌われる？ #全然怖くない #正しく生きたい #コミュニケーションタイプ #コミュニケーション  #きづっきー #診断` //U
      }
    }

    // - d +
  } else if (totalNegativeStroke >= totalDiscount && totalDiscount >= totalPositiveStroke) {
    if (!isFree) {
      if (totalCorrect >= 13) {
        textTypeNumber = 10;
        textType = `ドライヤー`; //  O
        typeImage = `hairdryer`; // ?
        textTypeLong = `コミュ力高し！\nドライヤータイプ`; // P
        textBubble = `「髪乾かして寝ないと！風邪ひいちゃうよ。うるさい？だって、心配なんだもん。」`; // Q
        textBanner = `世話焼きで人懐っこく、距離を縮めるのが得意なタイプです。`; // R
        textBannerText = `あなたは、相手の気持ちを十分理解したうえで、叱ることによって成長を促そうとするタイプです。ただし、何回も同じことで叱るよりは、一度叱ったら、あとはよく言えば見守る、悪く言えば放置する傾向化があります`; // N
        textAdvice = '叱られて伸びたという人は、褒められていたらもっと伸びていた、という説もあることを、頭の片隅ね'; //T
        textShare = `${nickname}の家電タイプは #ドライヤータイプ #コミュ力 #世話焼き #人懐っこい #距離縮めます #人のこと心配しがち #コミュニケーションタイプ #コミュニケーション  #きづっきー #診断` //U
      } else if (totalCorrect >= 7) {
        textTypeNumber = 11;
        textType = `冷蔵庫`; //  O
        typeImage = `refrigerator`; // ?
        textTypeLong = `高みを目指す氷の女王\n冷蔵庫タイプ`; // P
        textBubble = `「とにかく冷やして冷やして冷やしまくるわよ！私、手が抜けないの。私が仕事しないと、中身腐っちゃうでしょう。」`; // Q
        textBanner = `自分に厳しく、相手にもハイレベルを求める上昇志向タイプです。`; // R
        textBannerText = `あなたは、事の正否と相手の気持ちをバランスよく考えたうえで、叱ることによって相手の成長を促そうとするタイプです。叱っても相手の行動が変わらなければ、何度も叱ることは好みません`; // N
        textAdvice = 'あのね、もっと褒めてあげなよ💦減るもんじゃなし。'; //T
        textShare = `${nickname}の家電タイプは #冷蔵庫タイプ #高みを目指せ #手抜けない #自分に厳しく #ハイレベル好き #上昇志向 #コミュニケーションタイプ #コミュニケーション  #きづっきー #診断` //U
      } else {
        textTypeNumber = 12;
        textType = `アイロン`; //  O
        typeImage = `iron`; // ?
        textTypeLong = `折り目つけなきゃ、気が済まない！\nアイロンタイプ`; // P
        textBubble = `「シワシワの服を見ると、伸ばさずにはいられません。\nくちゃくちゃの服の良さ？僕には関係ありません。」`; // Q
        textBanner = `頑固で真面目。\n正義感の強い孤高のタイプです。`; // R
        textBannerText = `あなたは、相手の気持ちや事情より、事の正否を重要視するタイプです。相手が間違ったことをした場合は、厳しい態度で臨むことをしがちです。ただし、何度言っても相手が改まらない場合は、かかわりを少なくしていく傾向があります`; // N
        textAdvice = 'なんかあったときでも、苦笑いする余裕が大事。あわてない、あわてない。一休み、一休み'; //T
        textShare = `${nickname}の家電タイプは #アイロンタイプ #折り目ぴーん #シワは嫌い #頑固 #真面目 #正義感の塊 #コミュニケーションタイプ #コミュニケーション  #きづっきー #診断` //U
      }
    } else {
      if (totalCorrect >= 3) {
        textTypeNumber = 10;
        textType = `ドライヤー`; //  O
        typeImage = `hairdryer`; // ?
        textTypeLong = `コミュ力高し！\nドライヤータイプ`; // P
        textBubble = `「髪乾かして寝ないと！風邪ひいちゃうよ。うるさい？だって、心配なんだもん。」`; // Q
        textBanner = `世話焼きで人懐っこく、距離を縮めるのが得意なタイプです。`; // R
        textBannerText = `あなたは、相手の気持ちを十分理解したうえで、叱ることによって成長を促そうとするタイプです。ただし、何回も同じことで叱るよりは、一度叱ったら、あとはよく言えば見守る、悪く言えば放置する傾向化があります`; // N
        textAdvice = '叱られて伸びたという人は、褒められていたらもっと伸びていた、という説もあることを、頭の片隅ね'; //T
        textShare = `${nickname}の家電タイプは #ドライヤータイプ #コミュ力 #世話焼き #人懐っこい #距離縮めます #人のこと心配しがち #コミュニケーションタイプ #コミュニケーション  #きづっきー #診断` //U
      } else if (totalCorrect >= 1) {
        textTypeNumber = 11;
        textType = `冷蔵庫`; //  O
        typeImage = `refrigerator`; // ?
        textTypeLong = `高みを目指す氷の女王\n冷蔵庫タイプ`; // P
        textBubble = `「とにかく冷やして冷やして冷やしまくるわよ！私、手が抜けないの。私が仕事しないと、中身腐っちゃうでしょう。」`; // Q
        textBanner = `自分に厳しく、相手にもハイレベルを求める上昇志向タイプです。`; // R
        textBannerText = `あなたは、事の正否と相手の気持ちをバランスよく考えたうえで、叱ることによって相手の成長を促そうとするタイプです。叱っても相手の行動が変わらなければ、何度も叱ることは好みません`; // N
        textAdvice = 'あのね、もっと褒めてあげなよ💦減るもんじゃなし。'; //T
        textShare = `${nickname}の家電タイプは #冷蔵庫タイプ #高みを目指せ #手抜けない #自分に厳しく #ハイレベル好き #上昇志向 #コミュニケーションタイプ #コミュニケーション  #きづっきー #診断` //U
      } else {
        textTypeNumber = 12;
        textType = `アイロン`; //  O
        typeImage = `iron`; // ?
        textTypeLong = `折り目つけなきゃ、気が済まない！\nアイロンタイプ`; // P
        textBubble = `「シワシワの服を見ると、伸ばさずにはいられません。\nくちゃくちゃの服の良さ？僕には関係ありません。」`; // Q
        textBanner = `頑固で真面目。\n正義感の強い孤高のタイプです。`; // R
        textBannerText = `あなたは、相手の気持ちや事情より、事の正否を重要視するタイプです。相手が間違ったことをした場合は、厳しい態度で臨むことをしがちです。ただし、何度言っても相手が改まらない場合は、かかわりを少なくしていく傾向があります`; // N
        textAdvice = 'なんかあったときでも、苦笑いする余裕が大事。あわてない、あわてない。一休み、一休み'; //T
        textShare = `${nickname}の家電タイプは #アイロンタイプ #折り目ぴーん #シワは嫌い #頑固 #真面目 #正義感の塊 #コミュニケーションタイプ #コミュニケーション  #きづっきー #診断` //U
      }
    }

    // d + -
  } else if (totalDiscount >= totalPositiveStroke && totalPositiveStroke >= totalNegativeStroke) {
    if (!isFree) {
      if (totalCorrect >= 20) {
        textTypeNumber = 13;
        textType = `床暖房`; //  O
        typeImage = `floor-heating`; // ?
        textTypeLong = `おっとり穏やか\n床暖房タイプ`; // P
        textBubble = `「『え、いたの？』って言われることが多いです。存在感は薄めだけどホッとする快適な空間づくりには自信あります。」`; // Q
        textBanner = `主張は控えめ。縁の下の力持ちタイプです。`; // R
        textBannerText = `あなたは、よく言えば人の行動を見守るタイプです。時にその見守りが放置・放任ととられることもあるかもしれません。相手の気持ちや事情は十分に理解して、その点では好意的に見ることが多いのですが、あなたから何かアプローチをするのはあまり好みません。何かしてもらった時の感謝は素直に言えるタイプが多いです`; // N
        textAdvice = '周囲には、あなたに関心持ってる人が必ずいます。大丈夫、大丈夫。'; //T
        textShare = `${nickname}の家電タイプは #床暖房タイプ #存在感薄め #快適な空間づくり #主張しない #縁の下の力持ち #介入苦手 #コミュニケーションタイプ #コミュニケーション  #きづっきー #診断` //U
      } else if (totalCorrect >= 10) {
        textTypeNumber = 14;
        textType = `照明`; //  O
        typeImage = `illumination`; // ?
        textTypeLong = `かげから見守る\n照明タイプ`; // P
        textBubble = `「皆と楽しく笑うあなたも、真っ暗な部屋で泣いていたあなたも知ってるよ。うまいことは言えないけど、ちゃんと見てるよ。」`; // Q
        textBanner = `詮索せず、黙って相手に寄り添えるタイプです。`; // R
        textBannerText = `あなたは相手の気持ちや事情と、行動の正否をバランスよく判断しようとします。ただし、その結果にかかわらず、物事に積極的に介入することは多くありませんが、介入する場合は比較的、人間関係を重視したアプローチを好みます。しかし、何かしてもらった時の感謝は素直に言えるタイプが多いです`; // N
        textAdvice = '素直なあなたは、隠れた人気者かもしれません。もっと笑顔を！目指せアイドル！'; //T
        textShare = `${nickname}の家電タイプは #照明タイプ #見守る #ちゃんと見てます #詮索しない #そっと寄り添う #隠れた人気者 #コミュニケーションタイプ #コミュニケーション  #きづっきー #診断` //U
      } else {
        textTypeNumber = 15;
        textType = `食器洗い機`; //  O
        typeImage = `dishwasher`; // ?
        textTypeLong = `入れてくれれば洗うけど、テーブルまではいきません\n食器洗い機タイプ`; // P
        textBubble = `「できる限りのことはしますよ。でも、食器入れて\nくれなけりゃ、洗いたくても洗えません。\n入れてさえくれれば、ちゃんと仕事できますよ。」`; // Q
        textBanner = `"余分なことには手を出さず、\n自分のことを黙々としたいタイプです。"`; // R
        textBannerText = `あなたは、気持ちや事。情よりも、行動の正否を重要視していますが、それをあまり表に出して、あれこれ発言することは多くないでしょう。基本的には、自分は自分、他人は他人という考え方が強く出る傾向があります。しかし、何かしてもらった時の感謝は素直に言えるタイプが多いです`; // N
        textAdvice = 'もしあなたが挨拶が苦手だったら、ほほえむことから始めてください。ほほえみは世界共通の人に幸せをあげることです'; //T
        textShare = `${nickname}の家電タイプは #食器洗い機タイプ #もくもくと #自分に集中 #他人はほっとく #構わないで #仕事はきっちり #コミュニケーションタイプ #コミュニケーション  #きづっきー #診断` //U
      }
    } else {
      if (totalCorrect >= 6) {
        textTypeNumber = 13;
        textType = `床暖房`; //  O
        typeImage = `floor-heating`; // ?
        textTypeLong = `おっとり穏やか\n床暖房タイプ`; // P
        textBubble = `「『え、いたの？』って言われることが多いです。存在感は薄めだけどホッとする快適な空間づくりには自信あります。」`; // Q
        textBanner = `主張は控えめ。縁の下の力持ちタイプです。`; // R
        textBannerText = `あなたは、よく言えば人の行動を見守るタイプです。時にその見守りが放置・放任ととられることもあるかもしれません。相手の気持ちや事情は十分に理解して、その点では好意的に見ることが多いのですが、あなたから何かアプローチをするのはあまり好みません。何かしてもらった時の感謝は素直に言えるタイプが多いです`; // N
        textAdvice = '周囲には、あなたに関心持ってる人が必ずいます。大丈夫、大丈夫。'; //T
        textShare = `${nickname}の家電タイプは #床暖房タイプ #存在感薄め #快適な空間づくり #主張しない #縁の下の力持ち #介入苦手 #コミュニケーションタイプ #コミュニケーション  #きづっきー #診断` //U
      } else if (totalCorrect >= 3) {
        textTypeNumber = 14;
        textType = `照明`; //  O
        typeImage = `illumination`; // ?
        textTypeLong = `かげから見守る\n照明タイプ`; // P
        textBubble = `「皆と楽しく笑うあなたも、真っ暗な部屋で泣いていたあなたも知ってるよ。うまいことは言えないけど、ちゃんと見てるよ。」`; // Q
        textBanner = `詮索せず、黙って相手に寄り添えるタイプです。`; // R
        textBannerText = `あなたは相手の気持ちや事情と、行動の正否をバランスよく判断しようとします。ただし、その結果にかかわらず、物事に積極的に介入することは多くありませんが、介入する場合は比較的、人間関係を重視したアプローチを好みます。しかし、何かしてもらった時の感謝は素直に言えるタイプが多いです`; // N
        textAdvice = '素直なあなたは、隠れた人気者かもしれません。もっと笑顔を！目指せアイドル！'; //T
        textShare = `${nickname}の家電タイプは #照明タイプ #見守る #ちゃんと見てます #詮索しない #そっと寄り添う #隠れた人気者 #コミュニケーションタイプ #コミュニケーション  #きづっきー #診断` //U
      } else {
        textTypeNumber = 15;
        textType = `食器洗い機`; //  O
        typeImage = `dishwasher`; // ?
        textTypeLong = `入れてくれれば洗うけど、テーブルまではいきません\n食器洗い機タイプ`; // P
        textBubble = `「できる限りのことはしますよ。でも、食器入れて\nくれなけりゃ、洗いたくても洗えません。\n入れてさえくれれば、ちゃんと仕事できますよ。」`; // Q
        textBanner = `"余分なことには手を出さず、\n自分のことを黙々としたいタイプです。"`; // R
        textBannerText = `あなたは、気持ちや事。情よりも、行動の正否を重要視していますが、それをあまり表に出して、あれこれ発言することは多くないでしょう。基本的には、自分は自分、他人は他人という考え方が強く出る傾向があります。しかし、何かしてもらった時の感謝は素直に言えるタイプが多いです`; // N
        textAdvice = 'もしあなたが挨拶が苦手だったら、ほほえむことから始めてください。ほほえみは世界共通の人に幸せをあげることです'; //T
        textShare = `${nickname}の家電タイプは #食器洗い機タイプ #もくもくと #自分に集中 #他人はほっとく #構わないで #仕事はきっちり #コミュニケーションタイプ #コミュニケーション  #きづっきー #診断` //U
      }
    }

    // d - +
  } else if (totalDiscount >= totalNegativeStroke && totalNegativeStroke >= totalPositiveStroke) {
    if (!isFree) {
      if (totalCorrect >= 13) {
        textTypeNumber = 16;
        textType = `空気清浄機`; //  O
        typeImage = `air-purifier`; // ?
        textTypeLong = `寡黙な働き者\n空気清浄機タイプ`; // P
        textBubble = `「地味みたいで、あんまり感謝されたりしないですけど、\n状況を冷静に捉えて、仕事に専念。長い時間、皆のことを\n見守ってます。。実は熱い思いも、持ってます」`; // Q
        textBanner = `シャイな一面と仕事へのプライドを併せ持つ隠れ熱血漢タイプです。`; // R
        textBannerText = `あなたは、相手の事情や気持ちを十分理解して認めますが、基本的には、自分は自分、他人は他人という考え方が基本です。ただし、相手の行動が自分に影響を与えるような場合は、クレームという形で、意見を述べるケースが多いでしょう。何かしてもらった時も、感謝を述べるというのもあまり得意ではありません`; // N
        textAdvice = 'あなたのすぐ近くにいる大切な人に気づいてね。必ずいるのだから。'; //T
        textShare = `${nickname}の家電タイプは #空気清浄機タイプ #働き者 #寡黙 #地味 #シャイ #でも実はプライドあります #コミュニケーションタイプ #コミュニケーション #きづっきー #診断` //U
      } else if (totalCorrect >= 7) {
        textTypeNumber = 17;
        textType = `スマートスピーカー`; //  O
        typeImage = `smart-speaker`; // ?
        textTypeLong = `私、失敗しないので。\nスマートスピーカータイプ`; // P
        textBubble = `「間違ったことはできないから決まったことしかしません。でも決まったことは絶対にミスなく、やり遂げますよ。」`; // Q
        textBanner = `秩序を重んじて抜群の安定感を発揮するタイプです。`; // R
        textBannerText = `あなたは、相手の気持ちや事情と、行動の正否をバランスよく判断しようとします。ただし、それをもとに何か相手に働きかけるようなことはあまりしません。何かしてもらった時も、感謝を述べるというのもあまり得意ではありません`; // N
        textAdvice = '仕事ができるとかできないとかは、人生で大した問題じゃないです。幸せは人によって違いますから。'; //T
        textShare = `${nickname}の家電タイプは #スマートスピーカータイプ #私失敗しないので #ミスなし #秩序 重視 #安定感抜群 #決まったことをしっかり #コミュニケーションタイプ #コミュニケーション #きづっきー #診断` //U
      } else {
        textTypeNumber = 18;
        textType = `ロボット掃除機`; //  O
        typeImage = `robot-vacuum-cleaner`; // ?
        textTypeLong = `見返りは求めない！\nロボット掃除機タイプ`; // P
        textBubble = `「ゴミ一つないぴかぴかの床目指して、もくもくと、ただ一人、頑張ってるわけ。邪魔されたくないわけ。誰も見てくれてなくても良いわけ」`; // Q
        textBanner = `強い意志を持ったクールな一匹狼タイプです。`; // R
        textBannerText = `あなたは、どちらかといえば相手の気持ちや事情よりも、行動の正否に重きを置くタイプですが、そもそも、自分は自分、他人は他人という考え方が非常に強い傾向にあります。一匹狼型ともいえるタイプの人が多いようです`; // N
        textAdvice = '斜に構えずに素直になっても、人はあなたを嫌いになんかなりませんよ'; //T
        textShare = `${nickname}の家電タイプは #ロボット掃除機タイプ #見返り不要 #邪魔するな #意思強め #一匹狼 #クール #コミュニケーションタイプ #コミュニケーション #きづっきー #診断` //U
      }
    } else {
      if (totalCorrect >= 3) {
        textTypeNumber = 16;
        textType = `空気清浄機`; //  O
        typeImage = `air-purifier`; // ?
        textTypeLong = `寡黙な働き者\n空気清浄機タイプ`; // P
        textBubble = `「地味みたいで、あんまり感謝されたりしないですけど、\n状況を冷静に捉えて、仕事に専念。長い時間、皆のことを\n見守ってます。。実は熱い思いも、持ってます」`; // Q
        textBanner = `シャイな一面と仕事へのプライドを併せ持つ隠れ熱血漢タイプです。`; // R
        textBannerText = `あなたは、相手の事情や気持ちを十分理解して認めますが、基本的には、自分は自分、他人は他人という考え方が基本です。ただし、相手の行動が自分に影響を与えるような場合は、クレームという形で、意見を述べるケースが多いでしょう。何かしてもらった時も、感謝を述べるというのもあまり得意ではありません`; // N
        textAdvice = 'あなたのすぐ近くにいる大切な人に気づいてね。必ずいるのだから。'; //T
        textShare = `${nickname}の家電タイプは #空気清浄機タイプ #働き者 #寡黙 #地味 #シャイ #でも実はプライドあります #コミュニケーションタイプ #コミュニケーション #きづっきー #診断`; //U
      } else if (totalCorrect >= 1) {
        textTypeNumber = 17;
        textType = `スマートスピーカー`; //  O
        typeImage = `smart-speaker`; // ?
        textTypeLong = `私、失敗しないので。\nスマートスピーカータイプ`; // P
        textBubble = `「間違ったことはできないから決まったことしかしません。でも決まったことは絶対にミスなく、やり遂げますよ。」`; // Q
        textBanner = `秩序を重んじて抜群の安定感を発揮するタイプです。`; // R
        textBannerText = `あなたは、相手の気持ちや事情と、行動の正否をバランスよく判断しようとします。ただし、それをもとに何か相手に働きかけるようなことはあまりしません。何かしてもらった時も、感謝を述べるというのもあまり得意ではありません`; // N
        textAdvice = '仕事ができるとかできないとかは、人生で大した問題じゃないです。幸せは人によって違いますから。'; //T
        textShare = `${nickname}の家電タイプは #スマートスピーカータイプ #私失敗しないので #ミスなし #秩序 重視 #安定感抜群 #決まったことをしっかり #コミュニケーションタイプ #コミュニケーション #きづっきー #診断` //U
      } else {
        textTypeNumber = 18;
        textType = `ロボット掃除機`; //  O
        typeImage = `robot-vacuum-cleaner`; // ?
        textTypeLong = `見返りは求めない！\nロボット掃除機タイプ`; // P
        textBubble = `「ゴミ一つないぴかぴかの床目指して、もくもくと、ただ一人、頑張ってるわけ。邪魔されたくないわけ。誰も見てくれてなくても良いわけ」`; // Q
        textBanner = `強い意志を持ったクールな一匹狼タイプです。`; // R
        textBannerText = `あなたは、どちらかといえば相手の気持ちや事情よりも、行動の正否に重きを置くタイプですが、そもそも、自分は自分、他人は他人という考え方が非常に強い傾向にあります。一匹狼型ともいえるタイプの人が多いようです`; // N
        textAdvice = '斜に構えずに素直になっても、人はあなたを嫌いになんかなりませんよ'; //T
        textShare = `${nickname}の家電タイプは #ロボット掃除機タイプ #見返り不要 #邪魔するな #意思強め #一匹狼 #クール #コミュニケーションタイプ #コミュニケーション #きづっきー #診断` //U
      }
    }

  } else {

    textType = ``; //  O
    typeImage = ``; // ?
    textTypeLong = ``; // P
    textBubble = ``; // Q
    textBanner = ``; // R
    textBannerText = ``; // N
    textAdvice = ''; //T
    textShare = `` //U

    console.log('unexpeced if')
  }

  return {
    textTypeNumber,
    textType,
    typeImage,
    textTypeLong,
    textBubble,
    textBanner,
    textBannerText,
    textAdvice,
    textShare,
    freeUrl,
    totalCorrect,
    totalPositiveStroke,
    totalNegativeStroke,
    totalDiscount,
    totalFlexibility,
    totalImagination,
    totalReaction,
    totalEmpathy,
    totalFeeling,
  }

}
