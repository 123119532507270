import { API, graphqlOperation } from "aws-amplify";
import { GetAllNursingFreeResults, GetAdminNursingFreeResults, GetNursingFreeResults, GetNursingFreeResult, CreateNursingFreeResult } from "./graphql/nursing-free-results";
import {GetContent} from "./graphql/contents";

export const fetchAllNursingFreeResults = async (userId) => {
  const response = await API.graphql(graphqlOperation(
    GetAllNursingFreeResults,
    { userId },
    "AMAZON_COGNITO_USER_POOLS"
  ));

  return response.data.getAllNursingFreeResults;
};

export const fetchAdminNursingFreeResults = async () => {
  const response = await API.graphql(graphqlOperation(
    GetAdminNursingFreeResults,
    { },
    "AMAZON_COGNITO_USER_POOLS"
  ));

  return response.data.getAdminNursingFreeResults;
};

export const fetchNursingFreeResults = async () => {
  const response = await API.graphql(graphqlOperation(
    GetNursingFreeResults,
    { },
    "AMAZON_COGNITO_USER_POOLS"
  ));

  return response.data.getNursingFreeResults;
};

export const fetchNursingFreeResult = async (id) => {
  // const response = await API.graphql(graphqlOperation(
  //   GetNursingResult,
  //   { id },
  //   "AMAZON_COGNITO_USER_POOLS"
  // ));
  const response = await API.graphql({
    query: GetNursingFreeResult,
    variables: { id },
    authMode: "API_KEY",
  });

  return response.data.getNursingFreeResult;
};

export const createNursingFreeResult = async (input) => {
  // const response = await API.graphql(graphqlOperation(
  //   CreateNursingResult,
  //   { input },
  //   "AMAZON_COGNITO_USER_POOLS"
  // ));
  const response = await API.graphql({
    query: CreateNursingFreeResult,
    variables: { input },
    authMode: "API_KEY",
  });

  return response.data.createNursingFreeResult;
};
