import { API, graphqlOperation } from "aws-amplify";
import { GetAllNursingResults, GetAdminNursingResults, GetNursingResults, GetNursingResult, CreateNursingResult } from "./graphql/nursing-results";
import {GetContent} from "./graphql/contents";

export const fetchAllNursingResults = async (userId) => {
  const response = await API.graphql(graphqlOperation(
    GetAllNursingResults,
    { userId },
    "AMAZON_COGNITO_USER_POOLS"
  ));

  return response.data.getAllNursingResults;
};

export const fetchAdminNursingResults = async () => {
  const response = await API.graphql(graphqlOperation(
    GetAdminNursingResults,
    { },
    "AMAZON_COGNITO_USER_POOLS"
  ));

  return response.data.getAdminNursingResults;
};

export const fetchNursingResults = async () => {
  const response = await API.graphql(graphqlOperation(
    GetNursingResults,
    { },
    "AMAZON_COGNITO_USER_POOLS"
  ));

  return response.data.getNursingResults;
};

export const fetchNursingResult = async (id) => {
  // const response = await API.graphql(graphqlOperation(
  //   GetNursingResult,
  //   { id },
  //   "AMAZON_COGNITO_USER_POOLS"
  // ));
  const response = await API.graphql({
    query: GetNursingResult,
    variables: { id },
    authMode: "API_KEY",
  });

  return response.data.getNursingResult;
};

export const createNursingResult = async (input) => {
  // const response = await API.graphql(graphqlOperation(
  //   CreateNursingResult,
  //   { input },
  //   "AMAZON_COGNITO_USER_POOLS"
  // ));
  const response = await API.graphql({
    query: CreateNursingResult,
    variables: { input },
    authMode: "API_KEY",
  });

  return response.data.createNursingResult;
};
