export const MutsationTestCSVLayoutC = `
mutation TestCSVLayoutC($input: HtmlToPdfInput!) {
  testCSVLayoutC(input: $input) {
    locations
  }
}
`;

export const MutsationTestCSVLayoutCNew = `
mutation TestCSVLayoutCNew($input: HtmlToPdfInput!) {
  testCSVLayoutCNew(input: $input) {
    locations
  }
}
`;