import React, { Component } from "react";
import {Alert, Button, ButtonToolbar, Card, Dropdown, DropdownButton, Form} from "react-bootstrap";
import { SmallBgLoading } from "../../../components/commons/SmallBgLoading"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck, faMinusCircle, faPlusCircle} from "@fortawesome/free-solid-svg-icons";
import uuid from "uuid";
import { Storage } from "aws-amplify";
import * as commonService from "../../../services/common";
import * as contentsService  from "../../../services/contents";

class SubtleTestPage extends Component {

  constructor(props, context) {
    super(props, context);

    this.state = {
      contentId: 'SUBTLE',
      comicIntroductionSvgUrl: { separatedLanguage: true, required: true },
      questions: {
        image: {
          separatedLanguage: true,
          required: true
        },
        text: {
          separatedLanguage: true,
          required: true
        },
        subtitle: {
          separatedLanguage: true,
          required: true
        },
        imagination: {
          separatedLanguage: false,
          required: true
        },
        reaction: {
          separatedLanguage: false,
          required: true
        },
        empathy: {
          separatedLanguage: false,
          required: true
        },
        feeling: {
          separatedLanguage: false,
          required: true
        },
        flexibility: {
          separatedLanguage: false,
          required: true
        },
        answer1: {
          separatedLanguage: true,
          required: true
        },
        answer1IsCorrect: {
          separatedLanguage: false,
          required: false
        },
        answer2: {
          separatedLanguage: true,
          required: true
        },
        answer2IsCorrect: {
          separatedLanguage: false,
          required: false
        },
        answer3: {
          separatedLanguage: true,
          required: true
        },
        answer3IsCorrect: {
          separatedLanguage: false,
          required: false
        },
        value: [...Array(30).keys()].map(index => { return {}; }),
        multiply: true
      },
      selectedQuestion: 0,
      languages: [],
      selectedLanguage: null,
      data: null,

      error: null,
      loading: false,
    };
  }

  async componentWillMount() {
    this.setState({loading: true});

    try {
      let state = {loading: false};
      let content = await contentsService.fetchContent(this.state.contentId);
      if (content) {
        state.data = content;
        state.languages = JSON.parse(state.data.languages);

        let fields = ["comicIntroductionSvgUrl"];
        for (const field of fields) {
          if (this.state[field].separatedLanguage) {
            for (let langIndex in state.languages) {
              if (!state[field]) state[field] = {...this.state[field]};
              try {
                state[field][state.languages[langIndex]] = JSON.parse(state.data[field])[state.languages[langIndex]];
              } catch (e) {}
            }

          } else {
            state[field] = { value: state.data[field], ...this.state[field]};
          }
        }

        let settings = JSON.parse(state.data.settings);
        state.questions = this.state.questions;
        state.questions.value = settings.questions;
      } else {
        state.languages = [commonService.mainLanguage];
      }

      state.selectedLanguage = commonService.mainLanguage;

      this.setState(state);
    } catch (error) {
      this.onError(error);
    }
  }

  onError = (error) => {
    if (error.errors) {
      console.error(error.errors[0]);
      this.setState({ loading: false, error: error.errors[0] });
    } else {
      console.error(error);
      this.setState({ loading: false, error });
    }
  };

  onAddLanguage = (lang) => {
    let languages = this.state.languages;
    languages.push(lang);
    this.setState({ languages, selectedLanguage: lang });
  };

  onDeleteLanguage = (lang) => {
    let languages = this.state.languages.filter((item) => item !== lang);
    this.setState({ languages });
  };

  onChangeLanguage = (lang) => {
    if (this.state.selectedLanguage !== lang) {
      this.setState({ selectedLanguage: lang });
    }
  };

  onDoneClick = async () => {

    try {
      let fields = ["comicIntroductionSvgUrl"];
      let input = {};
      for (const field of fields) {
        if (this.state[field].separatedLanguage) {
          let values = {};
          for (let langIndex in this.state.languages) {
            let value = this.state[field][this.state.languages[langIndex]];
            values[this.state.languages[langIndex]] = value ? value : null;
          }
          input[field] = JSON.stringify(values);
        } else {
          input[field] = this.state[field].value ? this.state[field].value : null;
        }
      }

      input.title = this.state.contentId;
      input.languages = JSON.stringify(this.state.languages);
      input.settings = JSON.stringify({questions: this.state["questions"].value});

      input.contentType = "SUBTLE";
      input.id = this.state.contentId;

      await contentsService.createContent(input);

      this.setState({loading: false});

      this.props.history.push(`/admin/`);
    } catch (error) {
      this.onError(error);
    }
  };

  onQuestionClick = (index) => {
    this.setState({selectedQuestion: index});
  };

  onFileUpload = async (fieldName, key, file) => {
    const {contentId} = this.state;
    let filename = "question-" + key + "-" + uuid() + "." + file.name.split('.').pop();

    const s3Object = await Storage.put(`contents/subtle-${contentId}/${filename}`, file, {
      contentType: file.type,
      level: "public"
    });

    let url = `https://s3-ap-northeast-1.amazonaws.com/${process.env.REACT_APP_S3_BUCKET}/public/${s3Object.key}`;

    this.onFormControlChanged(fieldName, url);
  };

  onFormControlChanged = (fieldName, value) => {
    let pathComponents = fieldName.split(".");

    let data = {...this.state[pathComponents[0]]};

    if (pathComponents.length > 1) {
      if (data[pathComponents[2]].separatedLanguage) {
        if (data.value[parseInt(pathComponents[1])][pathComponents[2]]) {
          data.value[parseInt(pathComponents[1])][pathComponents[2]][this.state.selectedLanguage] = value;
        } else {
          let item = {};
          item[this.state.selectedLanguage] = value;
          data.value[parseInt(pathComponents[1])][pathComponents[2]] = item;
        }
      } else {
        data.value[parseInt(pathComponents[1])][pathComponents[2]] = { value };
      }
    } else {
      if (this.state[pathComponents[0]].separatedLanguage) {
        data[this.state.selectedLanguage] = value;
      } else {
        data.value = value;
      }
    }

    let state = {};
    state[pathComponents[0]] = data;

    if (this.state.error) {
      state.error = null;
    }

    this.setState(state);
  };

  formFieldRequiredValidation = (fieldName, isTouched = false) => {
    if (!isTouched && !this.state.touched) {
      return true;
    }

    let pathComponents = fieldName.split(".");

    if (pathComponents.length > 1) {
      if (this.state[pathComponents[0]][pathComponents[2]].separatedLanguage) {
        for (let i in this.state.languages) {
          let item = this.state[pathComponents[0]].value[parseInt(pathComponents[1])];
          let value = item[pathComponents[2]] && item[pathComponents[2]][this.state.languages[i]];
          if (!(value && value.length > 0)) return false;
        }
        return true;
      } else {
        let item = this.state[pathComponents[0]].value[parseInt(pathComponents[1])];
        return item[pathComponents[2]] && item[pathComponents[2]].value && item[pathComponents[2]].value.length > 0;
      }
    } else {
      if (this.state[pathComponents[0]].separatedLanguage) {
        for (let i in this.state.languages) {
          let value = this.state[pathComponents[0]][this.state.languages[i]];
          if (!(value && value.length > 0)) return false;
        }
        return true;
      } else {
        let value = this.state[pathComponents[0]].value;
        return value && value.length > 0;
      }
    }
  };

  formFieldRequiredValidationByLanguage = (fieldName) => {
    let pathComponents = fieldName.split(".");
    for (let i in this.state.languages) {
      if (pathComponents.length > 1) {
        let value = this.state[pathComponents[0]].value[parseInt(pathComponents[1])][this.state.languages[i]];
        if (!(value && value.length > 0)) return commonService.languages[this.state.languages[i]];
      } else {
        let value = this.state[pathComponents[0]][this.state.languages[i]];
        if (!(value && value.length > 0)) return commonService.languages[this.state.languages[i]];
      }
    }

    return "";
  };

  getFormControlValue = (fieldName) => {
    let pathComponents = fieldName.split(".");

    if (pathComponents.length > 1) {
      if (this.state[pathComponents[0]][pathComponents[2]].separatedLanguage) {
        if (
          this.state[pathComponents[0]].value[pathComponents[1]][pathComponents[2]] &&
          this.state[pathComponents[0]].value[pathComponents[1]][pathComponents[2]][this.state.selectedLanguage]
        ) {
          return this.state[pathComponents[0]].value[pathComponents[1]][pathComponents[2]][this.state.selectedLanguage];
        }  else {
          return "";
        }
      } else {
        if (
          this.state[pathComponents[0]].value[pathComponents[1]][pathComponents[2]] &&
          this.state[pathComponents[0]].value[pathComponents[1]][pathComponents[2]].value
        ) {
          return this.state[pathComponents[0]].value[pathComponents[1]][pathComponents[2]].value;
        }  else {
          return "";
        }
      }

    } else {
      if (this.state[fieldName].separatedLanguage) {
        let value = this.state[fieldName][this.state.selectedLanguage];
        return value ? value : "";
      } else {
        let value = this.state[fieldName].value;
        return value ? value : "";
      }
    }
  };

  renderToolbar = () => {
    return (
      <ButtonToolbar className="mt-4 justify-content-between align-items-center">
        <h1 className="m-0">機微力テスト編集</h1>
        <div className="d-flex">
          <Dropdown className="mr-2">
            <Dropdown.Toggle variant="success">
              {commonService.languages[this.state.selectedLanguage]}
            </Dropdown.Toggle>

            <Dropdown.Menu>
              {this.state.languages.map((key) => (
                <Dropdown.Item
                  key={key}
                  className="d-flex justify-content-between align-items-center"
                >
                  <div onClick={() => this.onChangeLanguage(key)}>{commonService.languages[key]}</div>
                  {this.state.selectedLanguage === key && (
                    <div>
                      <FontAwesomeIcon className="text-secondary" icon={faCheck} />
                    </div>
                  )}
                  {this.state.selectedLanguage !== key && (
                    <div onClick={() => this.onDeleteLanguage(key)}>
                      <FontAwesomeIcon className="icon-hover icon-danger" icon={faMinusCircle} />
                    </div>
                  )}
                </Dropdown.Item>
              ))}
              {Object.keys(commonService.languages).filter(item => !this.state.languages.includes(item)).length > 0 && (<Dropdown.Divider />)}
              {Object.keys(commonService.languages).filter(item => !this.state.languages.includes(item)).map((key) => (
                <Dropdown.Item key={key}
                               className="d-flex justify-content-between align-items-center"
                               onClick={() => this.onAddLanguage(key)}>
                  <div>{commonService.languages[key]}</div>
                  <FontAwesomeIcon className="icon-hover text-success" icon={faPlusCircle} />
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>

          <Button variant="primary"
                  type="submit"
                  className="mr-2"
                  onClick={this.onDoneClick}>保存</Button>
        </div>
      </ButtonToolbar>
    );
  };

  renderGeneral = () => {
    return (
      <div className="mt-4 mb-4">
        <Card>
          <Card.Header className="d-flex justify-content-between align-items-center">
            人物相関図
            {!this.getFormControlValue("comicIntroductionSvgUrl") && (
              <div className='btn btn-primary'>
                <label htmlFor="file" style={{marginBottom: 0}}>
                  イラストをアップロード
                </label>
                <input id="file" accept="image/svg+xml,|image/png,image/jpeg" type='file' style={{display: 'none'}} onChange={(e) => this.onFileUpload("comicIntroductionSvgUrl", "top", e.target.files[0])} />
              </div>
            )}
            {this.getFormControlValue("comicIntroductionSvgUrl") && (<Button variant="primary" onClick={() => this.onFormControlChanged("comicIntroductionSvgUrl", null)}>イラストを削除</Button>)}
          </Card.Header>
          {this.getFormControlValue("comicIntroductionSvgUrl") && (<Card.Img variant="top"style={{ maxHeight: '20rem', objectFit: 'contain' }} src={this.getFormControlValue('comicIntroductionSvgUrl')} />)}
          <Card.Body>
            {this.formFieldRequiredValidation("comicIntroductionSvgUrl") && !this.getFormControlValue('comicIntroductionSvgUrl') && (<Card.Text>svgファイルのみ</Card.Text>)}
            {!this.formFieldRequiredValidation("comicIntroductionSvgUrl") && (<Card.Text className="text-danger">[{this.formFieldRequiredValidationByLanguage("comicIntroductionSvgUrl")}] イラストをアップロードしてください</Card.Text>)}
          </Card.Body>
        </Card>
      </div>
    );
  };

  renderQuestions = () => {
    let {selectedQuestion} = this.state;

    return (
      <>
        <ButtonToolbar className="mt-4 justify-content-between align-items-center">
          <h1 className="m-0">テスト問題</h1>

          <DropdownButton title={`問${selectedQuestion + 1}`}>
            {[...Array(30).keys()].map(questionIndex => (
              <Dropdown.Item key={questionIndex} onClick={() => this.onQuestionClick(questionIndex)}>
                問{questionIndex + 1}
              </Dropdown.Item>
            ))}
          </DropdownButton>
        </ButtonToolbar>

        <Card className="mt-4">
          <Card.Header className="d-flex justify-content-between align-items-center">
            <div>イラスト</div>
            <div>
              {!this.getFormControlValue(`questions.${selectedQuestion}.image`) && (
                <div className='btn btn-primary'>
                  <label htmlFor="question-file" style={{marginBottom: 0}}>
                    イラストをアップロード
                  </label>
                  <input id="question-file" accept="image/svg+xml,|image/png,image/jpeg" type='file' style={{display: 'none'}} onChange={(e) => this.onFileUpload(`questions.${selectedQuestion}.image`, "image", e.target.files[0])} />
                </div>
              )}
              {this.getFormControlValue(`questions.${selectedQuestion}.image`) && (<Button variant="primary" onClick={() => this.onFormControlChanged(`questions.${selectedQuestion}.image`, null)}>イラストを削除</Button>)}
            </div>
          </Card.Header>
          {this.getFormControlValue(`questions.${selectedQuestion}.image`) && (<Card.Img variant="top"style={{ maxHeight: '20rem', objectFit: 'contain' }} src={this.getFormControlValue(`questions.${selectedQuestion}.image`)} />)}
        </Card>

        <Form id="form" className="mt-4">
          <Form.Group>
            <Form.Label>テキスト</Form.Label>
            <Form.Control required
                          type="text"
                          value={this.getFormControlValue(`questions.${selectedQuestion}.text`)}
                          onChange={(e) => this.onFormControlChanged(`questions.${selectedQuestion}.text`, e.target.value)}
                          isInvalid={!this.formFieldRequiredValidation(`questions.${selectedQuestion}.text`)} />
            <Form.Control.Feedback type="invalid">
              [{this.formFieldRequiredValidationByLanguage(`questions.${selectedQuestion}.text`)}] テキストを入力してください
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group>
            <Form.Label>サブタイトル</Form.Label>
            <Form.Control required
                          type="text"
                          value={this.getFormControlValue(`questions.${selectedQuestion}.subtitle`)}
                          onChange={(e) => this.onFormControlChanged(`questions.${selectedQuestion}.subtitle`, e.target.value)}
                          isInvalid={!this.formFieldRequiredValidation(`questions.${selectedQuestion}.subtitle`)} />
            <Form.Control.Feedback type="invalid">
              [{this.formFieldRequiredValidationByLanguage(`questions.${selectedQuestion}.subtitle`)}] サブタイトルを入力してください
            </Form.Control.Feedback>
          </Form.Group>
          {this.renderQuestionSkills()}
          <Form.Group>
            <Form.Label>回答①</Form.Label>
            <Form.Control required
                          type="text"
                          value={this.getFormControlValue(`questions.${selectedQuestion}.answer1`)}
                          onChange={(e) => this.onFormControlChanged(`questions.${selectedQuestion}.answer1`, e.target.value)}
                          isInvalid={!this.formFieldRequiredValidation(`questions.${selectedQuestion}.answer1`)} />
            <Form.Control.Feedback type="invalid">
              [{this.formFieldRequiredValidationByLanguage(`questions.${selectedQuestion}.answer1`)}] 回答①を入力してください
            </Form.Control.Feedback>
            <Form.Check
              type="checkbox"
              label="回答①は正しい？"
              checked={this.getFormControlValue(`questions.${selectedQuestion}.answer1IsCorrect`) === "1"}
              onChange={(e) => this.onFormControlChanged(`questions.${selectedQuestion}.answer1IsCorrect`, e.target.checked ? "1" : "0")}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>回答②</Form.Label>
            <Form.Control required
                          type="text"
                          value={this.getFormControlValue(`questions.${selectedQuestion}.answer2`)}
                          onChange={(e) => this.onFormControlChanged(`questions.${selectedQuestion}.answer2`, e.target.value)}
                          isInvalid={!this.formFieldRequiredValidation(`questions.${selectedQuestion}.answer2`)} />
            <Form.Control.Feedback type="invalid">
              [{this.formFieldRequiredValidationByLanguage(`questions.${selectedQuestion}.answer2`)}] 回答②を入力してください
            </Form.Control.Feedback>
            <Form.Check
              type="checkbox"
              label="回答②は正しい？"
              checked={this.getFormControlValue(`questions.${selectedQuestion}.answer2IsCorrect`) === "1"}
              onChange={(e) => this.onFormControlChanged(`questions.${selectedQuestion}.answer2IsCorrect`, e.target.checked ? "1" : "0")}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>回答③</Form.Label>
            <Form.Control required
                          type="text"
                          value={this.getFormControlValue(`questions.${selectedQuestion}.answer3`)}
                          onChange={(e) => this.onFormControlChanged(`questions.${selectedQuestion}.answer3`, e.target.value)}
                          isInvalid={!this.formFieldRequiredValidation(`questions.${selectedQuestion}.answer3`)} />
            <Form.Control.Feedback type="invalid">
              [{this.formFieldRequiredValidationByLanguage(`questions.${selectedQuestion}.answer3`)}] 回答③を入力してください
            </Form.Control.Feedback>
            <Form.Check
              type="checkbox"
              label="回答③は正しい？"
              checked={this.getFormControlValue(`questions.${selectedQuestion}.answer3IsCorrect`) === "1"}
              onChange={(e) => this.onFormControlChanged(`questions.${selectedQuestion}.answer3IsCorrect`, e.target.checked ? "1" : "0")}
            />
          </Form.Group>
        </Form>
      </>
    );
  };

  renderQuestionSkills = () => {
    const {selectedQuestion} = this.state;
    const skills = [
      {key: 'imagination', label: '状況想像力'},
      {key: 'reaction', label: 'リアクション力'},
      {key: 'empathy', label: '共感力'},
      {key: 'feeling', label: '気持ち察し力'},
      {key: 'flexibility', label: '柔軟性'},
    ];

    return (
      <Form.Group>
        <Form.Label>能力</Form.Label>
        {skills.map(item => (
          <Form.Check
            key={item.key}
            type="checkbox"
            label={item.label}
            checked={this.getFormControlValue(`questions.${selectedQuestion}.${item.key}`) === "1"}
            onChange={(e) => this.onFormControlChanged(`questions.${selectedQuestion}.${item.key}`, e.target.checked ? "1" : "0")}
          />
        ))}
      </Form.Group>
    );
  };

  render() {
    return (
      <>
        <SmallBgLoading isLoading={this.state.loading}>
          {this.state.error && (<Alert variant="danger" className="mt-4">{this.state.error.message}</Alert>)}
          {this.renderToolbar()}
          {this.renderGeneral()}
        </SmallBgLoading>
        {this.renderQuestions()}
      </>
    );
  }
}

export default SubtleTestPage;
