export const GetCoursesTestResults = `
  query {
    getCoursesTestResults {
        items {
            id
            results
            createdAt
            course {
                id
                title
                testQuestions {
                    id
                    title
                }
            }
        }
    }
  }
`;

export const GetAllCoursesTestResults = `
  query GetAllCoursesTestResults($userId: ID!){
    getAllCoursesTestResults(userId: $userId) {
      items {
        id
        results
        createdAt
        course {
          id
          title
          testQuestions {
              id
              title
              answers
          }
        }
      }
    }
  }
`;

export const GetAdminCoursesTestResults = `
    query GetAdminCoursesTestResults{
        getAdminCoursesTestResults {
            items {
                id
                results
                createdAt
                course {
                    id
                    title
                    testQuestions {
                        id
                        title
                    }
                }
                user {
                    id
                    name
                    parentId
                }
            }
        }
    }
`;

export const GetCourseTestResult = `
  query GetCourseTestResult($id: ID!) {
    getCourseTestResult(id: $id) {
      id
      results
      course {
        id
        title
        testQuestions {
          id
          title
        }
      }
    }
  }
`;

export const CreateCourseTestResult = `
  mutation CreateCourseTestResult($input: CourseTestResultInput!) {
    createCourseTestResult(input: $input) {
      id
    }
  }
`;
