import React, { Component } from "react";
import { connect } from "react-redux";
import {Alert, Button, ButtonToolbar, Form, ListGroup, Dropdown, Card} from "react-bootstrap";
import uuid from "uuid";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import * as contentsService  from "../../../../services/contents";
import * as practiceQuestionsService  from "../../../../services/practice-questions";
import * as testQuestionsService  from "../../../../services/test-questions";
import * as commonService  from "../../../../services/common";
import { SmallBgLoading } from "../../../../components/commons/SmallBgLoading"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlusCircle, faMinusCircle, faCheck, faBars } from '@fortawesome/free-solid-svg-icons'
import "./index.scss";

class CoursePage extends Component {

  constructor(props, context) {
    super(props, context);

    this.state = {
      practiceQuestions: [],
      testQuestions: [],
      settings: {},
      data: null,

      touched: false,
      title: { separatedLanguage: true },
      description: { separatedLanguage: true },
      languages: [],

      selectedLanguage: null,

      error: null,
      loading: false,
    };
  }

  async componentWillMount() {
    this.setState({loading: true});

    try {
      let state = {loading: false};

      if (this.props.match.params.courseId !== "new") {

        // Content
        state.data = await contentsService.fetchContent(this.props.match.params.courseId);
        state.languages = JSON.parse(state.data.languages);
        state.settings = state.data && state.data.settings ? JSON.parse(state.data.settings) : {};
        let fields = ["title", "description"];
        for (let fieldIndex in fields) {
            if (this.state[fields[fieldIndex]].separatedLanguage) {
              for (let langIndex in state.languages) {
                if (!state[fields[fieldIndex]]) state[fields[fieldIndex]] = {...this.state[fields[fieldIndex]]};
                state[fields[fieldIndex]][state.languages[langIndex]] = JSON.parse(state.data[fields[fieldIndex]])[state.languages[langIndex]];
              }

            } else {
              state[fields[fieldIndex]] = { value: state.data[fields[fieldIndex]], ...this.state[fields[fieldIndex]]};
            }
        }

        // Practice Questions
        let practiceQuestionsResponse = await practiceQuestionsService.fetchPracticeQuestions(this.props.match.params.courseId);

        if (state.settings && state.settings.practiceQuestions) {
          let order = state.settings.practiceQuestions;
          let items = practiceQuestionsResponse.items;
          items = items.map(function(item) {
            var n = order.indexOf(item.id);
            order[n] = '';
            return [n, item]
          }).sort().map(function(j) { return j[1] });
          state.practiceQuestions = items;
        } else {
          state.practiceQuestions = practiceQuestionsResponse.items;
        }

        // Test Questions
        let testQuestionsResponse = await testQuestionsService.fetchTestQuestions(this.props.match.params.courseId);
        state.testQuestions = testQuestionsResponse.items;
      } else {
        state.languages = [commonService.mainLanguage]
      }
      state.selectedLanguage = commonService.mainLanguage;
      this.setState(state);
    } catch (error) {
      this.onError(error);
    }

  }

  onCancelClick = () => {
    this.props.history.push("/admin/courses");
  };

  onDeleteClick = async () => {
    this.setState({error: null, loading: true});

    try {
      await contentsService.deleteContent(this.state.data.id);

      // update order
      let setting = await contentsService.fetchContentSetting("COURSE");
      let order = [];
      if (setting && setting.order) {
        order = JSON.parse(setting.order);
      }
      let index = order.indexOf(this.state.data.id);
      if (index !== -1) {
        order.splice(index, 1);
        await contentsService.updateContentSetting({id: "COURSE", order: JSON.stringify(order)});
      }

      this.setState({loading: false});
      this.props.history.push("/admin/courses/");
    } catch (error) {
      this.onError(error);
    }
  };

  onDoneClick = () => {
    document
      .getElementById('form')
      .dispatchEvent(new Event('submit', { cancelable: true }))
  };

  onSubmit = async (event) => {
    event.preventDefault();
    this.setState({error: null, touched: true});
    if (!this.formRequiredValidation(["title"])) return;

    this.setState({loading: true});

    try {
      let fields = ["title", "description"];
      let input = {};
      for (let fieldIndex in fields) {
        if (this.state[fields[fieldIndex]].separatedLanguage) {
          let values = {};
          for (let langIndex in this.state.languages) {
            let value = this.state[fields[fieldIndex]][this.state.languages[langIndex]];
            values[this.state.languages[langIndex]] = value ? value : null;
          }
          input[fields[fieldIndex]] = JSON.stringify(values);
        } else {
          input[fields[fieldIndex]] = this.state[fields[fieldIndex]].value;
        }
      }

      input.languages = JSON.stringify(this.state.languages);

      if (this.state.data) {
        input.id = this.state.data.id;
        input.settings = this.state.data.settings;
        await contentsService.updateContent(input);
      } else {
        input.id = uuid.v4();
        input.contentType = "COURSE";
        await contentsService.createContent(input);

        // update order
        let setting = await contentsService.fetchContentSetting("COURSE");
        let order = [];
        if (setting && setting.order) {
          order = JSON.parse(setting.order);
        }
        order.push(input.id);
        await contentsService.updateContentSetting({id: "COURSE", order: JSON.stringify(order)});
      }

      this.setState({loading: false});
      this.props.history.push("/admin/courses");
    } catch (error) {
      this.onError(error);
    }
  };

  onAddLanguage = (lang) => {
    let languages = this.state.languages;
    languages.push(lang);
    this.setState({ languages, selectedLanguage: lang });
  };

  onDeleteLanguage = (lang) => {
    let languages = this.state.languages.filter((item) => item !== lang);
    this.setState({ languages });
  };

  onChangeLanguage = (lang) => {
    if (this.state.selectedLanguage !== lang) {
      this.setState({ selectedLanguage: lang });
    }
  };

  onCreatePracticeQuestionClick = () => {
    this.props.history.push(`/admin/courses/${this.props.match.params.courseId}/practice-questions/new`);
  };

  onPracticeQuestionEditClick = (item) => {
    this.props.history.push(`/admin/courses/${this.props.match.params.courseId}/practice-questions/${item.id}`);
  };

  onCreateTestQuestionClick = () => {
    this.props.history.push(`/admin/courses/${this.props.match.params.courseId}/test-questions/new`);
  };

  onTestQuestionEditClick = (item) => {
    this.props.history.push(`/admin/courses/${this.props.match.params.courseId}/test-questions/${item.id}`);
  };

  onDragEnd = async (result) => {
    if (!result.destination) return;

    // Update local list
    const practiceQuestions = Array.from(this.state.practiceQuestions);
    const [removed] = practiceQuestions.splice(result.source.index, 1);
    practiceQuestions.splice(result.destination.index, 0, removed);
    this.setState({ practiceQuestions });

    // Update db
    let settings = this.state.settings;
    settings.practiceQuestions = practiceQuestions.map(item => item.id);

    await contentsService.updateContent({
      id: this.state.data.id,
      languages: this.state.data.languages,
      title: this.state.data.title,
      settings: JSON.stringify(settings),
      description: this.state.data.description
    });
    let data = await contentsService.fetchContent(this.state.data.id);
    this.setState({ data, settings });
  };

  onError = (error) => {
    if (error.errors) {
      console.error(error.errors[0]);
      this.setState({ loading: false, error: error.errors[0] });
    } else {
      console.error(error);
      this.setState({ loading: false, error });
    }
  };

  onFormControlChanged = (fieldName, value) => {
    let data = {...this.state[fieldName]};

    if (this.state[fieldName].separatedLanguage) {
      data[this.state.selectedLanguage] = value;
    } else {
      data.value = value;
    }

    let state = {};
    state[fieldName] = data;

    this.setState(state);
  };

  getFormControlValue = (fieldName) => {
    if (this.state[fieldName].separatedLanguage) {
      let value = this.state[fieldName][this.state.selectedLanguage];
      return value ? value : "";
    } else {
      let value = this.state[fieldName].value;
      return value ? value : "";
    }
  };

  formRequiredValidation = (fileds) => {
    for (let i in fileds) {
      if (!this.formFieldRequiredValidation(fileds[i], true)) return false;
    }

    return true;
  };

  formFieldRequiredValidation = (fieldName, isTouched = false) => {
    if (!isTouched && !this.state.touched) {
      return true;
    }

    if (this.state[fieldName].separatedLanguage) {
      for (let i in this.state.languages) {
        let value = this.state[fieldName][this.state.languages[i]];
        if (!(value && value.length > 0)) return false;
      }
      return true;
    } else {
      let value = this.state[fieldName].value;
      return value && value.length > 0;
    }

  };

  formFieldRequiredValidationByLanguage = (fieldName) => {
    for (let i in this.state.languages) {
      let value = this.state[fieldName][this.state.languages[i]];
      if (!(value && value.length > 0)) return commonService.languages[this.state.languages[i]];
    }

    return "";
  };

  renderToolbar = () => {
    let isNew = this.props.match.params.courseId === "new";

    return (
      <ButtonToolbar className="mt-4 justify-content-between align-items-center">
        <h1 className="m-0">コース{isNew ? "制作" : "編集"}</h1>
        <div className="d-flex">
          <Dropdown className="mr-2">
            <Dropdown.Toggle variant="success">
              {commonService.languages[this.state.selectedLanguage]}
            </Dropdown.Toggle>

            <Dropdown.Menu>
              {this.state.languages.map((key) => (
                <Dropdown.Item
                  key={key}
                  className="d-flex justify-content-between align-items-center"
                >
                  <div onClick={() => this.onChangeLanguage(key)}>{commonService.languages[key]}</div>
                  {this.state.selectedLanguage === key && (
                    <div>
                      <FontAwesomeIcon className="text-secondary" icon={faCheck} />
                    </div>
                  )}
                  {this.state.selectedLanguage !== key && (
                    <div onClick={() => this.onDeleteLanguage(key)}>
                      <FontAwesomeIcon className="icon-hover icon-danger" icon={faMinusCircle} />
                    </div>
                  )}
                </Dropdown.Item>
              ))}
              {Object.keys(commonService.languages).filter(item => !this.state.languages.includes(item)).length > 0 && (<Dropdown.Divider />)}
              {Object.keys(commonService.languages).filter(item => !this.state.languages.includes(item)).map((key) => (
                <Dropdown.Item key={key}
                               className="d-flex justify-content-between align-items-center"
                               onClick={() => this.onAddLanguage(key)}>
                  <div>{commonService.languages[key]}</div>
                  <FontAwesomeIcon className="icon-hover text-success" icon={faPlusCircle} />
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>

          <Button variant="primary"
                  type="submit"
                  className="mr-2"
                  onClick={this.onDoneClick}>保存</Button>
          <Button variant="outline-secondary"
                  onClick={this.onCancelClick}>キャンセル</Button>
        </div>
      </ButtonToolbar>
    );
  };

  renderForm = () => {
    return (
      <Form id="form" className="mt-4" onSubmit={this.onSubmit}>
        <Form.Group>
          <Form.Label>タイトル</Form.Label>
          <Form.Control required
                        type="text"
                        value={this.getFormControlValue("title")}
                        onChange={(e) => this.onFormControlChanged("title", e.target.value)}
                        isInvalid={!this.formFieldRequiredValidation("title")} />
          <Form.Control.Feedback type="invalid">
            [{this.formFieldRequiredValidationByLanguage("title")}] タイトルを入力してください
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group>
          <Form.Label>説明</Form.Label>
          <Form.Control as="textarea"
                        rows="3"
                        value={this.getFormControlValue("description")}
                        onChange={(e) => this.onFormControlChanged("description", e.target.value)} />
        </Form.Group>
      </Form>
    );
  };

  renderPracticeQuestions = () => {
    return (
      <>
        <ButtonToolbar className="mt-4 justify-content-between align-items-center">
          <h1 className="m-0">練習問題</h1>
          <div>
            <Button variant="primary"
                    type="submit"
                    disabled={false}
                    onClick={this.onCreatePracticeQuestionClick}>制作</Button>
          </div>
        </ButtonToolbar>

        <ListGroup className="mt-3">
          <DragDropContext onDragEnd={this.onDragEnd}>
            <Droppable droppableId="droppable">
              {(provided, snapshot) => (
                <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                >
                  {this.state.practiceQuestions.map((item, index) => (
                    <Draggable key={item.id} draggableId={item.id} index={index}>
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          className="d-flex justify-content-between align-items-center list-group-item pointer"
                          onClick={() => this.onPracticeQuestionEditClick(item)}
                        >
                          <div>
                            <FontAwesomeIcon className="text-secondary mr-2 grab" icon={faBars} />
                            {JSON.parse(item.title)[this.state.selectedLanguage] + " "}
                          </div>
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
          {this.state.practiceQuestions.length === 0 && (
            <ListGroup.Item>
              何も見つかりませんでした
            </ListGroup.Item>
          )}
        </ListGroup>
      </>
    );
  };

  renderTestQuestions = () => {
    return (
      <>
        <ButtonToolbar className="mt-4 justify-content-between align-items-center">
          <h1 className="m-0">テスト問題</h1>
          <div>
            <Button variant="primary"
                    type="submit"
                    disabled={false}
                    onClick={this.onCreateTestQuestionClick}>制作</Button>
          </div>
        </ButtonToolbar>

        <ListGroup className="mt-3">
          {this.state.testQuestions.map(item => (
            <ListGroup.Item key={item.id} className="d-flex justify-content-between align-items-center pointer" onClick={() => this.onTestQuestionEditClick(item)}>
              <div>
                {JSON.parse(item.title)[this.state.selectedLanguage] + " "}
              </div>
            </ListGroup.Item>
          ))}
          {this.state.testQuestions.length === 0 && (
            <ListGroup.Item>
              何も見つかりませんでした
            </ListGroup.Item>
          )}
        </ListGroup>
      </>
    );
  };

  renderDangerActions = () => {
    return (
      <Card className="mt-4 mb-4">
        <Card.Header variant="danger" text="white">注意アクション</Card.Header>
        <Card.Body>
          <Button variant="danger" className="mt-2" onClick={this.onDeleteClick}>コースを削除する</Button>
        </Card.Body>
      </Card>
    );
  };

  render() {
    let isNew = this.props.match.params.courseId === "new";

    return (
      <>
        <SmallBgLoading isLoading={this.state.loading}>
          {this.state.error && (<Alert variant="danger" className="mt-4">{this.state.error.message}</Alert>)}
          {this.renderToolbar()}
          {this.renderForm()}
        </SmallBgLoading>

        {!isNew && this.renderPracticeQuestions()}
        {!isNew && this.renderTestQuestions()}
        {!isNew && this.renderDangerActions()}
      </>
    )
  };
}

function mapStateToProps(state) {
  return {};
}

export default connect(mapStateToProps)(CoursePage);
