import { API, graphqlOperation } from "aws-amplify";
import {
  GetContents,
  GetContent,
  GetContentSetting,
  CreateContent,
  UpdateContent,
  DeleteContent,
  UpdateContentSetting
} from "./graphql/contents";

export const fetchContents = async () => {
  const response = await API.graphql(graphqlOperation(
    GetContents,
    {},
    "AMAZON_COGNITO_USER_POOLS"
  ));

  return response.data.getContents;
};

export const fetchContent = async (id) => {
  const response = await API.graphql({
    query: GetContent,
    variables: {id},
    authMode: "API_KEY",
  });

  return response.data.getContent;
};

export const fetchContentSetting = async (id) => {
  const response = await API.graphql({
    query: GetContentSetting,
    variables: {id},
    authMode: "API_KEY",
  });

  return response.data.getContentSetting;
};

export const createContent = async (input) => {
  const response = await API.graphql(graphqlOperation(
    CreateContent,
    { input },
    "AMAZON_COGNITO_USER_POOLS"
  ));

  return response.data.createContent;
};

export const updateContent = async (input) => {
  const response = await API.graphql(graphqlOperation(
    UpdateContent,
    { input },
    "AMAZON_COGNITO_USER_POOLS"
  ));

  return response.data.updateContent;
};

export const deleteContent = async (id) => {
  const response = await API.graphql(graphqlOperation(
    DeleteContent,
    { id },
    "AMAZON_COGNITO_USER_POOLS"
  ));

  return response.data.deleteContent;
};

export const updateContentSetting = async ({id, order}) => {
  const response = await API.graphql(graphqlOperation(
    UpdateContentSetting,
    { input: { id, order } },
    "AMAZON_COGNITO_USER_POOLS"
  ));

  return response.data.updateContentSetting;
};
