import React, { Component } from "react";
import { connect } from "react-redux";
import MarketContainer from "../../../components/containers/MarketContainer";
import { Button, Modal } from "react-bootstrap";
import * as commonService  from "../../../services/common";
import * as contentsService  from "../../../services/contents";
import * as accountService  from "../../../services/account";
import * as langService  from "../../../services/lang";
import { ReactComponent as KiiibinBack } from "../../../assets/svg/kiiibin_back.svg";
import { ReactComponent as KiiibinRelux } from "../../../assets/svg/kiiibin_relux.svg";
import { ReactComponent as KiiibinSad } from "../../../assets/svg/kiiibin_sad.svg";
import { ReactComponent as KiiibinSit } from "../../../assets/svg/kiiibin_sit.svg";
import { ReactComponent as KiiibinSmile } from "../../../assets/svg/kiiibin_smile.svg";
import { ReactComponent as KiiibinTeach } from "../../../assets/svg/kiiibin_teach.svg";

import { ReactComponent as LearningSvg } from "../../../assets/svg/icons8-hand-with-pen-2.svg";
import { ReactComponent as MangaSvg } from "../../../assets/svg/icons8-book.svg";
import { ReactComponent as TestSvg } from "../../../assets/svg/icons8-replay-30-2.svg";
import { ReactComponent as ResultsSvg } from "../../../assets/svg/icons8-report-card.svg";
import { ReactComponent as FeedbackSvg } from "../../../assets/svg/icons8-ask-question.svg";
import "./index.scss";
import * as rootActions from "../../../store/root/actions";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faTimes} from '@fortawesome/free-solid-svg-icons'
import Intercom from 'react-intercom';
import {Auth} from "aws-amplify";
import Div100vh from "react-div-100vh";

class MarketPage extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      account: null,
      user: null,
      loading: false,
      accountSettings: {},
      accountPermissions: [],
      courses: [],
      comicsLife: [],
      comicsSpirit: [],
      videoModalYoutube: null,
    };

    this.CourseRef = React.createRef();
    this.ComicSpiritRef = React.createRef();
    this.MoviesRef = React.createRef();
  }

  async componentWillMount() {
    this._isMounted = true;
    this.setState({loading: true});
    try {
      // Contents
      let state = {
        loading: false
      };
      let accountResponse = await accountService.fetchAccount();
      if (accountResponse.parent && accountResponse.parent.permissions) {
        state.accountPermissions = JSON.parse(accountResponse.parent.permissions);
      }
      let accountSettingsResponse = await accountService.fetchAccountSetting();
      let accountSettings = accountSettingsResponse ? JSON.parse(accountSettingsResponse) : {};
      state.accountSettings = accountSettings;

      let courseSetting = await contentsService.fetchContentSetting("COURSE");
      let comicsSpiritSetting = await contentsService.fetchContentSetting("COMICS_SPIRIT");
      let comicsLifeSetting = await contentsService.fetchContentSetting("COMICS_LIFE");
      let contentsResponse = await contentsService.fetchContents();
      let courses = contentsResponse.items.filter(item => item.contentType === "COURSE");
      let comicsSpirit = contentsResponse.items.filter(item => item.contentType === "COMICS_SPIRIT");
      let comicsLife = contentsResponse.items.filter(item => item.contentType === "COMICS_LIFE");

      if (courseSetting) {
        let order = JSON.parse(courseSetting.order);
        let newCourses = [];
        for (let i = 0; i < order.length; i++) {
          let index = courses.findIndex(item => item.id === order[i]);
          if (courses[index]) {
            newCourses.push(courses[index]);
          }
        }
        courses = newCourses;
      }

      if (comicsSpiritSetting) {
        let order = JSON.parse(comicsSpiritSetting.order);
        let newComicsSpirit = [];
        for (let i = 0; i < order.length; i++) {
          let index = comicsSpirit.findIndex(item => item.id === order[i]);
          if (comicsSpirit[index]) {
            newComicsSpirit.push(comicsSpirit[index]);
          }
        }
        comicsSpirit = newComicsSpirit;
      }

      if (comicsLifeSetting) {
        let order = JSON.parse(comicsLifeSetting.order);
        let newComicsLife = [];
        for (let i = 0; i < order.length; i++) {
          let index = comicsLife.findIndex(item => item.id === order[i]);
          if (comicsLife[index]) {
            newComicsLife.push(comicsLife[index]);
          }
        }
        comicsLife = newComicsLife;
      }

      state.selectedLanguage = commonService.mainLanguage;
      state.courses = courses;
      state.comicsSpirit = comicsSpirit;
      state.comicsLife = comicsLife;

      this.setState(state);
    } catch (e) {
      this.setState({loading: true});
      console.error(e);
    }

    this.fetchAccount()
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  fetchAccount = async () => {
    try {
      const user = await Auth.currentAuthenticatedUser();
      const account = await accountService.fetchAccount();
      let session = await Auth.currentSession();
      let groups = session.idToken.payload["cognito:groups"];

      if (this._isMounted) {
        this.setState({ user, account, groups });
      }
    } catch (e) {
      console.log(e);
    }
  };

  onSubtleTestClick() {
    this.props.history.push(`/subtle-test`);
  };

  onNursingClick() {
    this.props.history.push(`/nursing`);
  };

  onCoursePracticeClick(course) {
    this.props.history.push(`/course/${course.id}/practice`);
  };

  onCourseTestClick(course) {
    this.props.history.push(`/course/${course.id}/test`);
  };

  onComicSpiritClick(comic) {
    let { comicsSpirit } = this.state;
    let ids = comicsSpirit.map(item => item.id);

    this.props.dispatch(rootActions.setComicSpiritIds(ids));
    this.props.history.push(`/comic-spirit/${comic.id}`);
  };

  onResultsClick() {
    this.props.history.push(`/results`);
  };

  onKibiinNotificationClose = (item) => {
    let notificationsReaded = [...this.props.notificationsReaded];
    notificationsReaded.push(item.id);
    this.props.dispatch(rootActions.updateReadNotifications(notificationsReaded));
  };

  onCourseSectionClick = () => {
    window.scrollTo(0, this.CourseRef.current.offsetTop - 70);
  };

  async onComicLifeClick(comic) {
    let { comicsLife } = this.state;
    let ids = comicsLife.map(item => item.id);

    this.props.dispatch(rootActions.setComicLifeIds(ids));
    this.props.history.push(`/comic-life/${comic.id}`);
  };

  onComicSectionClick = () => {
    window.scrollTo(0, this.ComicSpiritRef.current.offsetTop - 70);
  };

  formatByLanguage = (value) => {
    let array = JSON.parse(value);
    if (array[this.props.currentLang]) {
      return array[this.props.currentLang];
    } else {
      return "Undefined"//array[commonService.mainLanguage];
    }
  };

  async onLogout() {

    try {
      await Auth.signOut();
      this.props.history.push('/login');
    } catch (e) {
      console.error(e);
    }

  }

  onMovies = () => {
    window.scrollTo(0, this.MoviesRef.current.offsetTop - 70);
  };

  renderKiiibinSpeech = () => {
    const {notificationsList, notificationsReaded} = this.props;

    let index = notificationsList.findIndex(item => notificationsReaded.indexOf(item.id) === -1);

    if (index === -1) {
      return null;
    }

    let item = notificationsList[index];
    return (
      <div className="speech">
        {item.avatar === 'kiiibin_back' && <KiiibinBack width={80} height={80} />}
        {item.avatar === 'kiiibin_relux' && <KiiibinRelux width={80} height={80} />}
        {item.avatar === 'kiiibin_sad' && <KiiibinSad width={80} height={80} />}
        {item.avatar === 'kiiibin_sit' && <KiiibinSit width={80} height={80} />}
        {item.avatar === 'kiiibin_smile' && <KiiibinSmile width={80} height={80} />}
        {item.avatar === 'kiiibin_teach' && <KiiibinTeach width={80} height={80} />}
        <div className="speech-bubble">{item.description}</div>
        <div className="d-flex flex-row justify-content-center align-items-center text-center pointer"
             style={{positions: 'absolute', top: 0, right: 0, width: 40, height: 40}}
             onClick={() => this.onKibiinNotificationClose(item)}
        >
          <FontAwesomeIcon className="text-secondary" icon={faTimes} />
        </div>
      </div>
    );
  };

  renderLargeActions = () => {
    const {accountPermissions} = this.state;
    return (
      <div>
        <div className="cards cards__row mb-3">
          {accountPermissions.indexOf('COURSE') !== -1 && (
            <div className={`cards__item blue pointer`} onClick={this.onCourseSectionClick}>
              <div className="cards__item__title">{langService.translate("study", this.props.currentLang)}</div>
              <LearningSvg width={80} height={80} fill="#ffffff" />
            </div>
          )}
          {accountPermissions.indexOf('COMIC') !== -1 && (
            <div className={`cards__item orange pointer`} onClick={this.onComicSectionClick}>
              <div className="cards__item__title">{langService.translate("learnByManga", this.props.currentLang)}</div>
              <MangaSvg width={80} height={80} fill="#ffffff" />
            </div>
          )}
          {accountPermissions.indexOf('SUBTLE') !== -1 && (
            <div className={`cards__item green pointer`} onClick={() => this.onSubtleTestClick()}>
              <div className="cards__item__title">{langService.translate("abilityTest", this.props.currentLang)}</div>
              <TestSvg width={80} height={80} fill="#ffffff" />
            </div>
          )}
          {accountPermissions.indexOf('NURSING') !== -1 && (
            <div className={`cards__item green pointer`} onClick={() => this.onNursingClick()}>
              <div className="cards__item__title">きづっきー診断</div>
              <TestSvg width={80} height={80} fill="#ffffff" />
            </div>
          )}
          {accountPermissions.indexOf('COURSE') !== -1 && accountPermissions.indexOf('COMIC') !== -1 && (
          <div className={`cards__item purple pointer`} onClick={() => this.onResultsClick()}>
            <div className="cards__item__title">{langService.translate("logResults", this.props.currentLang)}</div>
            <ResultsSvg width={80} height={80} fill="#ffffff" />
          </div>
          )}
          {accountPermissions.indexOf('MOVIE') !== -1 && (
          <div className={`cards__item yellow pointer`} onClick={() => this.onMovies()}>
            <div className="cards__item__title">{langService.translate("movies", this.props.currentLang)}</div>
            <ResultsSvg width={80} height={80} fill="#ffffff" />
          </div>
          )}
          {/*<div className={`cards__item yellow pointer`} onClick={() => console.log('feedback')}>*/}
          {/*  <div className="cards__item__title">{langService.translate("askAQuestion", this.props.currentLang)}</div>*/}
          {/*  <FeedbackSvg width={80} height={80} fill="#ffffff" />*/}
          {/*</div>*/}
        </div>
      </div>
    );
  };

  renderCourses = () => {
    let { courses, accountSettings } = this.state;
    let coursesPractice = accountSettings.coursesPractice ? accountSettings.coursesPractice : {};
    let lastCourse = courses[courses.length - 1];
    let practiceFinished = !!coursesPractice[lastCourse.id];

    return (
      <>
        <h2 className="font-weight-normal">
          KIBI-learning
          {practiceFinished && (<Button variant="info" size="sm" className="ml-2">{langService.translate("continue", this.props.currentLang)}</Button>)}
        </h2>
        <div className="cards mb-3">
          {courses.map((course, index) => (
            <div key={course.id} className="cards__item blue-outline">
              <div className="cards__item__title">
                {this.formatByLanguage(course.title)}
              </div>
              {course.description && (<div className="details">{this.formatByLanguage(course.description)}</div>)}
              <div className="actions">
                <Button
                  variant={coursesPractice[course.id] ? "outline-primary" : "primary"}
                  disabled={!(index === 0 || coursesPractice[course.id] || (courses[index - 1] && courses[index - 1].id && !!coursesPractice[courses[index - 1].id]))}
                  onClick={() => this.onCoursePracticeClick(course)}
                >
                  {langService.translate("practice", this.props.currentLang)}
                </Button>
                <Button
                  variant="primary"
                  className="mt-2"
                  disabled={!coursesPractice[course.id] && !practiceFinished}
                  onClick={() => this.onCourseTestClick(course)}
                >
                  {langService.translate("test", this.props.currentLang)}
                </Button>
              </div>
            </div>
          ))}
        </div>
      </>
    );
  };

  renderComicsSpirit = () => {
    let { comicsSpirit } = this.state;
    // let coursesPractice = accountSettings.coursesPractice ? accountSettings.coursesPractice : {};
    // let lastCourse = courses[courses.length - 1];
    // let practiceFinished = !!coursesPractice[lastCourse.id];

    return (
      <>
        <h2 className="font-weight-normal">
          KIBI-Manga-spirit
          {/*{practiceFinished && (<Button variant="info" size="sm" className="ml-2">続きから</Button>)}*/}
        </h2>
        <div className="cards mb-3">
          {comicsSpirit.map((comic, index) => (
            <div key={comic.id} className="cards__item blue-outline pointer" onClick={() => this.onComicSpiritClick(comic)}>
              <div className="cards__item__title">
                {this.formatByLanguage(comic.title)}
              </div>
              {comic.description && (<div className="details">{this.formatByLanguage(comic.description)}</div>)}
            </div>
          ))}
        </div>
      </>
    );
  };

  renderComicsLife = () => {
    let { comicsLife } = this.state;
    // let coursesPractice = accountSettings.coursesPractice ? accountSettings.coursesPractice : {};
    // let lastCourse = courses[courses.length - 1];
    // let practiceFinished = !!coursesPractice[lastCourse.id];

    return (
      <>
        <h2 className="font-weight-normal">
          KIBI-Manga-life
          {/*{practiceFinished && (<Button variant="info" size="sm" className="ml-2">続きから</Button>)}*/}
        </h2>
        <div className="cards mb-3">
          {comicsLife.map((comic, index) => (
            <div key={comic.id} className="cards__item blue-outline pointer" onClick={() => this.onComicLifeClick(comic)}>
              <div className="cards__item__title">
                {this.formatByLanguage(comic.title)}
              </div>
              {comic.description && (<div className="details">{this.formatByLanguage(comic.description)}</div>)}
            </div>
          ))}
        </div>
      </>
    );
  };

  renderDisabled = (intercomUser) => {
    return (
      <Div100vh className="d-flex justify-content-center align-items-center flex-column text-center">
        <div>アカウントは停止されています。</div>
        <Button variant="primary" className="mt-3" onClick={this.onLogout.bind(this)}>ログアウトする</Button>
        {intercomUser && <Intercom appID={process.env.REACT_APP_INTERCOM_API_KEY} { ...intercomUser }/>}
      </Div100vh>
    );
  };

  renderMovies = () => {
    const {videoModalYoutube} = this.state;
    const movies1 = [
      {title: '1-1', preview: '/images/movie-1-preview.png', youtube: 'https://www.youtube.com/embed/_m4NorEP6Jw'},
    ];
    const movies2 = [
      {title: '2-1', preview: '/images/movie-2-1-preview.png', youtube: 'https://www.youtube.com/embed/MCQENftTI1M'},
      {title: '2-2', preview: '/images/movie-2-2-preview.png', youtube: 'https://www.youtube.com/embed/75tg0zg6xOA'},
      {title: '2-3', preview: '/images/movie-2-3-preview.png', youtube: 'https://www.youtube.com/embed/i9_lW9SBYBg'},
      {title: '2-4', preview: '/images/movie-2-4-preview.png', youtube: 'https://www.youtube.com/embed/_x3kxEYbs8o'},
      {title: '2-5', preview: '/images/movie-2-5-preview.png', youtube: 'https://www.youtube.com/embed/riPKmafd9Gc'},
    ];

    return (
      <>
        <h2 className="font-weight-normal">
          キャリアデザイン就活
        </h2>
        <div className="cards mb-3">
          {movies1.map((movie, index) => (
            <div key={index}
                 className="cards__item img-bg pointer"
                 style={{
                   position: 'relative',
                   background: `url(${movie.preview}) no-repeat`,
                   border: '1px solid #ffffff',
                   overflow: 'hidden',
                 }}
                 onClick={() => this.setState({videoModalYoutube: movie.youtube})}>
              <div style={{position: 'absolute', left: 0, top: 0, right: 0, bottom: 0, backgroundColor: 'rgba(0,0,0,0.2)'}}/>
              <div style={{position: 'absolute', left: 16, top: 16}}>
                <div className="cards__item__title stroke">
                  {movie.title}
                </div>
              </div>
            </div>
          ))}
        </div>

        <h2 className="font-weight-normal">
          ビジネスコミュニケーション
        </h2>
        <div className="cards mb-3">
          {movies2.map((movie, index) => (
            <div key={index}
                 className="cards__item img-bg pointer"
                 style={{
                   position: 'relative',
                   background: `url(${movie.preview}) no-repeat`,
                   border: '1px solid #ffffff',
                   overflow: 'hidden',
                 }}
                 onClick={() => this.setState({videoModalYoutube: movie.youtube})}>
              <div style={{position: 'absolute', left: 0, top: 0, right: 0, bottom: 0, backgroundColor: 'rgba(0,0,0,0.2)'}}/>
              <div style={{position: 'absolute', left: 16, top: 16}}>
                <div className="cards__item__title stroke">
                  {movie.title}
                </div>
              </div>
            </div>
          ))}
        </div>

        {videoModalYoutube && (<div style={{backgroundColor: '#000',
          position: 'fixed',
          left: 0, top: 0, right: 0, bottom: 0, zIndex: 9999,
          display: 'flex',
          flexDirection: 'column'
        }}>
          <div style={{padding: 8, display: 'flex', flexDirection: 'row', justifyContent: 'flex-end'}}>
            <Button variant="outline-secondary" onClick={() => this.setState({videoModalYoutube: null})}>
              <FontAwesomeIcon className="text-white" icon={faTimes} />
            </Button>
          </div>
          <iframe style={{flex: '1 1 0', marginLeft: 8, marginRight: 8, marginBottom: 100, border: 'none'}}
                  src={`${videoModalYoutube}?autoplay=1`}>
          </iframe>
        </div>)}
      </>
    );
  };

  render() {
    const {accountPermissions, account, loading} = this.state;

    let intercomUser = null;

    if (account) {
      intercomUser = {
        user_id: account.id,
        email: account.email,
        name: account.name,
      };
    }

    if (account && (account.disabled || (account.parent && account.parent.disabled))) {
      return this.renderDisabled(intercomUser);
    }

    return (
      <>
      <MarketContainer isLoading={this.state.loading}>
        {/*{this.renderKiiibinSpeech()}*/}
        {this.renderLargeActions()}
        <div ref={this.CourseRef} />
        {this.state.courses.length > 0 && accountPermissions.indexOf('COURSE') !== -1 && this.renderCourses()}
        <div ref={this.ComicSpiritRef} />
        {this.state.comicsSpirit.length > 0 && accountPermissions.indexOf('COMIC') !== -1 && this.renderComicsSpirit()}
        {this.state.comicsLife.length > 0 && accountPermissions.indexOf('COMIC') !== -1 && this.renderComicsLife()}
        <div ref={this.MoviesRef} />
        {accountPermissions.indexOf('MOVIE') !== -1 && this.renderMovies()}
        {intercomUser && <Intercom appID={process.env.REACT_APP_INTERCOM_API_KEY} { ...intercomUser }/>}
      </MarketContainer>


      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    currentLang: state.root.currentLang,
    notificationsObject: state.root.notifications,
    notificationsList: state.root.notifications.kibiin,
    notificationsReaded: state.root.notifications.readed,
  };
}

export default connect(mapStateToProps)(MarketPage);
