import React, {Component} from "react";
import {Button, ButtonGroup} from "react-bootstrap";
import {connect} from "react-redux";
import {LinkContainer} from "react-router-bootstrap";
import MainContainer from "../../../../components/containers/MainContainer";
import {ReactComponent as CheckSVG} from "../../../../assets/svg/icons8-checkmark.svg";
import {ReactComponent as CancelSVG} from "../../../../assets/svg/icons8-delete.svg";
import * as courseTestResultsService from "../../../../services/course-test-results";
import * as contentsService from "../../../../services/contents";
import * as langService from "../../../../services/lang";

class CourseResultPage extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      testResults: null,
      nextCourseId: null,
      loading: false
    };
  }

  async componentDidMount() {
    const {testId} = this.props.match.params;

  //   const { courseId } = this.props.match.params;
  //   let testQuestionsResponse = await testQuestionsService.fetchTestQuestions(courseId);
  //   let courseProgress = await courseProgressService.fetchUserProgress();
  //   let items = courseProgress.filter((item) => item.courseId === courseId );
  //
  //   this.setState({ questions: testQuestionsResponse.items, courseProgress: items[0] });
    await this.fetchData(testId);
  }

  async fetchData(testId) {
    this.setState({loading: true});

    let state = {
      loading: false,
    };

    let testResults = await courseTestResultsService.fetchCourseTestResult(testId);
    console.log('testResults', testResults);
    if (!testResults) {
      console.log('results not found');
      this.setState({loading: false});
      return ;
    }

    let courseSettingResponse = await contentsService.fetchContentSetting("COURSE");
    let courseSetting = courseSettingResponse ? courseSettingResponse : {};
    let courseOrder = courseSetting.order ? JSON.parse(courseSetting.order) : [];

    let selectedCourseIndex = courseOrder.findIndex((item) => item === testResults.course.id);
    if (selectedCourseIndex + 1 < courseOrder.length) {
      state.nextCourseId = courseOrder[selectedCourseIndex + 1];
    }

    testResults.results = JSON.parse(testResults.results);
    state.testResults = testResults;

    this.setState(state);
  }

  formatByLanguage = (value) => {
    let array = typeof value === "string" ? JSON.parse(value) : value;
    if (array[this.props.currentLang]) {
      return array[this.props.currentLang];
    } else {
      return "Undefined"; //array[commonService.mainLanguage];
    }
  };

  getAnswer = (question, answers) => {
    let array = answers.filter((answer) => answer.questionId === question.id);
    return array.length !== 1 ? {} : array[0];
  };

  isCorrect = (question, answers) => {
    let array = answers.filter((answer) => answer.questionId === question.id);
    if (array.length !== 1) { return false; }
    return array[0].isCorrect;
  };

  onNextCourseClick = () => {
    this.props.history.push(`/course/${this.state.nextCourseId}/practice`);
  };

  renderResults = () => {
    const { testResults, nextCourseId } = this.state;
    const answers = testResults.results.answers;
    const testQuestions = testResults.course.testQuestions;

    return (
      <>
        <h4 className="mt-3 font-weight-normal text-center mb-3">
          {Math.floor(100 / testResults.results.totalAnswers * testResults.results.correctAnswers)}点
        </h4>
        <ButtonGroup vertical className="w-100">
          {testQuestions.map((question, index) => (
            <LinkContainer
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
                border: "1px solid #CFD4D9"
              }}
              to={`/course/${testResults.course.id}/test/${question.id}/${this.getAnswer(question, answers).answerId}/${testResults.id}`}
              key={question.id}
            >
              <Button className="py-2 px-3" variant="outline-dark">
                <div>{this.formatByLanguage(question.title)}</div>
                <div>
                  {this.isCorrect(question, answers) ? (
                    <CheckSVG width={30} height={30} />
                  ) : (
                    <CancelSVG width={30} height={30} />
                  )}
                </div>
              </Button>
            </LinkContainer>
          ))}
        </ButtonGroup>

        {nextCourseId && <Button
          variant="flat"
          block
          className="mt-3"
          onClick={this.onNextCourseClick.bind(this)}
        >
          {langService.translate("nextCourse", this.props.currentLang)}
        </Button>}
      </>
    );
  };

  renderNotFound = () => {
    return (
      <div className="d-flex justify-content-center align-content-center mt-4">
        テスト結果を見つかりませんでした
      </div>
    );
  };

  render() {


    return (
      <MainContainer isTest={true} isLoading={this.state.loading}>
        {!this.state.loading && this.state.testResults && this.renderResults()}
        {!this.state.loading && !this.state.testResults && this.renderNotFound()}
      </MainContainer>
    );
  }
}

function mapStateToProps(state, props) {
  return {
    currentLang: state.root.currentLang
  };
}

export default connect(mapStateToProps)(CourseResultPage);
