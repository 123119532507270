import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { Navbar, Nav, NavDropdown, Badge } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { Auth } from 'aws-amplify';
import * as rootActions from "../../../store/root/actions";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser, faHome, faSignInAlt } from '@fortawesome/free-solid-svg-icons'
import { TermsModal } from "../../../components/modals/TermsModal";
import * as accountService from '../../../services/account';
import * as commonService from '../../../services/common';
import * as langService from '../../../services/lang';
import "./index.scss";
import {PolicyModal} from "../../modals/PolicyModal";

export class MainHeader extends Component {

  constructor(props, context) {
    super(props, context);

    this.state = {
      user: null,
      account: null,
      groups: [],
      isLogout: false,
      isShowTermsModal: false,
      isShowPolicyModal: false,
      kibinNotification: null,
    };
  }

  async componentDidMount() {
    this._isMounted = true;

    await this.fetchAccount();
    this.props.dispatch(rootActions.fetchNotifications());
  }

  componentWillUnmount() {
    this._isMounted = false;
  }


  fetchAccount = async () => {
    try {
      const user = await Auth.currentAuthenticatedUser();
      const account = await accountService.fetchAccount();
      let session = await Auth.currentSession();
      let groups = session.idToken.payload["cognito:groups"];

      if (this._isMounted) {
        this.setState({ user, account, groups });
      }
    } catch (e) {
      console.log(e);
    }
  };

  onHome = () => {};
  onTerms = () => {
    this.setState({isShowTermsModal: true});
  };
  onPolicy = () => {};
  onFAQ = () => {};

  async onLogout() {

    try {
      await Auth.signOut();
      this.setState({ isLogout: true });
    } catch (e) {
      console.error(e);
    }

  }

  render() {
    const {notificationsList, notificationsReaded} = this.props;
    const {user, groups} = this.state;

    if (!user) {
      return null;
    }

    let notifications = notificationsList.filter(item => {
      if (item.userGroup) {
        return groups.indexOf(item.userGroup.toLowerCase()) !== -1;
      } else {
        return user && item.userId === user.getUsername();
      }
    });

    let totalUnread = 0;
    for (let i = 0; i < notifications.length; i++) {
      if (notificationsReaded.indexOf(notifications[i].id) === -1) {
        totalUnread += 1;
      }
    }

    return (
      <>
        <Navbar bg="white" fixed="top" expand="lg">
          <Navbar.Brand
            style={{ margin: "-10px 0", padding: 0 }}
            className="cursor-hover"
          >
            <LinkContainer to="/">
              <div>kibi-<span style={{color: "#0F80B4"}}>learning</span>.jp</div>
            </LinkContainer>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            {!this.props.isPractice && !this.props.isTest && (
              <>
                <Nav className="mr-auto">
                  <NavDropdown
                    title={commonService.languages[this.props.currentLang]}
                    id="basic-nav-dropdown"
                    className="mr-3"
                  >
                    {commonService.languagesList.map(lang => (
                      <NavDropdown.Item
                        key={lang}
                        onClick={() => this.props.dispatch(rootActions.setLang(lang))}
                      >
                        {commonService.languages[lang]}
                      </NavDropdown.Item>
                    ))}
                  </NavDropdown>
                </Nav>
                <Nav>
                  {/*<Nav.Link href="/notifications" className="d-flex align-items-center">{langService.translate("notifications", this.props.currentLang)} {totalUnread > 0 && <Badge variant="danger" className="ml-2">{totalUnread}</Badge>}</Nav.Link>*/}
                  <NavDropdown title={langService.translate("menu", this.props.currentLang)} alignRight>
                    {this.state.account && (
                      <NavDropdown.Item onClick={() => console.log('account')}><FontAwesomeIcon icon={faUser} /> {this.state.account.name ? `${this.state.account.name}${langService.translate("account-user-sufix", this.props.currentLang)}` : this.state.account.email}{langService.translate("account", this.props.currentLang)}</NavDropdown.Item>
                    )}

                    <NavDropdown.Divider />
                    <NavDropdown.Item onClick={() => console.log('home')}><FontAwesomeIcon icon={faHome} /> {langService.translate("home", this.props.currentLang)}</NavDropdown.Item>
                    <NavDropdown.Divider />
                    <NavDropdown.Item onClick={() => this.setState({isShowTermsModal: true})}>{langService.translate("terms", this.props.currentLang)}</NavDropdown.Item>
                    <NavDropdown.Item onClick={() => this.setState({isShowPolicyModal: true})}>{langService.translate("policy", this.props.currentLang)}</NavDropdown.Item>

                    <NavDropdown.Divider />
                    <NavDropdown.Item onClick={this.onLogout.bind(this)}><FontAwesomeIcon icon={faSignInAlt} /> {langService.translate("logout", this.props.currentLang)}</NavDropdown.Item>
                  </NavDropdown>
                </Nav>
              </>
            )}
            {((this.props.isPractice || this.props.isTest)) && (
              <>
                <Nav>
                  <LinkContainer to={`/`} activeClassName="">
                    <Nav.Link>{langService.translate("toMarket", this.props.currentLang)}</Nav.Link>
                  </LinkContainer>
                </Nav>
                <Nav className="ml-auto">
                  <NavDropdown
                    title={commonService.languages[this.props.currentLang]}
                    id="basic-nav-dropdown"
                    className="mr-3"
                  >
                    {commonService.languagesList.map(lang => (
                      <NavDropdown.Item
                        key={lang}
                        onClick={() => this.props.dispatch(rootActions.setLang(lang))}
                      >
                        {commonService.languages[lang]}
                      </NavDropdown.Item>
                    ))}
                  </NavDropdown>
                </Nav>
              </>
            )}
          </Navbar.Collapse>
        </Navbar>
        {this.state.isLogout && (<Redirect to="/login"/>)}
        <TermsModal show={this.state.isShowTermsModal}
                    isSimple={true}
                    onClose={() => this.setState({ isShowTermsModal: false })} />
        <PolicyModal show={this.state.isShowPolicyModal}
                     onClose={() => this.setState({ isShowPolicyModal: false })} />
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    langs: state.root.langs,
    currentLang: state.root.currentLang,
    notificationsObject: state.root.notifications,
    notificationsList: state.root.notifications.list,
    notificationsReaded: state.root.notifications.readed,
  };
}

export default connect(mapStateToProps)(MainHeader);
