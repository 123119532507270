import React, { Component } from "react";
import { connect } from "react-redux";
import { Spinner } from "react-bootstrap";
import MainHeader from "../commons/MainHeader/index";

export class MarketContainer extends Component {

  render() {
    return (
      <>
        <MainHeader isPractice={this.props.isPractice} isTest={this.props.isTest} />
        <div
          style={{ marginTop: "40px" }}
          className={
            this.props.isLoading
              ? "d-flex justify-content-center align-items-center"
              : ""
          }
        >
          {this.props.isLoading ? (
            <Spinner
              animation="grow"
              variant="primary"
              size="lg"
              className="ma-auto"
            />
          ) : (
            <div className="py-4 px-3">{this.props.children}</div>
          )}
        </div>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    // fullLoading: state.root.fullLoading,
    // courseGroups: state.courses.courseGroups,
    // progress: state.courses.progress
  };
}

export default connect(mapStateToProps)(MarketContainer);
