import React, { Component } from "react";
import {
  Alert,
  Button,
  ButtonToolbar,
  Dropdown,
  DropdownButton,
  Form,
  InputGroup,
  FormControl,
  Card,
  Col,
} from "react-bootstrap";
import { SmallBgLoading } from "../../../../components/commons/SmallBgLoading"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck, faMinusCircle, faPlusCircle} from "@fortawesome/free-solid-svg-icons";
import * as commonService from "../../../../services/common";
import * as contentsService  from "../../../../services/contents";
import uuid from "uuid";
import * as generator from "generate-password";
import * as csvService from "../../../../services/csv";
import * as userService from "../../../../services/users";

class SimpleFlowTestPage extends Component {

  constructor(props, context) {
    super(props, context);

    this.state = {
      contentId: null,
      touched: false,

      title: { separatedLanguage: true },
      description: { separatedLanguage: true },
      testTime: { separatedLanguage: false },
      // areas: {
      //   title: {
      //     separatedLanguage: true,
      //     required: true
      //   },
      //   factors: {
      //     separatedLanguage: false,
      //     required: true
      //   },
      //   value: [],
      //   multiply: true,
      // },
      factors: {
        key: {
          separatedLanguage: false,
          required: true
        },
        title: {
          separatedLanguage: true,
          required: true
        },
        value: [],
        multiply: true,
      },
      questions: {
        title: {
          separatedLanguage: true,
          required: true
        },
        description: {
          separatedLanguage: true,
          required: true
        },
        factors: {
          separatedLanguage: false,
          required: true
        },
        value: [],
        multiply: true
      },
      answers: {
        text: {
          separatedLanguage: true,
          required: true
        },
        // description: {
        //   separatedLanguage: true,
        //   required: true
        // },
        points: {
          separatedLanguage: false,
          required: true
        },
        value: [],
        multiply: true
      },
      selectedQuestion: null,
      selectedAnswer: null,
      languages: [],
      selectedLanguage: null,
      data: null,

      error: null,
      loading: false,
    };
  }

  // Lifecycle

  componentDidMount() {
    this._isMounted = true;
    this.fetchData()
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  // Data

  fetchData = async () => {
    if (!this._isMounted) {
      return;
    }
    this.setState({loading: true});

    try {
      let state = {loading: false};
      let contentId = this.props.match.params.contentId;
      if (contentId !== "new") {
        let content = await contentsService.fetchContent(contentId);
        state.contentId = contentId;
        state.data = content;
        state.languages = JSON.parse(state.data.languages);

        let fields = ["title", "description"];
        for (const field of fields) {
          if (this.state[field].separatedLanguage) {
            for (let langIndex in state.languages) {
              if (!state[field]) state[field] = {...this.state[field]};
              try {
                state[field][state.languages[langIndex]] = JSON.parse(state.data[field])[state.languages[langIndex]];
              } catch (e) {}
            }

          } else {
            state[field] = { value: state.data[field], ...this.state[field]};
          }
        }

        let settings = JSON.parse(state.data.settings);
        state.testTime = this.state.testTime;
        state.testTime.value = settings.testTime;

        // state.areas = this.state.areas;
        // state.areas.value = settings.areas;

        state.factors = this.state.factors;
        state.factors.value = settings.factors;

        state.questions = this.state.questions;
        state.questions.value = settings.questions;

        state.answers = this.state.answers;
        state.answers.value = settings.answers;
      } else {
        state.languages = [commonService.mainLanguage];
      }

      state.selectedLanguage = commonService.mainLanguage;

      if (this._isMounted) {
        this.setState(state);
      }
    } catch (error) {
      this.onError(error);
    }
  };

  // Form

  onFormControlChanged = (fieldName, value) => {
    let pathComponents = fieldName.split(".");

    let data = {...this.state[pathComponents[0]]};

    if (pathComponents.length > 1) {
      if (data[pathComponents[2]].separatedLanguage) {
        if (data.value[parseInt(pathComponents[1])][pathComponents[2]]) {
          data.value[parseInt(pathComponents[1])][pathComponents[2]][this.state.selectedLanguage] = value;
        } else {
          let item = {};
          item[this.state.selectedLanguage] = value;
          data.value[parseInt(pathComponents[1])][pathComponents[2]] = item;
        }
      } else {
        data.value[parseInt(pathComponents[1])][pathComponents[2]] = { value };
      }
    } else {
      if (this.state[pathComponents[0]].separatedLanguage) {
        data[this.state.selectedLanguage] = value;
      } else {
        data.value = value;
      }
    }

    let state = {};
    state[pathComponents[0]] = data;

    if (this.state.error) {
      state.error = null;
    }

    this.setState(state);
  };

  formRequiredValidation = (fileds) => {
    for (let i in fileds) {
      if (!this.formFieldRequiredValidation(fileds[i], true)) return false;
    }

    return true;
  };

  formFieldRequiredValidation = (fieldName, isTouched = false) => {
    if (!isTouched && !this.state.touched) {
      return true;
    }

    let pathComponents = fieldName.split(".");

    if (pathComponents.length > 1) {
      if (this.state[pathComponents[0]][pathComponents[2]].separatedLanguage) {
        for (let i in this.state.languages) {
          let item = this.state[pathComponents[0]].value[parseInt(pathComponents[1])];
          let value = item[pathComponents[2]] && item[pathComponents[2]][this.state.languages[i]];
          if (!(value && value.length > 0)) return false;
        }
        return true;
      } else {
        let item = this.state[pathComponents[0]].value[parseInt(pathComponents[1])];
        return item[pathComponents[2]] && item[pathComponents[2]].value && item[pathComponents[2]].value.length > 0;
      }
    } else {
      if (this.state[pathComponents[0]].separatedLanguage) {
        for (let i in this.state.languages) {
          let value = this.state[pathComponents[0]][this.state.languages[i]];
          if (!(value && value.length > 0)) return false;
        }
        return true;
      } else {
        let value = this.state[pathComponents[0]].value;
        return value && value.length > 0;
      }
    }
  };

  formFieldRequiredValidationByLanguage = (fieldName) => {
    let pathComponents = fieldName.split(".");
    for (let i in this.state.languages) {
      if (pathComponents.length > 1) {
        let value = this.state[pathComponents[0]].value[parseInt(pathComponents[1])][this.state.languages[i]];
        if (!(value && value.length > 0)) return commonService.languages[this.state.languages[i]];
      } else {
        let value = this.state[pathComponents[0]][this.state.languages[i]];
        if (!(value && value.length > 0)) return commonService.languages[this.state.languages[i]];
      }
    }

    return "";
  };

  getFormControlValue = (fieldName) => {
    let pathComponents = fieldName.split(".");

    if (pathComponents.length > 1) {
      if (this.state[pathComponents[0]][pathComponents[2]].separatedLanguage) {
        if (
          this.state[pathComponents[0]].value[pathComponents[1]][pathComponents[2]] &&
          this.state[pathComponents[0]].value[pathComponents[1]][pathComponents[2]][this.state.selectedLanguage]
        ) {
          return this.state[pathComponents[0]].value[pathComponents[1]][pathComponents[2]][this.state.selectedLanguage];
        }  else {
          return "";
        }
      } else {
        if (
          this.state[pathComponents[0]].value[pathComponents[1]][pathComponents[2]] &&
          this.state[pathComponents[0]].value[pathComponents[1]][pathComponents[2]].value
        ) {
          return this.state[pathComponents[0]].value[pathComponents[1]][pathComponents[2]].value;
        }  else {
          return "";
        }
      }

    } else {
      if (this.state[fieldName].separatedLanguage) {
        let value = this.state[fieldName][this.state.selectedLanguage];
        return value ? value : "";
      } else {
        let value = this.state[fieldName].value;
        return value ? value : "";
      }
    }
  };

  // Actions

  onError = (error) => {
    if (error.errors) {
      console.error(error.errors[0]);
      if (this._isMounted) {
        this.setState({ loading: false, error: error.errors[0] });
      }
    } else {
      console.error(error);
      if (this._isMounted) {
        this.setState({loading: false, error});
      }
    }
  };

  onAddLanguage = (lang) => {
    let languages = this.state.languages;
    languages.push(lang);
    this.setState({ languages, selectedLanguage: lang });
  };

  onDeleteLanguage = (lang) => {
    let languages = this.state.languages.filter((item) => item !== lang);
    this.setState({ languages });
  };

  onChangeLanguage = (lang) => {
    if (this.state.selectedLanguage !== lang) {
      this.setState({ selectedLanguage: lang });
    }
  };

  onDeleteClick = async () => {
    this.setState({error: null, loading: true});

    try {
      await contentsService.deleteContent(this.state.data.id);

      // update order
      let setting = await contentsService.fetchContentSetting("SIMPLE_FLOW_TEST");
      let order = [];
      if (setting && setting.order) {
        order = JSON.parse(setting.order);
      }
      let index = order.indexOf(this.state.data.id);
      if (index !== -1) {
        order.splice(index, 1);
        await contentsService.updateContentSetting({id: "SIMPLE_FLOW_TEST", order: JSON.stringify(order)});
      }

      this.setState({loading: false});
      this.props.history.push("/admin/test/simple-flow");
    } catch (error) {
      this.onError(error);
    }
  };

  onDoneClick = () => {
    document
      .getElementById('form')
      .dispatchEvent(new Event('submit', { cancelable: true }))
  };

  onSubmit = async (event) => {
    event.preventDefault();
    this.setState({error: null, touched: true});
    let validationFields = ["title", "testTime"];
    if (!this.formRequiredValidation(validationFields)) return;

    try {
      let fields = ["title", "description",];
      let input = {};
      for (const field of fields) {
        if (this.state[field].separatedLanguage) {
          let values = {};
          for (let langIndex in this.state.languages) {
            let value = this.state[field][this.state.languages[langIndex]];
            values[this.state.languages[langIndex]] = value ? value : null;
          }
          input[field] = JSON.stringify(values);
        } else {
          input[field] = this.state[field].value ? this.state[field].value : null;
        }
      }

      let settings = {
        testTime: this.state["testTime"].value,
        // areas: this.state["areas"].value,
        factors: this.state["factors"].value,
        questions: this.state["questions"].value,
        answers: this.state["answers"].value,
      };
      input.settings = JSON.stringify(settings);
      input.languages = JSON.stringify(this.state.languages);

      if (this.state.data) {
        input.id = this.state.data.id;
        await contentsService.updateContent(input);
      } else {
        input.id = uuid.v4();
        input.contentType = "SIMPLE_FLOW_TEST";
        await contentsService.createContent(input);

        // update order
        let setting = await contentsService.fetchContentSetting("SIMPLE_FLOW_TEST");
        let order = [];
        if (setting && setting.order) {
          order = JSON.parse(setting.order);
        }
        order.push(input.id);
        await contentsService.updateContentSetting({id: "SIMPLE_FLOW_TEST", order: JSON.stringify(order)});
      }

      this.setState({loading: false});

      this.props.history.push(`/admin/test/simple-flow`);
    } catch (error) {
      this.onError(error);
    }
  };

  onUpload = async (file) => {
    this.setState({ loading: true, error: null });
    let schema = [
      {path: "questionTitle", type: "String", required: true},
      {path: "answers", type: "String", required: true},
      {path: "answer1Points", type: "Int", required: true},
      {path: "answer2Points", type: "Int", required: true},
      {path: "answer3Points", type: "Int", required: true},
      {path: "answer4Points", type: "Int", required: true},
      {path: "questionDescription", type: "String", required: true},
      {path: "factor1", type: "Int", required: false}, // 状況想像力
      {path: "factor2", type: "Int", required: false}, // リアクション力
      {path: "factor3", type: "Int", required: false}, // 共感力
      {path: "factor4", type: "Int", required: false}, // 気持ち察し力
      {path: "factor5", type: "Int", required: false}, // 柔軟性
      {path: "factor6", type: "Int", required: false}, // コミュニケーション能力
      {path: "factor7", type: "Int", required: false}, // グローバルビジネスの理解
      {path: "factor8", type: "Int", required: false}, // 複雑性の認知
      {path: "factor9", type: "Int", required: false}, // コスモポリタン的な志向
      {path: "factor10", type: "Int", required: false}, // 多様性への情熱
      {path: "factor11", type: "Int", required: false}, // 冒険心
      {path: "factor12", type: "Int", required: false}, // 自信
      {path: "factor13", type: "Int", required: false}, // 異文化への共感
      {path: "factor14", type: "Int", required: false}, // 対人影響力
      {path: "factor15", type: "Int", required: false}, // 外交的手腕
      {path: "factor16", type: "Int", required: false}, // 他文化理解
      {path: "factor17", type: "Int", required: false}, // 自己相対化
      {path: "factor18", type: "Int", required: false}, // 多文化共生力
    ];
    try {

      let items = await csvService.readCsv(file, schema);
      console.log('items', items);
      // let item;
      // for (item of items) {
      //   await userService.createUserManager(item);
      // }
      // await this.fetchUsers();
      // window.alert("アップロードは完了しました");
    } catch (e) {
      if (e.errors) {
        window.alert(e.errors[0].message);
      } else {
        window.alert(e.message);
      }
    }
    this.setState({ loading: false});
  };

  onAreaAdd = () => {
    let areas = {...this.state.areas};
    areas.value = [...(areas.value ? areas.value : [])];
    areas.value.push({id: uuid.v4()});
    this.setState({areas, error: null});
  };

  onAreaRemove = (index) => {
    let areas = {...this.state.areas};
    areas.value.splice(index, 1);
    this.setState({areas, error: null});
  };

  onFactorAdd = () => {
    let factors = {...this.state.factors};
    factors.value = [...(factors.value ? factors.value : [])];
    factors.value.push({id: uuid.v4()});
    this.setState({factors, error: null});
  };

  onFactorRemove = (index) => {
    let factors = {...this.state.factors};
    factors.value.splice(index, 1);
    this.setState({factors, error: null});
  };

  onQuestion = (id) => {
    this.setState({selectedQuestion: id, selectedAnswer: null});
  };

  onQuestionAdd = () => {
    let questions = {...this.state.questions};
    const id = uuid.v4();
    questions.value = [...(questions.value ? questions.value : [])];
    questions.value.push({id});
    this.setState({questions, selectedQuestion: id, selectedAnswer: null, error: null});
  };

  onQuestionRemove = () => {
    let questions = {...this.state.questions};
    let questionIndex = questions.value.findIndex(question => question.id === this.state.selectedQuestion);
    questions.value.splice(questionIndex, 1);
    let state = {
      questions,
      error: null,
    };

    state.selectedQuestion = null;
    state.selectedAnswer = null;
    this.setState(state);
  };

  onAnswer = (id) => {
    this.setState({selectedAnswer: id});
  };

  onAnswerAdd = () => {
    let answers = {...this.state.answers};
    const id = uuid.v4();
    answers.value = [...(answers.value ? answers.value : [])];
    answers.value.push({id, questionId: this.state.selectedQuestion});
    this.setState({answers, selectedAnswer: id, error: null});
  };

  onAnswerRemove = () => {
    let answers = {...this.state.answers};
    let answerIndex = answers.value.findIndex(answer => answer.id === this.state.selectedAnswer);
    answers.value.splice(answerIndex, 1);
    let state = {
      answers,
      error: null,
    };

    state.selectedAnswer = null;
    this.setState(state);
  };

  // Render

  renderToolbar = () => {
    return (
      <ButtonToolbar className="mt-4 justify-content-between align-items-center">
        <h1 className="m-0">公式練習問題集編集</h1>
        <div className="d-flex">
          <Dropdown className="mr-2">
            <Dropdown.Toggle variant="success">
              {commonService.languages[this.state.selectedLanguage]}
            </Dropdown.Toggle>

            <Dropdown.Menu>
              {this.state.languages.map((key) => (
                <Dropdown.Item
                  key={key}
                  className="d-flex justify-content-between align-items-center"
                >
                  <div onClick={() => this.onChangeLanguage(key)}>{commonService.languages[key]}</div>
                  {this.state.selectedLanguage === key && (
                    <div>
                      <FontAwesomeIcon className="text-secondary" icon={faCheck} />
                    </div>
                  )}
                  {this.state.selectedLanguage !== key && (
                    <div onClick={() => this.onDeleteLanguage(key)}>
                      <FontAwesomeIcon className="icon-hover icon-danger" icon={faMinusCircle} />
                    </div>
                  )}
                </Dropdown.Item>
              ))}
              {Object.keys(commonService.languages).filter(item => !this.state.languages.includes(item)).length > 0 && (<Dropdown.Divider />)}
              {Object.keys(commonService.languages).filter(item => !this.state.languages.includes(item)).map((key) => (
                <Dropdown.Item key={key}
                               className="d-flex justify-content-between align-items-center"
                               onClick={() => this.onAddLanguage(key)}>
                  <div>{commonService.languages[key]}</div>
                  <FontAwesomeIcon className="icon-hover text-success" icon={faPlusCircle} />
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>

          <div className='btn btn-outline-primary mr-2 pointer'>
            <label htmlFor="file" style={{marginBottom: 0}}>
              アップロード
            </label>
            <input id="file" accept="text/csv" type='file' style={{display: 'none'}} onChange={(e) => {this.onUpload(e.target.files[0]); e.target.value = null;}} />
          </div>

          <Button variant="primary"
                  type="submit"
                  onClick={this.onDoneClick}>保存</Button>
        </div>
      </ButtonToolbar>
    );
  };

  renderGeneralAreaItem = (area, areaIndex) => {
    const {factors} = this.state;
    const selectedFactors = this.getFormControlValue(`areas.${areaIndex}.factors`) || '';
    const selectedFactorsList = selectedFactors.split(',');

    return (
      <div key={areaIndex}>
        <InputGroup key={areaIndex} className="mb-3">
          <FormControl
            required={true}
            placeholder="領域名"
            aria-describedby="basic-addon2"
            value={this.getFormControlValue(`areas.${areaIndex}.title`)}
            onChange={(e) => this.onFormControlChanged(`areas.${areaIndex}.title`, e.target.value)}
            isInvalid={!this.formFieldRequiredValidation(`areas.${areaIndex}.title`)}
          />
          <InputGroup.Append>
            <Button variant="outline-danger" onClick={() => this.onAreaRemove(areaIndex)}>削除</Button>
          </InputGroup.Append>
          <Form.Control.Feedback type="invalid">
            [{this.formFieldRequiredValidationByLanguage(`areas.${areaIndex}.title`)}] タイトルを入力してください
          </Form.Control.Feedback>
        </InputGroup>

        <Form.Group>
          {factors.value.map((factor, factorIndex) => (
            <Form.Check
              key={factor.id}
              type="checkbox"
              label={this.getFormControlValue(`factors.${factorIndex}.title`)}
              checked={selectedFactorsList.indexOf(factor.id) !== -1}
              onChange={(e) => {
                if (e.target.checked ) {
                  const array = [...selectedFactorsList];
                  array.push(factor.id);
                  this.onFormControlChanged(`areas.${areaIndex}.factors`, array.join(','))
                } else {
                  const array = [...selectedFactorsList];
                  array.splice(array.indexOf(factor.id), 1);
                  this.onFormControlChanged(`areas.${areaIndex}.factors`, array.join(','))
                }
              }}
            />
          ))}
        </Form.Group>
      </div>
    );
  };

  renderGeneralAreas = () => {
    const {areas} = this.state;

    return (
      <>
        <ButtonToolbar className="mt-4 mb-4 justify-content-between align-items-center">
          <h1 className="m-0">領域</h1>
          <div className="d-flex">
            <Button variant="primary"
                    onClick={this.onAreaAdd}>領域追加</Button>
          </div>
        </ButtonToolbar>
        {areas.value && areas.value.length > 0 && (
          <div>
            {areas.value.map((area, areaIndex) => this.renderGeneralAreaItem(area, areaIndex))}
          </div>
        )}
      </>
    );
  };

  renderGeneralFactors = () => {
    const {factors} = this.state;
    return (
      <>
        <ButtonToolbar className="mt-4 mb-4 justify-content-between align-items-center">
          <h1 className="m-0">ファクター</h1>
          <div className="d-flex">
            <Button variant="primary"
                    onClick={this.onFactorAdd}>ファクター追加</Button>
          </div>
        </ButtonToolbar>
        {factors.value && factors.value.length > 0 && (
          <div>
            {factors.value.map((item, index) => (
              <div key={index} className="mt-2">
                <Form.Row>
                  <Col>
                    <Form.Group>
                      <Form.Label>ファクターキー</Form.Label>
                      <FormControl
                        required={true}
                        placeholder="ファクターキー"
                        aria-describedby="basic-addon2"
                        value={this.getFormControlValue(`factors.${index}.key`)}
                        onChange={(e) => this.onFormControlChanged(`factors.${index}.key`, e.target.value)}
                        isInvalid={!this.formFieldRequiredValidation(`factors.${index}.key`)}
                      />
                      <Form.Control.Feedback type="invalid">
                        ファクターキーを入力してください
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group>
                      <Form.Label>ファクター名</Form.Label>
                      <FormControl
                        required={true}
                        placeholder="ファクター名"
                        aria-describedby="basic-addon2"
                        value={this.getFormControlValue(`factors.${index}.title`)}
                        onChange={(e) => this.onFormControlChanged(`factors.${index}.title`, e.target.value)}
                        isInvalid={!this.formFieldRequiredValidation(`factors.${index}.title`)}
                      />
                      <Form.Control.Feedback type="invalid">
                        [{this.formFieldRequiredValidationByLanguage(`factors.${index}.title`)}] ファクター名を入力してください
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Button variant="outline-danger" className="ml-2" onClick={() => this.onFactorRemove(index)}>削除</Button>
                </Form.Row>
                {/*<InputGroup  className="mb-3">*/}
                {/*  */}
                {/*  <InputGroup.Append>*/}
                {/*    <Button variant="outline-danger" onClick={() => this.onFactorRemove(index)}>削除</Button>*/}
                {/*  </InputGroup.Append>*/}
                {/*  <Form.Control.Feedback type="invalid">*/}
                {/*    [{this.formFieldRequiredValidationByLanguage(`factors.${index}.title`)}] タイトルを入力してください*/}
                {/*  </Form.Control.Feedback>*/}
                {/*</InputGroup>*/}
              </div>
            ))}
          </div>
        )}
      </>
    );
  };

  renderGeneral = () => {
    return (
      <Form id="form" className="mt-4" onSubmit={this.onSubmit}>
        <Form.Group>
          <Form.Label>タイトル</Form.Label>
          <Form.Control required
                        type="text"
                        value={this.getFormControlValue("title")}
                        onChange={(e) => this.onFormControlChanged("title", e.target.value)}
                        isInvalid={!this.formFieldRequiredValidation("title")} />
          <Form.Control.Feedback type="invalid">
            [{this.formFieldRequiredValidationByLanguage("title")}] タイトルを入力してください
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group>
          <Form.Label>説明</Form.Label>
          <Form.Control as="textarea"
                        rows="3"
                        value={this.getFormControlValue("description")}
                        onChange={(e) => this.onFormControlChanged("description", e.target.value)} />
        </Form.Group>
        <Form.Group>
          <Form.Label>テスト期間</Form.Label>
          <Form.Control required
                        type="number"
                        value={this.getFormControlValue("testTime")}
                        onChange={(e) => this.onFormControlChanged("testTime", e.target.value)}
                        isInvalid={!this.formFieldRequiredValidation("testTime")} />
          <Form.Control.Feedback type="invalid">
            テスト期間を入力してください
          </Form.Control.Feedback>
        </Form.Group>
        {this.renderGeneralFactors()}
        {/*{this.renderGeneralAreas()}*/}
      </Form>
    );
  };

  renderQuestionsHeader = () => {
    let {selectedQuestion, questions} = this.state;
    let questionIndex = selectedQuestion !== null
      ?
      this.state.questions.value.findIndex(question => question.id === selectedQuestion)
      :
      -1;

    return (
      <ButtonToolbar className="mt-4 justify-content-between align-items-center">
        <h1 className="m-0">テスト問題</h1>

        <div className="d-flex">
          <DropdownButton title={questionIndex !== -1 ? `問${questionIndex + 1}` : '未定'}>
            {questions.value.map((question, index) => (
              <Dropdown.Item key={index} onClick={() => this.onQuestion(question.id)}>
                問{index + 1}
              </Dropdown.Item>
            ))}
            {selectedQuestion !== null && (<Dropdown.Divider/>)}
            <Dropdown.Item onClick={this.onQuestionAdd}>
              追加
            </Dropdown.Item>

          </DropdownButton>
          {selectedQuestion !== null && (
            <Button variant="primary"
                  className="ml-2"
                  onClick={this.onQuestionRemove}>削除</Button>
          )}
        </div>
      </ButtonToolbar>
    );
  };

  renderQuestionsContent = () => {
    let {selectedQuestion, factors} = this.state;
    if (selectedQuestion === null) {
      return null;
    }

    let questionIndex = this.state.questions.value.findIndex(question => question.id === selectedQuestion);
    if (questionIndex === -1) {
      return ;
    }

    const selectedFactors = this.getFormControlValue(`questions.${questionIndex}.factors`) || '';
    const selectedFactorsList = selectedFactors.split(',');

    return (
      <Form id="form" className="mt-4">
        <Form.Group>
          <Form.Label>問題本文</Form.Label>
          <Form.Control required
                        as="textarea"
                        rows="3"
                        value={this.getFormControlValue(`questions.${questionIndex}.title`)}
                        onChange={(e) => this.onFormControlChanged(`questions.${questionIndex}.title`, e.target.value)}
                        isInvalid={!this.formFieldRequiredValidation(`questions.${questionIndex}.title`)} />
          <Form.Control.Feedback type="invalid">
            [{this.formFieldRequiredValidationByLanguage(`questions.${questionIndex}.title`)}] 問題本文を入力してください
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group>
          <Form.Label>解説</Form.Label>
          <Form.Control required
                        as="textarea"
                        rows="3"
                        value={this.getFormControlValue(`questions.${questionIndex}.description`)}
                        onChange={(e) => this.onFormControlChanged(`questions.${questionIndex}.description`, e.target.value)}
                        isInvalid={!this.formFieldRequiredValidation(`questions.${questionIndex}.description`)} />
          <Form.Control.Feedback type="invalid">
            [{this.formFieldRequiredValidationByLanguage(`questions.${questionIndex}.title`)}] 解説を入力してください
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group>
          {factors.value.map((factor, index) => (
            <Form.Check
              key={factor.id}
              type="checkbox"
              label={this.getFormControlValue(`factors.${index}.title`)}
              checked={selectedFactorsList.indexOf(factor.id) !== -1}
              onChange={(e) => {
                if (e.target.checked ) {
                  const array = [...selectedFactorsList];
                  array.push(factor.id);
                  this.onFormControlChanged(`questions.${questionIndex}.factors`, array.join(','))
                } else {
                  const array = [...selectedFactorsList];
                  array.splice(array.indexOf(factor.id), 1);
                  this.onFormControlChanged(`questions.${questionIndex}.factors`, array.join(','))
                }
              }}
            />
          ))}
        </Form.Group>
      </Form>
    );
  };

  renderQuestionSkills = () => {
    const {selectedQuestion} = this.state;
    const skills = [
      {key: 'imagination', label: '状況想像力'},
      {key: 'reaction', label: 'リアクション力'},
      {key: 'empathy', label: '共感力'},
      {key: 'feeling', label: '気持ち察し力'},
      {key: 'flexibility', label: '柔軟性'},
    ];

    return (
      <Form.Group>
        <Form.Label>能力</Form.Label>
        {skills.map(item => (
          <Form.Check
            key={item.key}
            type="checkbox"
            label={item.label}
            checked={this.getFormControlValue(`questions.${selectedQuestion}.${item.key}`) === "1"}
            onChange={(e) => this.onFormControlChanged(`questions.${selectedQuestion}.${item.key}`, e.target.checked ? "1" : "0")}
          />
        ))}
      </Form.Group>
    );
  };

  renderAnswerHeader = () => {
    let {selectedQuestion, selectedAnswer, answers} = this.state;
    if (selectedQuestion === null) {
      return null;
    }
    let questionAnswers = answers.value.filter(answer => answer.questionId === selectedQuestion);
    let answerIndex = selectedAnswer !== null
      ?
      questionAnswers.findIndex(answer => answer.id === selectedAnswer)
      :
      -1;

    return (
      <ButtonToolbar className="mt-4 justify-content-between align-items-center">
        <h1 className="m-0">答え</h1>

        <div className="d-flex">
          <DropdownButton title={answerIndex !== -1 ? `答え${answerIndex + 1}` : '未定'}>
            {questionAnswers.map((answer, index) => (
              <Dropdown.Item key={index} onClick={() => this.onAnswer(answer.id)}>
                問{index + 1}
              </Dropdown.Item>
            ))}
            {selectedAnswer !== null && (<Dropdown.Divider/>)}
            <Dropdown.Item onClick={this.onAnswerAdd}>
              追加
            </Dropdown.Item>

          </DropdownButton>
          {selectedAnswer !== null && (
            <Button variant="primary"
                    className="ml-2"
                    onClick={this.onAnswerRemove}>削除</Button>
          )}
        </div>
      </ButtonToolbar>
    );
  };

  renderAnswerContentFactor = (factorId, answerIndex) => {
    const {factors} = this.state;

    let factorIndex = factors.value.findIndex(factor => factor.id === factorId);
    if (factorIndex === -1) {
      return null;
    }

    const selectedFactorsValues = this.getFormControlValue(`answers.${answerIndex}.factorsValues`);
    const selectedFactorsValueList = selectedFactorsValues ? JSON.parse(selectedFactorsValues) : {};

    const handle = (e) => {
      let values = {...selectedFactorsValueList};
      if (!e.target.value || e.target.value.length === 0) {
        delete values[factorId];
      } else {
        values[factorId] = e.target.value;
      }
      this.onFormControlChanged(`answers.${answerIndex}.factorsValues`, JSON.stringify(values));
    };

    return (
      <div key={factorId}>
        <Form.Group>
          <Form.Label>{this.getFormControlValue(`factors.${factorIndex}.title`)}</Form.Label>
          <Form.Control as="select" value={selectedFactorsValueList[factorId] || ''} onChange={(e) => handle(e)}>
            <option value=''>未選択</option>
            <option value={2}>2</option>
            <option value={4}>4</option>
            <option value={6}>6</option>
            <option value={10}>10</option>
          </Form.Control>
        </Form.Group>
      </div>
    );
  };

  renderAnswerContent = () => {
    let {selectedQuestion, selectedAnswer, answers} = this.state;
    if (selectedQuestion === null || selectedAnswer === null) {
      return null;
    }

    let questionIndex = this.state.questions.value.findIndex(question => question.id === selectedQuestion);
    if (questionIndex === -1) {
      return ;
    }

    let answerIndex = answers.value.findIndex(answer => answer.id === selectedAnswer);
    if (answerIndex === -1) {
      return ;
    }

    // const selectedFactors = this.getFormControlValue(`questions.${questionIndex}.factors`) || '';
    // const selectedFactorsList = selectedFactors.split(',');

    return (
      <Form id="form" className="mt-4">
        <Form.Group>
          <Form.Label>テキスト</Form.Label>
          <Form.Control required
                        as="textarea"
                        rows="3"
                        value={this.getFormControlValue(`answers.${answerIndex}.text`)}
                        onChange={(e) => this.onFormControlChanged(`answers.${answerIndex}.text`, e.target.value)}
                        isInvalid={!this.formFieldRequiredValidation(`answers.${answerIndex}.text`)} />
          <Form.Control.Feedback type="invalid">
            [{this.formFieldRequiredValidationByLanguage(`answers.${answerIndex}.text`)}] テキストを入力してください
          </Form.Control.Feedback>
        </Form.Group>
        {/*<Form.Group>*/}
        {/*  <Form.Label>説明</Form.Label>*/}
        {/*  <Form.Control required*/}
        {/*                as="textarea"*/}
        {/*                rows="3"*/}
        {/*                value={this.getFormControlValue(`answers.${answerIndex}.description`)}*/}
        {/*                onChange={(e) => this.onFormControlChanged(`answers.${answerIndex}.description`, e.target.value)}*/}
        {/*                isInvalid={!this.formFieldRequiredValidation(`answers.${answerIndex}.description`)} />*/}
        {/*  <Form.Control.Feedback type="invalid">*/}
        {/*    [{this.formFieldRequiredValidationByLanguage(`answers.${answerIndex}.text`)}] 説明を入力してください*/}
        {/*  </Form.Control.Feedback>*/}
        {/*</Form.Group>*/}

        {/*{selectedFactorsList.map((selectedFactor) => this.renderAnswerContentFactor(selectedFactor, answerIndex))}*/}

        <Form.Group>
          <Form.Label>配点</Form.Label>
          <Form.Control as="select"
                        value={this.getFormControlValue(`answers.${answerIndex}.points`)}
                        onChange={(e) => this.onFormControlChanged(`answers.${answerIndex}.points`, e.target.value)}
                        isInvalid={!this.formFieldRequiredValidation(`answers.${answerIndex}.points`)} >
            <option value=''>未選択</option>
            <option value={2}>2</option>
            <option value={4}>4</option>
            <option value={6}>6</option>
            <option value={10}>10</option>
          </Form.Control>
        </Form.Group>
      </Form>
    );
  };

  renderDangerActions = () => {
    if (!this.state.data) {
      return null;
    }
    return (
      <Card className="mt-4 mb-4">
        <Card.Header variant="danger" text="white">注意アクション</Card.Header>
        <Card.Body>
          <Button variant="danger" className="mt-2" onClick={this.onDeleteClick}>テストを削除する</Button>
        </Card.Body>
      </Card>
    );
  };

  render() {
    const {loading, error} = this.state;
    return (
      <>
        <SmallBgLoading isLoading={loading}>
          {this.state.error && (<Alert variant="danger" className="mt-4">{error.message}</Alert>)}
          {this.renderToolbar()}
          {this.renderGeneral()}
        </SmallBgLoading>
        {this.renderQuestionsHeader()}
        {this.renderQuestionsContent()}
        {this.renderAnswerHeader()}
        {this.renderAnswerContent()}
        {this.renderDangerActions()}
      </>
    );
  }
}

export default SimpleFlowTestPage;
