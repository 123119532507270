import React, { Component } from "react";
import { connect } from "react-redux";
import { Form, Button, InputGroup, Alert } from "react-bootstrap";
import Div100vh from "react-div-100vh";
import { SmallBgLoading } from "../../components/commons/SmallBgLoading";
import { ReactComponent as Logo } from "../../assets/svg/kibi_logo.svg";
import { Auth } from 'aws-amplify';
import PermissionsGuard from "../../services/guards/PermissionsGuard";
import { TermsModal } from "../../components/modals/TermsModal";

class LoginPage extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      email: "",
      password: "",
      newPassword: "",
      newPasswordConfirmation: "",
      error: null,
      loading: false,
      isNewPasswordRequired: false,
      isPasswordReset: false,
      user: null,
      isShownTermsModal: false
    };
  }

  async componentWillMount() {
    try {
      if (await Auth.currentAuthenticatedUser() != null) this.props.history.push(await PermissionsGuard.route());
    } catch (e) {
      // do nothing
    }
  }

  async onSubmitLoginForm(event) {
    event.preventDefault();

    this.setState({ isShownTermsModal: true });

  }

  async onSubmitNewPasswordForm(event) {
    event.preventDefault();

    this.setState({loading: true, error: null});

    try {
      await Auth.completeNewPassword(this.state.user, this.state.newPassword, null);
      this.setState({loading: false});
      this.props.history.push(await PermissionsGuard.route());
    } catch (error) {
      this.setState({loading: false, error});
    }

  }

  onClickTermsApproved = async () => {
    this.setState({isShownTermsModal: false, loading: true, error: null});

    try {
      let result = await Auth.signIn(this.state.email, this.state.password);

      if(result.challengeName === "NEW_PASSWORD_REQUIRED") {
        this.setState({ loading: false, user: result, isNewPasswordRequired: true});
      } else {
        this.setState({loading: false});
        this.props.history.push(await PermissionsGuard.route());
      }

    } catch (error) {
      this.setState({loading: false, error});
    }
  };

  onClickPasswordReset = () => {
    this.setState({ isPasswordReset: true });
  };

  onSubmitPasswordRequest = async (event) => {
    event.preventDefault();

    this.setState({loading: true});
    try {
      let test = await Auth.forgotPassword(this.state.email);
      // this.setState({loading: false});
      // this.props.history.push(await PermissionsGuard.route());
    } catch (error) {
      this.setState({loading: false, error});
    }
  };

  buildTitle = () => {
    if (this.state.isNewPasswordRequired) {
      return "新しいパスワードが必要です";
    } else if (this.state.isPasswordReset) {
      return "パスワードリセット"
    } else {
      return "ログイン"
    }
  };

  render() {
    return (
      <Div100vh className="d-flex justify-content-center align-items-center flex-column text-center">
        <div style={{ width: 258 }}>
          <SmallBgLoading isLoading={this.state.loading}>
            <Logo />
            <h4 className="d-block text-center mb-3 font-weight-normal">
              {this.buildTitle()}
            </h4>

            {this.state.error && (
              <Alert variant="danger">{this.state.error.message}</Alert>
            )}
            {!this.state.isNewPasswordRequired && !this.state.isPasswordReset && (
              <Form onSubmit={this.onSubmitLoginForm.bind(this)}>
                <InputGroup className="vertical-input-group">
                  <Form.Control
                    required
                    type="email"
                    placeholder="Email address"
                    value={this.state.email}
                    onChange={e => this.setState({ email: e.target.value })}
                  />
                  <Form.Control
                    required
                    name="password"
                    type="password"
                    placeholder="Password"
                    value={this.state.password}
                    onChange={e => this.setState({ password: e.target.value })}
                  />
                </InputGroup>
                <Button
                  variant="primary"
                  className="mt-2"
                  type="submit"
                  disabled={!(this.state.email.length > 0 && this.state.password.length > 0)}
                  block
                >
                  ログイン
                </Button>
                <div className="text-center">
                  <Button variant="link"
                          onClick={this.onClickPasswordReset}>
                    パスワードを忘れた方こちらへ
                  </Button>
                </div>
              </Form>
            )}
            {this.state.isNewPasswordRequired && (
              <Form onSubmit={this.onSubmitNewPasswordForm.bind(this)}>
                <InputGroup className="vertical-input-group">
                  <Form.Control
                    required
                    name="password"
                    type="password"
                    placeholder="新しいパスワード"
                    value={this.state.newPassword}
                    onChange={e => this.setState({ newPassword: e.target.value })}
                  />
                  <Form.Control
                    required
                    name="password"
                    type="password"
                    placeholder="新しいパスワード確認"
                    value={this.state.newPasswordConfirmation}
                    onChange={e => this.setState({ newPasswordConfirmation: e.target.value })}
                  />
                </InputGroup>
                <Button
                  variant="primary"
                  className="mt-2"
                  type="submit"
                  disabled={!(this.state.newPassword.length > 0 && this.state.newPasswordConfirmation.length > 0 && this.state.newPassword === this.state.newPasswordConfirmation)}
                  block
                >
                  パスワード登録
                </Button>
              </Form>
            )}
            {this.state.isPasswordReset && (
              <Form onSubmit={this.onSubmitPasswordRequest.bind(this)}>
                <InputGroup className="vertical-input-group">
                  <Form.Control
                    required
                    name="email"
                    type="email"
                    placeholder="メールアドレス"
                    value={this.state.email}
                    onChange={e => this.setState({ email: e.target.value })}
                  />
                </InputGroup>
                <Button
                  variant="primary"
                  className="mt-2"
                  type="submit"
                  disabled={!(this.state.email.length > 0)}
                  block
                >
                  パスワードリセット
                </Button>
              </Form>
            )}
          </SmallBgLoading>
        </div>
        <TermsModal show={this.state.isShownTermsModal}
                    onClose={() => this.setState({ isShownTermsModal: false })}
                    onOk={this.onClickTermsApproved.bind(this)} />
      </Div100vh>
    );
  }
}

function mapStateToProps(state) {
  return {
    // error: state.auth.error,
    // loading: state.auth.loading,
    // isAuth: authSelectors.isAuth(state)
  };
}

export default connect(mapStateToProps)(LoginPage);
