import React, { Component } from "react";
import { connect } from "react-redux";
import Div100vh from "react-div-100vh";
import {Button, Container, Nav, Navbar, NavDropdown} from "react-bootstrap";
import * as contentsService from '../../../services/contents';
import "./index.scss";
import {LinkContainer} from "react-router-bootstrap";
import * as rootActions from "../../../store/root/actions";
import * as commonService from "../../../services/common";
import  { Redirect } from 'react-router-dom'

class ComicLifePage extends Component {

  constructor(props, context) {
    super(props, context);

    this.state = {
      nextContentId: null,
      isMap: false,
      isComic: false,
      isSituation: false,
      data: null,
      loading: false,
    };
  }

  componentDidMount() {
    this.fetchData();
  }

  fetchData = async (nextContentId = null) => {
    const {contentId} = this.props.match.params;
    const {comicLifeIds} = this.props;

    this.setState({loading: true});
    try {
      let content = await contentsService.fetchContent(nextContentId || contentId);

      if (content) {
        content.comicPages = JSON.parse(content.comicPages);
      }

      let state = {
        nextContentId: nextContentId,
        loading: false,
        data: content,
        isMap: !!content && this.props.comicLifeIntro,
        isComic: !!content && !this.props.comicLifeIntro,
        isSituation: false,
      };

      this.setState(state);
      window.scrollTo(0, 0);

    } catch (e) {
      console.log(e);
    }
  };

  formatByLanguage = (value) => {
    try {
      let array = typeof value === "string" ? JSON.parse(value) : value;
      if (array[this.props.currentLang]) {
        return array[this.props.currentLang];
      } else {
        return "Undefined";
      }
    } catch (e) {
      return null;
    }
  };

  onComic = () => {
    this.setState({isMap: false, isComic: true});
    this.props.dispatch(rootActions.setComicLifeIntro(false));
    window.scrollTo(0, 0);
  };

  onSituation = () => {
    this.setState({isComic: false, isSituation: true});
    window.scrollTo(0, 0);
  };

  onSituationBack = () => {
    this.setState({isComic: true, isSituation: false});
    window.scrollTo(0, 0);
  };

  onNextComic = () => {
    const {comicLifeIds, nextContentId} = this.props;
    const {contentId} = this.props.match.params;

    const comicIndex = nextContentId ? comicLifeIds.indexOf(nextContentId) : comicLifeIds.indexOf(contentId);
    const nextComicId = comicLifeIds[comicIndex + 1];

    this.props.history.push(`/comic-life/${nextComicId}`);
  };

  onMarket = () => {
    this.props.history.push('/');
  };

  renderHeader = () => {
    return (
      <Navbar bg="white" fixed="top" expand="lg">
        <Navbar.Brand
          style={{ margin: "-10px 0", padding: 0 }}
          className="cursor-hover"
        >
          <LinkContainer to="/">
            <div>kibi-<span style={{color: "#0F80B4"}}>learning</span>.jp</div>
          </LinkContainer>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav>
            <LinkContainer to={`/`} activeClassName="">
              <Nav.Link>マーケットへ</Nav.Link>
            </LinkContainer>
          </Nav>
          <Nav className="ml-auto">
            <NavDropdown
              title={commonService.languages[this.props.currentLang]}
              id="basic-nav-dropdown"
              className="mr-3"
            >
              {commonService.languagesList.map(lang => (
                <NavDropdown.Item
                  key={lang}
                  onClick={() => this.props.dispatch(rootActions.setLang(lang))}
                >
                  {commonService.languages[lang]}
                </NavDropdown.Item>
              ))}
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    );
  };

  renderMap = () => {
    const {data} = this.state;
    return (
      <>
        <Container className="comic-life-map">
          <h1 className="comic-life-map-header">Introduction</h1>
          <div>
            <h2 className="comic-life-map-sub-header">人物相関図</h2>
          </div>
          <div className="comic-life-map-content">
            <div
              className="comic-life-map-image"
              style={{
                background: `url('${this.formatByLanguage(data.comicIntroductionSvgUrl)}') no-repeat`
              }}
            />
            <div className="comic-life-map-introduction">
              <p>{this.formatByLanguage(data.comicIntroduction)}</p>
              <div className="comic-life-map-introduction-actions">
                <Button className="ml-2" onClick={this.onComic}>まんがを読む</Button>
              </div>
            </div>
          </div>
        </Container>
      </>
    );
  };

  renderComic = () => {
    const {data} = this.state;

    return (
      <div className="comic-life-pages">
        <h2 className="comic-life-pages-title">{this.formatByLanguage(data.title)}</h2>
        <div className="comic-life-pages-item">
          {data.comicPages.map((page, index) => (
            <div key={index} className="comic-life-pages-item-image" style={{
              background: `url('${this.formatByLanguage(page.url)}') no-repeat`,
            }}>
            </div>
          ))}
        </div>
        <div className="comic-life-pages-actions">
          <Button onClick={this.onSituation}>解説へ</Button>
        </div>
      </div>
    );
  };

  renderSituation = () => {
    const {data, nextContentId} = this.state;
    const {comicLifeIds} = this.props;
    const {contentId} = this.props.match.params;

    const comicIndex = comicLifeIds.indexOf(nextContentId || contentId);

    if (!data) {
      return (
        <div/>
      );
    }

    return (
      <Container className="comic-life-situation">
        <h2 className="comic-life-situation-header">{this.formatByLanguage(data.title)}</h2>
        <div className="comic-life-situation-content">
          <div className="comic-life-situation-image" style={{
            background: `url('${this.formatByLanguage(data.comicSituationSvgUrl)}') no-repeat`,
          }}>
          </div>
          <div className="comic-life-situation-description">
            <p>{this.formatByLanguage(data.comicSituation)}</p>
            <div className="comic-life-situation-description-actions">
              <Button onClick={this.onSituationBack}>戻る</Button>
              {comicIndex !== - 1 && comicIndex + 1 < comicLifeIds.length && <Button className="ml-2" onClick={this.onNextComic}>次へ</Button>}
              {!(comicIndex !== - 1 && comicIndex + 1 < comicLifeIds.length) && <Button className="ml-2" onClick={this.onMarket}>マーケットへ</Button>}
            </div>
          </div>
        </div>
      </Container>
    );
  };

  render() {
    const {loading, data, redirect} = this.state;

    if (loading || !data) {
      return (
        <div/>
      );
    }

    return (
      <>
        <Div100vh>
          {this.renderHeader()}
          <div className="comic-life-container">
            {this.state.isMap && this.renderMap()}
            {this.state.isComic && this.renderComic()}
            {this.state.isSituation && this.renderSituation()}
          </div>
        </Div100vh>
        {redirect && <Redirect to={redirect}  />}
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    currentLang: state.root.currentLang,
    comicLifeIntro: state.root.comicLifeIntro,
    comicLifeIds: state.root.comicLifeIds,
  };
}

export default connect(mapStateToProps)(ComicLifePage);
