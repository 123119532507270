import React, { Component } from "react";
import { connect } from "react-redux";
import {ButtonToolbar, Button, ListGroup} from "react-bootstrap";
import * as generator from "generate-password"
import * as userService from "../../../services/users";
import * as commonService from "../../../services/common";
import * as csvService from "../../../services/csv";
import * as accountService from "../../../services/account";
import { Auth } from 'aws-amplify';
import {SmallBgLoading} from "../../../components/commons/SmallBgLoading";
import * as nursingResultsMiddleware from "../../../services/nursing/results";
import * as nursingResultsService from "../../../services/nursing-results";
import uuid from "uuid";
import nursingCsv from "../../../services/nursing/csv";

class UsersPage extends Component {

  constructor(props, context) {
    super(props, context);

    this.state = {
      loading: true,
      error: null,
      items: [],
      accountPermissions: null,
      isUpload: false,
      uploadProgress: 0,
      uploadError: null,
      account: null,
    };
  }

  componentDidMount() {
    this._isMounted = true;
    this.fetchUsers();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  fetchUsers = async () => {
    this.setState({ loading: true, error: null });
    try {
      let user = await Auth.currentAuthenticatedUser();
      const account = await accountService.fetchAccount();
      let accountPermissions = null
      if (account.permissions) {
        accountPermissions = JSON.parse(account.permissions);
      }
      let response = await userService.fetchUsers();
      let items = response.items.filter(item => item.userGroup === "USER" && item.parentId === user.username);
      items = items.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
      if (this._isMounted) {
        this.setState({ items: items, account: account, loading: false, accountPermissions });
      }
    } catch (e) {
      console.error(e);
      if (this._isMounted) {
        this.setState({loading: true, error: e});
      }
    }
  };

  onCreateClick = () => {
    const {account, items} = this.state;
    if (!account || !account.childMaxCount) {
      return;
    }

    if (account.childMaxCount <= items.length) {
      alert('アカウント数は上限されてます');
    } else {
      this.props.history.push("/manager/new/user");
    }
  };

  onEditClick = (item) => {
    this.props.history.push(`/manager/${item.id}/user`);
  };

  onDownload = () => {

    // Generate data
    let schema = [
      {label: "ID", path: "id"},
      {label: "メールアドレス", path: "email", type: "String"},
      {label: "名前", path: "name", type: "String"},
    ];

    let csv = csvService.prepareCsv(schema, this.state.items);
    csvService.downloadCsv(csv, "users.csv");
  };

  onDownloadNursing = async () => {
    const nursingResultsResponse = await nursingResultsService.fetchAdminNursingResults();
    const nursingResults = nursingResultsResponse.items
    nursingResults.forEach((item) => {
      item.results = JSON.parse(item.results);
    });
    const csv = await nursingCsv({nursingResults, newestOnly: true, parentCheck: true });
    csvService.downloadCsv(csv, `nursing-test-results-${new Date().toISOString()}.csv`);
  };

  onUploadSample = () => {

    // Generate data
    let schema = [
      {label: "メールアドレス", path: "email", type: "String"},
      {label: "パスワード", path: "password", type: "String"},
      {label: "名前", path: "name", type: "String"},
    ];

    let items = [
      {
        email: "user-1@example.com",
        password: "qwerty",
        name: "John",
      },
      {
        email: "user-2@example.com",
        password: "",
        name: "Tanaka",
      }
    ];

    let csv = csvService.prepareCsv(schema, items);
    csvService.downloadCsv(csv, "users-sample.csv");
  };

  onUpload = async (file) => {
    const {account} = this.state;
    if (!account || !account.childMaxCount) {
      return;
    }

    this.setState({ loading: true, error: null });
    let schema = [
      {path: "email", type: "String", required: true},
      {path: "password", type: function(value) {
          if (value) return value;
          return generator.generate({
            length: 6,
            uppercase: false
          });
        }},
      {path: "name", type: "String", required: true},
    ];
    try {
      let user = await Auth.currentAuthenticatedUser();
      let items = await csvService.readCsv(file, schema);
      let item;

      if (account.childMaxCount < this.state.items.length + items.length) {
        this.setState({ loading: false});
        alert('アカウント数は上限されてます');
        return;
      }

      for (item of items) {
        item.userGroup = "USER";
        item.parentId = user.username;
        await userService.createUser(item);
      }
      await this.fetchUsers();
      window.alert("アップロードは完了しました");
    } catch (e) {
      if (e.errors) {
        window.alert(e.errors[0].message);
      } else {
        window.alert(e.message);
      }
    }
    this.setState({ loading: false});
  };

  render() {
    const {accountPermissions} = this.state;
    return (
      <>
        <SmallBgLoading isLoading={this.state.loading}>
          <ButtonToolbar className="mt-4 justify-content-between align-items-center">
            <h1 className="m-0">ユーザ一覧</h1>
            <div className="d-flex">
              <div className='btn btn-outline-primary mr-2 pointer'>
                <label htmlFor="file" style={{marginBottom: 0}}>
                  ユーザアップロード
                </label>
                <input id="file" accept="text/csv" type='file' style={{display: 'none'}} onChange={(e) => {this.onUpload(e.target.files[0]); e.target.value = null;}} />
              </div>
              <Button variant="outline-primary mr-2" onClick={this.onUploadSample}>ユーザ・CSVサンプル</Button>
              <Button variant="outline-primary mr-2" onClick={this.onDownload}>ユーザダウンロード</Button>
              {accountPermissions && accountPermissions.indexOf('NURSING') !== -1 && <Button variant="outline-primary mr-2" onClick={this.onDownloadNursing}>きづっきー診断・ダウンロード</Button>}
              <Button variant="primary" onClick={this.onCreateClick}>新規制作</Button>
            </div>
          </ButtonToolbar>

          <ListGroup className="mt-3">
            {this.state.items.map(item => (
              <ListGroup.Item key={item.id} className="d-flex justify-content-between align-items-center pointer" onClick={() => this.onEditClick(item)}>
                  <div>{item.name} ({item.email})</div>
                  <div>{commonService.formatDate(item.createdAt)}</div>
              </ListGroup.Item>
            ))}
            {this.state.items.length === 0 && (
              <ListGroup.Item>
                何も見つかりませんでした
              </ListGroup.Item>
            )}
          </ListGroup>
        </SmallBgLoading>
      </>
    )
  };
}

function mapStateToProps(state) {
  return {};
}

export default connect(mapStateToProps)(UsersPage);
