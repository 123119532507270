import React, { Component } from "react";
import { Route, withRouter } from "react-router-dom";

class ProtectedRoute extends Component {

  constructor(props, context) {
    super(props, context);

    this.state = {
      processed: false,
      isAuth: true,
    };
  }

  async componentDidMount() {
    let { guards, path } = this.props;

    for (let groupIndex in guards) {
      let guard = guards[groupIndex];
      if (!await guard.validate(path)) {
        let redirectTo = await guard.redirectOnError();
        this.props.history.push(redirectTo);
        break;
      }
    }

    this.setState({ processed: true });
  }

  render() {
    let { component: Component, guards, ...rest } = this.props;
    return (<>
      {!this.state.processed && (<div/>)}
      {this.state.processed && (
        <Route
          {...rest}
          render={props =>
            this.state.isAuth ? (
              <>
                <Component {...props} />
              </>
            ) : (<div/>)
          }
        />
      )}
    </>);
  }
}

export default withRouter(ProtectedRoute);
