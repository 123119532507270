import React, { Component } from "react";
import { connect } from "react-redux";
import { Alert, Button, ButtonToolbar, Form, Dropdown, Card, Nav } from "react-bootstrap";
import uuid from "uuid";
import * as contentsService  from "../../../../../services/contents";
import * as practiceQuestionsService  from "../../../../../services/practice-questions";
import * as commonService  from "../../../../../services/common";
import { SmallBgLoading } from "../../../../../components/commons/SmallBgLoading"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import { Storage } from "aws-amplify";
import "./index.scss";

class PracticeQuestionPage extends Component {

  constructor(props, context) {
    super(props, context);

    this.state = {
      data: null,

      selectedTab: null,
      selectedAnswer: null,
      touched: false,
      title: { separatedLanguage: true, required: true },
      svgUrl: { separatedLanguage: false, required: true },
      characters: {
        image: {
          separatedLanguage: false,
          required: true
        },
        name: {
          separatedLanguage: true,
          required: true
        },
        multiply: true
      },
      chats: {
        character: {
          separatedLanguage: false,
          required: true
        },
        text: {
          separatedLanguage: true,
          required: true
        },
        reading: {
          separatedLanguage: true,
          required: false
        },
        multiply: true
      },
      answers: {
        image: {
          separatedLanguage: false,
          required: true
        },
        text: {
          separatedLanguage: true,
          required: true
        },
        reading: {
          separatedLanguage: true,
          required: false
        },
        message: {
          separatedLanguage: false,
          required: true
        },
        descriptionImage: {
          separatedLanguage: false,
          required: true
        },
        descriptionJapaneseFeeling: {
          separatedLanguage: true,
          required: true
        },
        descriptionKibi: {
          separatedLanguage: true,
          required: true
        },
        descriptionKibiAvatar: {
          separatedLanguage: false,
          required: true
        },
        descriptionJapanesePoint: {
          separatedLanguage: true,
          required: true
        },
        descriptionBehavePoint: {
          separatedLanguage: true,
          required: true
        },

        isCorrect: {
          separatedLanguage: false,
          required: false
        },
        value: [{}, {}, {}],
        multiply: true
      },
      languages: [],

      content: null,
      selectedLanguage: null,

      error: null,
      loading: false,
    };
  }

  async componentWillMount() {
    this.setState({loading: true});

    try {

      let state = {loading: false};
      let content = await contentsService.fetchContent(this.props.match.params.courseId);
      state.content = content;
      state.languages = JSON.parse(content.languages);

      if (this.props.match.params.practiceQuestionId !== "new") {
        state.data = await practiceQuestionsService.fetchPracticeQuestion(this.props.match.params.practiceQuestionId);

        let fields = ["title", "svgUrl"];
        for (let fieldIndex in fields) {
          if (this.state[fields[fieldIndex]].separatedLanguage) {
            for (let langIndex in state.languages) {
              if (!state[fields[fieldIndex]]) state[fields[fieldIndex]] = {...this.state[fields[fieldIndex]]};

              try {
                state[fields[fieldIndex]][state.languages[langIndex]] = JSON.parse(state.data[fields[fieldIndex]])[state.languages[langIndex]];
              } catch (e) {}
            }

          } else {
            state[fields[fieldIndex]] = { value: state.data[fields[fieldIndex]], ...this.state[fields[fieldIndex]]};
          }
        }

        state.characters = { value: JSON.parse(state.data.characters), ...this.state.characters};
        state.chats = { value: JSON.parse(state.data.chats), ...this.state.chats};
        state.answers = this.state.answers;
        state.answers.value = JSON.parse(state.data.answers);
      }
      state.selectedLanguage = commonService.mainLanguage;

      this.setState(state);
    } catch (error) {
      this.onError(error);
    }


  }

  formatByLanguage = (value) => {
    let array = JSON.parse(value);
    if (array[this.state.selectedLanguage]) {
      return array[this.state.selectedLanguage];
    } else {
      return "Undefined"
    }
  };

  onCancelClick = () => {
    this.props.history.push(`/admin/courses/${this.props.match.params.courseId}`);
  };

  onDeleteClick = async () => {
    this.setState({error: null, loading: true});

    try {
      await practiceQuestionsService.deletePracticeQuestion(this.state.data.id);

      // update order
      let course = await contentsService.fetchContent(this.props.match.params.courseId);
      let courseSettings = course.settings ? JSON.parse(course.settings) : {};
      if (courseSettings.practiceQuestions) {
        let index = courseSettings.practiceQuestions.indexOf(this.state.data.id);
        if (index !== -1) {
          courseSettings.practiceQuestions.splice(index, 1);
        }
      } else {
        courseSettings.practiceQuestions = [];
      }
      await contentsService.updateContent({
        id: course.id,
        languages: course.languages,
        title: course.title,
        settings: JSON.stringify(courseSettings),
        description: course.description
      });

      this.setState({loading: false});
      this.props.history.push(`/admin/courses/${this.props.match.params.courseId}`);
    } catch (error) {
      this.onError(error);
    }
  };

  onDoneClick = async () => {
    await this.onSubmit(null);
  };

  onSubmit = async (event) => {
    if (event) event.preventDefault();
    this.setState({error: null, touched: true});

    let errorField = this.formRequiredValidation([
      "title",
      "svgUrl",
      "characters",
      "characters.n.image",
      "characters.n.name",
      "chats",
      "chats.n.character",
      "chats.n.text",
      "answers",
      "answers.n.image",
      "answers.n.text",
      "answers.n.message",
      "answers.n.descriptionImage",
      "answers.n.descriptionJapaneseFeeling",
      "answers.n.descriptionKibi",
      "answers.n.descriptionKibiAvatar",
      "answers.n.descriptionJapanesePoint",
      "answers.n.descriptionBehavePoint",
    ]);

    if (errorField) {
      this.setState({error: new Error(`保存できませんでした: ${errorField}`)});
      return;
    }

    this.setState({loading: true});

    try {
      let fields = ["title", "svgUrl"];
      let input = {};
      for (let fieldIndex in fields) {
        if (this.state[fields[fieldIndex]].separatedLanguage) {
          let values = {};
          for (let langIndex in this.state.languages) {
            let value = this.state[fields[fieldIndex]][this.state.languages[langIndex]];
            values[this.state.languages[langIndex]] = value ? value : null;
          }
          input[fields[fieldIndex]] = JSON.stringify(values);
        } else {
          input[fields[fieldIndex]] = this.state[fields[fieldIndex]].value;
        }
      }

      input["characters"] = JSON.stringify(this.state["characters"].value);
      input["chats"] = JSON.stringify(this.state["chats"].value);
      input["answers"] = JSON.stringify(this.state["answers"].value);

      if (this.state.data) {
        input.id = this.state.data.id;
        await practiceQuestionsService.updatePracticeQuestion(input);
      } else {
        input.id = uuid.v4();
        input.contentId = this.props.match.params.courseId;
        await practiceQuestionsService.createPracticeQuestion(input);

        // update order
        let course = await contentsService.fetchContent(this.props.match.params.courseId);
        let courseSettings = course.settings ? JSON.parse(course.settings) : {};
        if (courseSettings.practiceQuestions) {
          courseSettings.practiceQuestions.push(input.id);
        } else {
          courseSettings.practiceQuestions = [input.id];
        }
        await contentsService.updateContent({
          id: course.id,
          languages: course.languages,
          title: course.title,
          settings: JSON.stringify(courseSettings),
          description: course.description
        });
      }

      this.setState({loading: false});
      this.props.history.push(`/admin/courses/${this.props.match.params.courseId}`);
    } catch (error) {
      this.onError(error);
    }
  };

  onChangeLanguage = (lang) => {
    if (this.state.selectedLanguage !== lang) {
      this.setState({ selectedLanguage: lang });
    }
  };

  onCreateCharacter = () => {
    let value = this.state.characters.value ? this.state.characters.value : [];
    value.push({
      id: uuid.v4()
    });

    let characters = this.state.characters;
    characters.value = value;

    this.setState( { characters });
  };

  onDeleteCharacter = (character) => {
    let value = this.state.characters.value;
    let index = value.indexOf(character);
    if (index <= -1) return;

    value.splice(index, 1);

    let characters = this.state.characters;
    characters.value = value;

    this.setState( { characters });
  };

  onCreateChat = () => {
    let value = this.state.chats.value ? this.state.chats.value : [];
    value.push({
      id: uuid.v4()
    });

    let chats = this.state.chats;
    chats.value = value;

    this.setState( { chats });
  };

  onDeleteChat = (chat) => {
    let value = this.state.chats.value;
    let index = value.indexOf(chat);
    if (index <= -1) return;

    value.splice(index, 1);

    let chats = this.state.chats;
    chats.value = value;

    this.setState( { chats });
  };

  onFileUpload = async (fieldName, key, file) => {

    let contentId = this.props.match.params.courseId;
    let filename = "practice-" + key + "-" + uuid() + "." + file.name.split('.').pop();

    const s3Object = await Storage.put(`contents/course-${contentId}/${filename}`, file, {
      contentType: file.type,
      level: "public"
    });

    let url = `https://s3-ap-northeast-1.amazonaws.com/${process.env.REACT_APP_S3_BUCKET}/public/${s3Object.key}`;

    this.onFormControlChanged(fieldName, url);
  };

  onError = (error) => {
    if (error.errors) {
      console.error(error.errors[0]);
      this.setState({ loading: false, error: error.errors[0] });
    } else {
      console.error(error);
      this.setState({ loading: false, error });
    }
  };

  onFormControlChanged = (fieldName, value) => {

    let pathComponents = fieldName.split(".");

    let data = {...this.state[pathComponents[0]]};

    if (pathComponents.length > 1) {
      if (data[pathComponents[2]].separatedLanguage) {
        if (data.value[parseInt(pathComponents[1])][pathComponents[2]]) {
          data.value[parseInt(pathComponents[1])][pathComponents[2]][this.state.selectedLanguage] = value;
        } else {
          let item = {};
          item[this.state.selectedLanguage] = value;
          data.value[parseInt(pathComponents[1])][pathComponents[2]] = item;
        }
      } else {
        data.value[parseInt(pathComponents[1])][pathComponents[2]] = { value };
      }
    } else {
      if (this.state[pathComponents[0]].separatedLanguage) {
        data[this.state.selectedLanguage] = value;
      } else {
        data.value = value;
      }
    }

    let state = {};
    state[pathComponents[0]] = data;

    if (this.state.error) {
      state.error = null;
    }

    this.setState(state);
  };

  getFormControlValue = (fieldName) => {

    let pathComponents = fieldName.split(".");

    if (pathComponents.length > 1) {
      if (this.state[pathComponents[0]][pathComponents[2]].separatedLanguage) {
        if (
          this.state[pathComponents[0]].value[pathComponents[1]][pathComponents[2]] &&
          this.state[pathComponents[0]].value[pathComponents[1]][pathComponents[2]][this.state.selectedLanguage]
        ) {
          return this.state[pathComponents[0]].value[pathComponents[1]][pathComponents[2]][this.state.selectedLanguage];
        }  else {
          return "";
        }
      } else {
        if (
          this.state[pathComponents[0]].value[pathComponents[1]][pathComponents[2]] &&
          this.state[pathComponents[0]].value[pathComponents[1]][pathComponents[2]].value
        ) {
          return this.state[pathComponents[0]].value[pathComponents[1]][pathComponents[2]].value;
        }  else {
          return "";
        }
      }

    } else {
      if (this.state[fieldName].separatedLanguage) {
        let value = this.state[fieldName] ? this.state[fieldName][this.state.selectedLanguage] : null;
        return value ? value : "";
      } else {
        let value = this.state[fieldName].value;
        return value ? value : "";
      }
    }

  };

  formRequiredValidation = (fileds) => {
    for (let i in fileds) {
      let pathComponents = fileds[i].split(".");
      if (pathComponents.length > 1) {
        if (parseInt(pathComponents[1])) {
          if (!this.formFieldRequiredValidation(fileds[i], true)) {
            return fileds[i];
          }
        } else {
          if (this.state[pathComponents[0]].value && this.state[pathComponents[0]].value.length > 0) {
            for (let rowIndex in this.state[pathComponents[0]].value) {
              if (!this.formFieldRequiredValidation(`${pathComponents[0]}.${rowIndex}.${pathComponents[2]}`, true)) {
                return `${pathComponents[0]}.${rowIndex}.${pathComponents[2]}`;
              }
            }
          } else {
            return `${pathComponents[0]}.n.${pathComponents[2]}`
          }
        }
      } else {
        if (!this.formFieldRequiredValidation(fileds[i], true)) {
          return fileds[i];
        }
      }

    }

    return null;
  };

  formFieldRequiredValidation = (fieldName, isTouched = false) => {
    if (!isTouched && !this.state.touched) {
      return true;
    }

    let pathComponents = fieldName.split(".");

    if (pathComponents.length > 1) {
      if (this.state[pathComponents[0]][pathComponents[2]].separatedLanguage) {
        for (let i in this.state.languages) {
          let item = this.state[pathComponents[0]].value[parseInt(pathComponents[1])];
          let value = item[pathComponents[2]] && item[pathComponents[2]][this.state.languages[i]];
          if (!(value && value.length > 0)) return false;
        }
        return true;
      } else {
        let item = this.state[pathComponents[0]].value[parseInt(pathComponents[1])];
        return item[pathComponents[2]] && item[pathComponents[2]].value && item[pathComponents[2]].value.length > 0;
      }
    } else {
      if (this.state[pathComponents[0]].separatedLanguage) {
        for (let i in this.state.languages) {
          let value = this.state[pathComponents[0]][this.state.languages[i]];
          if (!(value && value.length > 0)) return false;
        }
        return true;
      } else {
        let value = this.state[pathComponents[0]].value;
        return value && value.length > 0;
      }
    }
  };

  formFieldRequiredValidationByLanguage = (fieldName) => {
    let pathComponents = fieldName.split(".");
    for (let i = 0; i < this.state.languages.length; i++) {
      if (pathComponents.length > 1) {
        let value = null;
        let stack = this.state[pathComponents[0]];
        if (!stack) {
          stack = stack.value[parseInt(pathComponents[1])];
        }
        if (!stack) {
          stack = stack[pathComponents[2]];
        }
        if (!stack) {
          value = stack[this.state.languages[i]];
        }
        // let value = this.state[pathComponents[0]].value[parseInt(pathComponents[1])][pathComponents[2]][this.state.languages[i]];
        if (!(value && value.length > 0)) return commonService.languages[this.state.languages[i]];
      } else {
        let value = this.state[pathComponents[0]][this.state.languages[i]];
        if (!(value && value.length > 0)) return commonService.languages[this.state.languages[i]];
      }
    }

    return "";
  };

  renderGeneral() {
    let isNew = this.props.match.params.practiceQuestionId === "new";

    return(
      <div className="mt-4 mb-4">
        <Form id="form" onSubmit={this.onSubmit}>
          <Form.Group>
            <Form.Label>タイトル</Form.Label>
            <Form.Control required
                          type="text"
                          value={this.getFormControlValue("title")}
                          onChange={(e) => this.onFormControlChanged("title", e.target.value)}
                          isInvalid={!this.formFieldRequiredValidation("title")} />
            <Form.Control.Feedback type="invalid">
              [{this.formFieldRequiredValidationByLanguage("title")}] タイトルを入力してください
            </Form.Control.Feedback>
          </Form.Group>
          <Card>
            <Card.Header className="d-flex justify-content-between align-items-center">
              問題イラスト
              {!this.getFormControlValue('svgUrl') && (
                <div className='btn btn-primary'>
                  <label htmlFor="file" style={{marginBottom: 0}}>
                    イラストをアップロード
                  </label>
                  <input id="file" accept="image/svg+xml,|image/png,image/jpeg" type='file' style={{display: 'none'}} onChange={(e) => this.onFileUpload("svgUrl", "top", e.target.files[0])} />
                </div>
              )}
              {this.getFormControlValue('svgUrl') && (<Button variant="primary" onClick={() => this.onFormControlChanged("svgUrl", null)}>イラストを削除</Button>)}
            </Card.Header>
            {this.getFormControlValue('svgUrl') && (<Card.Img variant="top"style={{ maxHeight: '20rem', objectFit: 'contain' }} src={this.getFormControlValue('svgUrl')} />)}
            <Card.Body>
              {this.formFieldRequiredValidation("svgUrl") && !this.getFormControlValue('svgUrl') && (<Card.Text>svgファイルのみ</Card.Text>)}
              {!this.formFieldRequiredValidation("svgUrl") && (<Card.Text className="text-danger">イラストをアップロードしてください</Card.Text>)}
            </Card.Body>
          </Card>
        </Form>

        {!isNew && (
          <Card className="mt-4">
            <Card.Header variant="danger" text="white">注意アクション</Card.Header>
            <Card.Body>
              <Button variant="danger" className="mt-2" onClick={this.onDeleteClick}>練習問を削除する</Button>
            </Card.Body>
          </Card>
        )}
      </div>
    );
  }

  renderCharacters() {

    return(
      <div className="mt-4 mb-4">

        {!this.formFieldRequiredValidation("characters") && (
          <Alert variant="danger" className="mb-4">キャラクターを追加してください</Alert>
        )}

        <ButtonToolbar className="justify-content-between align-items-center">
          <Button variant="primary"
                  type="submit"
                  className="mr-2"
                  onClick={this.onCreateCharacter}>キャラクターを追加</Button>
        </ButtonToolbar>

        {this.state.characters.value && this.state.characters.value.map((character, index) => (
          <Card key={character.id} className="mt-4">
            <Card.Header className="d-flex justify-content-between align-items-center">
              <div></div>
              <div>
                {!this.getFormControlValue(`characters.${index}.image`) && (
                  <div className='btn btn-primary'>
                    <label htmlFor="file" style={{marginBottom: 0}}>
                      イラストをアップロード
                    </label>
                    <input id="file" accept="image/svg+xml,|image/png,image/jpeg" type='file' style={{display: 'none'}} onChange={(e) => this.onFileUpload(`characters.${index}.image`, "character", e.target.files[0])} />
                  </div>
                )}
                {this.getFormControlValue(`characters.${index}.image`) && (<Button variant="primary" onClick={() => this.onFormControlChanged(`characters.${index}.image`, null)}>イラストを削除</Button>)}
                <Button variant="primary" className="ml-2" onClick={() => this.onDeleteCharacter(character)}>キャラクターを削除</Button>
              </div>
            </Card.Header>
            <div className="d-flex">
              {character.image && character.image.value && <Card.Img src={character.image.value} style={{ width: '10rem' }} />}
              <Card.Body>
                <Form.Group>
                  <Form.Label>名前</Form.Label>
                  <Form.Control required
                                type="text"
                                value={this.getFormControlValue(`characters.${index}.name`)}
                                onChange={(e) => this.onFormControlChanged(`characters.${index}.name`, e.target.value)}
                                isInvalid={!this.formFieldRequiredValidation(`characters.${index}.name`)}
                  />
                  <Form.Control.Feedback type="invalid">
                    [{this.formFieldRequiredValidationByLanguage(`characters.${index}.name`)}] 名前を入力してください
                  </Form.Control.Feedback>
                </Form.Group>
              </Card.Body>
            </div>
            {this.formFieldRequiredValidation(`characters.${index}.image`) && !this.getFormControlValue(`characters.${index}.image`) && (<Card.Footer>svgファイルのみ</Card.Footer>)}
            {!this.formFieldRequiredValidation(`characters.${index}.image`) && (<Card.Footer className="text-danger">イラストをアップロードしてください</Card.Footer>)}
          </Card>
        ))}
      </div>
    );
  }

  renderChat() {

    return(
      <div className="mt-4 mb-4">

        {!this.formFieldRequiredValidation("chats") && (
          <Alert variant="danger" className="mb-4">チャットを追加してください</Alert>
        )}

        <ButtonToolbar className="justify-content-between align-items-center">
          <Button variant="primary"
                  type="submit"
                  className="mr-2"
                  onClick={this.onCreateChat}>チャットを追加</Button>
        </ButtonToolbar>

        {this.state.chats.value && this.state.chats.value.map((chat, index) => (
          <Card key={chat.id} className="mt-4">
            <Card.Header className="d-flex justify-content-between align-items-center">
              <div></div>
              <div>
                <Button variant="primary" className="ml-2" onClick={() => this.onDeleteChat(chat)}>チャットを削除</Button>
              </div>
            </Card.Header>
            <Card.Body>
              <Form.Group>
                <Form.Label>キャラクター</Form.Label>
                <Form.Control required
                              as="select"
                              value={this.getFormControlValue(`chats.${index}.character`)}
                              onChange={(e) => this.onFormControlChanged(`chats.${index}.character`, e.target.value)}
                              isInvalid={!this.formFieldRequiredValidation(`chats.${index}.character`)}
                >
                  <option label='' value='' className="d-none" />
                  {this.state.characters.value && this.state.characters.value.map(character => (
                    <option key={character.id} label={character.name && character.name[this.state.selectedLanguage] ? character.name[this.state.selectedLanguage] : ""} value={character.id} />
                  ))}
                </Form.Control>
                <Form.Control.Feedback type="invalid">
                  キャラクターを選択してください
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group>
                <Form.Label>文章</Form.Label>
                <Form.Control required
                              type="text"
                              value={this.getFormControlValue(`chats.${index}.text`)}
                              onChange={(e) => this.onFormControlChanged(`chats.${index}.text`, e.target.value)}
                              isInvalid={!this.formFieldRequiredValidation(`chats.${index}.text`)}
                />
                <Form.Control.Feedback type="invalid">
                  [{this.formFieldRequiredValidationByLanguage(`chats.${index}.text`)}] 文章を入力してください
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group>
                <Form.Label>ふりがな</Form.Label>
                <Form.Control type="text"
                              value={this.getFormControlValue(`chats.${index}.reading`)}
                              onChange={(e) => this.onFormControlChanged(`chats.${index}.reading`, e.target.value)}
                />
              </Form.Group>
            </Card.Body>
          </Card>
        ))}
      </div>
    );
  }

  renderAnswers() {
    let index = this.state.selectedAnswer ? this.state.selectedAnswer - 1 : 0;

    return(
      <div className="d-flex mt-4 mb-4">
        <Nav variant="pills" className="flex-column col-2">
          <Nav.Item>
            <Nav.Link className={!this.state.selectedAnswer || this.state.selectedAnswer === "1" ? "active" : ""} onClick={() => {this.setState({ selectedAnswer: "1"})}}>答え①</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link className={this.state.selectedAnswer === "2" ? "active" : ""} onClick={() => {this.setState({ selectedAnswer: "2"})}}>答え②</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link className={this.state.selectedAnswer === "3" ? "active" : ""} onClick={() => {this.setState({ selectedAnswer: "3"})}}>答え③</Nav.Link>
          </Nav.Item>
        </Nav>

        <div className="flex-grow-1">
          <Card>
            <Card.Header className="d-flex justify-content-between align-items-center">
              <div>答え</div>
              <div>
                {!this.getFormControlValue(`answers.${index}.image`) && (
                  <div className='btn btn-primary'>
                    <label htmlFor="file" style={{marginBottom: 0}}>
                      イラストをアップロード
                    </label>
                    <input id="file" accept="image/svg+xml,|image/png,image/jpeg" type='file' style={{display: 'none'}} onChange={(e) => this.onFileUpload(`answers.${index}.image`, "answer", e.target.files[0])} />
                  </div>
                )}
                {this.getFormControlValue(`answers.${index}.image`) && (<Button variant="primary" onClick={() => this.onFormControlChanged(`answers.${index}.image`, null)}>イラストを削除</Button>)}
              </div>
            </Card.Header>
            {this.getFormControlValue(`answers.${index}.image`) && (<Card.Img variant="top"style={{ maxHeight: '20rem', objectFit: 'contain' }} src={this.getFormControlValue(`answers.${index}.image`)} />)}
            <Card.Body>
              <Form.Group>
                <Form.Label>文章</Form.Label>
                <Form.Control required
                              type="text"
                              value={this.getFormControlValue(`answers.${index}.text`)}
                              onChange={(e) => this.onFormControlChanged(`answers.${index}.text`, e.target.value)}
                              isInvalid={!this.formFieldRequiredValidation(`answers.${index}.text`)}
                />
                <Form.Control.Feedback type="invalid">
                  [{this.formFieldRequiredValidationByLanguage(`answers.${index}.text`)}] 文章を入力してください
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group>
                <Form.Label>ふりがな</Form.Label>
                <Form.Control type="text"
                              value={this.getFormControlValue(`answers.${index}.reading`)}
                              onChange={(e) => this.onFormControlChanged(`answers.${index}.reading`, e.target.value)}
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>メッセージ</Form.Label>
                <Form.Control required
                              as="select"
                              value={this.getFormControlValue(`answers.${index}.message`)}
                              onChange={(e) => this.onFormControlChanged(`answers.${index}.message`, e.target.value)}
                              isInvalid={!this.formFieldRequiredValidation(`answers.${index}.message`)}
                >
                  <option label='' value='' className="d-none" />
                  <option label="よくできました" value="WELL_DONE" />
                  <option label="あと一歩" value="ALMOST" />
                  <option label="もう一度考えてみよう" value="TRY_AGAIN" />
                </Form.Control>
                <Form.Control.Feedback type="invalid">
                  メッセージメッセージを選択してください
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group>
                <Form.Check type="checkbox"
                            label="正しい答え"
                            checked={this.getFormControlValue(`answers.${index}.isCorrect`) === "1"}
                            onChange={(e) => this.onFormControlChanged(`answers.${index}.isCorrect`, e.target.checked ? "1": "0")}
                />
              </Form.Group>
            </Card.Body>
          </Card>

          <Card className="mt-4">
            <Card.Header className="d-flex justify-content-between align-items-center">
              <div>説明</div>
              <div>
                {!this.getFormControlValue(`answers.${index}.descriptionImage`) && (
                  <div className='btn btn-primary'>
                    <label htmlFor="file" style={{marginBottom: 0}}>
                      イラストをアップロード
                    </label>
                    <input id="file" accept="image/svg+xml,|image/png,image/jpeg" type='file' style={{display: 'none'}} onChange={(e) => this.onFileUpload(`answers.${index}.descriptionImage`, "answer-description", e.target.files[0])} />
                  </div>
                )}
                {this.getFormControlValue(`answers.${index}.descriptionImage`) && (<Button variant="primary" onClick={() => this.onFormControlChanged(`answers.${index}.descriptionImage`, null)}>イラストを削除</Button>)}
              </div>
            </Card.Header>
            {this.getFormControlValue(`answers.${index}.descriptionImage`) && (<Card.Img variant="top"style={{ maxHeight: '20rem', objectFit: 'contain' }} src={this.getFormControlValue(`answers.${index}.descriptionImage`)} />)}
            <Card.Body>
              <Form.Group>
                <Form.Label>日本人の気持ち</Form.Label>
                <Form.Control required
                              as="textarea"
                              rows="3"
                              value={this.getFormControlValue(`answers.${index}.descriptionJapaneseFeeling`)}
                              onChange={(e) => this.onFormControlChanged(`answers.${index}.descriptionJapaneseFeeling`, e.target.value)}
                              isInvalid={!this.formFieldRequiredValidation(`answers.${index}.descriptionJapaneseFeeling`)}
                />
                <Form.Control.Feedback type="invalid">
                  [{this.formFieldRequiredValidationByLanguage(`answers.${index}.descriptionJapaneseFeeling`)}] 日本人の気持ちを入力してください
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group>
                <Form.Label>キービンの背景解説</Form.Label>
                <Form.Control required
                              as="textarea"
                              rows="3"
                              value={this.getFormControlValue(`answers.${index}.descriptionKibi`)}
                              onChange={(e) => this.onFormControlChanged(`answers.${index}.descriptionKibi`, e.target.value)}
                              isInvalid={!this.formFieldRequiredValidation(`answers.${index}.descriptionKibi`)}
                />
                <Form.Control.Feedback type="invalid">
                  [{this.formFieldRequiredValidationByLanguage(`answers.${index}.descriptionKibi`)}] キービンの背景解説を入力してください
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group>
                <Form.Label>キービンアバター</Form.Label>
                <Form.Control required
                              as="select"
                              value={this.getFormControlValue(`answers.${index}.descriptionKibiAvatar`)}
                              onChange={(e) => this.onFormControlChanged(`answers.${index}.descriptionKibiAvatar`, e.target.value)}
                              isInvalid={!this.formFieldRequiredValidation(`answers.${index}.descriptionKibiAvatar`)}
                >
                  <option label='' value='' className="d-none" />
                  <option label="Back" value="kiiibin_back" />
                  <option label="Relux" value="kiiibin_relux" />
                  <option label="Sad" value="kiiibin_sad" />
                  <option label="Sit" value="kiiibin_sit" />
                  <option label="Smile" value="kiiibin_smile" />
                  <option label="Teach" value="kiiibin_teach" />
                </Form.Control>
                <Form.Control.Feedback type="invalid">
                  キービンアバターを選択してください
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group>
                <Form.Label>日本の慣習ポイント</Form.Label>
                <Form.Control required
                              as="textarea"
                              rows="3"
                              value={this.getFormControlValue(`answers.${index}.descriptionJapanesePoint`)}
                              onChange={(e) => this.onFormControlChanged(`answers.${index}.descriptionJapanesePoint`, e.target.value)}
                              isInvalid={!this.formFieldRequiredValidation(`answers.${index}.descriptionJapanesePoint`)}
                />
                <Form.Control.Feedback type="invalid">
                  [{this.formFieldRequiredValidationByLanguage(`answers.${index}.descriptionJapanesePoint`)}] 日本の慣習ポイントを入力してください
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group>
                <Form.Label>振る舞い方のポイント</Form.Label>
                <Form.Control required
                              as="textarea"
                              rows="3"
                              value={this.getFormControlValue(`answers.${index}.descriptionBehavePoint`)}
                              onChange={(e) => this.onFormControlChanged(`answers.${index}.descriptionBehavePoint`, e.target.value)}
                              isInvalid={!this.formFieldRequiredValidation(`answers.${index}.descriptionBehavePoint`)}
                />
                <Form.Control.Feedback type="invalid">
                  [{this.formFieldRequiredValidationByLanguage(`answers.${index}.descriptionBehavePoint`)}] る舞い方のポイントを入力してください
                </Form.Control.Feedback>
              </Form.Group>

            </Card.Body>
          </Card>
        </div>
      </div>
    );
  }

  render() {
    const {content} = this.state;
    let isNew = this.props.match.params.practiceQuestionId === "new";

    return (
      <>
        <SmallBgLoading isLoading={this.state.loading}>
          {this.state.error && (
            <Alert variant="danger" className="mt-4">{this.state.error.message}</Alert>
          )}

          <ButtonToolbar className="mt-4 justify-content-between align-items-center">
            <h1 className="m-0">{content ? `${this.formatByLanguage(content.title)}: ` : ''}練習問題{isNew ? "制作" : "編集"}</h1>
            <div className="d-flex">
              <Dropdown className="mr-2">
                <Dropdown.Toggle variant="success">
                  {commonService.languages[this.state.selectedLanguage]}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  {this.state.languages.map((key) => (
                    <Dropdown.Item
                      key={key}
                      className="d-flex justify-content-between align-items-center"
                    >
                      <div onClick={() => this.onChangeLanguage(key)}>{commonService.languages[key]}</div>
                      {this.state.selectedLanguage === key && (
                        <div>
                          <FontAwesomeIcon className="text-secondary" icon={faCheck} />
                        </div>
                      )}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>

              <Button variant="primary"
                      type="submit"
                      className="mr-2"
                      onClick={this.onDoneClick}>保存</Button>
              <Button variant="outline-secondary"
                      onClick={this.onCancelClick}>キャンセル</Button>
            </div>
          </ButtonToolbar>

          <Nav variant="tabs" className="mt-4">
            <Nav.Item>
              <Nav.Link className={!this.state.selectedTab || this.state.selectedTab === "general" ? "active" : ""} onClick={() => {this.setState({ selectedTab: "general"})}}>問題</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link className={this.state.selectedTab === "characters" ? "active" : ""} onClick={() => this.setState({ selectedTab: "characters"})}>キャラクター</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link className={this.state.selectedTab === "chat" ? "active" : ""} onClick={() => {this.setState({ selectedTab: "chat"})}}>チャット</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link className={this.state.selectedTab === "answers" ? "active" : ""} onClick={() => {this.setState({ selectedTab: "answers"})}}>答え</Nav.Link>
            </Nav.Item>

          </Nav>

          {(!this.state.selectedTab || this.state.selectedTab === "general") && (this.renderGeneral())}
          {this.state.selectedTab === "characters" && (this.renderCharacters())}
          {this.state.selectedTab === "chat" && (this.renderChat())}
          {this.state.selectedTab === "answers" && (this.renderAnswers())}

        </SmallBgLoading>
      </>
    )
  };
}
function mapStateToProps(state) {
  return {};
}
export default connect(mapStateToProps)(PracticeQuestionPage);
