import React, { Component } from "react";
import { Modal, Button } from "react-bootstrap";

export class TermsModal extends Component {
  render() {
    return (
      <Modal show={this.props.show} onHide={this.props.onClose} backdrop="static" scrollable={true}>
        <Modal.Header>
          <Modal.Title>利用規約</Modal.Title>
        </Modal.Header>

        <Modal.Body style={{backgroundColor: "#FAFAFA", maxHeight: "calc(100vh - 210px)", overflowY: 'auto'}} >
          <p>
            こんにちは！<br/>
            「ＫＩＢＩラーニング」「きづっきー」へようこそ！<br/>
            <br/>
            次の事項を守って使用して下さい。<br/>
            １.自分のＩＤとパスワードを、他の人に教えたり使わせたりしません。<br/>
            ２.「KIBIラーニング」「きづっきー」の<a rel="noopener noreferrer" href="https://kibi-learning.com/private-policy.html" target="_blank">個人情報保護方針</a>に同意します。<br/>
            ３.「KIBIラーニング」「きづっきー」によって得た知識は、自分の判断と責任で使います。<br/>
            <br/>
            詳細な利用規約は<a rel="noopener noreferrer" href="https://kibi-learning.com/terms.html" target="_blank">こちら</a>よりご確認ください。<br/>
          </p>
        </Modal.Body>

        <Modal.Footer>
          {!this.props.isSimple && (<Button variant="outline-secondary" onClick={this.props.onClose}>キャンセル</Button>)}
          {!this.props.isSimple && (<Button variant="success" onClick={this.props.onOk}>同意する</Button>)}
          {this.props.isSimple && (<Button variant="outline-secondary" onClick={this.props.onClose}>閉じる</Button>)}
        </Modal.Footer>
      </Modal>
    );
  }
}

TermsModal.defaultProps = {
  show: false,
  isSimple: false,
  onClose: () => {},
  onOk: () => {}
};
