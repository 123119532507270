import React, { Component } from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import Amplify from 'aws-amplify';
import ProtectedRoute from "./components/commons/ProtectedRoute";
import AuthGuard from "./services/guards/AuthGuard";
import PermissionsGuard from "./services/guards/PermissionsGuard";

import LoginPage from "./pages/Login/index";
import MarketPage from "./pages/User/Market/index";
import NotificationsPage from "./pages/User/Notifications/index";
import CourseResultsPage from "./pages/User/CourseResults/index";
import AdminPage from "./pages/Admin/index";
import ManagerPage from "./pages/Manager/index";
import CoursePracticePage from "./pages/User/Course/CoursePractice/index";
import CourseTestPage from "./pages/User/Course/CourseTest/index";
import CourseResultPage from "./pages/User/Course/CourseResult/index";
import SubtleTestPage from "./pages/User/SubtleTest/index";
import NursingPage from "./pages/User/Nursing/index";
import ComicSpiritPage from "./pages/User/ComicSpirit/index";
import ComicLifePage from "./pages/User/ComicLife/index";
import SimpleFlowTestPage from "./pages/User/SimpleFlowTest/index";
import ErrorPage from "./pages/Error/index";

Amplify.configure({
  Auth: {
    identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,
    region: process.env.REACT_APP_REGION,
    userPoolId: process.env.REACT_APP_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_USER_POOL_CLIENT_ID,
  },
  API: {
    aws_appsync_graphqlEndpoint: process.env.REACT_APP_APPSYNC_ENDPOINT,
    aws_appsync_region: process.env.REACT_APP_REGION,
    aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS",
    aws_appsync_apiKey: process.env.REACT_APP_APPSYNC_API_KEY,
  },
  Storage: {
    AWSS3: {
      bucket: process.env.REACT_APP_S3_BUCKET,
      region: process.env.REACT_APP_REGION
    }
  }
});

export default class App extends Component {
  render() {
    if (process.env.REACT_APP_IS_PRODUCTION === "0") {
      console.log('env', process.env.REACT_APP_ENV_NAME)
    }
    return (
      <BrowserRouter>
        {/*<Suspense fallback={<FullLoading />}>*/}
          {/*{this.props.localStorageChecked && (*/}
            <Switch>
              <Route path="/login/" exact component={LoginPage} />
              <Route path="/error/" exact component={ErrorPage} />
              <ProtectedRoute path="/" exact component={MarketPage} guards={[AuthGuard, PermissionsGuard]} />
              {/*<ProtectedRoute*/}
              {/*  path="/course/:courseId/practice/:questionId?"*/}
              {/*  exact*/}
              {/*  component={CoursePracticePage}*/}
              {/*  guards={[AuthGuard, PermissionsGuard]}*/}
              {/*/>*/}
              <Route
                path="/course/:courseId/practice/:questionId?"
                exact
                render={props => <CoursePracticePage key={props.match.params.questionId} {...props} />}
              />
              <ProtectedRoute
                path="/course/:courseId/test/:questionId?/:answerId?/:testId?"
                exact
                component={CourseTestPage}
                guards={[AuthGuard, PermissionsGuard]}
              />
              <ProtectedRoute
                path="/course/:courseId/result/:testId"
                exact
                component={CourseResultPage}
              />
              <ProtectedRoute path="/subtle-test/:testId?" exact component={SubtleTestPage} guards={[AuthGuard, PermissionsGuard]} />
              <ProtectedRoute path="/simple-flow-test/:testId?" exact component={SimpleFlowTestPage} guards={[AuthGuard, PermissionsGuard]} />
              {/*<ProtectedRoute path="/nursing/:testId?" exact component={NursingPage} guards={[AuthGuard, PermissionsGuard]} />*/}
              <Route
                path={"/nursing/:testId?"}
                render={props => <NursingPage {...props} />}
              />
              {/*<ProtectedRoute path="/comic-spirit/:contentId" exact component={ComicSpiritPage} guards={[AuthGuard, PermissionsGuard]} />*/}
              <Route
                path={"/comic-spirit/:contentId"}
                render={props => <ComicSpiritPage key={props.match.params.contentId} {...props} />}
              />
              {/*<ProtectedRoute path="/comic-life/:contentId" exact component={ComicLifePage} guards={[AuthGuard, PermissionsGuard]} />*/}
              <Route
                path={"/comic-life/:contentId"}
                render={props => <ComicLifePage key={props.match.params.contentId} {...props} />}
              />
              <ProtectedRoute path="/notifications" component={NotificationsPage} guards={[AuthGuard, PermissionsGuard]} />
              <ProtectedRoute path="/results" exact component={CourseResultsPage} guards={[AuthGuard, PermissionsGuard]} />
              <ProtectedRoute path="/admin/" component={AdminPage} guards={[AuthGuard, PermissionsGuard]} />
              <ProtectedRoute path="/manager/" component={ManagerPage} guards={[AuthGuard, PermissionsGuard]} />
              {/*<Route component={Index} />*/}
            </Switch>
          {/*)}*/}
          {/*{!this.props.localStorageChecked && <FullLoading />}*/}
        {/*</Suspense>*/}
      </BrowserRouter>
    );
  }
}

// function mapStateToProps(state) {
//   return {
//     isAuth: true
//     // isAuth: auth.isAuth(),//authSelectors.isAuth(state),
//     // localStorageChecked: state.auth.localStorageChecked,
//     // courseGroups: state.courses.courseGroups,
//     // initDataLoaded: state.courses.initDataLoaded
//   };
// }

// export default connect(mapStateToProps)(App);
// export default withAuthenticator(App);
