import React, { Component } from "react";
import { connect } from "react-redux";
import {Alert, Button, ButtonToolbar, Form, ListGroup, Dropdown, Card} from "react-bootstrap";
import uuid from "uuid";
import { ListManager } from "react-beautiful-dnd-grid";
import * as contentsService  from "../../../../services/contents";
import * as commonService  from "../../../../services/common";
import { SmallBgLoading } from "../../../../components/commons/SmallBgLoading"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlusCircle, faMinusCircle, faCheck } from '@fortawesome/free-solid-svg-icons'
import "./index.scss";
import { Storage } from "aws-amplify";

class ComicLifePage extends Component {

  constructor(props, context) {
    super(props, context);

    this.state = {
      pages: [],
      settings: {},
      data: null,

      touched: false,
      title: { separatedLanguage: true },
      description: { separatedLanguage: true },
      comicIntroductionSvgUrl: { separatedLanguage: true },
      comicIntroduction: { separatedLanguage: true },
      comicSituationSvgUrl: { separatedLanguage: true },
      comicSituation: { separatedLanguage: true },
      languages: [],

      selectedLanguage: null,

      error: null,
      loading: false,
      isPagesUploadInProgress: false
    };
  }

  async componentWillMount() {
    this.setState({loading: true});

    try {
      let state = {loading: false};

      if (this.props.match.params.comicId !== "new") {
        // Content
        state.data = await contentsService.fetchContent(this.props.match.params.comicId);
        state.languages = JSON.parse(state.data.languages);
        state.pages = state.data && state.data.comicPages ? JSON.parse(state.data.comicPages) : [];
        let fields = ["title", "description", "comicIntroductionSvgUrl", "comicIntroduction", "comicSituationSvgUrl", "comicSituation"];
        for (let fieldIndex in fields) {
            if (this.state[fields[fieldIndex]].separatedLanguage) {
              for (let langIndex in state.languages) {
                if (!state[fields[fieldIndex]]) state[fields[fieldIndex]] = {...this.state[fields[fieldIndex]]};
                try {
                  state[fields[fieldIndex]][state.languages[langIndex]] = JSON.parse(state.data[fields[fieldIndex]])[state.languages[langIndex]];
                } catch (e) {}
              }

            } else {
              state[fields[fieldIndex]] = { value: state.data[fields[fieldIndex]], ...this.state[fields[fieldIndex]]};
            }
        }
      } else {
        state.languages = [commonService.mainLanguage]
      }
      state.selectedLanguage = commonService.mainLanguage;
      this.setState(state);
    } catch (error) {
      this.onError(error);
    }

  }

  onCancelClick = () => {
    this.props.history.push("/admin/comics/life");
  };

  onDeleteClick = async () => {
    this.setState({error: null, loading: true});

    try {
      await contentsService.deleteContent(this.state.data.id);
      this.setState({loading: false});
      this.props.history.push("/admin/comics/life/");
    } catch (error) {
      this.onError(error);
    }
  };

  onDoneClick = () => {
    document
      .getElementById('form')
      .dispatchEvent(new Event('submit', { cancelable: true }))
  };

  onSubmit = async (event) => {
    event.preventDefault();
    this.setState({error: null, touched: true});
    if (!this.formRequiredValidation(["title"])) return;

    this.setState({loading: true});

    try {
      let fields = ["title", "description", "comicIntroductionSvgUrl", "comicIntroduction", "comicSituationSvgUrl", "comicSituation"];
      let input = {};
      for (let fieldIndex in fields) {
        if (this.state[fields[fieldIndex]].separatedLanguage) {
          let values = {};
          for (let langIndex in this.state.languages) {
            let value = this.state[fields[fieldIndex]][this.state.languages[langIndex]];
            values[this.state.languages[langIndex]] = value ? value : null;
          }
          input[fields[fieldIndex]] = JSON.stringify(values);
        } else {
          input[fields[fieldIndex]] = this.state[fields[fieldIndex]].value;
        }
      }

      input.languages = JSON.stringify(this.state.languages);

      if (this.state.data) {
        input.id = this.state.data.id;
        input.settings = this.state.data.settings;
        await contentsService.updateContent(input);
      } else {
        input.id = uuid.v4();
        input.contentType = "COMICS_LIFE";
        await contentsService.createContent(input);

        // update order
        let setting = await contentsService.fetchContentSetting("COMICS_LIFE");
        let order = [];
        if (setting && setting.order) {
          order = JSON.parse(setting.order);
        }
        order.push(input.id);
        await contentsService.updateContentSetting({id: "COMICS_LIFE", order: JSON.stringify(order)});
      }

      this.setState({loading: false});
      this.props.history.push("/admin/comics/life");
    } catch (error) {
      this.onError(error);
    }
  };

  onAddLanguage = (lang) => {
    let languages = this.state.languages;
    languages.push(lang);
    this.setState({ languages, selectedLanguage: lang });
  };

  onDeleteLanguage = (lang) => {
    let languages = this.state.languages.filter((item) => item !== lang);
    this.setState({ languages });
  };

  onChangeLanguage = (lang) => {
    if (this.state.selectedLanguage !== lang) {
      this.setState({ selectedLanguage: lang });
    }
  };

  onDragEnd = async (from, to) => {
    // Update local list
    const pages = Array.from(this.state.pages);
    const [removed] = pages.splice(from, 1);
    pages.splice(to, 0, removed);
    this.setState({ pages });

    // Update db

    let input = {
      id: this.state.data.id,
      title: this.state.data.title,
      description: this.state.data.description,
      comicIntroductionSvgUrl: this.state.data.comicIntroductionSvgUrl,
      comicIntroduction: this.state.data.comicIntroduction,
      comicSituationSvgUrl: this.state.data.comicSituationSvgUrl,
      comicSituation: this.state.data.comicSituation,
      languages: this.state.data.languages,
      comicPages: JSON.stringify(pages),
    };

    await contentsService.updateContent(input);
  };

  onAddPage = async () => {
    let pages = [...this.state.pages];

    pages.push({
      id: uuid.v4()
    });

    let input = {
      id: this.state.data.id,
      title: this.state.data.title,
      description: this.state.data.description,
      comicIntroductionSvgUrl: this.state.data.comicIntroductionSvgUrl,
      comicIntroduction: this.state.data.comicIntroduction,
      comicSituationSvgUrl: this.state.data.comicSituationSvgUrl,
      comicSituation: this.state.data.comicSituation,
      languages: this.state.data.languages,
      comicPages: JSON.stringify(pages),
    };

    await contentsService.updateContent(input);
    this.setState( { pages} );
  };

  onFileUpload = async (fieldName, key, file) => {

    let comicId = this.props.match.params.comicId;
    let filename = "comic-life-" + key + "-" + uuid() + "." + file.name.split('.').pop();

    const s3Object = await Storage.put(`contents/comic-life-${comicId}/${filename}`, file, {
      contentType: file.type,
      level: "public"
    });

    let url = `https://s3-ap-northeast-1.amazonaws.com/${process.env.REACT_APP_S3_BUCKET}/public/${s3Object.key}`;

    this.onFormControlChanged(fieldName, url);
  };

  onUploadPage = async (item, files, e) => {
    e.preventDefault();

    let pages = [...this.state.pages];
    let index = pages.indexOf(item);
    if (index === -1) {
      return;
    }

    for (let i = 0; i < files.length; i++) {
      let file = files[i];
      let comicId = this.props.match.params.comicId;
      let filename = "comic-life-page-" + uuid() + "." + file.name.split('.').pop();

      const s3Object = await Storage.put(`contents/comic-life-${comicId}/${filename}`, file, {
        contentType: file.type,
        level: "public"
      });

      let url = `https://s3-ap-northeast-1.amazonaws.com/${process.env.REACT_APP_S3_BUCKET}/public/${s3Object.key}`;
      if (!pages[index].url || typeof pages[index].url !== 'object') {
        pages[index].url = {};
      }
      pages[index]['url'][this.state.selectedLanguage] = url;
    }

    let input = {
      id: this.state.data.id,
      title: this.state.data.title,
      description: this.state.data.description,
      comicIntroductionSvgUrl: this.state.data.comicIntroductionSvgUrl,
      comicIntroduction: this.state.data.comicIntroduction,
      comicSituationSvgUrl: this.state.data.comicSituationSvgUrl,
      comicSituation: this.state.data.comicSituation,
      languages: this.state.data.languages,
      comicPages: JSON.stringify(pages),
    };

    await contentsService.updateContent(input);
    this.setState( { pages, isPagesUploadInProgress: false } );
  };

  onDeletePage = async(item) => {
    let pages = this.state.pages;
    let index = pages.indexOf(item);
    pages.splice(index, 1);
    this.setState({ pages });

    // Update db

    let input = {
      id: this.state.data.id,
      title: this.state.data.title,
      description: this.state.data.description,
      comicIntroductionSvgUrl: this.state.data.comicIntroductionSvgUrl,
      comicIntroduction: this.state.data.comicIntroduction,
      languages: this.state.data.languages,
      comicPages: JSON.stringify(pages),
    };

    await contentsService.updateContent(input);
  };

  onError = (error) => {
    if (error.errors) {
      console.error(error.errors[0]);
      this.setState({ loading: false, error: error.errors[0] });
    } else {
      console.error(error);
      this.setState({ loading: false, error });
    }
  };

  onFormControlChanged = (fieldName, value) => {
    let data = {...this.state[fieldName]};

    if (this.state[fieldName].separatedLanguage) {
      data[this.state.selectedLanguage] = value;
    } else {
      data.value = value;
    }

    let state = {};
    state[fieldName] = data;

    this.setState(state);
  };

  getFormControlValue = (fieldName) => {
    if (this.state[fieldName].separatedLanguage) {
      let value = this.state[fieldName][this.state.selectedLanguage];
      return value ? value : "";
    } else {
      let value = this.state[fieldName].value;
      return value ? value : "";
    }
  };

  getPageUrl = (item) => {
    if (!item.url || typeof item.url !== 'object') {
      return null;
    }
    return item.url[this.state.selectedLanguage] || null;
  };

  formRequiredValidation = (fileds) => {
    for (let i in fileds) {
      if (!this.formFieldRequiredValidation(fileds[i], true)) return false;
    }

    return true;
  };

  formFieldRequiredValidation = (fieldName, isTouched = false) => {
    if (!isTouched && !this.state.touched) {
      return true;
    }

    if (this.state[fieldName].separatedLanguage) {
      for (let i in this.state.languages) {
        let value = this.state[fieldName][this.state.languages[i]];
        if (!(value && value.length > 0)) return false;
      }
      return true;
    } else {
      let value = this.state[fieldName].value;
      return value && value.length > 0;
    }

  };

  formFieldRequiredValidationByLanguage = (fieldName) => {
    for (let i in this.state.languages) {
      let value = this.state[fieldName][this.state.languages[i]];
      if (!(value && value.length > 0)) return commonService.languages[this.state.languages[i]];
    }

    return "";
  };

  renderToolbar = () => {
    let isNew = this.props.match.params.comicId === "new";

    return (
      <ButtonToolbar className="mt-4 justify-content-between align-items-center">
        <h1 className="m-0">漫画Life{isNew ? "制作" : "編集"}</h1>
        <div className="d-flex">
          <Dropdown className="mr-2">
            <Dropdown.Toggle variant="success">
              {commonService.languages[this.state.selectedLanguage]}
            </Dropdown.Toggle>

            <Dropdown.Menu>
              {this.state.languages.map((key) => (
                <Dropdown.Item
                  key={key}
                  className="d-flex justify-content-between align-items-center"
                >
                  <div onClick={() => this.onChangeLanguage(key)}>{commonService.languages[key]}</div>
                  {this.state.selectedLanguage === key && (
                    <div>
                      <FontAwesomeIcon className="text-secondary" icon={faCheck} />
                    </div>
                  )}
                  {this.state.selectedLanguage !== key && (
                    <div onClick={() => this.onDeleteLanguage(key)}>
                      <FontAwesomeIcon className="icon-hover icon-danger" icon={faMinusCircle} />
                    </div>
                  )}
                </Dropdown.Item>
              ))}
              {Object.keys(commonService.languages).filter(item => !this.state.languages.includes(item)).length > 0 && (<Dropdown.Divider />)}
              {Object.keys(commonService.languages).filter(item => !this.state.languages.includes(item)).map((key) => (
                <Dropdown.Item key={key}
                               className="d-flex justify-content-between align-items-center"
                               onClick={() => this.onAddLanguage(key)}>
                  <div>{commonService.languages[key]}</div>
                  <FontAwesomeIcon className="icon-hover text-success" icon={faPlusCircle} />
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>

          <Button variant="primary"
                  type="submit"
                  className="mr-2"
                  onClick={this.onDoneClick}>保存</Button>
          <Button variant="outline-secondary"
                  onClick={this.onCancelClick}>キャンセル</Button>
        </div>
      </ButtonToolbar>
    );
  };

  renderForm = () => {
    return (
      <Form id="form" className="mt-4" onSubmit={this.onSubmit}>
        <Form.Group>
          <Form.Label>タイトル</Form.Label>
          <Form.Control required
                        type="text"
                        value={this.getFormControlValue("title")}
                        onChange={(e) => this.onFormControlChanged("title", e.target.value)}
                        isInvalid={!this.formFieldRequiredValidation("title")} />
          <Form.Control.Feedback type="invalid">
            [{this.formFieldRequiredValidationByLanguage("title")}] タイトルを入力してください
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group>
          <Form.Label>説明</Form.Label>
          <Form.Control as="textarea"
                        rows="3"
                        value={this.getFormControlValue("description")}
                        onChange={(e) => this.onFormControlChanged("description", e.target.value)} />
        </Form.Group>
        <Form.Group>
          <Form.Label>Introduction説明</Form.Label>
          <Form.Control required
                        as="textarea"
                        rows="3"
                        value={this.getFormControlValue("comicIntroduction")}
                        onChange={(e) => this.onFormControlChanged("comicIntroduction", e.target.value)}
                        isInvalid={!this.formFieldRequiredValidation("comicIntroduction")} />
          <Form.Control.Feedback type="invalid">
            [{this.formFieldRequiredValidationByLanguage("comicIntroduction")}] タイトルを入力してください
          </Form.Control.Feedback>
        </Form.Group>
        <Card>
          <Card.Header className="d-flex justify-content-between align-items-center">
            Introductionイラスト
            {!this.getFormControlValue("comicIntroductionSvgUrl") && (
              <div className='btn btn-primary'>
                <label htmlFor="comicIntroductionSvgUrl" style={{marginBottom: 0}}>
                  イラストをアップロード
                </label>
                <input id="comicIntroductionSvgUrl" accept="image/svg+xml,|image/png,image/jpeg" type='file' style={{display: 'none'}} onChange={(e) => this.onFileUpload("comicIntroductionSvgUrl", "introduction", e.target.files[0])} />
              </div>
            )}
            {this.getFormControlValue("comicIntroductionSvgUrl") && (<Button variant="primary" onClick={() => this.onFormControlChanged("comicIntroductionSvgUrl", null)}>イラストを削除</Button>)}
          </Card.Header>
          {this.getFormControlValue("comicIntroductionSvgUrl") && (<Card.Img variant="top" style={{ maxHeight: '20rem', objectFit: 'contain' }} src={this.getFormControlValue("comicIntroductionSvgUrl")} />)}
          <Card.Body>
            {this.formFieldRequiredValidation("comicIntroductionSvgUrl") && !this.getFormControlValue("comicIntroductionSvgUrl") && (<Card.Text>svgファイルのみ</Card.Text>)}
            {!this.formFieldRequiredValidation("comicIntroductionSvgUrl") && (<Card.Text className="text-danger">[{this.formFieldRequiredValidationByLanguage("comicIntroductionSvgUrl")}] イラストをアップロードしてください</Card.Text>)}
          </Card.Body>
        </Card>
        <Form.Group className="mt-3">
          <Form.Label>状況説明</Form.Label>
          <Form.Control required
                        as="textarea"
                        rows="3"
                        value={this.getFormControlValue("comicSituation")}
                        onChange={(e) => this.onFormControlChanged("comicSituation", e.target.value)}
                        isInvalid={!this.formFieldRequiredValidation("comicSituation")} />
          <Form.Control.Feedback type="invalid">
            [{this.formFieldRequiredValidationByLanguage("comicSituation")}] 状況説明を入力してください
          </Form.Control.Feedback>
        </Form.Group>
        <Card>
          <Card.Header className="d-flex justify-content-between align-items-center">
            状況イラスト
            {!this.getFormControlValue("comicSituationSvgUrl") && (
              <div className='btn btn-primary'>
                <label htmlFor="comicSituationSvgUrl" style={{marginBottom: 0}}>
                  イラストをアップロード
                </label>
                <input id="comicSituationSvgUrl" accept="image/svg+xml,|image/png,image/jpeg" type='file' style={{display: 'none'}} onChange={(e) => this.onFileUpload("comicSituationSvgUrl", "situation", e.target.files[0])} />
              </div>
            )}
            {this.getFormControlValue("comicSituationSvgUrl") && (<Button variant="primary" onClick={() => this.onFormControlChanged("comicSituationSvgUrl", null)}>イラストを削除</Button>)}
          </Card.Header>
          {this.getFormControlValue("comicSituationSvgUrl") && (<Card.Img variant="top" style={{ maxHeight: '20rem', objectFit: 'contain' }} src={this.getFormControlValue("comicSituationSvgUrl")} />)}
          <Card.Body>
            {this.formFieldRequiredValidation("comicSituationSvgUrl") && !this.getFormControlValue("comicSituationSvgUrl") && (<Card.Text>svgファイルのみ</Card.Text>)}
            {!this.formFieldRequiredValidation("comicSituationSvgUrl") && (<Card.Text className="text-danger">[{this.formFieldRequiredValidationByLanguage("comicSituationSvgUrl")}] イラストをアップロードしてください</Card.Text>)}
          </Card.Body>
        </Card>
      </Form>
    );
  };

  renderPages = () => {
    return (
      <>
        <ButtonToolbar className="mt-4 justify-content-between align-items-center mb-3">
          <h1 className="m-0">ページ</h1>
          <Button variant="primary"
                  type="submit"
                  className="mr-2"
                  onClick={this.onAddPage}>ページ追加</Button>
        </ButtonToolbar>

        {this.state.pages.length > 0 && (
          <ListManager
            items={this.state.pages}
            direction="horizontal"
            maxItems={5}
            render={(item) => (
              <Card key={this.state.pages.indexOf(item)} className="mb-2 mr-2">
                {this.getPageUrl(item) && <Card.Img variant="top" style={{ padding: "8px 8px 0 8px", objectFit: 'contain' }} src={this.getPageUrl(item)} />}
                <Card.Body>
                  <div className="w-100 mb-2 btn btn-outline-primary" style={{minWidth: 130}}>
                    <label htmlFor={`page-${this.state.pages.indexOf(item)}`} style={{marginBottom: 0}}>
                      アップロード
                    </label>
                    <input id={`page-${this.state.pages.indexOf(item)}`} accept="image/svg+xml,|image/png,image/jpeg" type='file' multiple style={{display: 'none'}} onChange={(e) => {this.onUploadPage(item, e.target.files, e);}} />
                  </div>
                  <Button variant="outline-danger" className="w-100" onClick={() => this.onDeletePage(item)}>削除</Button>
                </Card.Body>
              </Card>
            )}
            onDragEnd={this.onDragEnd}
          />
        )}
        {this.state.pages.length === 0 && (
          <ListGroup className="mt-3">
            <ListGroup.Item>
              何も見つかりませんでした
            </ListGroup.Item>
          </ListGroup>
        )}
      </>
    );
  };

  renderDangerActions = () => {
    return (
      <Card className="mt-4 mb-4">
        <Card.Header variant="danger" text="white">注意アクション</Card.Header>
        <Card.Body>
          <Button variant="danger" className="mt-2" onClick={this.onDeleteClick}>漫画Lifeを削除する</Button>
        </Card.Body>
      </Card>
    );
  };

  render() {
    let isNew = this.props.match.params.comicId === "new";

    return (
      <>
        <SmallBgLoading isLoading={this.state.loading}>
          {this.state.error && (<Alert variant="danger" className="mt-4">{this.state.error.message}</Alert>)}
          {this.renderToolbar()}
          {this.renderForm()}
        </SmallBgLoading>

        {!isNew && this.renderPages()}
        {!isNew && this.renderDangerActions()}
      </>
    )
  };
}

function mapStateToProps(state) {
  return {};
}

export default connect(mapStateToProps)(ComicLifePage);
