export const GetNursingFreeResults = `
  query {
    getNursingFreeResults {
        items {
            id
            results
            createdAt
        }
    }
  }
`;

export const GetAllNursingFreeResults = `
  query GetAllNursingFreeResults($userId: ID!){
    getAllNursingResults(userId: $userId) {
      items {
        id
        results
        createdAt
      }
    }
  }
`;

export const GetAdminNursingFreeResults = `
    query GetAdminNursingFreeResults{
        getAdminNursingFreeResults {
            items {
                id
                results
                createdAt
                user {
                    id
                    name
                    parentId
                }
            }
        }
    }
`;

export const GetNursingFreeResult = `
  query GetNursingFreeResult($id: ID!) {
    getNursingFreeResult(id: $id) {
      id
      results
    }
  }
`;

export const CreateNursingFreeResult = `
  mutation CreateNursingFreeResult($input: NursingResultInput!) {
    createNursingFreeResult(input: $input) {
      id
    }
  }
`;
