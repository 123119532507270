import React, { Component } from "react";
import { connect } from "react-redux";
import "./index.scss";
import * as jsonService from "../../../services/json";
import * as testCSVService from "../../../services/test-csv";
import { Table } from "react-bootstrap";

class Index extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      status: 'none',
      error: null,
      items: [],
    };
  }

  onUpload = async (file, layout) => {
    console.log('layout', layout)
    const fileString = await jsonService.readFileAsync(file, "shift-jis");
    if (layout === "c") {      
      try {        
        this.setState({ status: 'prepare' });
        await testCSVService.mutsationTestCSVLayoutC({
          fileString,
        });
        this.setState({ status: 'processing'});
        const results = await testCSVService.mutsationTestCSVLayoutC({
          fileString,
        });
        this.setState({ status: 'complete', items: results.locations });
      } catch (error) {
        console.log("error", error);
        this.setState({ status: 'none', error });
      }
    } else if (layout === "c-new") {
      try {        
        this.setState({ status: 'prepare' });
        await testCSVService.mutsationTestCSVLayoutCNew({
          fileString,
        });
        this.setState({ status: 'processing'});
        const results = await testCSVService.mutsationTestCSVLayoutCNew({
          fileString,
        });
        this.setState({ status: 'complete', items: results.locations });
      } catch (error) {
        console.log("error", error);
        this.setState({ status: 'none', error });
      }      
    }
  };

  render() {
    const { status, error, items } = this.state;
    return (
      <div className={"test-csv-container"}>
        {error ? <div>{error.toString()}</div> : null}
        {status === 'prepare' ? <div>準備中...</div> : null}
        {status === 'processing' ? <div>処理中...</div> : null}        
        {status === 'none' ? (
          <div>          
            <div className="btn btn-outline-primary mr-2 pointer">
              <label htmlFor="file-c" style={{ marginBottom: 0 }}>
                C・Layout
              </label>
              <input
                id="file-c"
                accept="text/csv"
                type="file"
                style={{ display: "none" }}
                onChange={(e) => {
                  this.onUpload(e.target.files[0], "c");
                  e.target.value = null;
                }}
              />
            </div>
            <div className="btn btn-outline-primary mr-2 pointer">
              <label htmlFor="file-c-new" style={{ marginBottom: 0 }}>
                C・New・Layout
              </label>
              <input
                id="file-c-new"
                accept="text/csv"
                type="file"
                style={{ display: "none" }}
                onChange={(e) => {
                  this.onUpload(e.target.files[0], "c-new");
                  e.target.value = null;
                }}
              />
            </div>
          </div>
        ) : null}
        {items.length > 0 ? (
          <Table striped bordered hover style={{marginTop: 40}}>
            <thead>
              <tr>
                <th>#</th>
                <th>URL</th>
              </tr>
            </thead>
            <tbody>
              {items.map((item, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>
                    <a href={item} target={"_blank"}>
                      {item}
                    </a>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        ) : null}
      </div>
    );
  }
}

function mapStateToProps() {
  return {};
}

export default connect(mapStateToProps)(Index);
