import React, { Component } from "react";
import { connect } from "react-redux";
import MainHeader from "../../../components/commons/MainHeader";
import {Badge, ButtonToolbar, Container, ListGroup} from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons'
import * as commonService from "../../../services/common";
import * as rootActions from "../../../store/root/actions";
import {Auth} from "aws-amplify";
import * as accountService from "../../../services/account";

class NotificationsPage extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      user: null,
      groups: [],
      selectedIds: [],
    };
  }

  componentDidMount() {
    this.fetchData();
  }

  fetchData = async () => {
    const user = await Auth.currentAuthenticatedUser();
    let session = await Auth.currentSession();
    let groups = session.idToken.payload["cognito:groups"];

    this.setState({ user, groups });

    this.props.dispatch(rootActions.fetchNotifications());
  };

  onViewClick = (item) => {
    let notificationsReaded = [...this.props.notificationsReaded];
    let selectedIds = [...this.state.selectedIds];
    let index = selectedIds.indexOf(item.id);

    if (index === -1) {
      if (notificationsReaded.indexOf(item.id) === -1) {
        notificationsReaded.push(item.id);
        this.props.dispatch(rootActions.updateReadNotifications(notificationsReaded));
      }
      selectedIds.push(item.id);
    } else {
      selectedIds.splice(index, 1);
    }
    this.setState({selectedIds});
  };

  renderItem = (item) => {
    const {selectedIds} = this.state;
    const {notificationsReaded} = this.props;

    return (
      <ListGroup.Item key={item.id} className="d-flex flex-column">
        <div className="d-flex justify-content-between align-items-center pointer" onClick={() => this.onViewClick(item)}>
          <div className="d-flex flex-row justify-content-start align-items-center">
            {notificationsReaded.indexOf(item.id) === -1 && <Badge variant="danger" style={{marginRight: 8}}>NEW</Badge>}
            <div>{item.title}</div>
          </div>
          <div>
            {commonService.formatDate(item.createdAt)}
            <FontAwesomeIcon className="text-secondary ml-2" icon={selectedIds.indexOf(item.id) !== -1 ? faAngleUp : faAngleDown} />
          </div>
        </div>
        {selectedIds.indexOf(item.id) !== -1 && (
          <div className="mt-2">
            {item.description}
          </div>
        )}
      </ListGroup.Item>
    );
  };

  render() {
    const {notificationsList} = this.props;
    const {user, groups} = this.state;

    let notifications = notificationsList.filter(item => {
      if (item.userGroup) {
        return groups.indexOf(item.userGroup.toLowerCase()) !== -1;
      } else {
        return item.userId === user.getUsername();
      }
    });

    return (
      <>
        <MainHeader isPractice={false} isTest={false} />
        <Container style={{marginTop: 64}}>
          <ButtonToolbar className="justify-content-between align-items-center" >
            <h1 className="m-0">お知らせ</h1>
          </ButtonToolbar>

          <ListGroup className="mt-3">
            {notifications.map(item => this.renderItem(item))}
            {notifications.length === 0 && (
              <ListGroup.Item>
                何も見つかりませんでした
              </ListGroup.Item>
            )}
          </ListGroup>
        </Container>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    notificationsObject: state.root.notifications,
    notificationsList: state.root.notifications.list,
    notificationsReaded: state.root.notifications.readed,
  };
}

export default connect(mapStateToProps)(NotificationsPage);
