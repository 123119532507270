export const GetSubtleTestResults = `
  query {
    getSubtleTestResults {
        items {
            id
            results
            createdAt
        }
    }
  }
`;

export const GetAllSubtleTestResults = `
  query GetAllSubtleTestResults($userId: ID!){
    getAllSubtleTestResults(userId: $userId) {
      items {
        id
        results
        createdAt
      }
    }
  }
`;

export const GetAdminSubtleTestResults = `
    query GetAdminSubtleTestResults{
        getAdminSubtleTestResults {
            items {
                id
                results
                createdAt
                user {
                    id
                    name
                    parentId
                }
            }
        }
    }
`;

export const GetSubtleTestResult = `
  query GetSubtleTestResult($id: ID!) {
    getSubtleTestResult(id: $id) {
      id
      results
    }
  }
`;

export const CreateSubtleTestResult = `
  mutation CreateSubtleTestResult($input: SubtleTestResultInput!) {
    createSubtleTestResult(input: $input) {
      id
    }
  }
`;
