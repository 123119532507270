import React, { Component } from "react";
import { connect } from "react-redux";
import {ButtonToolbar, Button, ListGroup, Badge} from "react-bootstrap";
import * as generator from "generate-password"
import * as userService from "../../../services/users";
import * as commonService from "../../../services/common";
import * as csvService from "../../../services/csv";
import {SmallBgLoading} from "../../../components/commons/SmallBgLoading";

class ManagersPage extends Component {

  constructor(props, context) {
    super(props, context);

    this.state = {
      loading: true,
      error: null,
      items: [],
      isUpload: false,
      uploadProgress: 0,
      uploadError: null
    };
  }

  async componentWillMount() {
    await this.fetchUsers();
  }

  fetchUsers = async () => {
    this.setState({ loading: true, error: null });
    try {
      let response = await userService.fetchUsers();
      let items = response.items.filter(item => item.userGroup === "MANAGER");
      items = items.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
      this.setState({ items: items, loading: false });
    } catch (e) {
      console.error(e);
      this.setState({ loading: true, error: e });
    }
  };

  onCreateClick = () => {
    this.props.history.push("/admin/new/user");
  };

  onEditClick = (item) => {
    this.props.history.push(`/admin/${item.id}/user`);
  };

  onDownload = () => {

    // Generate data
    let schema = [
      {label: "ID", path: "id"},
      {label: "メールアドレス", path: "email", type: "String"},
      {label: "会社名", path: "name", type: "String"},
      {label: "担当者名", path: "department", type: "String"},
      {label: "部署名", path: "manager", type: "String"},
      {label: "アカウント数", path: "childMaxCount", type: "Int"},
      {label: "権限", path: "permissions", type: function(value) {
          if (!value) return "";
          let array = JSON.parse(value);
          let item;
          let result = "";
          for (item of array) {
            if (result.length > 0) result += ";";
            result += item;
          }
          return result
        }},
    ];

    let csv = csvService.prepareCsv(schema, this.state.items);
    csvService.downloadCsv(csv, "managers.csv");
  };

  onUploadSample = () => {

    // Generate data
    let schema = [
      {label: "メールアドレス", path: "email", type: "String"},
      {label: "パスワード", path: "password", type: "String"},
      {label: "会社名", path: "name", type: "String"},
      {label: "担当者名", path: "department", type: "String"},
      {label: "部署名", path: "manager", type: "String"},
      {label: "アカウント数", path: "childMaxCount", type: "Int"},
      {label: "権限", path: "permissions", type: function(value) {
          if (!value) return "";
          let array = JSON.parse(value);
          let item;
          let result = "";
          for (item of array) {
            if (result.length > 0) result += ";";
            result += item;
          }
          return result
        }},
    ];

    let items = [
      {
        email: "user-1@example.com",
        password: "qwerty",
        name: "株式会社KIBI-1",
        department: "管理部",
        manager: "キビ太郎",
        childMaxCount: 5,
        permissions: JSON.stringify(["COURSE", "COMIC"])
      },
      {
        email: "user-2@example.com",
        password: "",
        name: "株式会社KIBI-2",
        department: "",
        manager: "",
        childMaxCount: 2,
        permissions: JSON.stringify(["COURSE"])
      }
    ];

    let csv = csvService.prepareCsv(schema, items);
    csvService.downloadCsv(csv, "managers-sample.csv");
  };

  onUpload = async (file) => {
    this.setState({ loading: true, error: null });
    let schema = [
      {path: "email", type: "String", required: true},
      {path: "password", type: function(value) {
          if (value) return value;
          return generator.generate({
            length: 6,
            uppercase: false
          });
        }},
      {path: "name", type: "String", required: true},
      {path: "department", type: "String"},
      {path: "manager", type: "String"},
      {path: "childMaxCount", type: "Int", required: true},
      {path: "permissions", type: function(value) {
          if (!value) throw new Error("権限が必須になってます");
          return JSON.stringify(value.replace("\r","").split(";"));
        }, required: true},
    ];
    try {

      let items = await csvService.readCsv(file, schema);
      let item;
      for (item of items) {
        await userService.createUserManager(item);
      }
      await this.fetchUsers();
      window.alert("アップロードは完了しました");
    } catch (e) {
      if (e.errors) {
        window.alert(e.errors[0].message);
      } else {
        window.alert(e.message);
      }
    }
    this.setState({ loading: false});
  };

  render() {
    return (
      <>
        <SmallBgLoading isLoading={this.state.loading}>
          <ButtonToolbar className="mt-4 justify-content-between align-items-center">
            <h1 className="m-0">代理店一覧</h1>
            <div className="d-flex">
              <div className='btn btn-outline-primary mr-2 pointer'>
                <label htmlFor="file" style={{marginBottom: 0}}>
                  アップロード
                </label>
                <input id="file" accept="text/csv" type='file' style={{display: 'none'}} onChange={(e) => {this.onUpload(e.target.files[0]); e.target.value = null;}} />
              </div>
              <Button variant="outline-primary mr-2" onClick={this.onUploadSample}>CSVサンプル</Button>
              <Button variant="outline-primary mr-2" onClick={this.onDownload}>ダウンロード</Button>
              <Button variant="primary" onClick={this.onCreateClick}>新規制作</Button>
            </div>
          </ButtonToolbar>

          <ListGroup className="mt-3">
            {this.state.items.map(item => (
              <ListGroup.Item key={item.id} className="d-flex justify-content-between align-items-center pointer" onClick={() => this.onEditClick(item)}>
                <div className="d-flex flex-row justify-content-start align-items-center">
                  {item.disabled ? <Badge variant="danger" className="mr-2">停止中</Badge> : null}
                  <div>{item.name ? item.name : "未定"} ({item.email})</div>
                </div>
                <div>{commonService.formatDate(item.createdAt)}</div>
              </ListGroup.Item>
            ))}
            {this.state.items.length === 0 && (
              <ListGroup.Item>
                何も見つかりませんでした
              </ListGroup.Item>
            )}
          </ListGroup>
        </SmallBgLoading>
      </>
    )
  };
}

function mapStateToProps(state) {
  return {};
}

export default connect(mapStateToProps)(ManagersPage);
