import React, { Component } from "react";
import { connect } from "react-redux";
import {Badge, Button, ButtonToolbar, ListGroup} from "react-bootstrap";
import * as commonService from "../../../services/common";
import {Auth} from "aws-amplify";
import * as accountService from "../../../services/account";
import * as courseTestResultsService from "../../../services/course-test-results";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAngleDown, faAngleUp} from "@fortawesome/free-solid-svg-icons";

class CoursesResultsPage extends Component {

  constructor(props, context) {
    super(props, context);

    this.state = {
      loading: true,
      error: null,
      items: [],
      selectedId: null,
    };
  }

  async componentWillMount() {
    await this.fetchData();
  }

  fetchData = async () => {
    this.setState({ loading: true, error: null });
    try {
      let user = await Auth.currentAuthenticatedUser();
      const account = await accountService.fetchAccount();
      let response = await courseTestResultsService.fetchAdminCoursesTestResults();
      let items = [];
      if (response.items) {
        items = response.items.filter(item => item.user && item.user.parentId === user.username);
        items.forEach((item) => {
          item.results = JSON.parse(item.results);
        });
        items = items.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
      }
      this.setState({ items: items, account: account, loading: false });
    } catch (e) {
      console.error(e);
      this.setState({ loading: true, error: e });
    }
  };

  isCorrect = (question, answers) => {
    let array = answers.filter((answer) => answer.questionId === question.id);
    if (array.length !== 1) { return false; }
    return array[0].isCorrect;
  };

  onViewClick = (item) => {
    if (this.state.selectedId) {
      if (this.state.selectedId === item.id) {
        this.setState({ selectedId: null })
      } else {
        this.setState({ selectedId: item.id })
      }
    } else {
      this.setState({ selectedId: item.id })
    }
  };

  render() {
    return (
      <>
        <ButtonToolbar className="mt-4 justify-content-between align-items-center">
          <h1 className="m-0">コーステスト結果一覧</h1>
        </ButtonToolbar>

        <ListGroup className="mt-3">
          {this.state.items.map(item => (
            <ListGroup.Item key={item.id} className="d-flex pointer" style={{flexDirection: "column"}} onClick={() => this.onViewClick(item)}>
              <div className="d-flex justify-content-between align-items-center">
                <div>
                  {item.user.name + ": "}
                  {JSON.parse(item.course.title)[commonService.mainLanguage] + " "}
                  <Badge variant="primary">{Math.floor(100 / item.results.totalAnswers * item.results.correctAnswers)}点</Badge>
                </div>
                <div>{commonService.formatDate(item.createdAt)} <FontAwesomeIcon className="text-secondary ml-2" icon={item.id === this.state.selectedId ? faAngleUp :faAngleDown} /></div>
              </div>
              {item.id === this.state.selectedId && (
                <ListGroup className="mt-4">
                  {item.course.testQuestions.map((testQuestion) => (
                    <ListGroup.Item key={testQuestion.id} variant={this.isCorrect(testQuestion, item.results.answers) ? "success" : "danger"}>
                      {JSON.parse(testQuestion.title)[commonService.mainLanguage]}
                    </ListGroup.Item>
                  ))}
                </ListGroup>
              )}
            </ListGroup.Item>
          ))}
          {this.state.items.length === 0 && (
            <ListGroup.Item>
              何も見つかりませんでした
            </ListGroup.Item>
          )}
        </ListGroup>
      </>
    )
  }
}

function mapStateToProps(state) {
  return {
    currentLang: state.root.currentLang
  };
}

export default connect(mapStateToProps)(CoursesResultsPage);
