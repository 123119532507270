import React, { Component } from "react";
import { Button, ProgressBar } from "react-bootstrap";
import { connect } from "react-redux";
import AnswerReactionModals from "../../../../components/modals/AnswerReactionModals";
import MainContainer from "../../../../components/containers/MainContainer";
import QuestionChatItem from "../../../../components/elements/QuestionChatItem";
import { PracticeAnswerModal } from "../../../../components/modals/PracticeAnswerModal";
import * as accountService from "../../../../services/account";
import * as contentsService from "../../../../services/contents";
import * as practiceQuestionsService from "../../../../services/practice-questions";
import * as commonService from "../../../../services/common";
import * as langService from "../../../../services/lang";
import "./index.scss";
import { Auth } from "aws-amplify";

class CoursePracticePage extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      authUser: null,
      accountSettings: {},
      nextCourseId: null,
      course: null,
      courseSettings: {},
      questions: [],
      questionsCount: 0,
      questionIndex: -1,
      question: null,
      answers: [],
      chats: [],
      characters: [],

      selectedAnswer: null,
      showAnswerReactionModal: false,
      showPracticeAnswerModal: false,
      showNextCourse: false,

      isDescription: false,

      loading: false,
    };
  }

  async componentDidMount() {
    const { courseId, questionId } = this.props.match.params;

    await this.fetchQuestions(courseId, questionId);
  }

  async fetchQuestions(courseId, questionId) {
    this.setState({ loading: true });
    let state = {
      authUser: null,
    };

    try {
      state.authUser = await Auth.currentAuthenticatedUser();
    } catch (e) {
      // do nothing
    }

    if (state.authUser) {
      let accountSettingsResponse = await accountService.fetchAccountSetting();
      state.accountSettings = accountSettingsResponse ? JSON.parse(accountSettingsResponse) : {};

      state.showNextCourse = state.accountSettings && state.accountSettings.coursesPractice && state.accountSettings.coursesPractice[courseId] && state.accountSettings.coursesPractice[courseId].isFinished;
    }

    state.course = await contentsService.fetchContent(courseId);
    let practiceQuestionsResponse = await practiceQuestionsService.fetchPracticeQuestions(courseId);

    practiceQuestionsResponse.items[0].answers = JSON.parse(practiceQuestionsResponse.items[0].answers);
    practiceQuestionsResponse.items[0].answers.forEach(function (val1, key1) {
      Object.entries(val1).forEach(function (val2, key2) {
        Object.entries(val2[1]).forEach(function (val3, key3) {
          // console.log(val3[1].replace(/\r?\n/g, '<br>'));
          // practiceQuestionsResponse.items[0].answers[key1]['descriptionBehavePoint']['jp'] = practiceQuestionsResponse.items[0].answers[key1]['descriptionBehavePoint']['jp'].replace(/\r?\n/g, '<br>');
          console.log(practiceQuestionsResponse.items[0].answers[key1]['descriptionBehavePoint']['jp'].replace(/\r?\n/g, '<br>'));
        });
      });
    });
    // console.log(practiceQuestionsResponse.items[0].answers);
    practiceQuestionsResponse.items[0].answers = JSON.stringify(practiceQuestionsResponse.items[0].answers);
    state.courseSettings = state.course.settings ? JSON.parse(state.course.settings) : {};

    let questions = [];
    if (state.courseSettings && state.courseSettings.practiceQuestions) {
      let order = state.courseSettings.practiceQuestions;
      let items = practiceQuestionsResponse.items;
      items = items.map(function (item) {
        var n = order.indexOf(item.id);
        order[n] = '';
        return [n, item]
      }).sort().map(function (j) {
        return j[1]
      });
      questions = items;
    } else {
      questions = practiceQuestionsResponse.items;
    }

    state.questions = questions;
    state.questionsCount = questions.length;

    let questionsState = await this.prepareQuestions(state.questions, questionId, courseId);
    state = { ...state, ...questionsState };
    state.loading = false;

    this.setState(state);
  }

  prepareQuestions = async (questions, questionId, courseId) => {
    let state = {};

    let courseSettingResponse = await contentsService.fetchContentSetting("COURSE");
    let courseSetting = courseSettingResponse ? courseSettingResponse : {};
    let courseOrder = courseSetting.order ? JSON.parse(courseSetting.order) : [];

    let selectedCourseIndex = courseOrder.findIndex((item) => item === courseId);
    if (selectedCourseIndex + 1 < courseOrder.length) {
      state.nextCourseId = courseOrder[selectedCourseIndex + 1];
    }

    if (questionId && questionId === "finished") {

    } else if (questions.length > 0) {
      let currentQuestionIndex = 0;
      if (questionId) {
        let index = questions.findIndex((item) => item.id === questionId);
        currentQuestionIndex = index !== -1 ? index : 0;
      }

      state.questionIndex = currentQuestionIndex;
      state.question = questions[currentQuestionIndex];
      state.answers = commonService.shuffleArr(JSON.parse(state.question.answers));
      state.chats = JSON.parse(state.question.chats);
      state.characters = JSON.parse(state.question.characters);
    }

    state.selectedAnswer = null;
    state.showAnswerReactionModal = false;
    state.showPracticeAnswerModal = false;
    state.isDescription = false;

    return state;
  };

  formatByLanguage = (value) => {
    try {
      let array = typeof value === "string" ? JSON.parse(value) : value;
      if (array[this.props.currentLang]) {
        return array[this.props.currentLang];
      } else {
        return null;
      }
    } catch (e) {
      return null;
    }
  };

  onShowAnswerModal = () => {
    this.setState({ showPracticeAnswerModal: true });
  };

  onCloseAnswerModal = () => {
    this.setState({ showPracticeAnswerModal: false });
  };

  onOkAnswerModal = (answer) => {
    this.setState({ showPracticeAnswerModal: false });
    this.onClickAnswer(answer);
  };

  onClickAnswer = async (answer) => {
    this.setState({ selectedAnswer: answer, showAnswerReactionModal: true });
    if (this.state.questionsCount === this.state.questionIndex + 1) {
      let accountSettings = this.state.accountSettings;
      let coursesPracticeSettings = accountSettings.coursesPractice ? accountSettings.coursesPractice : {};
      if (!coursesPracticeSettings[this.state.course.id]) {
        coursesPracticeSettings[this.state.course.id] = {
          isFinished: true
        };
        accountSettings.coursesPractice = coursesPracticeSettings;
        if (this.state.authUser) {
          await accountService.updateAccountSettings({
            settings: JSON.stringify(accountSettings)
          });
        }
        this.setState({ accountSettings });
      }
    }
  };

  onCloseAnswerReactionModal = () => {
    this.setState({ showAnswerReactionModal: false, isDescription: true });
  };

  onOkAnswerReactionModal = () => {
    this.setState({ showAnswerReactionModal: false, isDescription: true });
  };

  onNextQuestionClick = async () => {
    let question = this.state.questions[this.state.questionIndex + 1];
    this.props.history.push(`/course/${this.state.course.id}/practice/${question.id}`);
  };

  onTestClick = () => {
    this.props.history.push(`/course/${this.state.course.id}/test`);
  };

  onNextCourseClick = async () => {
    this.props.history.push(`/course/${this.state.nextCourseId}/practice`);
    await this.fetchQuestions(this.state.nextCourseId);
  };

  renderHeader = () => {
    const {
      course,
      question,
      questionsCount,
      questionIndex,
      isDescription
    } = this.state;

    let now = Math.floor(100 / questionsCount) * (questionIndex + (isDescription ? 1 : 0));

    return (
      <div className="lesson__header">
        {course && question && (
          <div className="lesson__header__text">
            {this.formatByLanguage(course.title)}<span className="secondary">{" "}
              {this.formatByLanguage(question.title)}</span>
          </div>
        )}
        {questionsCount && <ProgressBar now={now} label={`${now}%`} />}
      </div>
    );
  };

  renderQuestion = () => {
    const {
      question,
      answers,
      chats,
      characters,
    } = this.state;

    return (
      <div className="practice">
        <div className="practice-question practice">
          <img src={question.svgUrl} alt="問題イラスト" />
          <div className="practice-question__chat">
            {chats.map(
              (chatItem, index) => (
                <QuestionChatItem
                  key={index}
                  characterId={chatItem.character.value}
                  characters={characters}
                  reading={chatItem.reading}
                  text={chatItem.text} />
              ))}
          </div>
        </div>

        <div className="practice-question-answers my-3 d-none d-md-block">
          <div className="pl-3 pr-3 pt-1 pb-1 text-center header">
            {langService.translate("answer", this.props.currentLang)}
          </div>
          <div className="my-3 items" >
            {answers.map(
              (answer, index) => (
                <div
                  key={index}
                  className="cursor-hover item"
                  onClick={this.onClickAnswer.bind(this, answer)}
                >
                  <img src={answer.image.value} alt="回答イラスト" />
                  <div className="texts">
                    {answer.reading && this.formatByLanguage(answer.reading) && (<div className="reading">{this.formatByLanguage(answer.reading)}</div>)}
                    <div>{this.formatByLanguage(answer.text)}</div>
                  </div>
                </div>
              )
            )}
          </div>
          <AnswerReactionModals
            show={this.state.showAnswerReactionModal}
            onClose={this.onCloseAnswerReactionModal.bind(this)}
            onOk={this.onOkAnswerReactionModal.bind(this)}
            currentLang={this.props.currentLang}
            answer={this.state.selectedAnswer}
          />
        </div>

        <div className="answer-mobile d-block d-md-none">
          <div className="p-3 text-center header" onClick={() => this.onShowAnswerModal()}>
            {langService.translate("answer", this.props.currentLang)}
          </div>
        </div>
        <PracticeAnswerModal
          show={this.state.showPracticeAnswerModal}
          onClose={this.onCloseAnswerModal.bind(this)}
          onOk={this.onOkAnswerModal.bind(this)}
          answers={answers}
        />
      </div>
    );
  };

  renderDescription = () => {
    const {
      question,
      chats,
      selectedAnswer,
      characters,
      questionsCount,
      questionIndex,
      nextCourseId,
      authUser,
      showNextCourse,
    } = this.state;

    let isPracticeFinished = questionsCount === questionIndex + 1;
    return (
      <>
        <div className="practice">
          <h2>解説</h2>
          <div className="practice-question">
            <img className="description" src={question.svgUrl} alt="説明イラスト" />
            <div className="practice-question__detail">
              <div className="practice-question__chat">
                {chats.map(
                  (chatItem, index) => (
                    <QuestionChatItem
                      key={index}
                      characterId={chatItem.character.value}
                      characters={characters}
                      reading={chatItem.reading}
                      text={chatItem.text} />
                  ))}
              </div>
            </div>
          </div>
        </div>
        <div className="incorrect-description">
          <div className="practice-question__kibin">
            <QuestionChatItem answer={selectedAnswer} kibin={true} />
          </div>

          <div className="texts mt-2">
            <div className="header">{langService.translate("pointOfJapaneseCulturesAndCustoms", this.props.currentLang)}</div>
            <div className="text" dangerouslySetInnerHTML={{ __html: this.formatByLanguage(selectedAnswer.descriptionJapanesePoint).replace(/\r?\n/g, '<br />').replace(/\[([^,]*),([ぁ-ん]*)\]/g, '<ruby><rb>$1</rb><rt>$2</rt></ruby>') }} ></div>
          </div>

          <div className="texts mt-2">
            <div className="header">{langService.translate("pointOfBehaviorAndReaction", this.props.currentLang)}</div>
            <div className="text" dangerouslySetInnerHTML={{ __html: this.formatByLanguage(selectedAnswer.descriptionBehavePoint).replace(/\r?\n/g, '<br />').replace(/\[([^,]*),([ぁ-ん]*)\]/g, '<ruby><rb>$1</rb><rt>$2</rt></ruby>') }} ></div>
          </div>
        </div>
        {
          !isPracticeFinished && (
            <Button
              variant="flat"
              block
              className="mt-3"
              onClick={this.onNextQuestionClick.bind(this)}
            >
              {langService.translate("goToTheNextQuestion", this.props.currentLang)}
            </Button>
          )
        }
        {
          isPracticeFinished && authUser && (
            <>
              <Button
                variant="flat"
                block
                className="mt-3"
                onClick={this.onTestClick.bind(this)}
              >
                {langService.translate("takeATest", this.props.currentLang)}
              </Button>
              {nextCourseId && (
                <Button
                  variant="flat"
                  block
                  className="mt-3"
                  disabled={!showNextCourse}
                  onClick={this.onNextCourseClick.bind(this)}
                >
                  {langService.translate("nextCourse", this.props.currentLang)}
                </Button>
              )}
            </>
          )
        }
      </>
    );
  };

  renderNotFound = () => {
    return (
      <div className="d-flex justify-content-center align-content-center mt-4">
        {langService.translate("notFound", this.props.currentLang)}
      </div>
    );
  };

  render() {
    const {
      isDescription,
    } = this.state;

    return (
      <MainContainer isPractice={true} isLoading={this.state.loading}>
        {!this.state.loading && this.state.question && (
          <>
            {this.renderHeader()}
            <div className="content">
              {!isDescription && this.renderQuestion()}
              {isDescription && this.renderDescription()}
            </div>
          </>
        )}
        {!this.state.loading && !this.state.question && this.renderNotFound()}
      </MainContainer>
    );
  }
}

function mapStateToProps(state) {
  return {
    currentLang: state.root.currentLang
  };
}

export default connect(mapStateToProps)(CoursePracticePage);
