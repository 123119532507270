import { osName } from 'react-device-detect';

export const prepareCsv = (schema, array) => {
  let result = "";

  // Header
  let schemaItem;
  for (schemaItem of schema) {
    if (result.length > 0) result += ",";
    result += schemaItem.label;
  }
  result += "\n";

  // Body
  let arrayItem;
  for (arrayItem of array) {
    let resultRow = "";
    for (schemaItem of schema) {
      if (resultRow.length > 0) resultRow += ",";
      let value = arrayItem[schemaItem.path];
      if (schemaItem.type) {
        if (schemaItem.type === "number") {
          resultRow += value
        } else if (typeof schemaItem.type === "string") {
          resultRow += value ? value : "";
        } else {
          resultRow += schemaItem.type(value);
        }

      } else {
        resultRow += value ? value : "";
      }

    }
    result += resultRow + "\n";
  }


  return  result;
};

export const downloadCsv = (csv, filename) => {
  let data = new Blob(["\ufeff" + csv]);//, {encoding:"Shift_JIS", type: 'text/csv;charset=Shift_JIS'}); // ;charset=Shift_JIS

  // osName

  let url = window.URL.createObjectURL(data);
  let element = document.getElementById("download");
  if (element) {
    element.parentNode.removeChild(element);
  }
  element = document.createElement("a");
  element.id = "download";
  element.style = "display: none";
  element.href = url;
  element.download = filename;
  document.body.appendChild(element);
  element.click();
  window.URL.revokeObjectURL(url);
};

export const readCsv = async (file, schema) => {
 let string = await readFileAsync(file);
 if (!string || string.length === 0) return [];
 let rows = string.split("\n");
 let items = [];
 for (let rowIndex = 0; rowIndex < rows.length; rowIndex++) {
   if (rowIndex === 0) continue; // skip header
   let cols = rows[rowIndex].split(",");
   if (cols.length <= 1) { continue; }
   if (cols.length !== schema.length) throw new Error(`${rowIndex + 1}行目で列数は合ってません。${schema.length}列が必要です`)

   let item = {};
   for (let schemaIndex = 0; schemaIndex < schema.length; schemaIndex++) {
     let value = cols[schemaIndex];
     if (schema[schemaIndex].required && (!value || value.length === 0)) throw new Error(`${rowIndex + 1}行目・${schemaIndex + 1}列目は必須になってます`);
     if (schema[schemaIndex].type) {
       if (typeof schema[schemaIndex].type === "string") {
         if (schema[schemaIndex].type === "Int") {
           item[schema[schemaIndex].path] = value ? parseInt(value) : null;
         } else {
           item[schema[schemaIndex].path] = value ? value : null
         }
       } else {
         let newValue = schema[schemaIndex].type(value);
         item[schema[schemaIndex].path] = newValue ? newValue : null
       }

     } else {
       item[schema[schemaIndex].path] = value ? value : null
     }
   }
   items.push(item);
 }

  return items;
};

function readFileAsync(file) {
  return new Promise((resolve, reject) => {
    let reader = new FileReader();
    reader.onload = () => {
      resolve(reader.result);
    };
    reader.onerror = reject;
    reader.readAsText(file, 'UTF-8');
  })
}
