import {
  SET_CURRENT_COURSE_GROUP,
  SET_COURSE_GROUPS,
  SET_PROGRESS,
  SET_INIT_DATA_LOADED
} from "./actionTypes";

const initialState = {
  courseGroups: null,
  currentCourseGroup: null,
  progress: null,
  initDataLoaded: false,
  initDataLoading: false
};

const coursesReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_COURSE_GROUPS:
      return Object.assign({}, state, {
        courseGroups: action.courseGroups
      });
    case SET_PROGRESS:
      return Object.assign({}, state, {
        progress: action.progress
      });
    case SET_INIT_DATA_LOADED:
      return Object.assign({}, state, {
        initDataLoaded: action.initDataLoaded
      });
    case SET_CURRENT_COURSE_GROUP:
      return Object.assign({}, state, {
        currentCourseGroup: action.courseGroup
      });
    default: {
      return state;
    }
  }
};

export function getCourseProgress(state, courseId) {
  if (state.courses.progress && state.courses.progress.length) {
    return state.courses.progress.find(courseProgress => courseProgress.courseId === courseId);
  } else {
    return undefined;
  }
}

export default coursesReducer;
