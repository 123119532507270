import { API, graphqlOperation } from "aws-amplify";
import { GetAllSubtleTestResults, GetAdminSubtleTestResults, GetSubtleTestResults, GetSubtleTestResult, CreateSubtleTestResult } from "./graphql/subtle-test-results";

export const fetchAllSubtleTestResults = async (userId) => {
  const response = await API.graphql(graphqlOperation(
    GetAllSubtleTestResults,
    { userId },
    "AMAZON_COGNITO_USER_POOLS"
  ));

  return response.data.getAllSubtleTestResults;
};

export const fetchAdminSubtleTestResults = async () => {
  const response = await API.graphql(graphqlOperation(
    GetAdminSubtleTestResults,
    { },
    "AMAZON_COGNITO_USER_POOLS"
  ));

  return response.data.getAdminSubtleTestResults;
};

export const fetchSubtleTestResults = async () => {
  const response = await API.graphql(graphqlOperation(
    GetSubtleTestResults,
    { },
    "AMAZON_COGNITO_USER_POOLS"
  ));

  return response.data.getSubtleTestResults;
};

export const fetchSubtleTestResult = async (id) => {
  const response = await API.graphql(graphqlOperation(
    GetSubtleTestResult,
    { id },
    "AMAZON_COGNITO_USER_POOLS"
  ));

  return response.data.getSubtleTestResult;
};

export const createSubtleTestResult = async (input) => {
  const response = await API.graphql(graphqlOperation(
    CreateSubtleTestResult,
    { input },
    "AMAZON_COGNITO_USER_POOLS"
  ));

  return response.data.createSubtleTestResult;
};
