import React, { Component } from "react";
import { connect } from "react-redux";
import { ButtonToolbar, Button, Badge, ListGroup } from "react-bootstrap";
import "./index.scss";
import * as contentsService from "../../../services/contents";
import * as commonService from "../../../services/common";
import * as jsonService from "../../../services/json";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBars} from "@fortawesome/free-solid-svg-icons";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";

class ComicsSpiritPage extends Component {

  constructor(props, context) {
    super(props, context);

    this.state = {
      contentType: "COMICS_SPIRIT",
      isEdit: false,
      loading: true,
      error: null,
      items: [],
      oldItems: [],
    };
  }

  async componentWillMount() {
    await this.fetchContents();
  }

  fetchContents = async () => {
    this.setState({ loading: true, error: null });
    try {
      let setting = await contentsService.fetchContentSetting(this.state.contentType);
      let response = await contentsService.fetchContents();
      let items = response.items.filter((item) => item.contentType === this.state.contentType);
      if (setting) {
        let order = JSON.parse(setting.order);
        let newList = [];
        for (let i = 0; i < order.length; i++) {
          let index = items.findIndex(item => item.id === order[i]);
          if (items[index]) {
            newList.push(items[index]);
          }
        }
        items = newList;
      }

      console.log('items', items);

      this.setState({ items: items, loading: false });
    } catch (e) {
      console.error(e);
      this.setState({ loading: true, error: e });
    }
  };

  onCreateClick = () => {
    this.props.history.push("/admin/comics/spirit/new");
  };

  onEditClick = (item) => {
    this.props.history.push(`/admin/comics/spirit/${item.id}`);
  };

  onDragEnd = async (result) => {
    if (!result.destination) return;

    // Update local list
    const items = Array.from(this.state.items);
    const [removed] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, removed);
    this.setState({ items });
  };

  onDownload = () => {

    let schema = [
      {path: "id"},
      {path: "title", type: "JSON"},
      {path: "description", type: "JSON"},
      {path: "languages", type: "JSON"},
      {path: "comicIntroduction", type: "JSON"},
      {path: "comicIntroductionSvgUrl"},
      {path: "comicPages", type: "JSON"},
    ];

    let json = jsonService.prepareJson(schema, this.state.items);
    jsonService.downloadJson(json, "comics-spirit.json");
  };

  onUploadSample = () => {

    let schema = [
      {path: "title"},
      {path: "description"},
      {path: "languages"},
      {path: "comicIntroduction"},
      {path: "comicIntroductionSvgUrl"},
      {
        path: "comicPages",
        type: "Array",
        items: [
          {path: "url"}
        ]
      }
    ];

    let items = [
      {
        title: {jp: "Some title"},
        description: {jp: "Some description"},
        languages: ["jp"],
        comicIntroduction: {jp: "Comic intro"},
        comicIntroductionSvgUrl: "comic-spirit-intro-image.svg",
        comicPages: [
          { url: "comic-spirit-page-1.svg" },
          { url: "comic-spirit-page-2.svg" },
          { url: "comic-spirit-page-3.svg" }
        ],
      }
    ];

    let json = jsonService.prepareJson(schema, items);
    jsonService.downloadJson(json, "comics-spirit.json");
  };

  onUpload = async (file) => {
    console.log('onUpload');

    // // byValue
    //
    // let schema = [
    //   {path: "title", required: true, separatedLanguage: true},
    //   {path: "description", separatedLanguage: true},
    //   {
    //     path: "practiceQuestions",
    //     type: "Array",
    //     items: [
    //       {path: "id", required: true},
    //       {path: "title", required: true, separatedLanguage: true},
    //       {path: "svgUrl", required: true},
    //       //     {
    //       //       path: "characters",
    //       //       type: "Array",
    //       //       items: [
    //       //         {path: "id"},
    //       //         {path: "title"},
    //       //         {path: "svgUrl"},
    //       //       ]
    //       //     },
    //       //     {
    //       //       path: "chats",
    //       //       type: "Array",
    //       //       items: [
    //       //         {path: "id"},
    //       //         {path: "character"},
    //       //         {path: "text"},
    //       //         {path: "reading"},
    //       //       ]
    //       //     },
    //       //     {
    //       //       path: "answers",
    //       //       type: "Array",
    //       //       items: [
    //       //         {path: "text"},
    //       //         {path: "reading"},
    //       //         {path: "message"},
    //       //         {path: "isCorrect"},
    //       //         {path: "image"},
    //       //         {path: "descriptionImage"},
    //       //         {path: "descriptionJapaneseFeeling"},
    //       //         {path: "descriptionKibi"},
    //       //         {path: "descriptionKibiAvatar"},
    //       //         {path: "descriptionJapanesePoint"},
    //       //         {path: "descriptionBehavePoint"},
    //       //       ]
    //       //     },
    //     ]
    //   },
    //   // {
    //   //   path: "testQuestions",
    //   //   type: "Array",
    //   //   items: [
    //   //     {path: "id"},
    //   //     {path: "title"},
    //   //     {path: "svgUrl"},
    //   //     {
    //   //       path: "characters",
    //   //       type: "Array",
    //   //       items: [
    //   //         {path: "id"},
    //   //         {path: "title"},
    //   //         {path: "svgUrl"},
    //   //       ]
    //   //     },
    //   //     {
    //   //       path: "chats",
    //   //       type: "Array",
    //   //       items: [
    //   //         {path: "id"},
    //   //         {path: "character"},
    //   //         {path: "text"},
    //   //         {path: "reading"},
    //   //       ]
    //   //     },
    //   //     {
    //   //       path: "answers",
    //   //       type: "Array",
    //   //       items: [
    //   //         {path: "text"},
    //   //         {path: "reading"},
    //   //         {path: "isCorrect"},
    //   //       ]
    //   //     },
    //   //   ]
    //   // },
    // ];
    //
    // // let schema = [
    // //   {path: "email", type: "String", required: true},
    // //   {path: "password", type: function(value) {
    // //       if (value) return value;
    // //       return generator.generate({
    // //         length: 6,
    // //         uppercase: false
    // //       });
    // //     }},
    // //   {path: "name", type: "String", required: true},
    // //   {path: "department", type: "String"},
    // //   {path: "manager", type: "String"},
    // //   {path: "childMaxCount", type: "Int", required: true},
    // //   {path: "permissions", type: function(value) {
    // //       if (!value) throw new Error("権限が必須になってます");
    // //       return JSON.stringify(value.replace("\r","").split(";"));
    // //     }, required: true},
    // // ];
    // try {
    //   let items = await jsonService.readJson(file, schema);
    //   console.log('items', items);
    //   // let item;
    //   // for (item of items) {
    //   //   await userService.createUserManager(item);
    //   // }
    //   // await this.fetchUsers();
    //   // window.alert("アップロードは完了しました");
    // } catch (e) {
    //   if (e.errors) {
    //     window.alert(e.errors[0].message);
    //   } else {
    //     window.alert(e.message);
    //   }
    // }
  };

  onEditOrder = () => {
    const {items} = this.state;
    this.setState({oldItems: [...items], isEdit: true})
  };

  onEditOrderCancel = () => {
    const {oldItems} = this.state;
    this.setState({items: [...oldItems], isEdit: false});
  };

  onEditOrderDone = async () => {
    const {items} = this.state;

    let order = items.map(item => item.id);
    await contentsService.updateContentSetting({id: this.state.contentType, order: JSON.stringify(order)})

    this.setState({oldItems: [], isEdit: false});
  };

  renderSimpleList = () => {
    const {items} = this.state;
    return (
      <ListGroup className="mt-3">
        {items.map((item, index) => (

          <div
            key={item.id}
            className="d-flex justify-content-between align-items-center list-group-item pointer"
            onClick={() => this.onEditClick(item)}
          >
            <div>
              <FontAwesomeIcon className="text-secondary mr-2 grab" icon={faBars} />
              {JSON.parse(item.title)[commonService.mainLanguage] + " "}
              {JSON.parse(item.languages).map((language) => (
                <Badge key={language} variant="info" className="mr-1">{commonService.languages[language]}</Badge>
              ))}
            </div>
          </div>
        ))}
        {!this.state.loading && this.state.items.length === 0 && (
          <ListGroup.Item>
            何も見つかりませんでした
          </ListGroup.Item>
        )}
      </ListGroup>
    );
  };

  renderDraggableList = () => {
    return (
      <ListGroup className="mt-3">

        <DragDropContext onDragEnd={this.onDragEnd}>
          <Droppable droppableId="droppable">
            {(provided, snapshot) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                {this.state.items.map((item, index) => (
                  <Draggable key={item.id} draggableId={item.id} index={index}>
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        className="d-flex justify-content-between align-items-center list-group-item pointer"
                        onClick={() => this.onEditClick(item)}
                      >
                        <div>
                          <FontAwesomeIcon className="text-secondary mr-2 grab" icon={faBars} />
                          {JSON.parse(item.title)[commonService.mainLanguage] + " "}
                          {JSON.parse(item.languages).map((language) => (
                            <Badge key={language} variant="info" className="mr-1">{commonService.languages[language]}</Badge>
                          ))}
                        </div>
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
        {!this.state.loading && this.state.items.length === 0 && (
          <ListGroup.Item>
            何も見つかりませんでした
          </ListGroup.Item>
        )}
      </ListGroup>
    );
  };

  render() {
    const {isEdit} = this.state;
    return (
      <>
        <ButtonToolbar className="mt-4 justify-content-between align-items-center">
          <h1 className="m-0">漫画Spirit一覧</h1>
          {!isEdit && (
            <div className="d-flex">
              <Button variant="primary" className="mr-2" onClick={this.onEditOrder}>順番変更</Button>
              <div className='btn btn-outline-primary mr-2 pointer'>
                <label htmlFor="file" style={{marginBottom: 0}}>
                  アップロード
                </label>
                <input id="file" accept="application/json" type='file' style={{display: 'none'}} onChange={(e) => {this.onUpload(e.target.files[0]); e.target.value = null;}} />
              </div>
              <Button variant="outline-primary mr-2" onClick={this.onUploadSample}>CSVサンプル</Button>
              <Button variant="outline-primary mr-2" onClick={this.onDownload}>ダウンロード</Button>
              <Button variant="primary" onClick={this.onCreateClick}>制作</Button>
            </div>
          )}
          {isEdit && (
            <div className="d-flex">
              <Button variant="outline-primary mr-2" onClick={this.onEditOrderCancel}>キャンセル</Button>
              <Button variant="primary" className="mr-2" onClick={this.onEditOrderDone}>保存</Button>
            </div>
          )}
        </ButtonToolbar>

        {!isEdit && this.renderSimpleList()}
        {isEdit && this.renderDraggableList()}
      </>
    )
  };
}

function mapStateToProps(state) {
  return {};
}

export default connect(mapStateToProps)(ComicsSpiritPage);
