export const GetPracticeQuestions = `
  query GetPracticeQuestions($contentId: ID!, $first: Int, $after: String) {
    getPracticeQuestions(contentId: $contentId, first: $first, after: $after) {
      items {
        id
        title
        svgUrl
        characters
        chats
        answers
        createdAt
      }
      nextToken
    }
  }
`;

export const GetPracticeQuestion = `
  query GetPracticeQuestion($id: ID!) {
    getPracticeQuestion(id: $id) {
      id
      contentId
      title
      svgUrl
      characters
      chats
      answers
      createdAt
    }
  }
`;

export const CreatePracticeQuestion = `
  mutation CreatePracticeQuestion($input: PracticeQuestionCreateInput!) {
    createPracticeQuestion(input: $input) {
      id
    }
  }
`;

export const UpdatePracticeQuestion = `
  mutation UpdatePracticeQuestion($input: PracticeQuestionUpdateInput!) {
    updatePracticeQuestion(input: $input) {
      id
    }
  }
`;

export const DeletePracticeQuestion = `
  mutation DeletePracticeQuestion($id: ID!) {
    deletePracticeQuestion(id: $id) {
      id
    }
  }
`;
