import {
  SET_USER_DATA,
  CLEAR_USER_DATA,
  SET_ERROR_DATA,
  CLEAR_ERROR_DATA,
  LOCAL_STORAGE_CKECKED,
  SET_LOADING
} from "./actionTypes";

const emptyUser = {
  email: null,
  jwt: null,
  sub: null
};
const initialState = {
  user: Object.assign({}, emptyUser),
  error: null,
  localStorageChecked: false,
  loading: false
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_USER_DATA:
      return Object.assign({}, state, {
        user: action.user
      });
    case CLEAR_USER_DATA:
      return Object.assign({}, state, { user: Object.assign({}, emptyUser) });
    case SET_ERROR_DATA:
      return Object.assign({}, state, { error: action.error });
    case CLEAR_ERROR_DATA:
      return Object.assign({}, state, { error: null });
    case LOCAL_STORAGE_CKECKED:
      return Object.assign({}, state, { localStorageChecked: true });
    case SET_LOADING:
      return Object.assign({}, state, { loading: action.loading });
    default: {
      return state;
    }
  }
};

export function isAuth(state) {
  return !!state.auth.user.jwt;
}

export default authReducer;
