import React, { Component } from "react";
import { connect } from "react-redux";
import { Alert, Button, ButtonToolbar, Badge, Card, ListGroup, Container, Nav } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons'
import * as usersService from "../../../../services/users";
import * as courseTestResultsService from "../../../../services/course-test-results";
import * as subtleTestResultsService from "../../../../services/subtle-test-results";
import * as nursingResultsService from "../../../../services/nursing-results";
import { SmallBgLoading } from "../../../../components/commons/SmallBgLoading"
import * as commonService from "../../../../services/common";
import RadarChart from "react-svg-radar-chart";
import * as contentsService from "../../../../services/contents";
import "./index.scss";
import * as langService from "../../../../services/lang";
import * as csvService from "../../../../services/csv";
import * as nursingResultsMiddleware from "../../../../services/nursing/results";
import nursingCsv from "../../../../services/nursing/csv";

class UserPage extends Component {

  constructor(props, context) {
    super(props, context);

    this.state = {
      touched: false,
      user: null,
      courseTestResults: [],
      subtleTestResults: [],
      nursingResults: [],
      courseTestResultsOpenCourseId: null,
      subtleTestResultsOpenCourseId: null,
      nursingTestResultsOpenCourseId: null,
      resultsSelectedIndex: 0,
      subtleData: null,
      nursingData: null,
      error: null,
      loading: false,
    };
  }

  onError = (error) => {
    if (error.errors) {
      console.error(error.errors[0]);
      this.setState({ loading: false, error: error.errors[0] });
    } else {
      console.error(error);
      this.setState({ loading: false, error });
    }
  };

  async componentWillMount() {
    let userId = this.props.match.params.userId;
    if (userId !== "new") {
      this.setState({ loading: true });
      try {
        let user = await usersService.fetchUser(userId);
        let courseTestResultsResponse = await courseTestResultsService.fetchAllCoursesTestResults(userId);
        let subtleTestResultsResponse = await subtleTestResultsService.fetchAllSubtleTestResults(userId);
        let nursingResultsResponse = await nursingResultsService.fetchAllNursingResults(userId);

        let courseTestResults = courseTestResultsResponse.items;
        courseTestResults.forEach((item) => {
          item.results = JSON.parse(item.results);
        });

        let subtleTestResults = subtleTestResultsResponse.items;
        subtleTestResults.forEach((item) => {
          item.results = JSON.parse(item.results);
        });

        let nursingResults = nursingResultsResponse.items;
        nursingResults.forEach((item) => {
          item.results = JSON.parse(item.results);
        });

        let content = await contentsService.fetchContent('SUBTLE');
        if (content) {
          content.settings = JSON.parse(content.settings);
        }

        let nursing = await contentsService.fetchContent('NURSING');
        if (nursing) {
          nursing.settings = JSON.parse(nursing.settings);
        }

        this.setState({
          loading: false,
          user,
          courseTestResults,
          subtleTestResults,
          nursingResults,
          subtleData: content,
          nursingData: nursing,
        });
      } catch (error) {
        this.onError(error);
      }
    }
  }

  onParentClick = () => {
    this.props.history.push(`/admin/${this.state.user.parent.id}/user`);
  };

  onCourseTestResultsClick = (item) => {
    if (this.state.courseTestResultsOpenCourseId) {
      if (this.state.courseTestResultsOpenCourseId === item.id) {
        this.setState({ courseTestResultsOpenCourseId: null })
      } else {
        this.setState({ courseTestResultsOpenCourseId: item.id })
      }
    } else {
      this.setState({ courseTestResultsOpenCourseId: item.id })
    }
  };

  onSubtleTestResultsClick = (item) => {
    if (this.state.subtleTestResultsOpenCourseId) {
      if (this.state.subtleTestResultsOpenCourseId === item.id) {
        this.setState({ subtleTestResultsOpenCourseId: null })
      } else {
        this.setState({ subtleTestResultsOpenCourseId: item.id })
      }
    } else {
      this.setState({ subtleTestResultsOpenCourseId: item.id })
    }
  };

  onNursingTestResultsClick = (item) => {
    if (this.state.nursingTestResultsOpenCourseId) {
      if (this.state.nursingTestResultsOpenCourseId === item.id) {
        this.setState({ nursingTestResultsOpenCourseId: null })
      } else {
        this.setState({ nursingTestResultsOpenCourseId: item.id })
      }
    } else {
      this.setState({ nursingTestResultsOpenCourseId: item.id })
    }
  };

  onDeleteClick = async () => {

    this.setState({ error: null, loading: true });

    try {
      await usersService.deleteUser(this.state.user.id);
      this.setState({ loading: false });
      this.props.history.push("/admin/users");
    } catch (error) {
      this.onError(error);
    }

  };

  onCourseTestResultsDownload = () => {
    const { courseTestResults } = this.state;

    let schema = [
      { label: "テストID", path: "id" },
      { label: "日付", path: "date" },
      { label: "コース名", path: "courseName", type: "String" },
      { label: "質問", path: "question", type: "String" },
      { label: "回答1", path: "answer1", type: "String" },
      { label: "回答2", path: "answer2", type: "String" },
      { label: "回答3", path: "answer3", type: "String" },
      { label: "正しい回答", path: "correctAnswerIndex", type: "String" },
      { label: "選択済回答", path: "selectedAnswerIndex", type: "String" },
    ];

    let data = [];

    for (let resultIndex = 0; resultIndex < courseTestResults.length; resultIndex++) {
      const courseTestResult = courseTestResults[resultIndex];
      let dataItem = {};
      const courseTitle = JSON.parse(courseTestResult.course.title);

      dataItem.id = courseTestResult.id;
      dataItem.date = courseTestResult.createdAt;
      dataItem.courseName = courseTitle['jp'];

      for (let questionIndex = 0; questionIndex < courseTestResult.course.testQuestions.length; questionIndex++) {
        const question = courseTestResult.course.testQuestions[questionIndex];
        const questionTitle = JSON.parse(question.title);
        const answers = JSON.parse(question.answers);

        dataItem.question = questionTitle['jp'];

        for (let answerIndex = 0; answerIndex < answers.length; answerIndex++) {
          const answer = answers[answerIndex];
          dataItem[`answer${answerIndex + 1}`] = answer.text.jp;

          if (answer.isCorrect) {
            dataItem.correctAnswerIndex = answerIndex + 1;
          }
        }

        let currentQuestionAnswerIndex = courseTestResult.results.answers.findIndex(item => item.questionId === question.id);
        if (currentQuestionAnswerIndex !== -1) {
          const currentQuestionAnswer = courseTestResult.results.answers[currentQuestionAnswerIndex];

          if (currentQuestionAnswer.isCorrect) {
            dataItem.selectedAnswerIndex = dataItem.correctAnswerIndex;
          } else {
            dataItem.selectedAnswerIndex = -1;
          }
        }
      }

      data.push(dataItem);
    }
    let csv = csvService.prepareCsv(schema, data);
    let userId = this.props.match.params.userId;
    csvService.downloadCsv(csv, `course-test-results-${userId}.csv`);
  };

  onSubtleTestResultsDownload = () => {
    const { subtleTestResults, subtleData } = this.state;

    let schema = [
      { label: "テストID", path: "id" },
      { label: "日付", path: "date" },
      { label: "質問", path: "question", type: "String" },
      { label: "詳細", path: "details", type: "String" },
      { label: "想像力", path: "imagination", type: "String" },
      { label: "リアクション力", path: "reaction", type: "String" },
      { label: "共感力", path: "empathy", type: "String" },
      { label: "気持ち察し力", path: "feeling", type: "String" },
      { label: "柔軟性", path: "flexibility", type: "String" },
      { label: "回答1", path: "answer1", type: "String" },
      { label: "回答2", path: "answer2", type: "String" },
      { label: "回答3", path: "answer3", type: "String" },
      { label: "正しい回答", path: "correctAnswerIndex", type: "String" },
      { label: "選択済回答", path: "selectedAnswerIndex", type: "String" },
    ];

    let data = [];

    for (let testIndex = 0; testIndex < subtleTestResults.length; testIndex++) {
      const test = subtleTestResults[testIndex];

      for (let resultIndex = 0; resultIndex < test.results.length; resultIndex++) {
        const result = test.results[resultIndex];

        let dataItem = {
          id: test.id,
          selectedAnswerIndex: result.answer,
          date: test.createdAt,
        };

        const question = subtleData.settings.questions[resultIndex];

        if (question.text && question.text.jp) {
          dataItem.question = question.text.jp
        } else {
          dataItem.question = null;
        }

        if (question.subtitle && question.subtitle.jp) {
          dataItem.details = question.subtitle.jp
        } else {
          dataItem.details = null;
        }

        if (question.imagination && question.imagination.value) {
          dataItem.imagination = parseInt(question.imagination.value, 0);
        } else {
          dataItem.imagination = 0;
        }

        if (question.reaction && question.reaction.value) {
          dataItem.reaction = parseInt(question.reaction.value, 0);
        } else {
          dataItem.reaction = 0;
        }

        if (question.empathy && question.empathy.value) {
          dataItem.empathy = parseInt(question.empathy.value, 0);
        } else {
          dataItem.empathy = 0;
        }

        if (question.feeling && question.feeling.value) {
          dataItem.feeling = parseInt(question.feeling.value, 0);
        } else {
          dataItem.feeling = 0;
        }

        if (question.flexibility && question.flexibility.value) {
          dataItem.flexibility = parseInt(question.flexibility.value, 0);
        } else {
          dataItem.flexibility = 0;
        }

        if (question.answer1 && question.answer1.jp) {
          dataItem.answer1 = question.answer1.jp
        } else {
          dataItem.details = null;
        }

        if (question.answer2 && question.answer2.jp) {
          dataItem.answer2 = question.answer2.jp
        } else {
          dataItem.answer2 = null;
        }

        if (question.answer3 && question.answer3.jp) {
          dataItem.answer3 = question.answer3.jp
        } else {
          dataItem.answer3 = null;
        }

        if (question.answer1IsCorrect && question.answer1IsCorrect.value && parseInt(question.answer1IsCorrect.value, 0) === 1) {
          dataItem.correctAnswerIndex = 1;
        } else if (question.answer2IsCorrect && question.answer2IsCorrect.value && parseInt(question.answer2IsCorrect.value, 0) === 1) {
          dataItem.correctAnswerIndex = 2;
        } else if (question.answer3IsCorrect && question.answer3IsCorrect.value && parseInt(question.answer3IsCorrect.value, 0) === 1) {
          dataItem.correctAnswerIndex = 3;
        }

        data.push(dataItem);
      }
    }

    let csv = csvService.prepareCsv(schema, data);
    let userId = this.props.match.params.userId;
    csvService.downloadCsv(csv, `subtle-test-results-${userId}.csv`);
  };

  onNursingResultsDownload = async () => {
    const { nursingResults } = this.state;
    const csv = await nursingCsv({ nursingResults, newestOnly: true });
    const userId = this.props.match.params.userId;
    csvService.downloadCsv(csv, `nursing-test-results-${userId}-${new Date().toISOString()}.csv`);
  };

  renderUser = () => {
    const { user } = this.state;

    return (
      <Card className="mt-4">
        <Card.Header className="d-flex justify-content-between align-items-center">
          <div>{user.parent && user.parent.name ? user.parent.name : "未定"}</div>
          <Button onClick={this.onParentClick}>管理</Button>
        </Card.Header>
        <ListGroup variant="flush">
          <ListGroup.Item className="d-flex justify-content-between align-items-center">
            <div>名前</div>
            <div>{user.name ? user.name : "未定"}</div>
          </ListGroup.Item>
          <ListGroup.Item className="d-flex justify-content-between align-items-center">
            <div>メールアドレス</div>
            <div>{user.email}</div>
          </ListGroup.Item>
        </ListGroup>
      </Card>
    );
  };

  isCorrect = (question, answers) => {
    let array = answers.filter((answer) => answer.questionId === question.id);
    if (array.length !== 1) { return false; }
    return array[0].isCorrect;
  };

  renderCourseTests = () => {
    return (
      <>
        <ButtonToolbar className="mt-4 justify-content-between align-items-center">
          <h1 className="m-0">コーステスト結果</h1>
          <Button onClick={this.onCourseTestResultsDownload}>ダウンロード</Button>
        </ButtonToolbar>

        <ListGroup className="mt-3">
          {this.state.courseTestResults.map(item => (
            <ListGroup.Item key={item.id} className="d-flex pointer" style={{ flexDirection: "column" }} onClick={() => this.onCourseTestResultsClick(item)}>
              <div className="d-flex justify-content-between align-items-center">
                <div>
                  {JSON.parse(item.course.title)[commonService.mainLanguage] + " "}
                  <Badge variant="primary">{Math.floor(100 / item.results.totalAnswers * item.results.correctAnswers)}点</Badge>
                </div>
                <div>{commonService.formatDate(item.createdAt)} <FontAwesomeIcon className="text-secondary ml-2" icon={item.id === this.state.courseTestResultsOpenCourseId ? faAngleUp : faAngleDown} /></div>
              </div>
              {item.id === this.state.courseTestResultsOpenCourseId && (
                <ListGroup className="mt-4">
                  {item.course.testQuestions.map((testQuestion) => (
                    <ListGroup.Item key={testQuestion.id} variant={this.isCorrect(testQuestion, item.results.answers) ? "success" : "danger"}>
                      {JSON.parse(testQuestion.title)[commonService.mainLanguage]}
                    </ListGroup.Item>
                  ))}
                </ListGroup>
              )}
            </ListGroup.Item>
          ))}
          {this.state.courseTestResults.length === 0 && (
            <ListGroup.Item>
              何も見つかりませんでした
            </ListGroup.Item>
          )}
        </ListGroup>
      </>
    );
  };

  buildTotalCount = (results) => {
    let totalCount = 0;
    for (let i = 0; i < results.length; i++) {
      if (i < 20) {
        if (results[i].isCorrect) {
          totalCount += 3;
        }
      } else {
        if (results[i].isCorrect) {
          totalCount += 4;
        }
      }
    }

    return totalCount;
  };

  buildSkills = (results) => {
    let { subtleData } = this.state;
    let skills = {
      imagination: 0,
      reaction: 0,
      empathy: 0,
      feeling: 0,
      flexibility: 0,
    };

    for (let i = 0; i < results.length; i++) {
      if (!results[i].isCorrect) {
        continue;
      }

      let question = subtleData.settings.questions[i];
      if (question.imagination && question.imagination.value === '1') {
        skills.imagination += 1;
      }
      if (question.reaction && question.reaction.value === '1') {
        skills.reaction += 1;
      }
      if (question.empathy && question.empathy.value === '1') {
        skills.empathy += 1;
      }
      if (question.feeling && question.feeling.value === '1') {
        skills.feeling += 1;
      }
      if (question.flexibility && question.flexibility.value === '1') {
        skills.flexibility += 1;
      }
    }

    return skills;

  };

  renderResultText = (data, points, title) => {

    let index = data.findIndex(item => points >= item.min && points <= item.max);
    if (index === -1) {
      return null;
    }

    return (
      <>
        <h4 className="mb-4">{langService.translate(data[index].title, this.props.currentLang)}</h4>
        <h5 className="mb-4">あなたの{title}は {points} 点です。</h5>
        <div>{langService.translate(data[index].details, this.props.currentLang)}</div>
      </>
    )
  };

  renderSubtleResultsItem = (item) => {
    const { resultsSelectedIndex } = this.state;
    const totalCount = this.buildTotalCount(item.results);
    const skills = this.buildSkills(item.results);

    const radarData = [
      {
        data: {
          imagination: skills.imagination / 12,
          reaction: skills.reaction / 12,
          empathy: skills.empathy / 12,
          feeling: skills.feeling / 12,
          flexibility: skills.flexibility / 12,
        },
        meta: { color: 'blue' }
      },
    ];

    const captions = {
      imagination: langService.translate({ jp: '状況想像力', jp2: '状況想像力', en: 'Imagine and be proactive', vn: 'Năng lực phán đoán' }, this.props.currentLang),
      reaction: langService.translate({ jp: 'リアクション力', jp2: 'リアクション力', en: 'React', vn: 'Năng lực phản ứng' }, this.props.currentLang),
      empathy: langService.translate({ jp: '共感力', jp2: '共感力', en: 'Empathize', vn: 'Năng lực đồng cảm' }, this.props.currentLang),
      feeling: langService.translate({ jp: '気持ち察し力', jp2: '気持ち察し力', en: 'Feel and understand', vn: 'Độ lí giải cảm xúc' }, this.props.currentLang),
      flexibility: langService.translate({ jp: '柔軟性', jp2: '柔軟性', en: 'Be flexible', vn: 'Tính linh hoạt' }, this.props.currentLang),
    };

    const texts = {
      imagination: [
        {
          min: 0, max: 4,
          title: { jp: "頑張りましょう", jp2: "頑張りましょう", en: "Try again!", vn: 'Cùng cố gắng nào' },
          details: {
            jp: "状況を想像する力が不足しています。想像力の欠如は、周囲から見た場合にズレや違和感に繋がるため要注意です。",
            jp2: "状況を想像する力が不足しています。想像力の欠如は、周囲から見た場合にズレや違和感に繋がるため要注意です。",
            en: "Your ability to imagine and be proactive is not enough. Please be careful not to misunderstand and cause problems between you and your co-workers and clients.",
            vn: "Không đủ khả năng để tưởng tượng tình huống.Việc thiếu trí tưởng tượng,có thể dẫn đến cách nhìn sai lệch và thiếu thiện cảm từ những người xung quanh nên cần đặc biệt chú ý."
          }
        },
        {
          min: 5, max: 8,
          title: { jp: "あと少し", jp2: "あと少し", en: "Almost!", vn: 'Chỉ còn chút nữa thôi !' },
          details: {
            jp: "状況を想像する力を、もう一歩伸ばすことで、あなたの一歩先回りする行動力を手に入れることに繋がります。",
            jp2: "状況を想像する力を、もう一歩伸ばすことで、あなたの一歩先回りする行動力を手に入れることに繋がります。",
            en: "If you improve the ability to imagine the situation more, you will become a proactive business person.  ",
            vn: "Bằng cách mở rộng khả năng tưởng tượng tình huống thêm một chút, bạn sẽ có thể có được sức mạnh để tiến lên phía trước một bước."
          }
        },
        {
          min: 9, max: 12,
          title: { jp: "素晴らしい！", jp2: "素晴らしい！", en: "Well done!", vn: 'Thật tuyệt vời!' },
          details: {
            jp: "状況を十分的確に想像する力を有しています。想像力を活かして、相手に丁寧に価値や優しさを届けてまいりましょう。",
            jp2: "状況を十分的確に想像する力を有しています。想像力を活かして、相手に丁寧に価値や優しさを届けてまいりましょう。",
            en: "Your ability to imagine and be proactive is excellent. Please keep your ability and be an attentive business person.",
            vn: "Đủ năng lực tưởng tượng để có thể nắm bắt toàn bộ tình hình đầy đủ và chính xác. Sử dụng khả năng tưởng tượng của mình để mang lại cho đối phương những giá trị và lòng tốt một cách lịch sự ."
          }
        },
      ],
      reaction: [
        {
          min: 0, max: 4,
          title: { jp: "頑張りましょう", jp2: "頑張りましょう", en: "Try again!", vn: 'Cùng cố gắng nào' },
          details: {
            jp: "リアクション力が不足しています。相互作用のコミュニケーションにおいて、相手から誤解を生む原因となるため要注意です。",
            jp2: "リアクション力が不足しています。相互作用のコミュニケーションにおいて、相手から誤解を生む原因となるため要注意です。",
            en: "Your ability to react is not enough. Please be careful not to misunderstand between you and your co-workers and clients in terms of the interpersonal communication.",
            vn: "Năng lực phản ứng không đủ. Trong giao tiếp trương tác giữa người với người nó có thể là nguyên nhân gây ra sự hiểu nhầm từ phía đối phương nên cần đặc biệt chú ý."
          }
        },
        {
          min: 5, max: 8,
          title: { jp: "あと少し", jp: "あと少し", en: "Almost!", vn: 'Chỉ còn chút nữa thôi !' },
          details: {
            jp: "リアクション力を、もう一歩伸ばすことで、相手から見たあなたの印象はぐっと良くなります。言語面だけでなく、相槌や頷き、笑顔、アイコンタクト、様々な非言語面も駆使してまいりましょう。",
            jp2: "リアクション力を、もう一歩伸ばすことで、相手から見たあなたの印象はぐっと良くなります。言語面だけでなく、相槌や頷き、笑顔、アイコンタクト、様々な非言語面も駆使してまいりましょう。",
            en: "Your ability to react is not enough. Please be careful not to misunderstand between you and your co-workers and clients in terms of the interpersonal communication.",
            vn: "Bằng việc tăng năng lực phản ứng của bản thân thêm 1 bước nữa thì đối phương sẽ có ấn tượng với bạn tốt đẹp hơn. Không chỉ về mặt ngôn ngữ, mà chúng ta hãy sử dụng thêm các hành động bổ trợ phi ngôn ngữ như là sự hưởng ứng, thủ thỉ, nét mặt tươi cười, giao tiếp bằng mắt."
          }
        },
        {
          min: 9, max: 12,
          title: { jp: "素晴らしい！", jp2: "素晴らしい！", en: "Well done!", vn: 'Thật tuyệt vời!' },
          details: {
            jp: "十分なリアクション力を有しています。感じ取って、察して、そのうえでどのようなリアクションをとるのか、他のチカラとも連動しながらリアクション力を発揮ください。",
            jp2: "十分なリアクション力を有しています。感じ取って、察して、そのうえでどのようなリアクションをとるのか、他のチカラとも連動しながらリアクション力を発揮ください。",
            en: "Your ability to react is excellent. Please feel, understand and react. Let's combine with the other abilities in various situations. ",
            vn: "Có đủ năng lực phản ứng. Hãy cảm nhận, quan sát, trong khi đó hãy nghĩ xem nên phản ứng thế nào, hãy sử dụng thêm sức mạnh khác nữa để phát huy năng lực phản ứng."
          }
        },
      ],
      empathy: [
        {
          min: 0, max: 4,
          title: { jp: "頑張りましょう", jp2: "頑張りましょう", en: "Try again!", vn: 'Cùng cố gắng nào' },
          details: {
            jp: "相手軸で、ともに感じたり考えたりする共感力が不足しています。情緒的コミュニケーションの非常に大事な能力なので、トレーニングで鍛えてまいりましょう。",
            jp2: "相手軸で、ともに感じたり考えたりする共感力が不足しています。情緒的コミュニケーションの非常に大事な能力なので、トレーニングで鍛えてまいりましょう。",
            en: "Your ability to empathize is not enough. Please cultivate the ability to empathize because it is very important in terms of emotional communication skills.",
            vn: "Không đủ sự đồng cảm để có cùng cảm nhận và suy nghĩ với đối phương. Vì nó là một loại năng lực rất quan trọng trong giao tiếp bằng cảm xúc nên cẩn trau dồi bằng cách luyện tập."
          }
        },
        {
          min: 5, max: 8,
          title: { jp: "あと少し", jp2: "あと少し", en: "Almost!", vn: 'Chỉ còn chút nữa thôi !' },
          details: {
            jp: "共感力を、もう一段、引き上げることで、相手から見たあなたへの信頼感や胸襟の開き方は大きく変わってまいります。",
            jp2: "共感力を、もう一段、引き上げることで、相手から見たあなたへの信頼感や胸襟の開き方は大きく変わってまいります。",
            en: "If you improve your ability to empathize more, you will become a reliable business person and can close the distance between you and others.",
            vn: "Bằng cách nâng cao năng lực đồng cảm thêm một bậc nữa thì sẽ có sự thay đổi to lớn rằng bạn sẽ mang lại cho  đối phương cảm giác đáng tin tưởng, và sẽ cởi mở với bạn hơn."
          }
        },
        {
          min: 9, max: 12,
          title: { jp: "素晴らしい！", jp2: "素晴らしい！", en: "Well done!", vn: 'Thật tuyệt vời!' },
          details: {
            jp: "十分な共感力を有しています。行動においては、一瞬で寄り添い、溶け合う場面も出てまいりますので、共感力を発揮しながら、輪を広げてまいりましょう。",
            jp2: "十分な共感力を有しています。行動においては、一瞬で寄り添い、溶け合う場面も出てまいりますので、共感力を発揮しながら、輪を広げてまいりましょう。",
            en: "Your ability to empathize is excellent. When you communicate with others on business, your ability to empathize will help yourself close the distance between you and others and build your network.",
            vn: "Có đủ sự đồng cảm. Trong hành động mọi người có thể nương tựa vào nhau, hòa đồng với nhau, vì vậy hãy vừa phát huy năng lực đồng cảm và mở lòng thêm nữa."
          }
        },
      ],
      feeling: [
        {
          min: 0, max: 4,
          title: { jp: "頑張りましょう", jp2: "頑張りましょう", en: "Try again!", vn: 'Cùng cố gắng nào' },
          details: {
            jp: "人の気持ちを察するチカラが不足しています。相手軸で、相手は今どう感じているか、どう思っているか、どう考えているか、常に相手軸で考える癖をつけてまいりましょう。",
            jp2: "人の気持ちを察するチカラが不足しています。相手軸で、相手は今どう感じているか、どう思っているか、どう考えているか、常に相手軸で考える癖をつけてまいりましょう。",
            en: "Your ability to feel and understand is not enough. Please imagine how others feel and think. To make it a habit to put yourself in other's shoes is very important.",
            vn: "Không đủ năng lực phán đoán cảm xúc của người khác. Hãy luôn có thói quen nghĩ về việc đối phương bây giờ đang cảm thấy gì, nghĩ gì, suy ngẫm về điều gì."
          }
        },
        {
          min: 5, max: 8,
          title: { jp: "あと少し", jp2: "あと少し", en: "Almost!", vn: 'Chỉ còn chút nữa thôi !' },
          details: {
            jp: "気持ち察し力をもう一歩、高めていただくことで、人の痛みや喜び、悲しみを精確に感じ取り、相手に寄り添うことが可能となります。気持ちは計算できないため、とにかく察することが肝要となります。",
            jp2: "気持ち察し力をもう一歩、高めていただくことで、人の痛みや喜び、悲しみを精確に感じ取り、相手に寄り添うことが可能となります。気持ちは計算できないため、とにかく察することが肝要となります。",
            en: "If you cultivate your ability to feel and understand more, you can know the other's suffering, joy and sadness. Human feelings can't be simulated, but can be felt and understood.",
            vn: "Bằng cách nâng cao năng lực phán đoán cảm xúc một bước nữa thì bạn có khả năng cảm nhận chính xác nỗi đau, niềm hạnh phúc, sự đau buồn trong tinh thần của đối phương. Vì cảm xúc của con người là không thể tính toán được nên hãy cố gắng hết sức để nắm bắt được nó."
          }
        },
        {
          min: 9, max: 12,
          title: { jp: "素晴らしい！", jp2: "素晴らしい！", en: "Well done!", vn: 'Thật tuyệt vời!' },
          details: {
            jp: "十分な気持ち察し力を有しています。相手に応じて、五感を駆使しながら、気持ちを察することは、リーダーシップやマネジメントにおいてもあなたを大いに助けてくれることに繋がるため、引き続きチカラを磨き続けてください。",
            jp2: "十分な気持ち察し力を有しています。相手に応じて、五感を駆使しながら、気持ちを察することは、リーダーシップやマネジメントにおいてもあなたを大いに助けてくれることに繋がるため、引き続きチカラを磨き続けてください。",
            en: "Your ability to feel and understand is excellent. In terms of leadership and management, to feel with your five senses will help yourself greatly. Please keep trying to improve yourself.",
            vn: "Đủ năng lực phán đoán cảm xúc. Ứng với từng đối tượng, việc vừa sử dụng năm giác quan vừa phán đoán cảm xúc thì chắc chắn sẽ giúp ích rất nhiều cho bạn vào việc quản lí và lãnh đạo."
          }
        },
      ],
      flexibility: [
        {
          min: 0, max: 4,
          title: { jp: "頑張りましょう", jp2: "頑張りましょう", en: "Try again!", vn: 'Cùng cố gắng nào' },
          details: {
            jp: "柔軟性が不足しています。凝り固まった価値観や固定概念は、時として、重大なコミュニケーションにおける齟齬や誤解、軋轢に繋がります。まず相手や周囲を受け入れ、そこから始めてまいりましょう。",
            jp2: "柔軟性が不足しています。凝り固まった価値観や固定概念は、時として、重大なコミュニケーションにおける齟齬や誤解、軋轢に繋がります。まず相手や周囲を受け入れ、そこから始めてまいりましょう。",
            en: "Your ability to be flexible is not enough. If you are not flexible, it will cause some serious problems in terms of business communication. Please try to accept the other's advice and feedback at first.",
            vn: "Không đủ tính linh hoạt. Các khái niệm cố định và các giá trị cứng nhắc đôi khi sẽ dẫn đến những vấn đề nghiêm trọng trong giao tiếp như là hiểu nhầm hay ganh ghét. Vì vậy, đầu tiên hãy tiếp nhận đối phương và môi trường xung quanh sau đó hãy bắt đầu."
          }
        },
        {
          min: 5, max: 8,
          title: { jp: "あと少し", jp2: "あと少し", en: "Almost!", vn: 'Chỉ còn chút nữa thôi !' },
          details: {
            jp: "もう一歩、柔軟性を高めることが期待されます。柔軟性を高めることは、けっして、あなたのスタイルを壊すことではなく、むしろ、あなたのスタイルを進化させてくれるため、様々な価値観や考え、文化を受容するための思考へと転換を図ってまいりましょう。",
            jp2: "もう一歩、柔軟性を高めることが期待されます。柔軟性を高めることは、けっして、あなたのスタイルを壊すことではなく、むしろ、あなたのスタイルを進化させてくれるため、様々な価値観や考え、文化を受容するための思考へと転換を図ってまいりましょう。",
            en: "If you cultivate your ability to be flexible more, your communication skills will be improved because you can accept various values, cultures and ideas.",
            vn: "Hi vọng là hãy tăng thêm tính linh hoạt một bước nữa. Việc tăng tính linh hoạt không hề phá hủy phong cách của bạn, trái lại, nó phát triển phong cách của bạn vì bạn có thể chuyển sang việc tiếp thu những tư tưởng văn hóa, giá trị quan hay những suy nghĩ khác nhau."
          }
        },
        {
          min: 9, max: 12,
          title: { jp: "素晴らしい！", jp2: "素晴らしい！", en: "Well done!", vn: 'Thật tuyệt vời!' },
          details: {
            jp: "十分な柔軟性を有しています。相手側から見た場合の、あなたの柔軟性は、円滑なコミュニケーションだけでなく、自由闊達な場の醸成にも一役買ってくれます。これからも、柔軟性を保ち、高めていってください。",
            jp2: "十分な柔軟性を有しています。相手側から見た場合の、あなたの柔軟性は、円滑なコミュニケーションだけでなく、自由闊達な場の醸成にも一役買ってくれます。これからも、柔軟性を保ち、高めていってください。",
            en: "Your ability to be flexible is excellent. From the viewpoint of others, you will make communication go smoothly and encourage collaboration among your team. Please keep trying to improve yourself.",
            vn: "Đủ năng tính linh hoạt. Tính linh hoạt của bạn là do nhìn từ phía đối phương, do đó không chỉ tạo điều kiện cho việc giao tiếp suôn sẻ, mà còn giúp bạn nuôi dưỡng được không gian tự do cá nhân. Vì vậy, hãy giữ cho bạn luôn luôn linh hoạt và cải thiện hơn nữa."
          }
        },
      ],
    };

    return (
      <ListGroup.Item key={item.id} className="d-flex pointer" style={{ flexDirection: "column" }}>
        <div className="d-flex justify-content-between align-items-center" onClick={() => this.onSubtleTestResultsClick(item)}>
          <div>
            <Badge variant="primary">{totalCount}点</Badge>
          </div>
          <div>{commonService.formatDate(item.createdAt)} <FontAwesomeIcon className="text-secondary ml-2" icon={item.id === this.state.subtleTestResultsOpenCourseId ? faAngleUp : faAngleDown} /></div>
        </div>
        {item.id === this.state.subtleTestResultsOpenCourseId && (
          <ListGroup className="mt-4">
            <div className="py-5 subtle-test-results">
              <div className="header">
                <h3>{langService.translate('subtle-results-title-0', this.props.currentLang)}</h3>
                <h3 className="points">
                  <div>{langService.translate('subtle-results-title-1', this.props.currentLang)}</div>
                  <div>{langService.translate('subtle-results-title-2', this.props.currentLang, [totalCount])}</div>
                </h3>
              </div>
              <div className="content subtle-results-content">
                {/*<div className="row">*/}
                <div className="radar">
                  <RadarChart
                    captions={captions}
                    data={radarData}

                  />
                </div>
                <div className="tabs">
                  <Nav variant="pills">
                    <Nav.Item>
                      <Nav.Link onClick={() => this.setState({ resultsSelectedIndex: 0 })} active={resultsSelectedIndex === 0}>状況想像力</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link onClick={() => this.setState({ resultsSelectedIndex: 1 })} active={resultsSelectedIndex === 1}>リアクション力</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link onClick={() => this.setState({ resultsSelectedIndex: 2 })} active={resultsSelectedIndex === 2}>共感力</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link onClick={() => this.setState({ resultsSelectedIndex: 3 })} active={resultsSelectedIndex === 3}>気持ち察し力</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link onClick={() => this.setState({ resultsSelectedIndex: 4 })} active={resultsSelectedIndex === 4}>柔軟性</Nav.Link>
                    </Nav.Item>
                  </Nav>
                  <div className="tabs-content">
                    {resultsSelectedIndex === 0 && (this.renderResultText(texts['imagination'], skills.imagination, '状況想像力'))}
                    {resultsSelectedIndex === 1 && (this.renderResultText(texts['reaction'], skills.reaction, 'リアクション力'))}
                    {resultsSelectedIndex === 2 && (this.renderResultText(texts['empathy'], skills.empathy, '共感力'))}
                    {resultsSelectedIndex === 3 && (this.renderResultText(texts['feeling'], skills.feeling, '気持ち察し力'))}
                    {resultsSelectedIndex === 4 && (this.renderResultText(texts['flexibility'], skills.flexibility, '柔軟性'))}
                  </div>
                </div>
                {/*</div>*/}
              </div>
            </div>
          </ListGroup>
        )}
      </ListGroup.Item>
    );
  };

  renderSubtleTest = () => {
    const { subtleTestResults } = this.state;
    return (
      <>
        <ButtonToolbar className="mt-4 justify-content-between align-items-center">
          <h1 className="m-0">機微力診断結果</h1>
          <Button onClick={this.onSubtleTestResultsDownload}>ダウンロード</Button>
        </ButtonToolbar>

        <ListGroup className="mt-3">
          {subtleTestResults.map(item => this.renderSubtleResultsItem(item))}
          {subtleTestResults.length === 0 && (
            <ListGroup.Item>
              何も見つかりませんでした
            </ListGroup.Item>
          )}
        </ListGroup>
      </>
    );
  };

  renderNursingResultsItem = (item) => {

    const nickname = item.user && item.user.name ? item.user.name : null;
    const {
      textType,
      typeImage,
      textTypeLong,
      textBubble,
      textBanner,
      textBannerText,
      totalPositiveStroke,
      totalNegativeStroke,
      totalDiscount,
      totalCorrect,
    } = nursingResultsMiddleware.buildResult({ results: item.results && item.results.items ? item.results.items : [], id: item.id, isFree: false, nickname })

    return (
      <ListGroup.Item key={item.id} className="d-flex pointer" style={{ flexDirection: "column" }}>
        <div className="d-flex justify-content-between align-items-center" onClick={() => this.onNursingTestResultsClick(item)}>
          <div className="d-flex flex-row justify-content-start align-items-center">
            <div className="mr-2">{textType}</div>
            <div><Badge variant="primary">正:{totalPositiveStroke}点</Badge></div>
            <div className="ml-2"><Badge variant="primary">負:{totalNegativeStroke}点</Badge></div>
            <div className="ml-2"><Badge variant="primary">D:{totalDiscount}点</Badge></div>
          </div>
          <div>{commonService.formatDate(item.createdAt)}  <FontAwesomeIcon className="text-secondary ml-2" icon={item.id === this.state.nursingTestResultsOpenCourseId ? faAngleUp : faAngleDown} /></div>
        </div>
        {item.id === this.state.nursingTestResultsOpenCourseId && (
          <ListGroup className="mt-4">
            <div className="nursing-results__container">
              <div className="nursing-results__container__name">{nickname}さんは…</div>
              {/*<div>id: {item.id}</div>*/}
              {/*<div>totalCorrect: {totalCorrect}</div>*/}
              {/*<div>totalPositiveStroke: {totalPositiveStroke}</div>*/}
              {/*<div>totalNegativeStroke: {totalNegativeStroke}</div>*/}
              {/*<div>totalDiscount: {totalDiscount}</div>*/}
              <div className="nursing-results__container__type-container">
                <div className="nursing-results__container__type-container__title">{textTypeLong}</div>
                <div className="nursing-results__container__type-container__image-container">
                  <img src={`/images/nursing/types/${typeImage}.png`}
                    width={200}
                    height={200}
                  />
                  <div className="nursing-results__container__type-container__image-container__bubble">
                    <div className="nursing-results__container__type-container__image-container__bubble__arrow" />{textBubble}
                  </div>
                </div>
              </div>
              <div className="nursing-results__container__banner">{textBanner}</div>
              <div className="nursing-results__container__text">{textBannerText}</div>
            </div>
          </ListGroup>
        )}
      </ListGroup.Item>
    );
  };

  renderNursingTest = () => {
    const { nursingResults } = this.state;
    return (
      <>
        <ButtonToolbar className="mt-4 justify-content-between align-items-center">
          <h1 className="m-0">きづっきー診断結果</h1>
          <Button onClick={this.onNursingResultsDownload}>ダウンロード</Button>
        </ButtonToolbar>

        <ListGroup className="mt-3">
          {nursingResults.map(item => this.renderNursingResultsItem(item))}
          {nursingResults.length === 0 && (
            <ListGroup.Item>
              何も見つかりませんでした
            </ListGroup.Item>
          )}
        </ListGroup>
      </>
    );
  };

  render() {
    return (
      <>
        <SmallBgLoading isLoading={this.state.loading}>
          {this.state.error && (
            <Alert variant="danger" className="mt-4">{this.state.error.message}</Alert>
          )}

          <ButtonToolbar className="mt-4 justify-content-between align-items-center">
            <h1 className="m-0">ユーザー情報</h1>
          </ButtonToolbar>

          {this.state.user && this.renderUser()}
          {this.state.user && this.renderCourseTests()}
          {this.state.user && this.renderSubtleTest()}
          {this.state.user && this.renderNursingTest()}

          <Card className="mt-4 mb-4">
            <Card.Header variant="danger" text="white">注意アクション</Card.Header>
            <Card.Body>
              <Button variant="danger" className="mt-2" onClick={this.onDeleteClick}>ユーザーを削除する</Button>
            </Card.Body>
          </Card>
        </SmallBgLoading>
      </>
    )
  };
}

function mapStateToProps(state) {
  return {
    currentLang: state.root.currentLang
  };
}

export default connect(mapStateToProps)(UserPage);
