import React, { Component } from "react";
import { connect } from "react-redux";
import { ButtonToolbar, Button, Badge, ListGroup } from "react-bootstrap";
import "./index.scss";
import * as contentsService from "../../../services/contents";
import * as commonService from "../../../services/common";
import * as jsonService from "../../../services/json";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBars} from "@fortawesome/free-solid-svg-icons";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";

class SimpleFlowTestListPage extends Component {

  constructor(props, context) {
    super(props, context);

    this.state = {
      contentType: "SIMPLE_FLOW_TEST",
      isEdit: false,
      loading: false,
      error: null,
      items: [],
      oldItems: [],
    };
  }

  componentDidMount() {
    this._isMounted = true;
    this.fetchContents();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  fetchContents = async () => {
    if (!this._isMounted) {
      return;
    }
    this.setState({ loading: true, error: null });
    try {
      let setting = await contentsService.fetchContentSetting(this.state.contentType);
      let response = await contentsService.fetchContents();
      let items = response.items.filter((item) => item.contentType === this.state.contentType);
      if (setting) {
        let order = JSON.parse(setting.order);
        let newList = [];
        for (let i = 0; i < order.length; i++) {
          let index = items.findIndex(item => item.id === order[i]);
          if (items[index]) {
            newList.push(items[index]);
          }
        }
        items = newList;
      }

      if (this._isMounted) {
        this.setState({ items: items, loading: false });
      }
    } catch (e) {
      console.error(e);
      if (this._isMounted) {
        this.setState({loading: true, error: e});
      }
    }
  };

  onCreateClick = () => {
    this.props.history.push("/admin/test/simple-flow/new");
  };

  onEditClick = (item) => {
    this.props.history.push(`/admin/test/simple-flow/${item.id}`);
  };

  onDragEnd = async (result) => {
    if (!result.destination) return;

    // Update local list
    const items = Array.from(this.state.items);
    const [removed] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, removed);
    this.setState({ items });
  };

  onEditOrder = () => {
    const {items} = this.state;
    this.setState({oldItems: [...items], isEdit: true})
  };

  onEditOrderCancel = () => {
    const {oldItems} = this.state;
    this.setState({items: [...oldItems], isEdit: false});
  };

  onEditOrderDone = async () => {
    const {items} = this.state;

    let order = items.map(item => item.id);
    await contentsService.updateContentSetting({id: this.state.contentType, order: JSON.stringify(order)})

    this.setState({oldItems: [], isEdit: false});
  };

  renderSimpleList = () => {
    const {items} = this.state;
    return (
      <ListGroup className="mt-3">
        {items.map((item, index) => (

          <div
            key={item.id}
            className="d-flex justify-content-between align-items-center list-group-item pointer"
            onClick={() => this.onEditClick(item)}
          >
            <div>
              <FontAwesomeIcon className="text-secondary mr-2 grab" icon={faBars} />
              {JSON.parse(item.title)[commonService.mainLanguage] + " "}
              {JSON.parse(item.languages).map((language) => (
                <Badge key={language} variant="info" className="mr-1">{commonService.languages[language]}</Badge>
              ))}
            </div>
          </div>
        ))}
        {!this.state.loading && this.state.items.length === 0 && (
          <ListGroup.Item>
            何も見つかりませんでした
          </ListGroup.Item>
        )}
      </ListGroup>
    );
  };

  renderDraggableList = () => {
    return (
      <ListGroup className="mt-3">

        <DragDropContext onDragEnd={this.onDragEnd}>
          <Droppable droppableId="droppable">
            {(provided, snapshot) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                {this.state.items.map((item, index) => (
                  <Draggable key={item.id} draggableId={item.id} index={index}>
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        className="d-flex justify-content-between align-items-center list-group-item pointer"
                        onClick={() => this.onEditClick(item)}
                      >
                        <div>
                          <FontAwesomeIcon className="text-secondary mr-2 grab" icon={faBars} />
                          {JSON.parse(item.title)[commonService.mainLanguage] + " "}
                          {JSON.parse(item.languages).map((language) => (
                            <Badge key={language} variant="info" className="mr-1">{commonService.languages[language]}</Badge>
                          ))}
                        </div>
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
        {!this.state.loading && this.state.items.length === 0 && (
          <ListGroup.Item>
            何も見つかりませんでした
          </ListGroup.Item>
        )}
      </ListGroup>
    );
  };

  render() {
    const {isEdit} = this.state;
    return (
      <>
        <ButtonToolbar className="mt-4 justify-content-between align-items-center">
          <h1 className="m-0">公式練習問題集一覧</h1>
          {!isEdit && (
            <div className="d-flex">
              <Button variant="primary" className="mr-2" onClick={this.onEditOrder}>順番変更</Button>
              <Button variant="primary" onClick={this.onCreateClick}>制作</Button>
            </div>
          )}
          {isEdit && (
            <div className="d-flex">
              <Button variant="outline-primary mr-2" onClick={this.onEditOrderCancel}>キャンセル</Button>
              <Button variant="primary" className="mr-2" onClick={this.onEditOrderDone}>保存</Button>
            </div>
          )}
        </ButtonToolbar>

        {!isEdit && this.renderSimpleList()}
        {isEdit && this.renderDraggableList()}
      </>
    )
  };
}

function mapStateToProps(state) {
  return {};
}

export default connect(mapStateToProps)(SimpleFlowTestListPage);
