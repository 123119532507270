import React, { Component } from "react";
import { connect } from "react-redux";
import {Button, ButtonToolbar, ListGroup} from "react-bootstrap";
import * as userService from "../../../services/users";
import * as commonService from "../../../services/common";
import * as nursingResultsService from "../../../services/nursing-results";
import {Auth} from "aws-amplify";
import * as nursingResultsMiddleware from "../../../services/nursing/results";
import * as csvService from "../../../services/csv";

class UsersPage extends Component {

  constructor(props, context) {
    super(props, context);

    this.state = {
      loading: true,
      error: null,
      items: [],
      isUpload: false,
      uploadProgress: 0,
      uploadError: null
    };
  }

  async componentWillMount() {
    await this.fetchUsers();
  }

  fetchUsers = async () => {
    this.setState({ loading: true, error: null });
    try {
      let response = await userService.fetchUsers();
      let items = response.items.filter(item => item.userGroup === "USER");
      items = items.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
      this.setState({ items: items, loading: false });
    } catch (e) {
      console.error(e);
      this.setState({ loading: true, error: e });
    }
  };

  onViewClick = (item) => {
    this.props.history.push(`/admin/users/${item.id}`);
  };

  onDownload = async () => {

    const  nursingResultsResponse = await nursingResultsService.fetchAdminNursingResults();
    const items = nursingResultsResponse.items.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

    let filtered = [];
    items.forEach(item => {
      if (!item.user) {
        return;
      }
      if (filtered.findIndex(filteredItem => filteredItem.user.id === item.user.id) !== -1) {
        return;
      }

      filtered.push(item)
    })

    let schema = [
      {label: "受講者番号", path: "id"},
      {label: "受講者名漢字", path: "nickname"},
      {label: "受講者名かな", path: "nicknameKana"}, // ?
      {label: "タイプNO.", path: "numberType"}, // ?
      {label: "タイプ名", path: "textType"},
      {label: "正の点数", path: "totalPositiveStroke", type: "number"},
      {label: "負の点数", path: "totalNegativeStroke", type: "number"},
      {label: "Dの点数", path: "totalDiscount", type: "number"},
      {label: "状況想像力点数", path: "totalImagination", type: "number"},
      {label: "リアクション力点数", path: "totalReaction", type: "number"},
      {label: "共感力点数", path: "totalEmpathy", type: "number"},
      {label: "気持ち察し力点数", path: "totalFeeling", type: "number"},
      {label: "柔軟性点数", path: "totalFlexibility", type: "number"},
    ];

    let data = [];

    for (let testIndex = 0; testIndex < filtered.length; testIndex++) {
      const item = filtered[testIndex];


      const nickname = item.user && item.user.name ? item.user.name : null ;
      const {
        textType,
        totalPositiveStroke,
        totalNegativeStroke,
        totalDiscount,
        totalFlexibility,
        totalImagination,
        totalReaction,
        totalEmpathy,
        totalFeeling,
      } = nursingResultsMiddleware.buildResult({results: item.results && item.results.items ? item.results.items : [] , id: item.id, isFree: false, nickname})


      data.push({
        id: item.id,
        nickname,
        nicknameKana: null,
        numberType: null,
        textType,
        totalPositiveStroke,
        totalNegativeStroke,
        totalDiscount,
        totalImagination,
        totalReaction,
        totalEmpathy,
        totalFeeling,
        totalFlexibility,
      });
    }

    let csv = csvService.prepareCsv(schema, data);
    csvService.downloadCsv(csv, `nursing-test-results-${new Date().toISOString()}.csv`);
  };

  render() {
    return (
      <>
        <ButtonToolbar className="mt-4 justify-content-between align-items-center">
          <h1 className="m-0">ユーザー一覧</h1>
          <div className="d-flex">
            <Button variant="outline-primary mr-2" onClick={this.onDownload}>ダウンロード</Button>
          </div>
        </ButtonToolbar>

        <ListGroup className="mt-3">
          {this.state.items.map(item => (
            <ListGroup.Item key={item.id} className="d-flex justify-content-between align-items-center pointer" onClick={() => this.onViewClick(item)}>
                <div>{item.name ? item.name : "未定"} ({item.email}) : {item.parent && item.parent.name ? item.parent.name : "未定"}</div>
                <div>{commonService.formatDate(item.createdAt)}</div>
            </ListGroup.Item>
          ))}
          {this.state.items.length === 0 && (
            <ListGroup.Item>
              何も見つかりませんでした
            </ListGroup.Item>
          )}
        </ListGroup>
      </>
    )
  };
}

function mapStateToProps(state) {
  return {};
}

export default connect(mapStateToProps)(UsersPage);
