export const GetAccount = `
    query {
        getAccount {
            name
            email
            childMaxCount
            disabled
            permissions
            parent {
                id
                permissions
                disabled
            }
        }
    }
`;

export const GetAccountSettings = `
  query {
    getAccountSettings
  }
`;

export const UpdateAccountSettings = `
  mutation UpdateAccountSettings($settings: String!) {
    updateAccountSettings(settings: $settings) 
  }
`;

export const GetAccountReadNotifications = `
    query {
        getAccountReadNotifications
    }
`;

export const UpdateAccountReadNotifications = `
    mutation UpdateAccountReadNotifications($readNotifications: String!) {
        updateAccountReadNotifications(readNotifications: $readNotifications)
    }
`;

export const GetAccountSubtleResults = `
    query {
        getAccountSubtleResults
    }
`;

export const UpdateAccountSubtleResults = `
    mutation UpdateAccountSubtleResults($subtleResults: String!) {
        updateAccountSubtleResults(subtleResults: $subtleResults)
    }
`;
