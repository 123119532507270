export const texts = {
  "notifications": { jp: "お知らせ", jp2: "お知らせ", en: "Notifications", vn: 'Thông báo' },
  "menu": { jp: "メニュー", jp2: "メニュー", en: "Menu", vn: 'Danh mục' },
  "account-user-sufix": { jp: "さん", jp2: "さん", en: "'s", vn: '' },
  "account": { jp: "のアカウント", jp2: "のアカウント", en: " account", vn: 'Tài khoản của' },
  "home": { jp: "ホーム", jp2: "ホーム", en: "Home", vn: 'Trang chủ' },
  "terms": { jp: "利用規約", jp2: "利用規約", en: "Terms", vn: 'Điều khoản sử dụng' },
  "policy": { jp: "プライバシーポリシー", jp2: "プライバシーポリシー", en: "Private Policy", vn: 'Chính sách bảo mật' },
  "faq": { jp: "よくある質問・お問い合わせ", jp2: "よくある質問・お問い合わせ", en: "FAQ", vn: '?' },
  "logout": { jp: "ログアウト", jp2: "ログアウト", en: "Sign out", vn: 'Đăng xuất' },
  "toMarket": { jp: "マーケットへ", jp2: "マーケットへ", en: "Back to Market", vn: 'Đi đến chợ' },
  "lang": { jp: "", jp2: "", en: "", vn: '' },

  "study": { jp: "勉強する", jp2: "勉強する", en: "Study", vn: 'Học' },
  "learnByManga": { jp: "まんがで学ぶ", jp2: "まんがで学ぶ", en: "Learn by Manga", vn: 'Học qua truyện tranh' },
  "abilityTest": { jp: "診断する", jp2: "診断する", en: "Ability test", vn: 'Kiểm tra khả năng' },
  "logResults": { jp: "履歴・成績", jp2: "履歴・成績", en: "Log・Results", vn: 'Lược sử.Kết quả' },
  "movies": { jp: "動画で学ぶ", jp2: "動画で学ぶ", en: "動画で学ぶ", vn: '動画で学ぶ' },
  "askAQuestion": { jp: "質問する", jp2: "質問する", en: "Ask a question", vn: 'Câu hỏi' },
  "continue": { jp: "続きから", jp2: "続きから", en: "Continue", vn: '?' },
  "practice": { jp: "練習", jp2: "練習", en: "Practice", vn: 'Luyện tập' },
  "test": { jp: "Test", jp2: "Test", en: "Test", vn: 'Kiểm tra' },

  "answer": { jp: "あなたの答えは？", jp2: "あなたの答えは？", en: "Answer is?", vn: 'Câu trả lời của bạn là gì' },
  "pointOfJapaneseCulturesAndCustoms": { jp: "日本の慣習ポイント", jp2: "日本の慣習ポイント", en: "Point of Japanese cultures and customs", vn: 'Điểm mấu chốt về phong tục nhật bản' },
  "pointOfBehaviorAndReaction": { jp: "振る舞い方のポイント", jp2: "振る舞い方のポイント", en: "Point of behavior and reaction", vn: 'Điểm mấu chốt về cách ứng xử ' },
  "goToTheNextQuestion": { jp: "次の練習問題へ", jp2: "次の練習問題へ", en: "Go to the Next question", vn: 'Câu hỏi tiếp theo' },
  "takeATest": { jp: "テストを受ける", jp2: "テストを受ける", en: "Take a test", vn: 'Làm bài kiểm tra' },
  "nextCourse": { jp: "次のコースへ", jp2: "次のコースへ", en: "Go to the Next course", vn: 'Khoá học tiếp theo ' },
  "notFound": { jp: "練習問題は見つかりませんでした", jp2: "練習問題は見つかりませんでした", en: "Practice questions couldn't be found", vn: '?' },

  "wellDone": { jp: "よくできました", jp2: "よくできました", en: "Well Done", vn: 'Làm tốt lắm' },
  "almost": { jp: "あと[一歩,いっぽ]", jp2: "あと[一歩,いっぽ]", en: "Almost", vn: 'Còn một chút nữa' },
  "tryAgain": { jp: "もう[一度考,いちどかんが]えてみよう", jp2: "もう[一度考,いちどかんが]えてみよう", en: "Try Again", vn: 'Thử lại lần nữa' },

  "kiiibin": { jp: "キービン", jp2: "キービン", en: "Keyvin", vn: '?' },

  "subtle-start-text-1": { jp: "あなたの「社会人機微力」を測定します。", jp2: "あなたの「社会人機微力」を測定します。", en: "Let' s check your \"KIBI ability\"", vn: 'Kiểm tra năng lực tác phong người đi làm.' },
  "subtle-start-text-2": { jp: "問題は全部で@?問。", jp2: "問題は全部で@?問。", en: "There are @? Questions and", vn: 'Có @? câu hỏi và thời gian giới hạn là' },
  "subtle-start-text-3-1": { jp: "制限時間は", jp2: "制限時間は", en: "your time limit is ", vn: '' },
  "subtle-start-text-3-2": { jp: "分", jp2: "分", en: " minutes", vn: '' },
  "subtle-start-text-3-3": { jp: "です。", jp2: "です。", en: ".", vn: 'phút' },
  "subtle-start": { jp: "はじめる", jp2: "はじめる", en: "Start", vn: 'Bắt đầu' },
  "subtle-intro-title": { jp: "人物相関図", jp2: "人物相関図", en: "Correlation chart", vn: 'Sơ đồ các mối quan hệ' },
  "subtle-intro-next": { jp: "進む", jp2: "進む", en: "Go on to the next page", vn: 'Trang tiếp theo' },
  "subtle-intro-back": { jp: "戻る", jp2: "戻る", en: "Back", vn: 'Quay lại' },
  "subtle-view-map": { jp: "人物相関図を見る", jp2: "人物相関図を見る", en: "See the correlation chart", vn: 'Xem biểu đồ mối quan hệ các nhân vật' },
  "subtle-title": { jp: "どう捉えておくのが良いでしょうか？", jp2: "どう捉えておくのが良いでしょうか？", en: "How should you understand?", vn: '?' },
  "subtle-question": { jp: "問@?", jp2: "問@?", en: "Question @?", vn: 'Question  @?' },
  "subtle-finish-title": { jp: "お疲れさまでした。", jp2: "お疲れさまでした。", en: "Thank you for your hard work", vn: 'Bạn đã vất vả rồi !' },
  "subtle-finish-back": { jp: "問題に戻る", jp2: "問題に戻る", en: "Go back to the TEST Part", vn: 'Quay trở lại câu hỏi' },
  "subtle-finish-results": { jp: "提出して結果を見る", jp2: "提出して結果を見る", en: "Submit and check your result", vn: 'Xem kết quả nộp' },
  "subtle-results-title-0": { jp: "診断結果", jp2: "診断結果", en: "Result", vn: 'Kết quả kiểm tra' },
  "subtle-results-title-1": { jp: "あなたの「社会人機微力」総合得点は", jp2: "あなたの「社会人機微力」総合得点は", en: "Your KIBI Ability score is", vn: 'Số điểm của bạn về “Mức độ nhạy cảm đối với xã hội” là ' },
  "subtle-results-title-2": { jp: "@?点です", jp2: "@?点です", en: "@? points.", vn: '@? điểm.' },
  "subtle-results-title-3": { jp: "あなたの@?は @? 点です。", jp2: "あなたの@?は @? 点です。", en: "Your '@?' is @? points.", vn: '@? @?' },
};

export const translate = (key, lang, args = []) => {
  if (typeof key === 'object') {
    let value = key.hasOwnProperty(lang) ? key[lang] : "unknown";

    for (let i = 0; i < args.length; i++) {
      value = value.replace("@?", args[i]);
    }

    return value;
  } else {
    let value = texts.hasOwnProperty(key) && texts[key].hasOwnProperty(lang) ? texts[key][lang] : "unknown";

    for (let i = 0; i < args.length; i++) {
      value = value.replace("@?", args[i]);
    }

    return value;
  }

};
