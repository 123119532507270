import React, { Component } from "react";
import {Alert, Button, ButtonToolbar, Dropdown} from "react-bootstrap";
// import {Alert, Button, ButtonToolbar, Card, Dropdown, DropdownButton, Form} from "react-bootstrap";
import { SmallBgLoading } from "../../../components/commons/SmallBgLoading"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck, faMinusCircle, faPlusCircle} from "@fortawesome/free-solid-svg-icons";
// import uuid from "uuid";
// import { Storage } from "aws-amplify";
import * as commonService from "../../../services/common";
import * as contentsService  from "../../../services/contents";

// ----------------------------------------------------------------------
// class SubtleTestPage extends Component {
class TestAaPage extends Component {

// ----------------------------------------------------------------------
  constructor(props, context) {
    super(props, context);

    this.state = {
      contentId: 'SUBTLE',
      comicIntroductionSvgUrl: { separatedLanguage: true, required: true },
      questions: {
        image: {
          separatedLanguage: true,
          required: true
        },
        text: {
          separatedLanguage: true,
          required: true
        },
        subtitle: {
          separatedLanguage: true,
          required: true
        },
        imagination: {
          separatedLanguage: false,
          required: true
        },
        reaction: {
          separatedLanguage: false,
          required: true
        },
        empathy: {
          separatedLanguage: false,
          required: true
        },
        feeling: {
          separatedLanguage: false,
          required: true
        },
        flexibility: {
          separatedLanguage: false,
          required: true
        },
        answer1: {
          separatedLanguage: true,
          required: true
        },
        answer1IsCorrect: {
          separatedLanguage: false,
          required: false
        },
        answer2: {
          separatedLanguage: true,
          required: true
        },
        answer2IsCorrect: {
          separatedLanguage: false,
          required: false
        },
        answer3: {
          separatedLanguage: true,
          required: true
        },
        answer3IsCorrect: {
          separatedLanguage: false,
          required: false
        },
        value: [...Array(30).keys()].map(index => { return {}; }),
        multiply: true
      },
      selectedQuestion: 0,
      languages: [],
      selectedLanguage: null,
      data: null,

      error: null,
      loading: false,
    };
  }

// ----------------------------------------------------------------------
  async componentWillMount() {
    this.setState({loading: true});

    try {
      let state = {loading: false};
      let content = await contentsService.fetchContent(this.state.contentId);
      if (content) {
        state.data = content;
        state.languages = JSON.parse(state.data.languages);

        let fields = ["comicIntroductionSvgUrl"];
        for (const field of fields) {
          if (this.state[field].separatedLanguage) {
            for (let langIndex in state.languages) {
              if (!state[field]) state[field] = {...this.state[field]};
              try {
                state[field][state.languages[langIndex]] = JSON.parse(state.data[field])[state.languages[langIndex]];
              } catch (e) {}
            }

          } else {
            state[field] = { value: state.data[field], ...this.state[field]};
          }
        }

        let settings = JSON.parse(state.data.settings);
        state.questions = this.state.questions;
        state.questions.value = settings.questions;
      } else {
        state.languages = [commonService.mainLanguage];
      }

      state.selectedLanguage = commonService.mainLanguage;

      this.setState(state);
    } catch (error) {
      this.onError(error);
    }
  }

  onError = (error) => {
    if (error.errors) {
      console.error(error.errors[0]);
      this.setState({ loading: false, error: error.errors[0] });
    } else {
      console.error(error);
      this.setState({ loading: false, error });
    }
  };

  onAddLanguage = (lang) => {
    let languages = this.state.languages;
    languages.push(lang);
    this.setState({ languages, selectedLanguage: lang });
  };

  onDeleteLanguage = (lang) => {
    let languages = this.state.languages.filter((item) => item !== lang);
    this.setState({ languages });
  };

  onChangeLanguage = (lang) => {
    if (this.state.selectedLanguage !== lang) {
      this.setState({ selectedLanguage: lang });
    }
  };


// ----------------------------------------------------------------------
  onDoneClick = async () => {

    try {
      let fields = ["comicIntroductionSvgUrl"];
      let input = {};
      for (const field of fields) {
        if (this.state[field].separatedLanguage) {
          let values = {};
          for (let langIndex in this.state.languages) {
            let value = this.state[field][this.state.languages[langIndex]];
            values[this.state.languages[langIndex]] = value ? value : null;
          }
          input[field] = JSON.stringify(values);
        } else {
          input[field] = this.state[field].value ? this.state[field].value : null;
        }
      }

      input.title = this.state.contentId;
      input.languages = JSON.stringify(this.state.languages);
      input.settings = JSON.stringify({questions: this.state["questions"].value});

      input.contentType = "SUBTLE";
      input.id = this.state.contentId;

      await contentsService.createContent(input);

      this.setState({loading: false});

      this.props.history.push(`/admin/`);
    } catch (error) {
      this.onError(error);
    }
  };

// ----------------------------------------------------------------------
  renderToolbar = () => {
    return (
      <ButtonToolbar className="mt-4 justify-content-between align-items-center">
        <h1 className="m-0">テストAa Jul/25/2022 AM 08:04</h1>
        <div className="d-flex">
          <Dropdown className="mr-2">
            <Dropdown.Toggle variant="success">
              {commonService.languages[this.state.selectedLanguage]}
            </Dropdown.Toggle>

            <Dropdown.Menu>
              {this.state.languages.map((key) => (
                <Dropdown.Item
                  key={key}
                  className="d-flex justify-content-between align-items-center"
                >
                  <div onClick={() => this.onChangeLanguage(key)}>{commonService.languages[key]}</div>
                  {this.state.selectedLanguage === key && (
                    <div>
                      <FontAwesomeIcon className="text-secondary" icon={faCheck} />
                    </div>
                  )}
                  {this.state.selectedLanguage !== key && (
                    <div onClick={() => this.onDeleteLanguage(key)}>
                      <FontAwesomeIcon className="icon-hover icon-danger" icon={faMinusCircle} />
                    </div>
                  )}
                </Dropdown.Item>
              ))}
              {Object.keys(commonService.languages).filter(item => !this.state.languages.includes(item)).length > 0 && (<Dropdown.Divider />)}
              {Object.keys(commonService.languages).filter(item => !this.state.languages.includes(item)).map((key) => (
                <Dropdown.Item key={key}
                               className="d-flex justify-content-between align-items-center"
                               onClick={() => this.onAddLanguage(key)}>
                  <div>{commonService.languages[key]}</div>
                  <FontAwesomeIcon className="icon-hover text-success" icon={faPlusCircle} />
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>

          <Button variant="primary"
                  type="submit"
                  className="mr-2"
                  onClick={this.onDoneClick}>保存</Button>
        </div>
	    <Button>Hello Jul/07</Button>
      </ButtonToolbar>
    );
  };


// ----------------------------------------------------------------------
  render() {
    return (
      <>
        <SmallBgLoading isLoading={this.state.loading}>
          {this.state.error && (<Alert variant="danger" className="mt-4">{this.state.error.message}</Alert>)}
          {this.renderToolbar()}
        </SmallBgLoading>
      </>
    );
  }
}

// ----------------------------------------------------------------------
export default TestAaPage;
