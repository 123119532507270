import { API, graphqlOperation } from "aws-amplify";
import { GetNotifications, GetNotification, CreateNotification, UpdateNotification, DeleteNotification } from "./graphql/notifications";

export const fetchNotifications = async () => {
  const response = await API.graphql(graphqlOperation(
    GetNotifications,
    {},
    "AMAZON_COGNITO_USER_POOLS"
  ));

  return response.data.getNotifications;
};

export const fetchNotification = async (id) => {
  const response = await API.graphql(graphqlOperation(
    GetNotification,
    { id },
    "AMAZON_COGNITO_USER_POOLS"
  ));

  return response.data.getNotification;
};

export const createNotification = async (input) => {
  const response = await API.graphql(graphqlOperation(
    CreateNotification,
    { input },
    "AMAZON_COGNITO_USER_POOLS"
  ));

  return response.data.createNotification;
};

export const updateNotification = async (input) => {
  const response = await API.graphql(graphqlOperation(
    UpdateNotification,
    { input },
    "AMAZON_COGNITO_USER_POOLS"
  ));

  return response.data.updateNotification;
};

export const deleteNotification = async (id) => {
  const response = await API.graphql(graphqlOperation(
    DeleteNotification,
    { id },
    "AMAZON_COGNITO_USER_POOLS"
  ));

  return response.data.deleteNotification;
};
