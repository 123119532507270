import * as types from "./actionTypes";
import * as notificationsService from '../../services/notifications';
import * as accountService from "../../services/account";

export function setFullLoading (loading) {
  return dispatch => dispatch({ type: types.SET_FULL_LOADING, loading });
}

export function setLang (lang) {
  return dispatch => dispatch({ type: types.SET_LANG, lang });
}

export function setComicLifeIntro (intro) {
  return dispatch => dispatch({ type: types.SET_COMIC_LIFE_INTRO, intro });
}

export function setComicLifeIds (ids) {
  return dispatch => dispatch({ type: types.SET_COMIC_LIFE_IDS, ids });
}

export function setComicSpiritIds (ids) {
  return dispatch => dispatch({ type: types.SET_COMIC_SPIRIT_IDS, ids });
}

export function fetchNotifications() {
  return async (dispatch, getState) => {
    try {

      let notifications = await notificationsService.fetchNotifications();
      let notificationsList = notifications.items.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
      let generalNotifications = notificationsList.filter(item => item.type === 'GENERAL');
      let kibiinNotifications = notificationsList.filter(item => item.type === 'KIBIIN');

      let accountReadNotifications = await accountService.fetchAccountReadNotifications();
      accountReadNotifications = accountReadNotifications ? JSON.parse(accountReadNotifications) : [];

      dispatch({ type: types.SET_NOTIFICATIONS, list: generalNotifications });
      dispatch({ type: types.SET_KIBIIN_NOTIFICATIONS, kibiin: kibiinNotifications });
      dispatch({ type: types.SET_READ_NOTIFICATIONS, readed: accountReadNotifications });
    } catch (error) {
      console.log('error', error);
    }
  };
}

export function updateReadNotifications(readNotifications) {
  return async (dispatch, getState) => {
    try {

      await accountService.updateAccountReadNotifications({
        readNotifications: JSON.stringify(readNotifications)
      });

      let notifications = await notificationsService.fetchNotifications();
      let notificationsList = notifications.items.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
      let generalNotifications = notificationsList.filter(item => item.type === 'GENERAL');
      let kibiinNotifications = notificationsList.filter(item => item.type === 'KIBIIN');

      let accountReadNotifications = await accountService.fetchAccountReadNotifications();
      accountReadNotifications = accountReadNotifications || [];

      dispatch({ type: types.SET_NOTIFICATIONS, list: generalNotifications });
      dispatch({ type: types.SET_KIBIIN_NOTIFICATIONS, kibiin: kibiinNotifications });
      dispatch({ type: types.SET_READ_NOTIFICATIONS, readed: accountReadNotifications });
    } catch (error) {
      console.log('error', error);
    }
  };
}
