export const GetUsers = `
  query GetUsers($first: Int, $after: String) {
    getUsers(first: $first, after: $after) {
      items {
        id
        userGroup
        name
        department
        manager
        childMaxCount
        email
        parentId
        permissions
        createdAt
          disabled
        parent {
          id
          name
            disabled
        }
      }
      nextToken
    }
  }
`;

export const GetUser = `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      userGroup
      name
      department
      manager
      childMaxCount
      email
      permissions
      createdAt
        disabled
      parent {
        id
        name
          disabled
      }
      subtleResults
    }
  }
`;

export const CreateUser = `
  mutation CreateUser($input: UserCreateInput!) {
    createUser(input: $input) {
      id
      userGroup
      email
      createdAt
    }
  }
`;

export const CreateUserManager = `
  mutation CreateUserManager($input: UserManagerCreateInput!) {
    createUserManager(input: $input) {
      id
    }
  }
`;

export const UpdateUser = `
  mutation UpdateUser($input: UserUpdateInput!) {
    updateUser(input: $input) {
      id
      userGroup
      email
      createdAt
    }
  }
`;

export const UpdateUserManager = `
  mutation UpdateUserManager($input: UserManagerUpdateInput!) {
    updateUserManager(input: $input) {
      id
    }
  }
`;

export const DeleteUser = `
  mutation DeleteUser($id: ID!) {
    deleteUser(id: $id)
  }
`;

export const DisableUser = `
    mutation DisableUser($id: ID!) {
        disableUser(id: $id) {
            id
        }
    }
`;

export const EnableUser = `
    mutation EnableUser($id: ID!) {
        enableUser(id: $id) {
            id
        }
    }
`;

export const OnUpdateUserStatus = `
    subscription OnUpdateUserStatus($id: ID, $parentId: ID) {
        onUpdateUserStatus(id: $id, parentId: $parentId) {
            id
            userGroup
            name
            department
            manager
            childMaxCount
            email
            permissions
            createdAt
            parentId
        }
    }
`;
