import React from "react";
import { Spinner } from "react-bootstrap";
import { TransitionGroup, CSSTransition } from "react-transition-group";

export const SmallBgLoading = props => (
  <div style={{ position: "relative" }}>
    <TransitionGroup>
    {props.isLoading && (
      <CSSTransition classNames="fadeIn" timeout={1000}>

          <div
            style={{
              position: "absolute",
              top: 0,
              right: 0,
              left: 0,
              bottom: 0,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              zIndex: 1,
              // backgroundColor: "rgba(0, 0, 0, 0.1)",
              // boxShadow: "0px 0px 30px rgba(0, 0, 0, 0.2)",
              // borderRadius: "15%",
              // margin: -16
            }}
          >
            <Spinner animation="grow" variant="primary" size="lg" />
          </div>

      </CSSTransition>
      )}
    </TransitionGroup>

    {props.children}
  </div>
);
