import React, { Component } from "react";
import { connect } from "react-redux";
import {Container, Navbar, Nav, Button, ProgressBar, ListGroup, NavDropdown, Form} from "react-bootstrap";
import * as contentsService from '../../../services/contents';
import * as nursingResultsService from '../../../services/nursing-results';
import * as nursingFreeResultsService from '../../../services/nursing-free-results';
import 'react-svg-radar-chart/build/css/index.css'
import "./index.scss";
import * as commonService from "../../../services/common";
import * as langService  from "../../../services/lang";
import * as accountService from "../../../services/account";
import * as nursingResultsMiddleware from "../../../services/nursing/results";
import uuid from "uuid";
import {Auth} from "aws-amplify";
import {
  FacebookShareButton,
  TwitterShareButton,
} from "react-share";

class NursingPage extends Component {

  constructor(props, context) {
    super(props, context);

    this.state = {
      questions: [],
      maxTime: 30 * 60 * 1000,
      time: 0,
      startTime: null,

      isLanding: false,
      isQuestion: false,
      isAnket: false,
      isFinished: false,
      isResults: false,
      isShowTimer: false,
      data: null,
      results: [],
      resultsOther: null,
      questionIndex: 0,
      resultsSelectedIndex: 0,

      account: null,
      id: null,

      anketName: {},
      anketGender: {},
      anketAge: {},
      anketLocation: {},


      touched: false,
      loading: false,
    };
  }

  async componentDidMount() {
    this.setState({loading: true});
    try {
      await Auth.currentAuthenticatedUser();
      let accountResponse = await accountService.fetchAccount();
      this.setState({account: accountResponse})
    } catch (e) {
      // do noting
    }
    this.fetchData();
  }

  fetchData = async() => {
    const {testId} = this.props.match.params;
    try {
      let content = await contentsService.fetchContent(this.state.account ? 'NURSING' : 'NURSING_FREE');

      let results = [];
      let resultsOther = null;
      if (testId) {
        if (this.state.account) {
          let resultsResponse = await nursingResultsService.fetchNursingResult(testId);
          let tmpResults = JSON.parse(resultsResponse.results);
          let {items, ...other} = tmpResults;
          results = items
          resultsOther = other;
        } else {
          let resultsResponse = await nursingFreeResultsService.fetchNursingFreeResult(testId);
          let tmpResults = JSON.parse(resultsResponse.results);
          let {items, ...other} = tmpResults;
          results = items
          resultsOther = other;
        }

      }

      if (content) {
        content.settings = JSON.parse(content.settings);
      }

      let state = {
        loading: false,
        results,
        resultsOther,
      };
      state.data = content;
      if (results.length > 0) {
        state.isResults = true;
      } else if (content) {
        state.isLanding = true;
      }

      this.setState(state);

    } catch (e) {
      console.log(e);
    }
  };

  componentWillUnmount() {
    if (this.timer) {
      clearInterval(this.timer);
    }
  }

  onStart = () => {
    // this.startTimer();
    // this.setState({isLanding: false, isQuestion: true, isShowTimer: true});
    this.setState({isLanding: false, isQuestion: true});
  };

  onCancel = () => {
    const {account} = this.state;
    window.open(account　? "/" : "https://kibi-global.jp/kidukky/")
  };

  onQuestionBack = () => {
    const { results } = this.state;
    let newResults = [...results]
    if (newResults.length > 0) {
      newResults.splice((newResults.length - 1, 1));
    }
    this.setState({questionIndex: this.state.questionIndex - 1, results: newResults});
  };

  onAnketBack = () => {
    const { results } = this.state;
    let newResults = [...results]
    if (newResults.length > 0) {
      newResults.splice((newResults.length - 1, 1));
    }
    this.setState({results: newResults, isAnket: false, isQuestion: true});
  };

  onFinishedBack = () => {
    this.setState({isQuestion: true, isFinished: false})
  };

  onFinishedResults = async () => {
    const {results, account} = this.state;

    if (this.timer) {
      clearInterval(this.timer);
    }

    try {
      const user = await Auth.currentAuthenticatedUser();
      let id = uuid.v4()
      let input = {
        id,
        userId: user.getUsername(),
        results: JSON.stringify({

          items: results,
        }),
      };
      await nursingResultsService.createNursingResult(input);
      // this.setState({isFinished: false, isShowTimer: false, isResults: true,})
      this.setState({isFinished: false, isResults: true, id})
    } catch (e) {
      console.log(e);
      this.setState({ loading: false });
    }
  };

  onQuestionAnswer = (index) => {
    const {data, results, questionIndex, account} = this.state;
    const question = data.settings.questions[questionIndex];

    const positiveStroke = question[`answer${index}PositiveStroke`] && question[`answer${index}PositiveStroke`].hasOwnProperty('value') ? parseInt( question[`answer${index}PositiveStroke`].value, 0) : 0
    const item = {
      answer: index,
      positiveStroke,
      negativeStroke: question[`answer${index}NegativeStroke`] && question[`answer${index}NegativeStroke`].hasOwnProperty('value') ? parseInt( question[`answer${index}NegativeStroke`].value, 0) : 0,
      discount: question[`answer${index}Discount`] && question[`answer${index}Discount`].hasOwnProperty('value') ? parseInt( question[`answer${index}Discount`].value, 0) : 0,

      flexibility: positiveStroke > 0 ? (question[`flexibility`] && question[`flexibility`].hasOwnProperty('value') ? parseInt( question[`flexibility`].value, 0) : 0) : 0,
      imagination: positiveStroke > 0 ? (question[`imagination`] && question[`imagination`].hasOwnProperty('value') ? parseInt( question[`imagination`].value, 0) : 0) : 0,
      reaction: positiveStroke > 0 ? (question[`reaction`] && question[`reaction`].hasOwnProperty('value') ? parseInt( question[`reaction`].value, 0) : 0) : 0,
      empathy: positiveStroke > 0 ? (question[`empathy`] && question[`empathy`].hasOwnProperty('value') ? parseInt( question[`empathy`].value, 0) : 0) : 0,
      feeling: positiveStroke > 0 ? (question[`feeling`] && question[`feeling`].hasOwnProperty('value') ? parseInt( question[`feeling`].value, 0) : 0) : 0,
    };

    if (results[questionIndex]) {
      results[questionIndex] = item;
      this.setState({results, questionIndex: questionIndex + 1})
    } else {
      results.push(item);

      if (questionIndex + 1 < (account ? 30 : 10)) {
        this.setState({results, questionIndex: questionIndex + 1})
      } else {
        if (account) {
          this.setState({results, isQuestion: false, isFinished: true})
        } else {
          this.setState({results, isQuestion: false, isAnket: true})
        }
      }
    }

  };

  isSelectedAnswer = (index) => {
    const {results, questionIndex} = this.state;
    if (results[questionIndex]) {
      return results[questionIndex].answer === index;
    } else {
      return false;
    }
  };

  startTimer = () => {
    this.setState({
      startTime: new Date()
    });
    this.timer = setInterval(() => this.setState({
      time: Date.now() - this.state.startTime
    }), 1);
  };

  formatByLanguage = (value) => {
    let array = typeof value === "string" ? JSON.parse(value) : value;
    if (array[this.props.currentLang]) {
      return array[this.props.currentLang];
    } else {
      return "Undefined";
    }
  };

  buildTime = () => {
    const currentTime = this.state.maxTime - this.state.time;
    const minutes = '' + (Math.floor(currentTime / 1000 / 60));
    const seconds = '' + (Math.floor(currentTime / 1000) - (minutes * 60));

    return `${minutes.padStart(2, '0')}:${seconds.padStart(2, '0')}`;
  };

  buildMaxTime = () => {
    return Math.floor(this.state.maxTime / 60 / 1000);
  };

  getFormControlValue = (fieldName) => {
    // if (this.state[fieldName].separatedLanguage) {
    //   let value = this.state[fieldName][this.state.selectedLanguage];
    //   return value ? value : "";
    // } else {
      let value = this.state[fieldName].value;
      return value ? value : "";
    // }
  };

  onFormControlChanged = (fieldName, value) => {
    let data = {...this.state[fieldName]};

    // if (this.state[fieldName].separatedLanguage) {
    //   data[this.state.selectedLanguage] = value;
    // } else {
      data.value = value;
    // }

    let state = {};
    state[fieldName] = data;

    this.setState(state);
  };

  formFieldRequiredValidation = (fieldName, isTouched = false) => {
    if (!isTouched && !this.state.touched) {
      return true;
    }

    // if (this.state[fieldName].separatedLanguage) {
    //   for (let i in this.state.languages) {
    //     let value = this.state[fieldName][this.state.languages[i]];
    //     if (!(value && value.length > 0)) return false;
    //   }
    //   return true;
    // } else {
      let value = this.state[fieldName].value;
      return value && value.length > 0;
    // }

  };

  onAnketDone = () => {
    document
      .getElementById('form')
      .dispatchEvent(new Event('submit', { cancelable: true }))
  };

  onAnketSubmit = async (event) => {
    event.preventDefault();
    this.setState({error: null, touched: true});
    if (!this.formFieldRequiredValidation("anketName")) return;
    if (!this.formFieldRequiredValidation("anketGender")) return;
    if (!this.formFieldRequiredValidation("anketAge")) return;
    if (!this.formFieldRequiredValidation("anketLocation")) return;

    const {results} = this.state;

    if (this.timer) {
      clearInterval(this.timer);
    }


    try {
      let id = uuid.v4()
      let input = {
        id,
        userId: `guest-${id}`,
        results: JSON.stringify({
          // totalPositiveStroke,
          // totalNegativeStroke,
          // totalDiscount,
          anket: {
            name: this.getFormControlValue('anketName'),
            gender: this.getFormControlValue('anketGender'),
            age: this.getFormControlValue('anketAge'),
            location: this.getFormControlValue('anketLocation'),
          },
          items: results,
        }),
      };
      await nursingFreeResultsService.createNursingFreeResult(input);
      // this.setState({isFinished: false, isShowTimer: false, isResults: true,})
      this.setState({isAnket: false, isResults: true, id})
    } catch (e) {
      console.log(e);
      this.setState({ loading: false });
    }

  };

  renderHeader = () => {
    const {account} = this.state;
    return (
      <Navbar className="nursing__navbar" fixed="top" expand="lg">
        <Navbar.Brand
          style={{ margin: "-10px 0", paddingTop: 8, paddingBottom: 8 }}
          className="cursor-hover"
        >
          {account ? (
            <a href="/">
              <img src={'/images/nursing/kiduki-logo.png'}
                   width={113}
                   height={56}
              />
            </a>
          ) : (
            <a href="https://kibi-global.jp/kidukky/" target="_blank">
              <img src={'/images/nursing/kiduki-logo.png'}
                   width={113}
                   height={56}
              />
            </a>
          )}

        </Navbar.Brand>
      </Navbar>
    );
  };

  renderLanding = () => {
    const {data} = this.state;
    return (
      <Container className="py-4 mt-auto mb-auto">
        <div className="d-flex flex-column justify-content-center align-items-center mt-auto">
          <div className="nursing-landing__banner">
            <img src={'/images/nursing/kiduki-logo.png'}/>
            きづっきーは<br/>
            全18種類の家電に例えて<br/>
            あなたのコミュニケーションタイプを診断します。<br/>
            <br/>
            あなたは何タイプ？！<br/>
            さぁ診断してみよう！
          </div>
          <Button size="lg" className="nursing-landing__action" onClick={this.onStart}>
            診断スタート
          </Button>
        </div>
      </Container>
    );
  };

  renderQuestion = () => {
    const {data, questionIndex, account} = this.state;
    // const now = Math.floor(questionIndex / data.settings.questions.length * 100);
    const now = Math.floor(questionIndex / (account ? 30 : 10) * 100);
    const question = data.settings.questions[questionIndex]? data.settings.questions[questionIndex] : {};
    // const answers = account ? [1,2,3] : commonService.shuffleArr([1,2,3]);
    const answers = commonService.shuffleArr([1,2,3]);
    return (
      <Container className="py-4 d-flex flex-column" style={{marginTop: 20}}>
        <div className="nursing__header">
          <div className="nursing__header__text">
            {langService.translate('subtle-question', this.props.currentLang, [questionIndex + 1])}
          </div>
          <ProgressBar variant="warning" now={now} label={''} />
        </div>
        <h2 className="nursing-question__title">
          {question.text ? this.formatByLanguage(question.text) : 'Undefined'}
        </h2>
        <div className="nursing-question">
          {account && (
            <div className="nursing-question__image">
              {question.image &&
              <img
                alt="イラスト"
                src={this.formatByLanguage(question.image)} />
              }
            </div>
          )}
          <div className="nursing-question__answers-block">
            <div className="nursing-question__answers-block__title">{question.subtitle ? this.formatByLanguage(question.subtitle) : 'Undefined'}</div>
            {account ? (
              <div className="answers">
                <ListGroup>
                  {answers.map((item) => (
                    <ListGroup.Item key={item} className="pointer" onClick={() => this.onQuestionAnswer(item)}
                                    active={this.isSelectedAnswer(item)}>{this.formatByLanguage(question[`answer${item}`])}</ListGroup.Item>
                  ))}
                </ListGroup>
              </div>
            ) : (
              <div className="nursing-question__answers-block__answers">
                {answers.map((item) => (
                  <div key={item} className="nursing-question__answers-block__answers__item" onClick={() => this.onQuestionAnswer(item)}>{this.formatByLanguage(question[`answer${item}`])}</div>
                ))}
              </div>
            )
            }
          </div>
        </div>
        <div className="nursing-question__answers-block__actions">
          <Button size="lg" variant="outline-secondary" onClick={this.onCancel}>途中でやめる</Button>
          {questionIndex > 0 && <Button size="lg" variant="outline-secondary" className="ml-2" onClick={this.onQuestionBack}>ひとつ戻る</Button>}
        </div>
      </Container>
    );
  };

  renderAnket = () => {
    return (
      <Container className="py-4 d-flex flex-column" style={{marginTop: 20}}>

        <p className="nursing-anket__title">あなたについて、宜ければ教えてください</p>

        <Form id="form" className="mt-4" onSubmit={this.onAnketSubmit}>
          <Form.Group>
            <Form.Label><span className="nursing-anket__label">ニックネーム</span></Form.Label>
            <Form.Control required
                          type="text"
                          value={this.getFormControlValue('anketName')}
                          onChange={(e) => this.onFormControlChanged("anketName", e.target.value)}
                          isInvalid={!this.formFieldRequiredValidation("anketName")} />
            <Form.Control.Feedback type="invalid">
              ニックネームを入力してください
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group>
            <Form.Label><span className="nursing-anket__label">性別</span></Form.Label>
            <Form.Control required={true}
                          as="select"
                          value={this.getFormControlValue('anketGender')}
                          onChange={(e) => this.onFormControlChanged("anketGender", e.target.value)}
                          isInvalid={!this.formFieldRequiredValidation("anketGender")}>

                <option value=""></option>
                <option value="male">男性</option>
                <option value="female">女性</option>
                <option value="other">その他</option>
            </Form.Control>
            <Form.Control.Feedback type="invalid">
              性別を選択してください
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group>
            <Form.Label><span className="nursing-anket__label">年代</span></Form.Label>
            <Form.Control required={true}
                          as="select"
                          value={this.getFormControlValue('anketAge')}
                          onChange={(e) => this.onFormControlChanged("anketAge", e.target.value)}
                          isInvalid={!this.formFieldRequiredValidation("anketAge")}>

              <option value=""></option>
              <option value="10">10代</option>
              <option value="20">20代</option>
              <option value="30">30代</option>
              <option value="40">40代</option>
              <option value="50">50代</option>
              <option value="60">60代</option>
              <option value="70">70代</option>
              <option value="80">80代</option>
              <option value="90">90代</option>
            </Form.Control>
            <Form.Control.Feedback type="invalid">
              性別を選択してください
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group>
            <Form.Label><span className="nursing-anket__label">住んでいるのは</span></Form.Label>
            <Form.Control required={true}
                          as="select"
                          value={this.getFormControlValue('anketLocation')}
                          onChange={(e) => this.onFormControlChanged("anketLocation", e.target.value)}
                          isInvalid={!this.formFieldRequiredValidation("anketLocation")}>

              <option value=""></option>
              {commonService.prefectureList.map((item, index) => (
                <option key={index} value={item.key}>{item.label}</option>
              ))}
            </Form.Control>
            <Form.Control.Feedback type="invalid">
              住んでいるのはを選択してください
            </Form.Control.Feedback>
          </Form.Group>
        </Form>

        <Button size="lg" className="nursing-anket__action" onClick={this.onAnketDone}>
          診断結果を見る
        </Button>

        <div style={{marginTop: 30, display: 'flex', justifyContent: 'center'}}>
          <Button size="lg" variant="outline-secondary" onClick={this.onCancel}>途中でやめる</Button>
          <Button size="lg" variant="outline-secondary" className="ml-2" onClick={() => this.onAnketBack()}>ひとつ戻る</Button>
        </div>

      </Container>
    );
  };

  renderFinished = () => {
    const {account} = this.state;
    return (
      <Container className="py-4 mt-auto mb-auto">
        <div className="d-flex flex-column justify-content-center align-items-center mt-auto">
          <div className="mb-4" style={{fontSize: "20px"}}>お疲れさまでした！</div>
          <div>
            <Button size="lg" style={{
              backgroundColor: '#6FF381',
              fontSize: "25px",
              fontWeight: 'bold',
              border: 'unset',
              borderRadius: 25,
              minWidth: '40vw',
              minHeight: '50px',
              marginTop: 20,
            }} onClick={this.onFinishedResults}>
              {account ? '診断結果を見る' : '提出する'}
            </Button>

            <div style={{marginTop: 30, display: 'flex', justifyContent: 'center'}}>
              <Button size="lg" variant="outline-secondary" onClick={this.onCancel}>途中でやめる</Button>
              <Button size="lg" variant="outline-secondary" className="ml-2" onClick={() => this.setState({isFinished: false, isQuestion: true})}>ひとつ戻る</Button>
            </div>
          </div>
        </div>
      </Container>
    );
  };

  renderFailed = () => {
    return (
      <Container className="py-4 mt-auto mb-auto">
        <div className="d-flex flex-column justify-content-center align-items-center mt-auto">
          <div className="mb-4" style={{fontSize: "20px"}}>時間切れです。<br />
            練習問題を読み直して、再度チャレンジしましょう！</div>
          <div>
            <Button size="lg" className="ml-2">再度チャレンジします</Button>
          </div>
        </div>
      </Container>
    );
  };

  renderResults = () => {
    const {results, id, account} = this.state;

    const nickname = account ? account.name : this.getFormControlValue('anketName');
    const {
      typeImage,
      textTypeLong,
      textBubble,
      textBanner,
      textBannerText,
      textAdvice,
      textShare,
      freeUrl,

      totalCorrect,
      totalPositiveStroke,
      totalNegativeStroke,
      totalDiscount,
    } = nursingResultsMiddleware.buildResult({results, id, isFree: !account, nickname})

    return (
      <div className="nursing-results">
        <div className="nursing-results__container">
          <div className="nursing-results__container__name">{nickname}さんは…</div>
          {/*<div>id: {id}</div>*/}
          {/*<div>totalCorrect: {totalCorrect}</div>*/}
          {/*<div>totalPositiveStroke: {totalPositiveStroke}</div>*/}
          {/*<div>totalNegativeStroke: {totalNegativeStroke}</div>*/}
          {/*<div>totalDiscount: {totalDiscount}</div>*/}
          <div className="nursing-results__container__type-container">
            <div className="nursing-results__container__type-container__title">{textTypeLong}</div>
            <div className="nursing-results__container__type-container__image-container">
              <img src={`/images/nursing/types/${typeImage}.png`}
                   width={200}
                   height={200}
              />
              <div className="nursing-results__container__type-container__image-container__bubble">
                <div className="nursing-results__container__type-container__image-container__bubble__arrow"/>{textBubble}
              </div>
            </div>
          </div>
          <div className="nursing-results__container__banner">{textBanner}</div>
          <div style={{padding: 30}}>↑スクリーンショットしてみんなにシェアしてね</div>
        </div>

        <div className="nursing-results__footer">
          {!account && (
            <>
              <div className="nursing-results__footer__sns">
                <div style={{width: 70}} className="nursing-results__footer__sns__image"/>
                <div className="d-flex flex-column">
                  <div className="nursing-results__footer__sns__title">友達や家族、恋人にもやってみよう</div>
                  <div className="d-flex flex-row justify-content-between mt-3">
                    <div>
                      <FacebookShareButton quote={textShare} url={freeUrl}>
                        <img src={'/images/nursing/facebook.svg'} width={70}/>
                      </FacebookShareButton>
                    </div>
                    <div>
                      <TwitterShareButton title={textShare} url={freeUrl}>
                        <img src={'/images/nursing/twitter.svg'} width={70}/>
                      </TwitterShareButton>
                    </div>
                  </div>

                </div>
              </div>
              <div className="nursing-results__footer__block1">{textShare} {freeUrl}</div>
            </>
          )}

          <div className="nursing-results__container__text">{textBannerText}</div>

          <div className="nursing-results__footer__message">
            <div className="nursing-results__footer__message__image"><img src={'/images/nursing/message.svg'} width={70}/></div>
            <div className="d-flex flex-column">
              <div className="nursing-results__footer__message__title">ワンポイントアドバイス</div>
              <div className="nursing-results__footer__message__description">{textAdvice}</div>
            </div>
          </div>

          {!account && (
            <Button size="lg" className="nursing-anket__action" onClick={() => window.open("https://kibi-global.jp/kidukky/", "_blank")}>
              製品ページはこちら
            </Button>
          )}
        </div>
      </div>
    );
  };

  render() {
    const {loading, data} = this.state;

    if (loading || !data) {
      return (
        <div/>
      );
    }
    return (
      <div className={this.state.isResults ? "" : "nursing"}>
      {/*<Div100vh>*/}
        {this.renderHeader()}
        <div className="d-flex" style={{ paddingTop: "40px", height: "100%", backgroundColor: "white" }}>
          {this.state.isLanding && this.renderLanding()}
          {this.state.isQuestion && this.renderQuestion()}
          {this.state.isAnket && this.renderAnket()}
          {this.state.isFinished && this.renderFinished()}
          {this.state.isResults && this.renderResults()}
        </div>
      {/*</Div100vh>*/}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    currentLang: state.root.currentLang
  };
}

export default connect(mapStateToProps)(NursingPage);
