import React, { Component } from "react";
import Div100vh from "react-div-100vh";
import { ReactComponent as Error } from "../../assets/svg/icons8-error.svg";

export default class ErrorPage extends Component {
  render() {
    return (
      <Div100vh className="d-flex justify-content-center align-items-center flex-column">
        <Error
          style={{
            minWidth: 150,
            minHeight: 150,
            display: "block",
            margin: "auto",
          }}
          className="my-4"
        />
        <h1 className="text-center">Error</h1>
      </Div100vh>
    );
  }
}
