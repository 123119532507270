import React, { Component } from "react";
import { connect } from "react-redux";
import {Button, ButtonToolbar} from "react-bootstrap";

class ManualPage extends Component {

  constructor(props, context) {
    super(props, context);

    this.state = {};
  }

  onDownload = () => {
    window.open("https://kibi-prod-uploads.s3-ap-northeast-1.amazonaws.com/public/manual/manual.pdf", "_blank")
  }

  render() {
    return (
      <ButtonToolbar className="mt-4 justify-content-between align-items-center" >
        <h1 className="m-0">管理者マニュアル</h1>
        <div>
          <Button variant="primary"
                  onClick={this.onDownload}>ダウンロード</Button>
        </div>
      </ButtonToolbar>
    )
  }
}

function mapStateToProps(state) {
  return {
    currentLang: state.root.currentLang
  };
}

export default connect(mapStateToProps)(ManualPage);
