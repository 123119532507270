export const GetContents = `
  query GetContents($first: Int, $after: String) {
    getContents(first: $first, after: $after) {
      items {
        id
        languages
        title
        description
        contentType
        settings
        comicIntroductionSvgUrl
        comicIntroduction
        comicSituationSvgUrl
        comicSituation
        comicPages
        createdAt

        practiceQuestions {
          id
          title
          svgUrl
          characters
          chats
          answers
        }

        testQuestions {
          id
          title
          svgUrl
          characters
          chats
          answers
        }
      }
      nextToken
    }
  }
`;

export const GetContent = `
  query GetContent($id: ID!) {
    getContent(id: $id) {
      id
      languages
      title
      description
      contentType
      settings
      comicIntroductionSvgUrl
      comicIntroduction
      comicSituationSvgUrl
      comicSituation
      comicPages
      createdAt
    }
  }
`;

export const GetContentSetting = `
  query GetContentSetting($id: ID!) {
    getContentSetting(id: $id) {
      id
      order
    }
  }
`;

export const CreateContent = `
  mutation CreateContent($input: ContentCreateInput!) {
    createContent(input: $input) {
      id
    }
  }
`;

export const UpdateContent = `
  mutation UpdateContent($input: ContentUpdateInput!) {
    updateContent(input: $input) {
      id
    }
  }
`;

export const DeleteContent = `
  mutation DeleteContent($id: ID!) {
    deleteContent(id: $id) {
      id
    }
  }
`;

export const UpdateContentSetting = `
  mutation UpdateContentSetting($input: ContentSettingInput!) {
    updateContentSetting(input: $input) {
      id
    }
  }
`;
