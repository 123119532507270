import { API, graphqlOperation } from "aws-amplify";
import { GetUsers, GetUser, CreateUser, CreateUserManager, UpdateUser, UpdateUserManager, DeleteUser, DisableUser, EnableUser } from "./graphql/users";

export const fetchUsers = async () => {
  const response = await API.graphql(graphqlOperation(
    GetUsers,
    {},
    "AMAZON_COGNITO_USER_POOLS"
  ));

  return response.data.getUsers;
};

export const fetchUser = async (id) => {
  const response = await API.graphql(graphqlOperation(
    GetUser,
    { id },
    "AMAZON_COGNITO_USER_POOLS"
  ));

  return response.data.getUser;
};

export const createUser = async ({userGroup, name, manager, email, password, parentId}) => {
  const response = await API.graphql(graphqlOperation(
    CreateUser,
    { input: { userGroup, name: name ? name : null, manager: manager ? manager : null, email, password, parentId: parentId ? parentId : null } },
    "AMAZON_COGNITO_USER_POOLS"
  ));

  return response.data.createUser;
};

export const createUserManager = async (input) => {
  const response = await API.graphql(graphqlOperation(
    CreateUserManager,
    { input },
    "AMAZON_COGNITO_USER_POOLS"
  ));

  return response.data.createUserManager;
};

export const updateUser = async (input) => {
  const response = await API.graphql(graphqlOperation(
    UpdateUser,
    { input },
    "AMAZON_COGNITO_USER_POOLS"
  ));

  return response.data.updateUser;
};

export const updateUserManager = async (input) => {
  const response = await API.graphql(graphqlOperation(
    UpdateUserManager,
    { input },
    "AMAZON_COGNITO_USER_POOLS"
  ));

  return response.data.updateUserManager;
};

export const deleteUser = async (id) => {
  const response = await API.graphql(graphqlOperation(
    DeleteUser,
    { id },
    "AMAZON_COGNITO_USER_POOLS"
  ));

  return response.data.deleteUser;
};

export const disableUser = async (id) => {
  const response = await API.graphql(graphqlOperation(
    DisableUser,
    { id },
    "AMAZON_COGNITO_USER_POOLS"
  ));

  return response.data.disableUser;
};

export const enableUser = async (id) => {
  const response = await API.graphql(graphqlOperation(
    EnableUser,
    { id },
    "AMAZON_COGNITO_USER_POOLS"
  ));

  return response.data.enableUser;
};
