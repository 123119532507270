export const prepareJson = (schema, array) => {
  let items = [];

  let arrayItem;
  for (arrayItem of array) {
    let item = {};
    let schemaItem;
    for (schemaItem of schema) {
      if (schemaItem.type && schemaItem.type === "Array") {
        let value = arrayItem[schemaItem.path];
        if (schemaItem.items && value && value.length > 0) {
          item[schemaItem.path] = prepareJson(schemaItem.items, value);
        } else {
          item[schemaItem.path] = [];
        }
      } else if (schemaItem.type && schemaItem.type === "JSON") {
        let value = arrayItem[schemaItem.path];
        item[schemaItem.path] = value ? JSON.parse(value) : null;
      } else {
        item[schemaItem.path] = arrayItem[schemaItem.path];
      }
    }
    items.push(item);
  }

  return items;
};

export const downloadJson = (json, filename) => {
  let data = new Blob([JSON.stringify(json)], {type: 'application/json'});

  let url = window.URL.createObjectURL(data);
  let element = document.getElementById("download");
  if (element) {
    element.parentNode.removeChild(element);
  }
  element = document.createElement("a");
  element.id = "download";
  element.style = "display: none";
  element.href = url;
  element.download = filename;
  document.body.appendChild(element);
  element.click();
  window.URL.revokeObjectURL(url);
};

export const readJson = async (file, schema) => {
 let string = await readFileAsync(file);
 if (!string || string.length === 0) return [];
 let json = JSON.parse(string);
 return parseJson(json, schema);
};

function parseJson(json, schema, languages = null) {
  let items = [];
  for (let jsonIndex = 0; jsonIndex < json.length; jsonIndex++) {
    let jsonItem = json[jsonIndex];
    if (!languages && (!jsonItem.languages || jsonItem.languages.length === 0))  throw new Error(`${jsonIndex + 1}番目のアイテムでlanguagesが必要です`);

    let itemLanguages = languages ? languages : jsonItem.languages;
    let item = {};
    if (!languages) {
      item.languages = JSON.stringify(itemLanguages);
    }
    for (let schemaIndex = 0; schemaIndex < schema.length; schemaIndex++) {
      let schemaItem = schema[schemaIndex];
      if (schemaItem.type && schemaItem.type === "Array") {
        item[schemaItem.path] = parseJson(jsonItem[schemaItem.path], schemaItem.items, itemLanguages);
      } else {
        let value = jsonItem[schemaItem.path];
        if (schemaItem.separatedLanguage) {
          let language;
          for (language of itemLanguages) {
		  console.log("json.js " + language + " value = " + value + " " + schemaItem.path);
            if (!value.hasOwnProperty(language))
		  	{
			console.log("*** error *** aaa");
		  console.log("json.js " + language + " value = " + value + " " + schemaItem.path);
				console.log(JSON.stringify(value));

			throw new Error(`${jsonIndex + 1}番目の${schemaItem.path}で言語「${language}」は登録されてません`);
			console.log("*** error *** aaa 2");
			}
            if (schemaItem.required && value[language] === null) throw new Error(`${jsonIndex + 1}番目の${schemaItem.path}で言語「${language}」の内容は必須となってます`);
          }
          item[schemaItem.path] = value;
        } else {
          if (schemaItem.byValue) {
            if (!value.hasOwnProperty("value"))
		  {
			console.log("*** error *** bbb");
		  throw new Error(`${jsonIndex + 1}番目の${schemaItem.path}で「value」は登録されてません`);
		  }
            if (schemaItem.required && value.value === null) throw new Error(`${jsonIndex + 1}番目の${schemaItem.path}で「value」の内容は必須となってます`);
            item[schemaItem.path] = value;
          } else {
            if (schemaItem.required && value === null) throw new Error(`${jsonIndex + 1}番目の${schemaItem.path}での内容は必須となってます`);
            item[schemaItem.path] = value;
          }
        }
      }
    }
    items.push(item);
  }

  return items;
}

export function readFileAsync(file, encoding = undefined) {
  return new Promise((resolve, reject) => {
    let reader = new FileReader();
    reader.onload = () => {
      resolve(reader.result);
    };
    reader.onerror = reject;
    reader.readAsText(file, encoding);
  })
}
