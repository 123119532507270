import { API, graphqlOperation } from "aws-amplify";
import { GetAllCoursesTestResults, GetAdminCoursesTestResults, GetCoursesTestResults, GetCourseTestResult, CreateCourseTestResult } from "./graphql/course-test-results";

export const fetchAllCoursesTestResults = async (userId) => {
  const response = await API.graphql(graphqlOperation(
    GetAllCoursesTestResults,
    { userId },
    "AMAZON_COGNITO_USER_POOLS"
  ));

  return response.data.getAllCoursesTestResults;
};

export const fetchAdminCoursesTestResults = async () => {
  const response = await API.graphql(graphqlOperation(
    GetAdminCoursesTestResults,
    { },
    "AMAZON_COGNITO_USER_POOLS"
  ));

  return response.data.getAdminCoursesTestResults;
};

export const fetchCoursesTestResults = async () => {
  const response = await API.graphql(graphqlOperation(
    GetCoursesTestResults,
    { },
    "AMAZON_COGNITO_USER_POOLS"
  ));

  return response.data.getCoursesTestResults;
};

export const fetchCourseTestResult = async (id) => {
  const response = await API.graphql(graphqlOperation(
    GetCourseTestResult,
    { id },
    "AMAZON_COGNITO_USER_POOLS"
  ));

  return response.data.getCourseTestResult;
};

export const createCourseTestResult = async (input) => {
  const response = await API.graphql(graphqlOperation(
    CreateCourseTestResult,
    { input },
    "AMAZON_COGNITO_USER_POOLS"
  ));

  return response.data.createCourseTestResult;
};
