import * as nursingResultsMiddleware from "./results";
import * as csvService from "../csv";
import {Auth} from "aws-amplify";

export const build = async ({nursingResults, newestOnly, parentCheck}) => {

  let items = [];
  if (newestOnly) {
    const ordered = nursingResults.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
    const filtered = [];
    const user = await Auth.currentAuthenticatedUser();
    ordered.forEach(item => {
      if (!item.user) {
        return;
      }
      if (parentCheck) {
        if (item.user.parentId !== user.getUsername()) {
          return;
        }
      }
      if (filtered.findIndex(filteredItem => filteredItem.user.id === item.user.id) !== -1) {
        return;
      }
      filtered.push(item)
    })
    items = [...filtered]
  } else {
    items = [...nursingResults]
  }

  const schema = [
    {label: "受講者番号", path: "id"},
    {label: "受講者名漢字", path: "nickname"},
    {label: "受講者名かな", path: "nicknameKana"}, // ?
    {label: "タイプNO.", path: "numberType"}, // ?
    {label: "タイプ名", path: "textType"},
    {label: "正の点数", path: "totalPositiveStroke", type: "number"},
    {label: "負の点数", path: "totalNegativeStroke", type: "number"},
    {label: "Dの点数", path: "totalDiscount", type: "number"},
    {label: "状況想像力点数", path: "totalImagination", type: "number"},
    {label: "リアクション力点数", path: "totalReaction", type: "number"},
    {label: "共感力点数", path: "totalEmpathy", type: "number"},
    {label: "気持ち察し力点数", path: "totalFeeling", type: "number"},
    {label: "柔軟性点数", path: "totalFlexibility", type: "number"},
  ];

  let data = [];

  for (let testIndex = 0; testIndex < items.length; testIndex++) {
    const item = items[testIndex];
    const nickname = item.user && item.user.name ? item.user.name : null ;
    const {
      textTypeNumber,
      textType,
      totalPositiveStroke,
      totalNegativeStroke,
      totalDiscount,
      totalFlexibility,
      totalImagination,
      totalReaction,
      totalEmpathy,
      totalFeeling,
    } = nursingResultsMiddleware.buildResult({results: item.results && item.results.items ? item.results.items : [] , id: item.id, isFree: false, nickname})

    data.push({
      id: item.userId,
      nickname,
      nicknameKana: null,
      numberType: textTypeNumber,
      textType,
      totalPositiveStroke,
      totalNegativeStroke,
      totalDiscount,
      totalImagination,
      totalReaction,
      totalEmpathy,
      totalFeeling,
      totalFlexibility,
    });
  }

  return csvService.prepareCsv(schema, data);
}

export default build
