import {
  SET_COMIC_LIFE_IDS,
  SET_COMIC_SPIRIT_IDS,
  SET_COMIC_LIFE_INTRO,
  SET_FULL_LOADING,
  SET_KIBIIN_NOTIFICATIONS,
  SET_LANG,
  SET_NOTIFICATIONS,
  SET_READ_NOTIFICATIONS
} from "./actionTypes";
import * as commonService from "../../services/common";
const initialState = {
  fullLoading: false,
  // langs,
  currentLang: commonService.mainLanguage,
  comicLifeIntro: true,
  comicLifeIds: [],
  comicSpiritIds: [],
  notifications: {
    list: [],
    kibiin: [],
    readed: [],
  }
};

const rootReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_FULL_LOADING:
      return Object.assign({}, state, {
        fullLoading: action.loading
      });
    case SET_LANG:
      return Object.assign({}, state, {
        currentLang: action.lang
      });
    case SET_COMIC_LIFE_INTRO:
      return Object.assign({}, state, {
        comicLifeIntro: action.intro
      });
    case SET_COMIC_LIFE_IDS:
      return Object.assign({}, state, {
        comicLifeIds: action.ids
      });
    case SET_COMIC_SPIRIT_IDS:
      return Object.assign({}, state, {
        comicSpiritIds: action.ids
      });
    case SET_NOTIFICATIONS:
      return { ...state, notifications: { ...state.notifications, list: action.list } };
    case SET_KIBIIN_NOTIFICATIONS:
      return { ...state, notifications: { ...state.notifications, kibiin: action.kibiin } };
    case SET_READ_NOTIFICATIONS:
      return { ...state, notifications: { ...state.notifications, readed: action.readed } };
    default: {
      return state;
    }
  }
};

export default rootReducer;
