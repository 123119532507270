import { API, graphqlOperation } from "aws-amplify";
import { GetTestQuestions, GetTestQuestion, CreateTestQuestion, UpdateTestQuestion, DeleteTestQuestion } from "./graphql/test-questions";

export const fetchTestQuestions = async (contentId) => {
  const response = await API.graphql(graphqlOperation(
    GetTestQuestions,
    { contentId },
    "AMAZON_COGNITO_USER_POOLS"
  ));

  return response.data.getTestQuestions;
};

export const fetchTestQuestion = async (id) => {
  const response = await API.graphql(graphqlOperation(
    GetTestQuestion,
    { id },
    "AMAZON_COGNITO_USER_POOLS"
  ));

  return response.data.getTestQuestion;
};

export const createTestQuestion = async ({id, contentId, title, subtitle, svgUrl, characters, chats, answers}) => {
  const response = await API.graphql(graphqlOperation(
    CreateTestQuestion,
    { input: { id, contentId, title, subtitle, svgUrl , characters, chats, answers} },
    "AMAZON_COGNITO_USER_POOLS"
  ));

  return response.data.createTestQuestion;
};

export const updateTestQuestion = async ({id, title, subtitle, svgUrl, characters, chats, answers}) => {
  const response = await API.graphql(graphqlOperation(
    UpdateTestQuestion,
    { input: { id, title, subtitle, svgUrl , characters, chats, answers} },
    "AMAZON_COGNITO_USER_POOLS"
  ));

  return response.data.updateTestQuestion;
};

export const deleteTestQuestion = async (id) => {
  const response = await API.graphql(graphqlOperation(
    DeleteTestQuestion,
    { id },
    "AMAZON_COGNITO_USER_POOLS"
  ));

  return response.data.deleteTestQuestion;
};
