import React, { Component } from "react";
import {} from "react-bootstrap";
import { ReactComponent as PictureSVG } from "../../assets/svg/icons8-picture.svg";

export class PictureElement extends Component {
  render() {
    return (
      <div
        style={{
          ...this.props.style,
          width: "100%",
          backgroundColor: "white"
        }}
        className="d-flex justify-content-center align-items-center rounded"
      >
        {!this.props.src && (
          <PictureSVG
            style={{
              minWidth: this.props.iconWidth,
              minHeight: this.props.iconHeight
            }}
          />
        )}

        {this.props.src && (
          <img
            src={this.props.src}
            style={{
              width: "100%"
              // minWidth: this.props.iconWidth,
              // minHeight: this.props.iconHeight,
              // maxWidth: this.props.maxWidth,
              // maxHeight: this.props.maxHeight,
            }}
            alt="イラスト"
          />
        )}
      </div>
    );
  }
}

PictureElement.defaultProps = {
  iconWidth: 300,
  iconHeight: 300,
  maxWidth: 300,
  maxHeight: 300,
};
