import { API, graphqlOperation } from "aws-amplify";
import { MutsationTestCSVLayoutC, MutsationTestCSVLayoutCNew } from "./graphql/test-csv";

export const mutsationTestCSVLayoutC = async (input) => {
  const response = await API.graphql(graphqlOperation(
    MutsationTestCSVLayoutC,
    { input },
    "AMAZON_COGNITO_USER_POOLS"
  ));

  return response.data.testCSVLayoutC;
};

export const mutsationTestCSVLayoutCNew = async (input) => {
  const response = await API.graphql(graphqlOperation(
    MutsationTestCSVLayoutCNew,
    { input },
    "AMAZON_COGNITO_USER_POOLS"
  ));

  return response.data.testCSVLayoutCNew;
};