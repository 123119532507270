import { Auth } from 'aws-amplify';

export default class PermissionsGuard {

  static async validate() {
    try {
      if (await Auth.currentAuthenticatedUser() != null) {
        return true;
      }
    } catch (e) {
      // do nothing
    }
    return false;
  }

  static async redirectOnError() {
    return "/login"
  }

};
