import React, { Component } from "react";
import { Modal, Button } from "react-bootstrap";
import { PictureElement } from "../../components/elements/PictureElement";
import * as commonService from "../../services/common";

export class PracticeAnswerModal extends Component {

  formatByLanguage = (value) => {
    let array = typeof value === "string" ? JSON.parse(value) : value;
    if (array[this.props.currentLang]) {
      return array[this.props.currentLang];
    } else {
      return array[commonService.mainLanguage];
    }
  };

  render() {
    return (
      <Modal show={this.props.show} onHide={this.props.onClose} backdrop="static">
        <Modal.Header>
          <Modal.Title>答えは？</Modal.Title>
        </Modal.Header>

        <Modal.Body style={{backgroundColor: "#FAFAFA"}}>
          {this.props.answers.map(
            (answer, index) => (
              <div
                key={index}
                className="cursor-hover answer__item"
                onClick={() => this.props.onOk(answer)}
              >
                <PictureElement
                  iconWidth={100}
                  iconHeight={100}
                  maxWidth={100}
                  maxHeight={164}
                  style={{ minHeight: 164 }}
                  src={answer.image.value}
                />
                <div className="answer__item__texts">
                  {answer.reading && (<div className="reading">{this.formatByLanguage(answer.reading)}</div>)}
                  <div>{this.formatByLanguage(answer.text)}</div>
                </div>
              </div>
            )
          )}
        </Modal.Body>

        <Modal.Footer>
          <Button variant="flat" onClick={this.props.onClose}>
            キャンセル
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

PracticeAnswerModal.defaultProps = {
  show: false,
  onClose: () => {}
};
