export const GetNotifications = `
  query GetNotifications($first: Int, $after: String) {
    getNotifications(first: $first, after: $after) {
      items {
        id
        type
        userId
        userGroup
        avatar
        title
        description
        createdAt
      }
      nextToken
    }
  }
`;

export const GetNotification = `
  query GetNotification($id: ID!) {
    getNotification(id: $id) {
        id
        type
        userId
        userGroup
        avatar
        title
        description
        createdAt
    }
  }
`;

export const CreateNotification = `
  mutation CreateNotification($input: NotificationCreateInput!) {
    createNotification(input: $input) {
      id
    }
  }
`;

export const UpdateNotification = `
  mutation UpdateNotification($input: NotificationUpdateInput!) {
    updateNotification(input: $input) {
      id
    }
  }
`;


export const DeleteNotification = `
  mutation DeleteNotification($id: ID!) {
    deleteNotification(id: $id) {
        id
    }
  }
`;
