import React, { Component } from "react";
import {ProgressBar, Button, Badge} from "react-bootstrap";
import { connect } from "react-redux";
import uuid from "uuid";
import MainContainer from "../../../../components/containers/MainContainer";
import QuestionChatItem from "../../../../components/elements/QuestionChatItem";
import * as contentsService  from "../../../../services/contents";
import * as courseTestResultsService  from "../../../../services/course-test-results";
import * as commonService  from "../../../../services/common";
import * as accountService from "../../../../services/account";
import * as testQuestionsService from "../../../../services/test-questions";
import "./index.scss";

class CourseTestPage extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      accountSettings: {},
      course: null,
      questions: [],
      questionsCount: 0,
      questionIndex: -1,
      question: null,
      answers: [],
      chats: [],
      characters: [],
      results: [],
      loading: false,
    };
  }

  async componentDidMount() {
    const {courseId, questionId} = this.props.match.params;

    await this.fetchQuestions(courseId, questionId);
  }

  async fetchQuestions(courseId, questionId = null) {
    this.setState({loading: true});
    let state = {};

    let accountSettingsResponse = await accountService.fetchAccountSetting();
    state.accountSettings = accountSettingsResponse ? JSON.parse(accountSettingsResponse) : {};

    state.course = await contentsService.fetchContent(courseId);
    let testQuestionsResponse = await testQuestionsService.fetchTestQuestions(courseId);
    let questions = testQuestionsResponse.items;
    state.questions = commonService.shuffleArr(questions);
    state.questionsCount = questions.length;

    if (questionId) {
      let questionIndex = questions.findIndex(question => question.id === questionId);
      state.questionIndex = questionIndex;
      state.question = questions[questionIndex];
      state.answers = JSON.parse(state.question.answers);
      state.chats = JSON.parse(state.question.chats);
      state.characters = JSON.parse(state.question.characters);
    } else {
      let questionsState = await this.prepareQuestions(state.questions, courseId);
      state = {...state, ...questionsState};
    }

    state.loading = false;

    this.setState( state );
  }

  prepareQuestions = async (questions) => {
    let state = {};

    if (this.state.questionIndex === -1) {
      state.questionIndex = 0;
    } else {
      state.questionIndex = this.state.questionIndex + 1;
    }

    if (questions.length === 0) { return state; }

    state.question = questions[state.questionIndex];
    state.answers = JSON.parse(state.question.answers);
    state.chats = JSON.parse(state.question.chats);
    state.characters = JSON.parse(state.question.characters);

    return state;
  };

  onClickAnswer = async (answer) => {
    let answerIndex = this.state.answers.indexOf(answer);

    let results = this.state.results;
    results.push({
      questionId: this.state.question.id,
      answerId: answer.id,
      isCorrect: !!(this.state.answers[answerIndex].isCorrect && this.state.answers[answerIndex].isCorrect.value === "1")
    });

    if (this.state.questionIndex + 1 < this.state.questionsCount) {
      let state = await this.prepareQuestions(this.state.questions);
      state.results = results;
      this.setState( state );
    } else {
      this.setState({ loading: true });
      let correctAnswers = 0;
      results.forEach((result) => {
        if (result.isCorrect) {
          correctAnswers += 1;
        }
      });
      let storeResults = {
        totalAnswers: results.length,
        correctAnswers,
        answers: results
      };
      try {
        let input = {
          id: uuid.v4(),
          courseId: this.state.course.id,
          results: JSON.stringify(storeResults),
        };
        await courseTestResultsService.createCourseTestResult(input);
        this.setState({ loading: false });
        this.props.history.push(`/course/${this.state.course.id}/result/${input.id}`);
      } catch (e) {
        console.log(e);
        this.setState({ loading: false });
      }
    }
  };

  onClickBack = () => {
    const {testId} = this.props.match.params;
    this.props.history.push(`/course/${this.state.course.id}/result/${testId}`);
  };

  onClickFinish = () => {
    this.props.history.push(`/`);
  };

  formatByLanguage = (value) => {
    try {
      let array = typeof value === "string" ? JSON.parse(value) : value;
      if (array[this.props.currentLang]) {
        return array[this.props.currentLang];
      } else {
        return "Undefined"; //array[commonService.mainLanguage];
      }
    } catch (e) {
      return "Undefined";
    }
  };

  renderHeader = () => {
    const {testId} = this.props.match.params;
    const {
      course,
      question,
      questionsCount,
      questionIndex,
      isDescription
    } = this.state;

    let now = Math.floor(100 / questionsCount) * (questionIndex + (isDescription ? 1 : 0));

    return (
      <div className="lesson__header">
        {course && question && (
          <div className="lesson__header__text">
            {this.formatByLanguage(course.title)}<span className="secondary">{" "}
            {this.formatByLanguage(question.title)}</span>
          </div>
        )}
        {!testId && questionsCount && <ProgressBar now={now} label={`${now}%`} />}
        {testId && (
          <>
            <Button
              onClick={this.onClickBack}
              className="ml-auto"
              variant="outline-primary"
            >
              戻る
            </Button>
            <Button
              onClick={this.onClickFinish}
              className="ml-2"
              variant="primary"
            >
              終了
            </Button>
          </>
        )}
      </div>
    );
  };

  renderQuestion = () => {
    const {testId, answerId} = this.props.match.params;
    const {
      question,
      answers,
      chats,
      characters,
    } = this.state;

    let answerIndex= answerId != null && answerId!= undefined ? answers.findIndex(item => item.id === answerId) : -1;
    let selectedAnswer = answerIndex !== -1 ? answers[answerIndex] : null;
    let suffleAnswers = commonService.shuffleArr(answers);

    return (
      <>
        <div className="test-question">
          <img src={question.svgUrl} alt="問題イラスト" />
          <div className="test-question__chat">
            {chats.map(
              (chatItem, index) => (
                <QuestionChatItem
                  key={index}
                  characterId={chatItem.character.value}
                  characters={characters}
                  reading={chatItem.reading}
                  text={chatItem.text}/>
              ))}
          </div>
        </div>
        <div className="mt-3 mb-2 text-center">
          <strong>{this.formatByLanguage(question.subtitle)}</strong>
        </div>
        <div className="my-3">
          {suffleAnswers.map((answer, index) => (
            <Button
              key={index}
              onClick={this.onClickAnswer.bind(this, answer)}
              block
              className="my-3"
              variant={selectedAnswer && answer.isCorrect && answer.isCorrect.value ? "success" : "outline-dark"}
              disabled={!!testId}
            >
              {this.formatByLanguage(answer.text)}
              {selectedAnswer && answer.id === selectedAnswer.id && <Badge variant="secondary">選択済</Badge>}
            </Button>
          ))}
        </div>
      </>
    );
  };

  renderNotFound = () => {
    return (
      <div className="d-flex justify-content-center align-content-center mt-4">
        テストは見つかりませんでした
      </div>
    );
  };

  render() {
    return (
      <MainContainer isTest={true} isLoading={this.state.loading}>
        {!this.state.loading && this.state.question && (
          <>
            {this.renderHeader()}
            <div className="content">
              {this.renderQuestion()}
            </div>
          </>
        )}
        {!this.state.loading && !this.state.question && this.renderNotFound()}
      </MainContainer>
    );
  }
}

function mapStateToProps(state, props) {
  return {
    currentLang: state.root.currentLang
  };
}

export default connect(mapStateToProps)(CourseTestPage);
