import React, { Component } from "react";
import { connect } from "react-redux";
import {ButtonToolbar, Button, ListGroup} from "react-bootstrap";
import * as commonService from "../../../services/common";
import * as notificationsService from "../../../services/notifications";

class TestBbsPage extends Component {

  constructor(props, context) {
    super(props, context);

    this.state = {
      loading: true,
      error: null,
      items: [],
    };
  }

  async componentWillMount() {
    await this.fetchList();
  }

  fetchList = async () => {
    this.setState({ loading: true, error: null });
    try {
      let response = await notificationsService.fetchNotifications();
      let items = response.items.filter(item => true);
      items = items.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
      this.setState({ items: items, loading: false });
    } catch (e) {
      console.error(e);
      this.setState({ loading: true, error: e });
    }
  };

  onCreateClick = () => {
	  console.log("*** onCreateClick ***");
    this.props.history.push("/admin/testbbs/new");
  };

  onEditClick = (item) => {
	  console.log("*** onEditClick ***");
    this.props.history.push(`/admin/testbbs/${item.id}`);
  };

  render() {
    const {items} = this.state;
	  console.log("*** render() *** Jul/06/2022");
    return (
      <>
        <ButtonToolbar className="mt-4 justify-content-between align-items-center">
          <h1 className="m-0">お知らせ一覧 Jul/25/2022 AM 08:04</h1>
          <div className="d-flex">
            <Button variant="primary" onClick={this.onCreateClick}>新規制作</Button>
          </div>
        </ButtonToolbar>

        <ListGroup className="mt-3">
          {items.map(item => (
            <ListGroup.Item key={item.id} className="d-flex justify-content-between align-items-center pointer" onClick={() => this.onEditClick(item)}>
                {item.type === 'GENERAL' && <div>{item.title}</div>}
                {item.type === 'KIBIIN' && (
                  <div>
                    <div><strong>キービン</strong></div>
                    <div style={{whiteSpace: 'pre-wrap'}}>{item.description}</div>
                  </div>
                )}
                <div>{commonService.formatDate(item.createdAt)}</div>
            </ListGroup.Item>
          ))}
          {this.state.items.length === 0 && (
            <ListGroup.Item>
              何も見つかりませんでした
            </ListGroup.Item>
          )}
        </ListGroup>
      </>
    )
  };
}

function mapStateToProps(state) {
  return {};
}

export default connect(mapStateToProps)(TestBbsPage);
